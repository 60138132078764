(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.factory:Statistics
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .factory('Statistics', Statistics);

  function Statistics(ycoResource) {
    var url = 'stats/'
      , StatisticsBase;

    StatisticsBase = ycoResource('statistics', url, {}, {
      getBasicStatistics: {
        method: 'GET',
        url: url + 'basic',
        cache: false
      },
      getMentorStatistics: {
        method: 'GET',
        url: url + 'mentor',
        cache: false
      },
      getStatFileData: {
        method: 'GET',
        url: url + 'overallMonthlyStats',
        cache: false
      }
    });

    return StatisticsBase;
  }
}());
