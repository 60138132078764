(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.constant:enquiryStatuses
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .constant('enquiryStatuses', {
      NEW: {
        name: 'newEnquiry',
        label: 'Új',
        class: 'badge-info'
      },
      WAITING_FOR_APPROVEMENT: {
        name: 'waitingForApprovement',
        label: 'Elfogadásra vár',
        class: 'badge-warning'
      },
      APPROVED: {
        name: 'approved',
        label: 'Elfogadott',
        class: 'badge-success'
      },
      REJECTED: {
        name: 'rejected',
        label: 'Elutasított',
        class: 'badge-default'
      }
    });
}());
