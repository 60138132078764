(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(statistics, mentorStatistics, unreadThreads, newEnquiries, EnquiryService, user, UserRole, User, Statistics, $window) {
    var vm = this;

    vm.stats = statistics.data;
    vm.mentorStats = mentorStatistics.data;
    vm.unreadThreads = unreadThreads.data;
    vm.newEnquiries = newEnquiries.data;
    vm.user = user.data;
    vm.userRoles = UserRole.getUserRoles();
    vm.showStatFileLoader = false;

    if (vm.user.roleId === vm.userRoles.DOCTOR.id) {
      User.getChildrenByDoctor({userId: vm.user.id}).$promise
        .then(function (children) {
          vm.childrenByDoctor = children.data;
        });
    }

    if (vm.user.roleId === vm.userRoles.PARENT.id) {
      User.get().$promise
        .then(function (users) {
          vm.users = users.data;
        });
    }

    vm.getEnquiryStatus = function getEnquiryStatus(statusName) {
      var status = EnquiryService.getStatusByStatusName(statusName);
      return '<span class="badge ' + status.class + '">' + status.label + '</span>';
    };

    vm.getUserRoleById = function (roleId) {
      var role = UserRole.getUserRoleById(roleId).name.singular;
      return '<span class="badge">' + role + '</span>';
    };

    vm.processStatFile = function processStatFile() {
      vm.showStatFileLoader = true;
      Statistics.getStatFileData().$promise
        .then(function (data) {
          vm.statFileData = data.data;
          vm.showStatFileLoader = false;
          $window.open(
            data.data.url,
            '_self'
          );
        })
        .catch(function (err) {
          console.error(err.message);
          vm.showStatFileLoader = false;
        });
    };
  }
}());
