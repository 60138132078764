<nav id="sidebar" class="yco-sidebar">
  <div id="sidebar-scroll">
    <div class="sidebar-content">
      <div class="side-header side-content bg-white-op">
        <a class="h5 text-white" ui-sref="dashboard">
          <img class="yco-sidebar__logo" src="images/logo_symbol.svg" alt="Koraszülött Mentor Program">
        </a>
      </div>

      <div class="side-content side-content-full">
        <ul class="nav-main" data-js-main-nav>
          <li class="nav-main-heading">Menü</li>
          <li>
            <a ui-sref="dashboard" ui-sref-active="active">
              <i class="fa fa-fw fa-tachometer"></i>Áttekintés
            </a>
          </li>
          <a ui-sref="messages" ui-sref-active="active">
            <i class="fa fa-fw fa-envelope"></i>Üzenetek
            <span ng-if="ycoSidebar.unreadThreads.data.length"
                  class="badge">
              {{ycoSidebar.unreadThreads.data.length}}
            </span>
          </a>
          </li>
          <li
            ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id"
            class="nav-main-heading">Résztvevők
          </li>
          <li>
            <a ui-sref="listChildren"
               ui-sref-active="active">
              Mentoráltak
            </a>
          </li>
          <li ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.PARENT.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.PARENT.name.plural}}
            </a>
          </li>
          <li
            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id
                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.EARLY_DEV.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.EARLY_DEV.name.plural}}
            </a>
          </li>
          <li
            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id
                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.DOCTOR.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.DOCTOR.name.plural}}
            </a>
          </li>
          <li ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.LEAD_DOCTOR.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.LEAD_DOCTOR.name.plural}}
            </a>
          </li>
          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.MENTOR.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.MENTOR.name.plural}}
            </a>
          </li>
          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">
            <a ui-sref="listUsers({role: '{{::ycoSidebar.userRoles.ADMIN.slug.plural}}'})"
               ui-sref-active="active">
              {{::ycoSidebar.userRoles.ADMIN.name.plural}}
            </a>
          </li>
          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">
            <a ui-sref="listUsers({role: ''})"
               ui-sref-active="active">
              Összes felhasználó
            </a>
          </li>
          <li class="nav-main-heading">Mentor Program</li>
          <li
            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id
                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">
            <a ui-sref="listEnquiries"
               ui-sref-active="active">
              <i class="fa fa-pencil-square-o"></i>Regisztrációk kezelése
            </a>
          </li>
          <li>
            <a ui-sref="listEvents" ui-sref-active="active">
              <i class="fa fa-fw fa-calendar"></i>Események
            </a>
          </li>
          <li>
            <a ui-sref="listPublications()" ui-sref-active="active">
              <i class="fa fa-fw fa-book"></i>Kiadványok
            </a>
          </li>
          <li class="nav-main-heading">Fiók</li>
          <li>
            <a ui-sref="viewUser({userId: 'me'})" ui-sref-active="active">
              <i class="fa fa-fw fa-user"></i>Profil
            </a>
          </li>
          <li>
            <a ui-sref="logout">
              <i class="fa fa-fw fa-sign-out"></i>Kijelentkezés
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
