(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:EditUserCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('EditUserCtrl', EditUserCtrl);

  function EditUserCtrl(user, FormControl, $q, $state, User, _, YcoDialog, UserRole, loggedUser, userRoles, $scope, toastr, countyConstant) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('Felhasználói adatok sikeresen frissítve!');

    vm.form = {};
    vm.user = user.data;
    vm.role = UserRole.getUserRoleById(vm.user.roleId);
    vm.availableRoles = UserRole.getUserRoles();
    vm.isCurrentUserAdmin = loggedUser.data.roleId === userRoles.ADMIN.id;
    vm.isOwnAccount = user.data.id === loggedUser.data.id;
    vm.loggedUser = loggedUser.data;
    vm.possibleMentors = [];
    vm.canNavigate = false;
    vm.showPassword = false;
    vm.countyList = countyConstant;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , userResource = _.defaultsDeep(new User(), vm.user);

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('viewUser', {userId: vm.user.id});
        });

      userResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deleteUser = function deleteUser() {
      var deferred = $q.defer()
        , userResource = _.defaultsDeep(new User(), vm.user);

      YcoDialog.show({
        title: 'Valóban törli a kijelölt felhasználót?',
        content: 'A törléssel a felhasználó adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        userResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          $state.go('listUsers');
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });
    };

    if (vm.user.mentor) {
      vm.possibleMentors.push(vm.user.mentor.data);

      vm.user.mentorId = vm.user.mentor.data.id;
    }

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.togglePassword = function togglePassword() {
      vm.showPassword = !vm.showPassword;
    }
  }
}());
