(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:ListEventsCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEvent')
    .controller('ListEventsCtrl', ListEventsCtrl);

  function ListEventsCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, $uibModal, Event, toastr, LoggedUser, loggedUser, userRoles) {
    var vm = this;

    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;
    vm.showExpired = false;

    vm.viewEvent = function viewEvent(eventId) {
      $uibModal.open({
        templateUrl: 'yco-event/views/view-event.tpl.html',
        controllerAs: 'viewEvent',
        controller: 'ViewEventCtrl',
        resolve: {
          event: function () {
            return Event.getEventWithParticipants({id: eventId}).$promise;
          },
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      });
    };


    vm.dtInstance = {};

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'events',
      scope: $scope,
      dynamicParams: function () {
        return {
          withExpired: vm.showExpired
        }
      }
    }).withOption('order', [2, 'desc']);

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('name').withTitle('Név')
      , DTColumnBuilder.newColumn('date').withTitle('Dátum')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn('location').withTitle('Helyszín')
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<button class="btn btn-default btn-xs push-5-r"' +
            'ng-click="listEvents.viewEvent(' + data.id + ')" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</button>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="listEvents.loggedUser.roleId === listEvents.availableRoles.ADMIN.id"' +
            'ui-sref="editEvent({eventId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];

    vm.toggleEvents = function toggleEvents() {
      vm.dtInstance.reloadData();
    }
  }
}());
