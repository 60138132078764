(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childBasicFields
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childBasicFields', {
      FIRSTNAME: {
        name: 'firstName',
        label: 'Keresztnév'
      },
      LASTNAME: {
        name: 'lastName',
        label: 'Vezetéknév'
      },
      PARENT: {
        name: 'parentId',
        label: 'Szülő / Gondviselő'
      },
      MOTHERNAME: {
        name: 'motherName',
        label: 'Anyja neve'
      },
      BIRTH_WEEK: {
        name: 'birthWeek',
        label: 'Születési hét'
      },
      BIRTH_WEIGHT: {
        name: 'birthWeight',
        label: 'Születési súly'
      },
      BIRTH_DATE: {
        name: 'dateOfBirth',
        label: 'Születési dátum'
      },
      BIRTH_YEAR: {
        name: 'birthYear',
        label: 'Születési év'
      },
      IMAGE: {
        name: 'imageId',
        label: ''
      },
      ADDRESS: {
        name: 'address',
        label: 'Cím'
      }
    });
}());
