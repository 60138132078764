<form name="rejectEnquiry.form" ng-submit="rejectEnquiry.submit()" novalidate>
  <div class="modal-header">
    <h3>Regisztáció elutasítása</h3>
  </div>
  <div class="modal-body">
    <p>Az elutasítás alkalmával a regisztrációt véglegesen és visszafordíthatatlanul töröljük a rendszerből. Kérjük,
      indokolja meg a regisztráció elutasítását az alábbi mezőben. Az elutasításról és annak indoklásáról a
      regisztrációt kezdeményező szülő/gondviselő emailes értesítést kap.</p>

    <div class="form-group">
      <yco-input-container>
        <label>Indoklás</label>
        <textarea class="yco-enquiry__modal__reject__message form-control"
                  name="statusMessage"
                  rows="5"
                  ng-model="rejectEnquiry.statusMessage"
                  ng-minlength="5"
                  required>
      </textarea>
        <yco-messages form="rejectEnquiry.form" server="rejectEnquiry.getServerValidation()"
                      field-name="statusMessage"></yco-messages>
      </yco-input-container>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="rejectEnquiry.close()">
      Mégse
    </button>
    <yco-button class="btn btn-danger"
                type="submit"
                yco-is-processing="rejectEnquiry.isProcessing">
      Elutasítás
    </yco-button>
  </div>
</form>
