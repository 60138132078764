(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoNavigation.directive:ycoHeader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoNavigation">
   <file name="index.html">
   <yco-header></yco-header>
   </file>
   </example>
   *
   */
  angular
    .module('ycoNavigation')
    .directive('ycoHeader', ycoHeader);

  function ycoHeader(appConfig) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'yco-navigation/directives/yco-header-directive.tpl.html',
      replace: false,
      controllerAs: 'ycoHeader',
      controller: function (LoggedUser) {
        var vm = this;

        vm.user = LoggedUser.getUser();
        vm.appConfig = appConfig;
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
