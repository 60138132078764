<form name="newThread.form" ng-submit="newThread.submit()" novalidate>
  <div class="modal-header">
    Új üzenetváltás
  </div>
  <div class="modal-body">
    <yco-input-container>
      <label>
        Címzett(ek)
        <i class="fa fa-question-circle text-muted push-5-l"
           uib-tooltip="Üzenetéhez több címzettet is kiválaszthat. Kezdje el begépelni a kívánt címzett nevét, majd
           miután rendszerünk felkínálta a találatokat, válasszon egy lehetőséget a lenyíló listából."
           tooltip-placement="right"
           tooltip-append-to-body="true"></i>
      </label>
      <ui-select class="yco-messages__new-thread__autocomplete"
                 multiple
                 required
                 name="participantIds"
                 ng-disabled="newThread.hasRecipient"
                 ng-model="newThread.thread.participantIds">
        <ui-select-match>{{$item.lastName}} {{$item.firstName}}</ui-select-match>
        <ui-select-choices repeat="user.id as user in newThread.possibleParticipants track by $index"
                           refresh="newThread.refreshPossibleParticipants($select.search)"
                           refresh-delay="200"
                           minimum-input-length="3">
          <div class="yco-messages__new-thread__autocomplete__suggestion media">
            <div class="media-left">
              <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                   alt="{{user.lastName}} {{user.firstName}}">
            </div>
            <div class="media-right">
              <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

              <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
            </div>
            <div class="yco-messages__new-thread__autocomplete__suggestion__email">
              <span>{{user.email}}</span>
            </div>
          </div>
        </ui-select-choices>
      </ui-select>
      <yco-messages form="newThread.form" server="newThread.getServerValidation()"
                    field-name="participantIds"></yco-messages>
    </yco-input-container>

    <yco-input-container>
      <label>
        Tárgy
      </label>
      <input class="form-control" type="text" name="subject" ng-model="newThread.thread.subject" required>
      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="subject"></yco-messages>
    </yco-input-container>

    <yco-input-container>
      <label>
        Kapcsolódó profil (opcionális)
        <i class="fa fa-question-circle text-muted push-5-l"
           uib-tooltip="Lehetősége van mentorált profilt csatolni az üzenethez. A csatolt profil az üzenetváltás
           többi résztvevője számára is megjelenik, így kontextusba helyezve az adott mentoráltról szóló
           párbeszédet."
           tooltip-placement="right"
           tooltip-append-to-body="true"></i>
      </label>
      <ui-select append-to-body="true"
                 class="yco-messages__new-thread__autocomplete"
                 ng-model="newThread.thread.childId">
        <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}
        </ui-select-match>
        <ui-select-choices repeat="child.id as child in newThread.possibleChildren track by $index"
                           refresh="newThread.refreshPossibleChildren($select.search)"
                           refresh-delay="200"
                           minimum-input-length="3">
          <div class="yco-messages__new-thread__autocomplete__suggestion media">
            <div class="media-left">
              <img class="media-object img-avatar img-avatar32" ng-src="{{child.image}}"
                   alt="{{child.lastName}} {{child.firstName}}">
            </div>
            <div class="media-right">
              <h5 class="media-heading">{{child.lastName}} {{child.firstName}}</h5>

              <div>Szülő: {{child.parent.data.lastName}} {{child.parent.data.firstName}}</div>
            </div>
          </div>
        </ui-select-choices>
      </ui-select>
      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="childId"></yco-messages>
    </yco-input-container>

    <yco-input-container>
      <label>Üzenet</label>
      <textarea class="form-control" name="message" ng-model="newThread.thread.message" rows="5"
                placeholder="Írja be üzenetét" required></textarea>
      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="message"></yco-messages>
    </yco-input-container>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="newThread.close()">
      Bezárás
    </button>
    <yco-button class="btn btn-success" yco-is-processing="newThread.isProcessing()">Küldés</yco-button>
  </div>
</form>
