(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.service:LoggedUser
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .service('LoggedUser', LoggedUser);

  function LoggedUser(User) {
    var user;

    this.getUser = function getUser() {
      return user;
    };

    this.setLoggedUser = function setLoggedUser() {
      user = User.getLoggedInUser();
    };

    this.setLoggedUser();
  }
}());
