(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:EditEventCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEvent')
    .controller('EditEventCtrl', EditEventCtrl);

  function EditEventCtrl(event, FormControl, Event, YcoDialog, _, $q, $state, toastr, moment, $scope, UserRole) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.event = event.data;
    vm.participants = vm.event.participants.data;
    vm.dateTime = new Date(vm.event.date);
    vm.dateFormat = 'yyyy-MM-dd';
    vm.availableRoles = UserRole.getUserRoles();
    vm.mailtoParticipantsLink = getMailtoParticipantsLink();

    vm.event.canApply = vm.event.canApply ? 1 : 0;
    vm.event.public = vm.event.public ? 1 : 0;

    vm.updateEventDate = function (data) {
      vm.event.date = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.hasParticipants = function hasParticipants() {
      return vm.participants.length > 0;
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , eventResource;

      if (vm.form.$invalid) {
        return;
      }

      vm.event.participants.data = vm.participants;
      eventResource = _.defaultsDeep(new Event(), vm.event);

      formControl
        .process(deferred.promise)
        .then(function () {
          toastr.success('Az esemény mentése sikerült.');
        });

      eventResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deleteEvent = function deleteEvent() {
      var deferred = $q.defer()
        , eventResource = _.defaultsDeep(new Event(), vm.event);

      YcoDialog.show({
        title: 'Valóban törli a kijelölt eseményt?',
        content: 'A törléssel az esemény adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        eventResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          $state.go('listEvents');
        });
    };

    vm.revokeApplication = function revokeApplication(userId) {
      Event.deleteParticipantFromAnEvent({id: vm.event.id, userId: userId}).$promise
        .then(function () {
          return Event.getEventWithParticipants({id: vm.event.id}).$promise;
        })
        .then(function (eventWithParticipants) {
          vm.participants = eventWithParticipants.data.participants.data;
          toastr.success('A jelentkezés visszavonása sikerült.');
        });
    };

    function getMailtoParticipantsLink() {
      var link;

      link = 'mailto:';

      link += vm.participants
        .map(function (participant) {
          return participant.email;
        })
        .join(';');

      return link;
    }
  }
}());
