(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.factory:User
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .factory('User', User);

  function User(ycoResource, userRoles) {
    var url = 'users/'
      , UserBase;

    UserBase = ycoResource('user', url + ':id', {id: '@id'},
      {
        getLoggedInUser: {
          method: 'GET',
          url: url + 'me'
        },
        getUserByEmail: {
          method: 'GET',
          url: url + 'by-email',
        },
        getChildrenByDoctor: {
          method: 'GET',
          url: url + ':userId/children',
          params: {
            limit: -1,
            includes: 'parent'
          }
        },
        getParents: {
          method: 'GET',
          url: url,
          params: {
            roleIds: [userRoles.PARENT.id]
          }
        },
        query: {
          cache: false
        }
      }
    );

    return UserBase;
  }
}());
