(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.service:UserRole
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .service('UserRole', UserRole);

  function UserRole(userRoles, _) {
    var self = this;

    self.getUserRoles = function () {
      return userRoles;
    };

    self.getUserRoleBySlug = function getUserRoleBySlug(slug) {
      return _.find(userRoles, function (role) {
        return role.slug.plural === slug || role.slug.singular === slug;
      });
    };

    self.getUserRoleById = function getUserRoleById(roleId) {
      return _.find(userRoles, function (role) {
        return role.id === roleId;
      });
    };

    self.getUserRoles = function getUserRoles() {
      return userRoles;
    };
  }
}());
