(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:AddParticipantDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('AddParticipantDialogCtrl', AddParticipantDialogCtrl);

  function AddParticipantDialogCtrl($uibModalInstance, User, userRoles) {
    var vm = this;

    vm.form = {};
    vm.participantId = null;
    vm.possibleParticipants = [];
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close(vm.participantId);
    };

    vm.refreshPossibleParticipants = function refreshPossibleParticipants(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: [userRoles.DOCTOR.id, userRoles.EARLY_DEV.id].join()
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParticipants = response.data;
        });
    };
  }
}());
