(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoEnquiry
   * @description
   *
   */
  angular
    .module('ycoEnquiry', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());
