(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoButton
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-button yco-is-processing="isProcessing()"></yco-button>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoButton', ycoButton);

  function ycoButton() {
    return {
      restrict: 'E',
      scope: {
        ycoIsProcessing: '&',
        ycoDisabled: '&'
      },
      templateUrl: 'yco-form/directives/yco-button-directive.tpl.html',
      replace: true,
      transclude: true
    };
  }
}());
