<yco-input-container ng-if="!readonly">
  <label for="{{::field.id}}" ng-class="{'yco-child__label__missing-value': field.value === null}">{{::field.name}}</label>

  <div class="input-group">
    <div class="input-group-addon" ng-if="field.prefix">{{::field.prefix}}</div>

    <input class="form-control"
           type="number"
           name="{{::field.id}}"
           id="{{::field.id}}"
           ng-model="field.value"
           ng-disabled="field.readonly"
           ng-min="field.validation.min"
           ng-max="field.validation.max">

    <div class="input-group-addon" ng-if="field.suffix">{{::field.suffix}}</div>
  </div>

  <yco-messages form="form" field-name="field.id"></yco-messages>
</yco-input-container>

<div ng-if="readonly">
  <p class="font-w600 push-5">{{::field.name}}</p>

  <p>
    <span ng-if="field.prefix">{{::field.prefix}}</span>
    {{::field.value}}
    <span ng-if="field.suffix">{{::field.suffix}}</span>
  </p>
</div>
