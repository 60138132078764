<div class="modal-header" ng-if="modal.title">
  <h3>{{modal.title}}</h3>
</div>
<div class="modal-body" ng-if="modal.content">
  <p ng-bind-html="modal.content"></p>
</div>
<div class="modal-footer">
  <button class="btn"
          type="button"
          ng-click="modal.close()">
    {{modal.closeButtonText}}
  </button>
  <button class="btn btn-success"
          type="button"
          ng-click="modal.ok()">
    {{modal.actionButtonText}}
  </button>
</div>
