<div class="content bg-image" style="background-image: url('images/user-bg.jpg');">
  <div class="push-50-t push-15 clearfix">
    <div class="push-15-r pull-left">
      <img class="img-avatar img-avatar-thumb" ng-src="{{viewChild.child.image}}">
    </div>
    <h1 class="h2 text-white push-5-t">{{viewChild.child.lastName}} {{viewChild.child.firstName}}</h1>

    <h2 class="h5 text-white-op">Szülő: {{viewChild.child.parent.data.lastName}}
      {{viewChild.child.parent.data.firstName}}</h2>
  </div>
</div>

<div class="yco-child__view content">
  <div class="block">
    <div
      class="block-content">
      <div class="push-20">
        <button type="button" class="btn btn-default" ui-sref="listChildren">
          <i class="fa fa-arrow-left push-5-r"></i>Vissza a mentoráltak listára
        </button>
        <button
          ng-if="viewChild.canEditChild"
          type="button" class="btn btn-info" ui-sref="editChild({childId: viewChild.child.id})">
          <i class="fa fa-pencil push-5-r"></i>Szerkesztés
        </button>
        <button class="btn btn-danger"
          ng-if="!viewChild.isDataSheetClosed"
          type="button"
          ng-click="viewChild.manageChildDataSheetState({isClosed: true})">
          <i class="fa fa-ban push-5-r"></i>Adatlap lezárása
        </button>
        <button class="btn btn-success"
          ng-if="viewChild.isDataSheetClosed"
          type="button"
          ng-click="viewChild.manageChildDataSheetState({isClosed: false})">
          <i class="fa fa-unlock push-5-r"></i>Adatlap újranyitása
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="yco-child__activities block block-themed">
        <div class="block-header bg-info">
          <ul class="block-options">
            <li ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id">
              <button class="btn btn-default" type="button" ng-click="viewChild.showAddTextEntryDialog()"
                      ng-disabled="!viewChild.canEditChild">
                <i class="fa fa-plus push-5-r"></i>Új szöveges bejegyzés
              </button>
            </li>
          </ul>
          <h3 class="block-title">Tevékenységek</h3>
        </div>
        <div class="block-content block-content-full">
          <ul class="list list-timeline push" ng-if="viewChild.latestActivities.length">
            <yco-child-activity ng-repeat="activity in viewChild.latestActivities"
                                activity="activity"></yco-child-activity>
          </ul>
          <div class="yco-child__activities__skeleton text-center" ng-if="!viewChild.latestActivities.length">
            <i class="yco-child__activities__skeleton__icon fa fa-4x fa-file-text-o"></i>
            <h4 class="yco-child__activities__skeleton__title">Nincs tevékenység</h4>

            <p class="yco-child__activities__skeleton__legend">Jelenleg nincs megjeleníthető tevékenység az adatlapon.
              Módosítson adatokat, vagy vigyen fel szöveges bejegyzést, ami feljegyzésre kerül ebben a listában.</p>

            <button class="btn btn-success"
                    type="button"
                    ng-click="viewChild.showAddTextEntryDialog()"
            >
              <i class="fa fa-plus push-5-r"></i>
              Új szöveges bejegyzés
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="block block-themed">
        <div class="block-header bg-warning">
          <ul class="block-options">
            <li ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id">
              <button
                class="btn btn-default"
                type="button"
                ng-disabled="!viewChild.canEditChild"
                ng-click="viewChild.showAddParticipantDialog()">
                <i class="fa fa-plus push-5-r"></i>Új hozzáadása
              </button>
            </li>
          </ul>
          <h3 class="block-title">
            Adatlaphoz köthető személyek
          </h3>
        </div>
        <div class="block-content clearfix">
          <p ng-if="!viewChild.participants.length">Nincs az adatlaphoz köthető személy.</p>

          <table class="table" ng-if="viewChild.participants.length">
            <thead>
            <tr>
              <th>Név</th>
              <th>Jogcím</th>
              <th class="text-center">Műveletek</th>
            </tr>
            </thead>

            <tbody>
            <tr ng-repeat="user in viewChild.participants">
              <td class="yco-child__view__participant">{{user.lastName}} {{user.firstName}} <a href="#"
                                                                                               ng-if="user.note.length"
                                                                                               uib-tooltip="{{user.note}}"><i
                class="fa fa-info-circle"></i></a></td>
              <td><span class="badge badge-default">{{user.roleId | userRole}}</span></td>
              <td class="text-center">
                <a ui-sref="viewUser({userId: user.id})"
                   class="btn btn-default btn-xs">
                  <i class="fa fa-user push-5-r"></i>Profil
                </a>
                <a ui-sref="messages({userId: user.id})"
                   class="btn btn-default btn-xs"
                   ng-disabled="!viewChild.canParticipantBeMessaged($index)">
                  <i class="fa fa-envelope push-5-r"></i>Üzenet
                </a>
                <button type="button"
                        class="btn btn-danger btn-xs"
                        ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id"
                        ng-click="viewChild.removeParticipant($index)"
                        ng-disabled="!viewChild.canParticipantBeRemoved($index) || !viewChild.canEditChild">
                  <i class="fa fa-trash push-5-r"></i>Eltávolítás
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="block block-themed">
        <div class="block-header bg-modern">
          <h3 class="block-title">
            Dokumentumok
          </h3>
        </div>
        <div class="yco-child__documents block-content clearfix">
          <div ng-if="viewChild.childDocuments.length">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Fájlnév</th>
                <th class="text-center">Letöltés</th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="document in viewChild.childDocuments">
                <td>{{document.name}}</td>
                <td class="text-center">
                  <a class="btn btn-info push-5-r"
                     ng-href="{{document.url}}"
                     uib-tooltip="Letöltés" download target="_blank"><i class="fa fa-download"></i>
                    Letöltés
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div ng-if="!viewChild.childDocuments.length">
            Jelenleg nincs feltöltött dokumentum
          </div>
        </div>
      </div>

      <div class="block block-themed">
        <div class="block-header bg-flat">
          <h3 class="block-title">
            Személyes adatok
            <span class="badge {{(viewChild.child.dataSheetStatus | childStatus).class}} push-5-l">{{(viewChild.child.dataSheetStatus | childStatus).label}}</span>
          </h3>
        </div>
        <div class="block-content clearfix">
          <div class="row">
            <div class="col-md-3">
              <p class="font-w600 push-5">Azonosító</p>

              <p>{{viewChild.child.id}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Név</p>

              <p>{{viewChild.child.lastName}} {{viewChild.child.firstName}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Anyja neve</p>

              <p>{{viewChild.child.motherName}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Születési dátum</p>

              <p>{{viewChild.child.dateOfBirth | moment : 'YYYY. MM. DD. HH:mm'}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Lakcím</p>

              <p>{{viewChild.child.address}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Születési hét</p>

              <p>{{viewChild.child.birthWeek}}.</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Születési súly</p>

              <p>{{viewChild.child.birthWeight}} g</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">Regisztráció dátuma</p>

              <p>{{viewChild.child.createdAt | moment : 'YYYY. MM. DD. HH:mm'}}</p>
            </div>
            <div class="col-md-3">
              <p class="font-w600 push-5">BMI</p>

              <p>{{viewChild.child.bmi | number : 2}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="yco-child__field-group block block-themed"
           ng-repeat="fieldGroup in viewChild.child.fields.data.groups">
        <div class="block-header bg-flat">
          <h3 class="block-title">
            {{::fieldGroup.name}}
          </h3>
        </div>
        <div class="block-content">
          <div class="row" ng-if="fieldGroup.fields.length">
            <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-3 clearfix"
                 ng-repeat="field in fieldGroup.fields | filter : viewChild.emptyFieldFilter"
                 ng-class="{'yco-child__field-group__wrapper--child': !!field.parent}"
                 ng-if="viewChild.checkConditionalDisplay($index, fieldGroup.fields)">
              <div ng-switch="field.type">
                <text-field field="field"
                            ng-switch-default
                            readonly="true"></text-field>
                <number-field field="field"
                              ng-switch-when="number"
                              readonly="true"></number-field>
                <select-field field="field"
                              ng-switch-when="select"
                              readonly="true"></select-field>
                <select-field field="field"
                              ng-switch-when="multiselect"
                              readonly="true"></select-field>
                <date-field field="field"
                            ng-switch-when="date"
                            readonly="true"></date-field>
                <bno-field field="field"
                           ng-switch-when="bno"
                           readonly="true"></bno-field>
                <boolean-field field="field"
                               ng-switch-when="boolean"
                               readonly="true"></boolean-field>
              </div>
            </div>
          </div>
        </div>

        <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--has-tabs"
             ng-if="!(fieldGroup.tabs | isEmpty)">
          <div class="inner-push-20-l inner-push-20-r">
            <uib-tabset>
              <uib-tab ng-repeat="(tabKey, tab) in fieldGroup.tabs" index="$index" heading="{{::tab.name}}">
                <div class="row inner-push-20-t">
                  <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-3 clearfix"
                       ng-repeat="field in tab.fields | filter : viewChild.emptyFieldFilter"
                       ng-class="{'yco-child__field-group__wrapper--child': !!field.parent}"
                       ng-if="viewChild.checkConditionalDisplay($index, tab.fields)">
                    <div ng-switch="field.type">
                      <text-field field="field"
                                  ng-switch-default
                                  readonly="true"></text-field>
                      <number-field field="field"
                                    ng-switch-when="number"
                                    readonly="true"></number-field>
                      <select-field field="field"
                                    ng-switch-when="select"
                                    readonly="true"></select-field>
                      <select-field field="field"
                                    ng-switch-when="multiselect"
                                    readonly="true"></select-field>
                      <date-field field="field"
                                  ng-switch-when="date"
                                  readonly="true"></date-field>
                      <bno-field field="field"
                                 ng-switch-when="bno"
                                 readonly="true"></bno-field>
                      <boolean-field field="field"
                                     ng-switch-when="boolean"
                                     readonly="true"></boolean-field>
                    </div>
                  </div>
                </div>
              </uib-tab>
            </uib-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
