(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoMessage.filter:filterEnter
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoMessage')
    .filter('filterEnter', filterEnter);

  function filterEnter() {
    return function (input) {
      return input.replace(/\n/g, '<br>');
    };
  }
}());
