(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:ViewChildCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('ViewChildCtrl', ViewChildCtrl);

  function ViewChildCtrl(child, YcoDialog, $uibModal, FormControl, $state, latestActivities, participants, Child, user, userRoles, _, toastr, childStatuses, $window, $stateParams) {
    $window.scrollTo(0, 0);

    var vm = this
      , formControl = new FormControl();

    vm.child = child.data;
    vm.participants = participants.data;
    vm.latestActivities = latestActivities.data;
    vm.currentUser = user.data;
    vm.availableRoles = userRoles;
    vm.childDocuments = [];
    vm.canEditChild = false;

    var status = _.find(childStatuses, 'name', vm.child.dataSheetStatus) ? _.find(childStatuses, 'name', vm.child.dataSheetStatus) : false;

    if (status.name === childStatuses.INACTIVE.name) {
      vm.canEditChild = vm.currentUser.roleId === userRoles.ADMIN.id;
    } else {
      vm.canEditChild = vm.currentUser.roleId !== userRoles.PARENT.id;
    }

    vm.isDataSheetClosed = status.name === childStatuses.CLOSED.name;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.checkConditionalDisplay = function checkConditionalDisplay($index, container) {
      var currentField = container[$index]
        , dependencies = _.get(currentField, 'visibleIf')
        , shouldDisplay = true;

      if (!dependencies || _.isEmpty(dependencies)) {
        return shouldDisplay;
      }

      shouldDisplay = _.reduce(dependencies, function (acc, value, key) {
        acc = !!_.filter(container, function (field) {
          return field.id === key && field.value === value;
        }).length;

        return acc;
      }, shouldDisplay);

      return shouldDisplay;
    };

    vm.removeParticipant = function removeParticipant($index) {
      YcoDialog.show({
        title: 'Személy eltávolítása az adatlapról',
        content: 'Biztosan eltávolítja <strong>' + vm.participants[$index].lastName + ' ' + vm.participants[$index].firstName + '</strong> személyt az adatlapról?</strong>',
        actionButtonText: 'Eltávolítás'
      }).then(function () {
        return Child.removeParticipant({
          childId: vm.child.id,
          userId: vm.participants[$index].id
        }).$promise;
      }).then(function () {
        $state.reload();
      });
    };

    vm.showAddParticipantDialog = function showAddParticipantDialog() {
      formControl.setSuccessText('A kiválasztott személyt hozzáadásra került.');

      $uibModal.open({
        templateUrl: 'yco-child/views/add-participant-dialog.tpl.html',
        controllerAs: 'addParticipant',
        controller: 'AddParticipantDialogCtrl'
      }).result
        .then(function (userId) {
          return Child.addParticipant({
            childId: vm.child.id,
            userId: userId
          }, {}).$promise;
        })
        .then(function () {
          $state.reload();
        });
    };

    vm.canParticipantBeMessaged = function canParticipantBeMessaged($index) {
      var participant = vm.participants[$index];
      return participant.id !== vm.currentUser.id;
    };

    vm.canParticipantBeRemoved = function canParticipantBeRemoved($index) {
      var participant = vm.participants[$index]
        , unremovableUserRoleIds = [userRoles.MENTOR.id, userRoles.PARENT.id];

      return participant.id !== vm.currentUser.id && !_.includes(unremovableUserRoleIds, participant.roleId);
    };

    vm.showAddTextEntryDialog = function showAddTextEntryDialog() {
      $uibModal.open({
        templateUrl: 'yco-child/views/add-text-entry-dialog.tpl.html',
        controllerAs: 'addTextEntry',
        controller: 'AddTextEntryDialogCtrl'
      }).result
        .then(function (entry) {
          return Child.createTextEntry({childId: vm.child.id}, entry).$promise;
        })
        .then(function () {
          toastr.success('A bejegyzést sikeresen létrejött.');
          $state.reload();
        });
    };

    vm.emptyFieldFilter = function emptyFieldFilter(field) {
      return !_.isUndefined(field.rawValue) && !_.isNull(field.rawValue);
    };

    vm.getChildDocuments = function getChildDocuments() {
      Child.getFilesForChild({childId: vm.child.id}).$promise
        .then(function (data) {
          vm.childDocuments = data.data;
        });
    };

    vm.getChildDocuments();

    vm.manageChildDataSheetState = function manageChildDataSheetState(state) {
      return Child.update({close: state.isClosed, id: $stateParams.childId}, vm.child).$promise
        .then(function () {
          vm.isDataSheetClosed = state.isClosed;
          toastr.success(vm.isDataSheetClosed ? 'Az adatlap lezárásra került.' : 'Az adatlap újranyitásra került.');
          $state.go('listChildren');
        })
        .catch(function (error) {
          if (error.data) {
            toastr.error(error.data.detail);
          } else {
            toastr.error(vm.isDataSheetClosed ? 'Hiba az adatlap lezárása során.' : 'Hiba az adatlap újranyitása során.');
          }
        });
    };
  }
}());
