(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childStatuses
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childStatuses', {
      UNFILLED: {
        name: 'unfilled',
        label: 'Kitöltetlen',
        class: 'badge-default'
      },
      DEFICIENT: {
        name: 'deficient',
        label: 'Hiányos',
        class: 'badge-danger'
      },
      FILLED_OUT: {
        name: 'filledOut',
        label: 'Kitöltött',
        class: 'badge-success'
      },
      INACTIVE: {
        name: 'inactive',
        label: 'Inaktív',
        class: 'badge-warning'
      },
      CLOSED: {
        name: 'closed',
        label: 'Lezárt',
        class: 'badge-warning'
      }
    });
}());
