(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoResource.service:YcoDataTables
   *
   * @description
   *
   */
  angular
    .module('ycoResource')
    .service('YcoDataTables', YcoDataTables);

  function YcoDataTables(DTOptionsBuilder, datatablesI18n, appConfig, _, $compile, Auth, $state) {
    var self = this;
    self.getOptions = function getOptions(options) {
      return DTOptionsBuilder.newOptions()
        .withLanguage(datatablesI18n)
        .withBootstrap()
        .withOption('bSort', true)
        .withOption('stateSave', true)
        .withOption('stateSaveCallback', function (settings, data) {
          var key = ($state.params && $state.params.role) ? $state.params.role : $state.current.url;
          localStorage.setItem(key, JSON.stringify(data))
        })
        .withOption('stateLoadCallback', function (settings) {
          var key = ($state.params && $state.params.role) ? $state.params.role : $state.current.url;
          return JSON.parse(localStorage.getItem(key))
        })
        .withOption('ajax', {
          dataSrc: 'data',
          url: appConfig.api.url + options.apiEndpoint,
          headers: Auth.getAuthHeader(),
          dataFilter: function (rawResponse) {
            var response = angular.fromJson(rawResponse)
              , data = {};

            data.data = response.data;
            data.recordsTotal = response.meta.pagination.total;
            data.recordsFiltered = response.meta.pagination.total;

            return angular.toJson(data);
          },
          data: function (data, dtInstance) {
            var dtData = _.assign({}, data)
              , itemsPerPage = dtData.length
              , currentPage = dtData.start / itemsPerPage + 1
              , orderPredicateColumnIndex
              , orderPredicateColumn
              , orderDirection;

            data = {
              page: currentPage,
              limit: itemsPerPage
            };

            if (dtData.search && dtData.search.value) {
              data.search = dtData.search.value;
            }

            if (dtData.order && dtData.order.length) {
              orderPredicateColumnIndex = dtData.order[0].column;
              orderPredicateColumn = dtInstance.aoColumns[orderPredicateColumnIndex];
              orderDirection = dtData.order[0].dir;

              data.order = orderDirection;
              data.orderBy = orderPredicateColumn.compoundOf ? orderPredicateColumn.compoundOf.join(',') : orderPredicateColumn.mData;
            }

            if (options.dynamicParams && typeof options.dynamicParams === 'function') {
              data = _.assign(data, options.dynamicParams());
            }

            data = _.assign(data, options.params);

            return data;
          }
        })
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withOption('createdRow', function createdRow(row) {
          $compile(angular.element(row).contents())(options.scope);
        });
    };
  }
}());
