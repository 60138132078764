(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:dateField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <date-field></date-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('dateField', dateField);

  function dateField() {
    return {
      restrict: 'EA',
      scope: {
        form: '=',
        field: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/date-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function ($scope, moment, _) {
        var vm = this;

        vm.nativeDateValue = $scope.field.value ? moment($scope.field.value).toDate() : null;
        vm.isDatepickerPopupOpen = false;

        vm.toggleDatepickerPopup = function toggleDatepickerPopup() {
          vm.isDatepickerPopupOpen = !vm.isDatepickerPopupOpen;
        };

        vm.datePickerOptions = {
          maxDate: new Date()
        };

        $scope.$watch(function () {
          return vm.nativeDateValue;
        }, function (value) {
          if (_.isNull(vm.nativeDateValue) || !_.has($scope, 'form[' + $scope.field.id + ']')) {
            return;
          }
          $scope.form[$scope.field.id].$setValidity('maxDate', (moment(vm.nativeDateValue).isSameOrBefore(new Date())));
          $scope.field.value = moment(value).format('YYYY-MM-DD HH:mm:ss');
        });
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
