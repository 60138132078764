<yco-page-header>Regisztráció kezelése</yco-page-header>

<form name="editEnquiry.form" ng-submit="editEnquiry.submit()" novalidate>
  <div class="content ng-scope">
    <div class="block">
      <div
        class="block-content">
        <div class="push-20">
          <button type="button" class="btn btn-default" ui-sref="listEnquiries">
            <i class="fa fa-arrow-left push-5-r"></i>Vissza a regisztrációkhoz
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="block block-bordered">
          <div class="block-header">
            <h3 class="block-title">Adatok</h3>
          </div>
          <div class="block-content tab-content">
            <uib-tabset active="editEnquiry.activeTab" justified="true" class="block">
              <uib-tab index="0" heading="1. Alapadatok">
                <div class="col-md-6 col-md-offset-3 push-30-t">
                  <div class="push-50">
                    <yco-input-container>
                      <label>Vezetéknév</label>
                      <input class="form-control"
                             type="text"
                             name="lastName"
                             ng-model="editEnquiry.enquiry.lastName"
                             required>
                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                                    field-name="lastName"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Keresztnév</label>
                      <input class="form-control"
                             type="text"
                             name="firstName"
                             ng-model="editEnquiry.enquiry.firstName"
                             required>
                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                                    field-name="firstName"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Email</label>
                      <input class="form-control"
                             type="text"
                             name="email"
                             ng-model="editEnquiry.enquiry.email"
                             required>
                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                                    field-name="email"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Telefonszám</label>
                      <input class="form-control"
                             type="text"
                             name="phone"
                             ng-model="editEnquiry.enquiry.phone"
                             required>
                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                                    field-name="phone"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Település</label>
                      <input class="form-control"
                             type="text"
                             name="city"
                             ng-minlength="2"
                             ng-model="editEnquiry.enquiry.city"
                             required>
                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                                    field-name="city"></yco-messages>
                    </yco-input-container>

                  </div>
                </div>
              </uib-tab>
              <uib-tab index="1" heading="2. Gyermekek adatai">
                <table class="table table-bordered push-30-t">
                  <thead>
                  <tr>
                    <th>Vezetéknév</th>
                    <th>Keresztnév</th>
                    <th>Születési év</th>
                    <th>Születési hét</th>
                    <th>Születési súly</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr ng-repeat="child in editEnquiry.children">
                    <td>
                      <input class="form-control" type="text" ng-model="child.lastName" disabled>
                    </td>
                    <td>
                      <input class="form-control" type="text" ng-model="child.firstName" disabled>
                    </td>
                    <td>
                      <input class="form-control" type="text" ng-model="child.birthYear" disabled>
                    </td>
                    <td>
                      <input class="form-control" type="text" ng-model="child.birthWeek" disabled>
                    </td>
                    <td>
                      <div class="input-group">
                        <input class="form-control" type="text" ng-model="child.birthWeight" disabled>
                        <div class="input-group-addon">g</div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </uib-tab>
            </uib-tabset>
          </div>
          <div class="block-content block-content-mini block-content-full border-t">
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-default"
                        type="button"
                        ng-disabled="editEnquiry.activeTab === 0"
                        ng-click="editEnquiry.setActiveTab(0)">
                  <i class="fa fa-arrow-left"></i>
                  Vissza
                </button>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-default"
                        type="button"
                        ng-disabled="editEnquiry.activeTab === 1"
                        ng-click="editEnquiry.setActiveTab(1)">
                  Tovább <i class="fa fa-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="btn-group btn-block push-30" ng-if="editEnquiry.isNewEnquiry()">
          <button class="btn btn-info btn-lg btn-block" type="button" ng-click="editEnquiry.showAssignEnquiryDialog()">
            <i class="fa fa-user"></i><br>Mentor hozzárendelése
          </button>
        </div>

        <div class="btn-group btn-group-justified push-30">
          <div class="btn-group">
            <button class="btn btn-success btn-lg"
                    type="button"
                    ng-disabled="!editEnquiry.enquiry.mentorId"
                    ng-click="editEnquiry.showApproveEnquiryDialog()">
              <i class="fa fa-check"></i><br>Elfogadás
            </button>
          </div>
          <div class="btn-group">
            <button class="btn btn-danger btn-lg" type="button" ng-click="editEnquiry.showRejectEnquiryDialog()">
              <i class="fa fa-times"></i><br>Elutasítás
            </button>
          </div>
        </div>

        <div class="block block-bordered">
          <div class="block-header">
            <h3 class="block-title">Regisztráció részletek</h3>
          </div>

          <div class="block-content block-content-full">
            <div>
              <p class="font-w600 push-5">Azonosító</p>

              <p>{{editEnquiry.enquiry.id}}</p>
            </div>
            <div>
              <p class="font-w600 push-5">Regisztráció dátuma</p>

              <p>{{editEnquiry.enquiry.createdAt | moment : 'YYYY.MM.DD. HH:mm'}}</p>
            </div>
            <div>
              <p class="font-w600 push-5">Státusz</p>

              <p>{{editEnquiry.enquiry.status | enquiryStatus}}</p>
            </div>
            <div ng-if="!editEnquiry.isNewEnquiry()">
              <p class="font-w600 push-5">Hozzárendelt mentor</p>

              <ui-select append-to-body="true"
                         name="mentorId"
                         class="yco-enquiry__autocomplete__select"
                         ng-disabled="editEnquiry.loggedUser.roleId === editEnquiry.availableRoles.MENTOR.id"
                         ng-model="editEnquiry.enquiry.mentorId">
                <ui-select-match allow-clear="false">
                  {{$select.selected.lastName}} {{$select.selected.firstName}}
                </ui-select-match>
                <ui-select-choices repeat="user.id as user in editEnquiry.possibleMentors track by $index"
                                   refresh="editEnquiry.refreshPossibleMentors($select.search)"
                                   refresh-delay="200"
                                   minimum-input-length="3">
                  <div class="inner-push-5 inner-push-5-t media">
                    <div class="media-left">
                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                           alt="{{user.lastName}} {{user.firstName}}">
                    </div>
                    <div class="media-right">
                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                    </div>
                    <div class="yco-enquiry__autocomplete__suggestion__email">
                      <span>{{user.email}}</span>
                    </div>
                  </div>
                </ui-select-choices>
              </ui-select>
              <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"
                            field-name="mentorId"></yco-messages>
            </div>
            <div class="push-20-t clearfix">
              <yco-button class="btn btn-success pull-right" type="submit"
                          yco-is-processing="editEnquiry.isProcessing()">
                <i class="fa fa-save push-5-r"></i> Mentés
              </yco-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
