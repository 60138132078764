<yco-page-header>Új kiadvány létrehozása</yco-page-header>

<div class="content">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="block">
        <div class="block-header">
          <h3 class="block-title">Kiadvány adatai</h3>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <form name="newPublication.form" ng-submit="newPublication.submit()" novalidate
                    flow-init>
                <yco-input-container>
                  <label>Cím</label>
                  <input class="form-control" type="text" name="title"
                         ng-model="newPublication.publication.title" required>
                  <yco-messages form="newPublication.form"
                                server="newPublication.getServerValidation()"
                                field-name="title"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Rövid leírás</label>
                  <textarea class="form-control" name="content"
                            ng-model="newPublication.publication.content" rows="10"
                            required></textarea>
                  <yco-messages form="newPublication.form"
                                server="newPublication.getServerValidation()"
                                field-name="content"></yco-messages>
                </yco-input-container>

                <div class="yco-publications__new__upload__wrapper form-group">
                  <label>Borítókép</label>

                  <div
                    flow-init="newPublication.flowOptions"
                    flow-file-added="!!{png:1,gif:1,jpg:1,jpeg:1}[$file.getExtension()]"
                    flow-files-submitted="newPublication.sendToAPI($file, $event, $flow)"
                    flow-file-error="newPublication.handleAPIErrors($file, $message, $flow)"
                    flow-file-success="newPublication.handleAPISuccess($file, $message, $flow, 'cover')"
                  >
                    <div class="push-10-t push-10 position-relative" ng-if="newPublication.hasCoverImage">
                      <button class="btn btn-rounded btn-danger" type="button"
                              ng-click="newPublication.cancelCoverImage($flow)"><i class="fa fa-times"></i></button>
                      <img class="image-responsive" flow-img="$flow.files[0]">
                    </div>
                    <input ng-if="!newPublication.hasCoverImage" type="file" name="image" flow-btn>
                  </div>
                  <yco-messages form="newPublication.form"
                                server="newPublication.getServerValidation()"
                                field-name="image"></yco-messages>
                </div>

                <div class="yco-publications__new__upload__wrapper form-group">
                  <label>Fájl</label>

                  <div
                    flow-init="newPublication.flowOptions"
                    flow-file-added="!!{jpg:1,jpeg:1,png:1,gif:1,pdf:1,xls:1,xlsx:1,doc:1,docx:1,ppt:1,pptx:1,zip:1}[$file.getExtension()]"
                    flow-files-submitted="newPublication.sendToAPI($file, $event, $flow)"
                    flow-file-error="newPublication.handleAPIErrors($file, $message, $flow)"
                    flow-file-success="newPublication.handleAPISuccess($file, $message, $flow, 'file')"
                  >
                    <div class="push-10-t push-10 position-relative" ng-if="newPublication.hasPublicationFile">
                      <button class="btn btn-rounded btn-danger" type="button"
                              ng-click="newPublication.cancelFile($flow)"><i class="fa fa-times"></i></button>
                      <img ng-if="newPublication.isPublicationFileImage($flow.files[0])" class="image-responsive" flow-img="$flow.files[0]">
                      <span ng-if="!newPublication.isPublicationFileImage($flow.files[0])">{{$flow.files[0].name}}</span>
                    </div>
                    <input ng-if="!newPublication.hasPublicationFile" type="file" name="file" flow-btn>
                    <yco-messages form="newPublication.form"
                                  server="newPublication.getServerValidation()"
                                  field-name="file"></yco-messages>
                  </div>
                </div>

                <yco-input-container>
                  <label>Jogosultság</label>
                  <select name="roles" class="form-control" ng-model="newPublication.publication.roles" multiple>
                    <option value="{{role.id}}"
                            ng-selected="role.id == newPublication.publication.roles"
                            ng-repeat="role in newPublication.availableRoles">
                      {{role.name.singular}}
                    </option>
                  </select>
                  <div class="help-block">A kiadványt csak az itt megjelölt jogosultsági körrel
                    rendelkező felhasználók fogják tudni megtekinteni. A listából több jogosultság
                    is kiválasztható egyszerre. Amennyiben egy jogosultság sem kerül kiválasztásra,
                    a kiadvány bármely belépett felhasználó számára látható lesz.
                  </div>
                </yco-input-container>

                <hr>

                <div class="push text-center">
                  <yco-button class="btn btn-success" type="submit"
                              yco-is-processing="newPublication.isProcessing()">
                    <i class="fa fa-save push-5-r"></i>
                    Mentés
                  </yco-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
