<form name="addTextEntry.form" ng-submit="addTextEntry.submit()" novalidate>
  <div class="modal-header">
    <h3>Új szöveges bejegyzés</h3>
  </div>
  <div class="modal-body">
    <yco-input-container>
      <label>Szöveg</label>
      <textarea class="form-control" name="content" ng-model="addTextEntry.entry.content" rows="5" required></textarea>
      <yco-messages form="addTextEntry.form" field-name="content"></yco-messages>
    </yco-input-container>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="addTextEntry.close()">
      Mégse
    </button>
    <button class="btn btn-success"
            type="submit">
      Küldés
    </button>
  </div>
</form>
