(function () {
  'use strict';

  /* @ngdoc object
   * @name koraszulottmentorExtranet
   * @description
   *
   */
  angular
    .module('koraszulottmentorExtranet', [
      'ngAria',
      'ngAnimate',
      'ngCookies',
      'ngPassword',
      'ngResource',
      'ngSanitize',
      'datatables',
      'datatables.bootstrap',
      'luegg.directives',
      'ng-form-group',
      'ngTextareaEnter',
      'ui.router',
      'ui.bootstrap',
      'ui.select',
      'angular-jwt',
      'angular-loading-bar',
      'angular-storage',
      'flow',
      'lodash',
      'moment',
      'oneui',
      'toastr',
      'ycoAuth',
      'ycoForm',
      'ycoNavigation',
      'ycoResource',
      'ycoUser',
      'ycoPublication',
      'ycoMessage',
      'ycoEnquiry',
      'ycoChild',
      'ycoEvent',
      'angulartics',
      'angulartics.google.analytics'
    ])
    .run(activateLogs)
    .run(setupAuthentication)
    .run(setDateLocale);

  function activateLogs($log, $rootScope, _, $window) {
    $log.debug('APPLICATION START');
    $rootScope.$on('$destroy', function () {
      $log.debug('APPLICATION STOP');
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeSuccess from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeStart from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $log.error('$stateChangeError from: %s(%s) to: %s(%s), error: %s',
        fromState.name, fromState.url, toState.name, toState.url, _.has(error, 'message') ? error.message : error);

      if (fromState.name === 'login' && toState.name === 'dashboard') {
        $window.location.reload();
      }
    });
  }

  function setupAuthentication($rootScope, $state, Auth, _) {
    $rootScope.$on('$stateChangeStart', function (event, toState) {
      Auth.isAuthenticated()
        .then(function (authenticated) {
          if (_.get(toState, 'data.authenticatedOnly') && !authenticated) {
            event.preventDefault();
            $state.go('login');
          }

          if (_.get(toState, 'data.notForAuthenticated') && authenticated) {
            event.preventDefault();
            $state.go('dashboard');
          }
        });
    });
  }

  function setDateLocale(moment) {
    moment.locale('hu');
  }
}());
