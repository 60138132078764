(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:RejectEnquiryDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .controller('RejectEnquiryDialogCtrl', RejectEnquiryDialogCtrl);

  function RejectEnquiryDialogCtrl($uibModalInstance) {
    var vm = this;

    vm.form = {};
    vm.statusMessage = '';
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close(vm.statusMessage);
    };
  }
}());
