<li>
  <div class="list-timeline-time" title="{{ycoChildActivity.activity.createdAt | moment : 'YYYY. MM. DD. HH:mm:ss'}}">
    {{ycoChildActivity.activity.createdAt | fromNow}}
  </div>
  <i class="{{ycoChildActivity.activityType.class}} list-timeline-icon"></i>

  <div class="list-timeline-content">
    <p class="font-w600">{{ycoChildActivity.activityType.label}}</p>

    <p class="push-5-t push-5 font-s12">
      <i class="fa fa-user push-5-r"></i>{{ycoChildActivity.activity.user.data.lastName}}
      {{ycoChildActivity.activity.user.data.firstName}}
    </p>

    <ul class="nav-users" ng-if="ycoChildActivity.activity.staffChange">
      <li ng-if="ycoChildActivity.activity.staffChange.added.data"
          ng-repeat="user in ycoChildActivity.activity.staffChange.added.data">
        <a ui-sref="viewUser({userId: user.id})">
          <img class="img-avatar" ng-src="{{::user.image}}" alt="">
          <i class="fa fa-plus-circle text-success"></i> {{::user.lastName}} {{::user.firstName}}
          <div class="font-w400 text-muted">
            <small>{{::user.roleId | userRole}}</small>
          </div>
        </a>
      </li>
      <li ng-if="ycoChildActivity.activity.staffChange.removed.data"
          ng-repeat="user in ycoChildActivity.activity.staffChange.removed.data">
        <a ui-sref="viewUser({userId: user.id})">
          <img class="img-avatar" ng-src="{{::user.image}}" alt="">
          <i class="fa fa-minus-circle text-danger"></i> {{::user.lastName}} {{::user.firstName}}
          <div class="font-w400 text-muted">
            <small>{{::user.roleId | userRole}}</small>
          </div>
        </a>
      </li>
    </ul>

    <ul class="font-s13" ng-if="ycoChildActivity.activity.dataChange">
      <li ng-repeat="(key,change) in ycoChildActivity.activity.dataChange"
          ng-if="key != 'imageId'">
        <strong>{{::change.field.name || ycoChildActivity.getActivityLabel(key)}}:</strong>
        <strike>{{::change.old}}</strike> {{::change.new}}
      </li>
    </ul>

    <blockquote class="push-5 push-5-t font-s13" ng-if="ycoChildActivity.activity.content">
      {{ycoChildActivity.activity.content}}
    </blockquote>
  </div>
</li>
