(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:MessagesCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoMessage')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl(initialThreads, Thread, initialMessages, user, recipient, $uibModal, _, $rootScope, $filter, $window) {
    var vm = this
      , threads = initialThreads
      , messages = initialMessages
      , currentUserId = user.data.id
      , uppercaseFilter = $filter('parseUrl');

    vm.newMessage = {};
    vm.newThread = {};
    vm.threads = threads.data;
    vm.messages = messages.data;
    vm.activeThread = threads.data[0];
    vm.isThreadReloading = false;
    vm.isThreadListReloading = false;
    vm.currentUserId = currentUserId;

    if (recipient) {
      vm.recipient = recipient.data;
    }

    vm.selectThread = function selectThread(index) {
      vm.activeThread = threads.data[index];
      vm.reloadMessages();
    };

    vm.reloadThreads = function reloadThreads() {
      vm.isThreadListReloading = true;

      return Thread.getThreads()
        .$promise
        .then(function (response) {
          threads = response;
          vm.threads = response.data;
        })
        .finally(function () {
          vm.isThreadListReloading = false;
        });
    };

    vm.reloadActiveThread = function reloadActiveThread() {
      vm.isThreadReloading = true;

      return Thread.get({id: vm.activeThread.id})
        .$promise
        .then(function (response) {
          vm.activeThread = response.data;
        })
        .finally(function () {
          vm.isThreadReloading = false;
        });
    };

    vm.reloadMessages = function reloadMessages() {
      vm.isThreadReloading = true;

      return Thread.getMessagesByThreadId({id: vm.activeThread.id})
        .$promise
        .then(function (response) {
          messages = response;
          vm.messages = response.data;
        })
        .finally(function () {
          vm.isThreadReloading = false;
        });
    };

    vm.isSubmitDisabled = function () {
      return vm.messages[0].user.data.firstName == 'Rendszerüzenet';
    };

    vm.sendMessage = function sendMessage() {
      if (vm.isSubmitDisabled()) {
        return;
      }

      vm.isThreadReloading = true;
      var message = _.assign({}, vm.newMessage);
      message.message = uppercaseFilter(message.message);

      Thread.addMessageToThread({id: vm.activeThread.id}, message)
        .$promise
        .then(function () {
          return vm.reloadMessages();
        })
        .then(function () {
          vm.newMessage = {};
          vm.isThreadReloading = false;
          vm.setFocus();
        });
    };

    vm.setFocus = function setFocus() {
      setTimeout(function () {
        $window.document.getElementById('yco-messages__message-textarea').focus();
      }, 0);
    };

    vm.submitTextarea = function submitTextarea() {
      vm.sendMessage();
    };

    vm.isOwnMessage = function isOwnMessage(index) {
      return messages.data[index].user.data.id === currentUserId;
    };

    vm.showNewThreadDialog = function showNewThreadDialog(hasRecipient) {
      $uibModal
        .open({
          controllerAs: 'newThread',
          templateUrl: 'yco-message/views/new-thread-dialog.tpl.html',
          controller: 'NewThreadCtrl',
          resolve: {
            recipient: function () {
              return vm.recipient;
            },
            hasRecipient: function () {
              return hasRecipient;
            }
          }
        })
        .result
        .then(function () {
          return vm.reloadThreads();
        })
        .then(function () {
          vm.selectThread(0);
        });
    };

    vm.getParticipantTooltipContent = function getParticipantTooltipContent(index, excludedParticipantId) {
      var participants
        , participantNames;

      participants = _.filter(threads.data[index].participants.data, function (participant) {
        return participant.user.data.id !== excludedParticipantId;
      });

      participantNames = _.map(participants, function (participant) {
        return participant.user.data.lastName + ' ' + participant.user.data.firstName;
      });

      return participantNames.join('<br />');
    };

    vm.showParticipantsDialog = function showParticipantsDialog() {
      $uibModal
        .open({
          templateUrl: 'yco-message/views/list-participants-dialog.tpl.html',
          bindToController: true,
          controllerAs: 'listParticipants',
          controller: 'ListParticipantsCtrl',
          resolve: {
            thread: function () {
              return vm.activeThread;
            }
          }
        });
    };

    $rootScope.$on('newThreadCreated', function () {
      vm.reloadThreads();
    });

    $rootScope.$on('participantsChanged', function () {
      vm.reloadThreads();
      vm.reloadActiveThread();
      vm.reloadMessages();
    });

    if (vm.recipient) {
      vm.showNewThreadDialog(true);
    }

    vm.setFocus();
  }
}());
