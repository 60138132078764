<yco-input-container ng-if="!readonly">
  <label for="{{::field.id}}" ng-class="{'yco-child__label__missing-value': field.value === null}">{{::field.name}}</label>

  <div class="input-group date">
    <input class="form-control"
           type="text"
           id="{{::field.id}}"
           name="{{::field.id}}"
           ng-model="fieldCtrl.nativeDateValue"
           ng-required="field.validation.required"
           is-open="fieldCtrl.isDatepickerPopupOpen"
           uib-datepicker-popup="yyyy-MM-dd"
           datepicker-options="fieldCtrl.datePickerOptions"
           close-text="Bezárás"
           placeholder="Válasszon dátumot">
    <span class="input-group-addon" ng-click="fieldCtrl.toggleDatepickerPopup()">
              <span class="fa fa-calendar"></span>
            </span>
  </div>
  <yco-messages form="form" field-name="{{::field.id}}"></yco-messages>
</yco-input-container>

<div ng-if="readonly">
  <p class="font-w600 push-5">
    {{::field.name}}
  </p>

  <p>
    <span ng-if="field.prefix">{{::field.prefix}}</span>
    {{::field.value | moment : 'YYYY-MM-DD'}}
    <span ng-if="field.suffix">{{::field.suffix}}</span>
  </p>
</div>
