<yco-page-header>Kiadványok</yco-page-header>

<div class="content ng-scope">
  <div class="block">
    <div class="block-header">
      <h3 class="block-title">Kiadványok</h3>
    </div>
    <div class="block-content">
      <a
        ng-if="listPublications.loggedUser.roleId === listPublications.availableRoles.ADMIN.id"
        class="btn btn-success push-20" ui-sref="newPublication"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>

      <table class="table table-bordered table-striped" datatable
             dt-options="listPublications.dtOptions"
             dt-columns="listPublications.dtColumns"></table>
    </div>
  </div>
</div>
