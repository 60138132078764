<header id="header-navbar" class="content-mini content-mini-full">
  <div ng-if="ycoHeader.appConfig.debug"
       class="yco-header__notification text-center">
    Ez egy teszt környezet. Az éles rendszerhez kattintson ide: <a
    href="https://portal.koraszulottmentor.hu/"
    target="_blank">Belépés</a>
  </div>
  <ul class="nav-header pull-right">
    <li>
      <div class="btn-group" data-on-toggle="toggled(open)" data-uib-dropdown>
        <button class="btn btn-default btn-image" type="button" data-uib-dropdown-toggle>
          <img src="images/avatar.png" alt="Felhasználó">
          {{ycoHeader.user.data.lastName}} {{ycoHeader.user.data.firstName}}
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu-right" data-uib-dropdown-menu>
          <li class="dropdown-header">Fiók</li>
          <li>
            <a ui-sref="viewUser({userId: 'me'})">
              <i class="fa fa-fw fa-user pull-right"></i>Profil
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a ui-sref="logout">
              <i class="fa fa-fw fa-sign-out pull-right"></i>Kijelentkezés
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</header>
