(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.factory:Enquiry
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .factory('Enquiry', Enquiry);

  function Enquiry(ycoResource) {
    var url = 'enquiries/'
      , EnquiryBase;

    EnquiryBase = ycoResource('enquiry', url + ':id', {id: '@id'},
      {
        getEnquiry: {
          method: 'GET',
          url: url + ':id',
          params: {
            includes: 'children,mentor'
          },
          cache: false
        },
        getEnquiries: {
          method: 'GET',
          url: url,
          cache: false
        }
      }
    );

    return EnquiryBase;
  }
}());
