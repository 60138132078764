(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoForm
   * @description
   *
   */
  angular
    .module('ycoForm', [
      'ngMessages',
      'validation.match'
    ]);
}());
