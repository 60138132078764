<yco-page-header>Üzenetek</yco-page-header>

<div class="yco-messages content">
  <div class="yco-messages__inner">
    <div class="yco-messages__thread-list block block-bordered remove-margin-b">
      <div class="block-header bg-gray-lighter">
        <ul class="block-options">
          <li>
            <button type="button"
                    class="btn btn-success btn-xs"
                    ng-click="messages.showNewThreadDialog()">
              <i class="fa fa-plus push-5-r"></i>
              Új üzenetváltás
            </button>
          </li>
          <li>
            <button type="button"
                    uib-tooltip="Üzenetváltás lista frissítése"
                    ng-disabled="messages.isThreadListReloading"
                    ng-click="messages.reloadThreads()">
              <i class="fa fa-refresh"
                 ng-class="{'fa-spin': messages.isThreadListReloading}"></i>
            </button>
          </li>
        </ul>
        <h3 class="block-title">Üzenetváltások</h3>
      </div>

      <div class="block-content remove-padding">
        <div class="yco-messages__thread-list__list list-group overflow-y-auto"
             ng-class="{'yco-messages__thread-list__list--empty': !messages.threads.length}">
          <div class="list-group-item"
               ng-if="messages.threads.length"
               ng-repeat="thread in messages.threads"
               ng-class="{'yco-messages__thread-list__list__item--active': thread.id === messages.activeThread.id,
                          'yco-messages__thread-list__list__item--hasUnread': thread.hasUnread}"
               ng-click="messages.selectThread($index)">
            <p class="pull-right text-muted"
               title="{{thread.createdAt | moment : 'YYYY.MM.DD. HH:mm:ss'}}">
              {{thread.createdAt | fromNow}}
            </p>
            <h4 class="list-group-item-heading">{{thread.subject}}</h4>

            <p class="list-group-item-text push-5-t push-5" ng-if="thread.child">
              <small>
                <i class="fa fa-file-text fa-fw push-5-r"></i>
                <strong>Mentorált:</strong> {{thread.child.data.lastName}} {{thread.child.data.firstName}}
              </small>
            </p>

            <p class="list-group-item-text push-5-t push-5">
              <small>
                <i class="fa fa-fw push-5-r"
                   ng-class="{'fa-user': thread.participants.data.length === 1, 'fa-users': thread.participants.data.length > 1}"></i>
                {{thread.participants.data[0].user.data.lastName}}
                {{thread.participants.data[0].user.data.firstName}}
                <span ng-if="thread.participants.data.length > 1"
                      uib-tooltip-html="messages.getParticipantTooltipContent($index, thread.participants.data[0].user.data.id)">
                    és további {{thread.participants.data.length - 1}} személy
                  </span>
              </small>
            </p>
          </div>

          <div class="yco-messages__thread-list__list__skeleton"
               ng-if="!messages.threads.length">
            <i class="yco-messages__thread-list__list__skeleton__icon fa fa-commenting-o fa-5x push-10"></i>

            <p class="yco-messages__thread-list__list__skeleton__title push-10">Nincs üzenetváltás</p>

            <p class="yco-messages__thread-list__list__skeleton__subtitle push-20">
              Kattintson az alábbi gombra, vagy a doboz tetején lévő 'Új üzenetváltás' feliratra, hogy
              üzenetváltást kezdeményezzen.</p>

            <button type="button"
                    class="btn btn-success"
                    ng-click="messages.showNewThreadDialog()">
              <i class="fa fa-plus push-5-r"></i>Új üzenetváltás
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="yco-messages__message-list block block-bordered remove-margin-b"
         ng-if="messages.activeThread && messages.activeThread.id !== -1">
      <div class="block-header bg-gray-lighter">
        <ul class="block-options">
          <li>
            <button type="button"
                    class="btn btn-xs"
                    uib-tooltip="Résztvevők megtekintése"
                    ng-disabled="messages.isThreadReloading"
                    ng-click="messages.showParticipantsDialog()">
              <i class="fa fa-users"></i>
            </button>
          </li>
          <li>
            <button type="button"
                    class="btn btn-xs"
                    uib-tooltip="Üzenetek frissítése"
                    ng-disabled="messages.isThreadReloading"
                    ng-click="messages.reloadMessages()">
              <i class="fa fa-refresh"
                 ng-class="{'fa-spin': messages.isThreadReloading}"></i>
            </button>
          </li>
        </ul>
        <h3 class="block-title">{{messages.activeThread.subject}}</h3>
        <div class="yco-messages__message-list__child" ng-if="messages.activeThread.child.data"><span>Mentorált: </span>
          <a ui-sref="viewChild({childId:messages.activeThread.child.data.id})">{{messages.activeThread.child.data.lastName}}
            {{messages.activeThread.child.data.firstName}}</a></div>
      </div>
      <div class="block-content">
        <div class="yco-messages__message-list__list block-content block-content-full overflow-y-auto"
             scroll-glue-bottom>
          <div class="block block-rounded block-transparent push-15"
               title="Küldve {{message.user.data.lastName}} {{message.user.data.firstName}} által, ekkor: {{message.createdAt | moment : 'YYYY.MM.DD. HH:mm:ss'}}"
               ng-repeat="message in messages.messages"
               ng-class="{'push-50-l yco-messages__message-list__message yco-messages__message-list__message--own': messages.isOwnMessage($index), 'push-50-r yco-messages__message-list__message': !messages.isOwnMessage($index)}">
            <img uib-tooltip="{{message.user.data.lastName}} {{message.user.data.firstName}}"
                 class="yco-messages__message-list__message__author" ng-src="{{message.user.data.image}}" alt="Avatar">
            <div class="yco-messages__message-list__message__text"
                 ng-bind-html="message.message | filterEnter">
            </div>
          </div>
        </div>

        <div class="block-content block-content-full block-content-mini">
          <form name="messages.newMessageForm"
                ng-submit="messages.sendMessage()"
                novalidate>
        <textarea class="form-control yco-messages__message-textarea"
                  id="yco-messages__message-textarea"
                  autofocus
                  ng-disabled="messages.isThreadReloading"
                  ng-model="messages.newMessage.message"
                  ng-textarea-enter="messages.submitTextarea()"
                  placeholder="Írja be üzenetét, majd nyomjon Enter-t a küldéshez..."></textarea>
            <button ng-disabled="messages.isSubmitDisabled()" type="submit"
                    class="btn btn-success pull-right">Elküld
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="yco-messages__message-list__skeleton"
         ng-if="!messages.activeThread || messages.activeThread.id === -1">
      <p class="yco-messages__message-list__skeleton__title push-10">Nincs megjeleníthető üzenet</p>

      <p class="yco-messages__message-list__skeleton__subtitle">
        Jelenleg nincs megjeleníthető üzenet. Választhat egy meglévő üzenetváltási szálat a bal oldali listából,
        vagy létrehozhat egy új beszélgetést a mentorprogram további résztvevőivel.</p>
    </div>
  </div>
</div>
