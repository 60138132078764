(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoEnquiry.filter:enquiryStatus
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoEnquiry')
    .filter('enquiryStatus', enquiryStatus);

  function enquiryStatus(enquiryStatuses, _) {
    return function (input) {
      return _.result(_.find(enquiryStatuses, 'name', input), 'label');
    };
  }
}());
