<yco-page-header>Események</yco-page-header>

<div class="content ng-scope">
  <div class="block">
    <div class="block-header">
      <h3 class="block-title">Események</h3>
      <div class="form-group pull-right">
        <label class="css-input switch switch-info">
          Múltbéli események megjelenítése
          <input type="checkbox"
                 class="form-control"
                 name="showExpired"
                 ng-model="listEvents.showExpired"
                 ng-true-value="true"
                 ng-false-value="false"
                 ng-change="listEvents.toggleEvents()"
                 ng-checked="listEvents.showExpired">
          <span></span>
        </label>
      </div>
    </div>
    <div class="block-content">
      <a
        ng-if="listEvents.loggedUser.roleId === listEvents.availableRoles.ADMIN.id"
        class="btn btn-success push-20" ui-sref="newEvent"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>
      <table class="table table-bordered table-striped"
             datatable
             dt-options="listEvents.dtOptions"
             dt-columns="listEvents.dtColumns"
             dt-instance="listEvents.dtInstance"></table>
    </div>
  </div>
</div>
