<div class="modal-header">
  <h3>{{::viewEvent.event.name}}</h3>
</div>
<div class="modal-body yco-events__view__modal__body">
  <table class="table table-bordered">
    <tbody>
    <tr>
      <td><i class="fa fa-calendar"></i><br>Időpont</td>
      <td>{{::viewEvent.event.date}}</td>
    </tr>
    <tr>
      <td><i class="fa fa-map-marker"></i><br>Helyszín</td>
      <td>{{::viewEvent.event.location}}</td>
    </tr>
    <tr>
      <td><i class="fa fa-info-circle"></i><br>Leírás</td>
      <td>{{::viewEvent.event.description}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer" ng-if="!viewEvent.event.canApply">
  Az eseményen való részvétel nem igényel előzetes jelentkezést.
</div>
<div class="modal-footer">
  <button class="btn btn-default"
          type="button"
          ng-click="viewEvent.close()">
    Bezárás
  </button>
  <button class="btn btn-success"
          type="button"
          ng-if="viewEvent.event.canApply && !viewEvent.hasUserApplied"
          ng-click="viewEvent.apply()">
    Jelentkezés az eseményre
  </button>
  <button class="btn btn-danger"
          type="button"
          ng-if="viewEvent.event.canApply && viewEvent.hasUserApplied"
          ng-click="viewEvent.revokeApplication()">
    Jelentkezés visszavonása
  </button>
</div>
