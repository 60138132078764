(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoForm.factory:FormControl
   *
   * @description
   *
   */
  angular
    .module('ycoForm')
    .factory('FormControl', FormControl);

  function FormControl(toastr) {
    function FormControlBase() {
      var processing = false
        , serverValidation = {}
        , successText = '';

      this.isProcessing = function isProcessing() {
        return processing;
      };

      this.process = function process(promise) {
        setProcessing();

        promise.then(onSuccess)
          .then(clearValidation)
          .catch(onFail)
          .finally(clearProcessing);

        return promise;
      };

      this.getServerValidation = function getServerValidation() {
        return serverValidation;
      };

      this.setSuccessText = function setSuccessText(newSuccessText) {
        successText = newSuccessText;
      };

      function onSuccess() {
        if (successText) {
          showToast(successText);
        }
      }

      function showToast(content) {
        toastr.success(content);
      }

      function onFail(response) {
        if (response.status >= 400 && response.status < 500) {
          serverValidation = response.data.error;
        }
      }

      function clearProcessing() {
        processing = false;
      }

      function setProcessing() {
        processing = true;
      }

      function clearValidation() {
        serverValidation = {};
      }
    }

    return FormControlBase;
  }
}());
