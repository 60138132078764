(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:ListUsersCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('ListUsersCtrl', ListUsersCtrl);

  function ListUsersCtrl(DTColumnBuilder, YcoDataTables, $scope, $stateParams, UserRole, _, moment, loggedUser) {
    var vm = this
      , roleFilter = $stateParams.role ? UserRole.getUserRoleBySlug($stateParams.role) : undefined;

    vm.roleFilter = roleFilter;
    vm.currentRole = $stateParams.role;
    vm.isNewUserWithChildCreationAvailable = _.get(roleFilter, 'slug.singular') === 'parent';
    vm.loggedUser = loggedUser.data;
    vm.userRoles = UserRole.getUserRoles();

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'users',
      params: {
        roleIds: _.get(roleFilter, 'id')
      },
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Teljes név')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (user) {
          return user.lastName + ' ' + user.firstName;
        })
      , DTColumnBuilder.newColumn('email').withTitle('Email')
      , DTColumnBuilder.newColumn('roleId').withTitle('Jogosultság')
        .renderWith(function (roleId) {
          var role = UserRole.getUserRoleById(roleId);
          return '<a ui-sref="listUsers({role: \'' + role.slug.plural + '\'})">' +
            '<span class="badge badge-default">' + role.name.singular + '</span>' +
            '</a>';
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn('lastLoginAt').withTitle('Utolsó belépés dátuma')
        .renderWith(function (data) {
          return data ? moment(data).format('YYYY.MM.DD. HH:mm') : 'Még nem lépett be.';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<a class="btn btn-default btn-xs push-5-r"' +
            'ui-sref="viewUser({userId: ' + data.id + '})" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs push-5-r"' +
            'ng-if="listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"' +
            'ui-sref="editUser({userId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-disabled="listUsers.loggedUser.id === ' + data.id + '"' +
            'ui-sref="messages({userId: ' + data.id + '})" uib-tooltip="Üzenet">' +
            '<i class="fa fa-envelope"></i>' +
            '</a>';
        })
    ];
  }
}());
