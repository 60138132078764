(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name moment.filter:moment
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('moment')
    .filter('moment', momentFilter);

  function momentFilter(moment) {
    return function (input, format) {
      return moment(input).format(format);
    };
  }
}());
