(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:AssignEnquiryDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .controller('AssignEnquiryDialogCtrl', AssignEnquiryDialogCtrl);

  function AssignEnquiryDialogCtrl($uibModalInstance, userRoles, User) {
    var vm = this;

    vm.form = {};
    vm.mentorId = null;
    vm.possibleMentors = [];
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close({mentorId: vm.mentorId, possibleMentors: vm.possibleMentors});
    };

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };
  }
}());
