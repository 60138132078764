(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.constant:countyConstant
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .constant('countyConstant', [
      {
        name: 'Baranya'
      },
      {
        name: 'Somogy'
      },
      {
        name: 'Tolna'
      }
    ]);
}());
