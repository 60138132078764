(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:textField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <text-field></text-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('textField', textField);

  function textField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        form: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/text-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function ($scope) {
        var vm = this;
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
