<form name="addParticipant.form" ng-submit="addParticipant.submit()" novalidate>
  <div class="modal-header">
    <h3>Új személy hozzáadása</h3>
  </div>
  <div class="modal-body">
    <p>Kezdje el begépelni egy programban részt vevő személy nevét, majd válasszon a lenyíló listában megjelenő
      lehetőségek közül. A gyermek adatlapjához csak Orvost vagy Korai fejlesztőt adhat hozzá.</p>

    <div class="form-group">
      <yco-input-container>
        <label>Felhasználó</label>
        <ui-select append-to-body="true" name="participant" ng-model="addParticipant.participantId" required>
          <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}
          </ui-select-match>
          <ui-select-choices repeat="user.id as user in addParticipant.possibleParticipants track by $index"
                             refresh="addParticipant.refreshPossibleParticipants($select.search)"
                             refresh-delay="200"
                             minimum-input-length="3">
            <div class="inner-push-5 inner-push-5-t media">
              <div class="media-left">
                <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                     alt="{{user.lastName}} {{user.firstName}}">
              </div>
              <div class="media-right">
                <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
              </div>
              <div class="yco-child__autocomplete__suggestion__email">
                <span>{{user.email}}</span>
              </div>
            </div>
          </ui-select-choices>
        </ui-select>
        <yco-messages form="addParticipant.form" server="addParticipant.getServerValidation()"
                      field-name="participant"></yco-messages>
      </yco-input-container>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="addParticipant.close()">
      Mégse
    </button>
    <yco-button class="btn btn-success"
                type="submit"
                yco-is-processing="addParticipant.isProcessing">
      Hozzáadás
    </yco-button>
  </div>
</form>
