(function () {
  'use strict';

  angular
    .module('ycoChild')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listChildren', {
        url: '/children/list',
        templateUrl: 'yco-child/views/list-children.tpl.html',
        controller: 'ListChildrenCtrl',
        controllerAs: 'listChildren',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('newChild', {
        url: '/children/new',
        templateUrl: 'yco-child/views/new-child.tpl.html',
        controller: 'NewChildCtrl',
        controllerAs: 'newChild',
        data: {
          authenticatedOnly: true
        }
      })
      .state('viewChild', {
        url: '/children/view/:childId',
        templateUrl: 'yco-child/views/view-child.tpl.html',
        controller: 'ViewChildCtrl',
        controllerAs: 'viewChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          child: function (Child, $stateParams) {
            return Child.get({
              id: $stateParams.childId,
              includes: 'parent,fields'
            }).$promise;
          },
          latestActivities: function (Child, $stateParams) {
            return Child.getLatestActivities({id: $stateParams.childId}).$promise;
          },
          participants: function (Child, $stateParams) {
            return Child.getParticipants({id: $stateParams.childId,}).$promise;
          },
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('editChild', {
        url: '/children/edit/:childId',
        templateUrl: 'yco-child/views/edit-child.tpl.html',
        controller: 'EditChildCtrl',
        controllerAs: 'editChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          child: function (Child, $stateParams) {
            return Child.get({
              cache: false,
              id: $stateParams.childId,
              includes: 'parent,fields'
            }).$promise;
          },
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      });
  }
}());
