(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoNavigation.directive:ycoPageHeader
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoNavigation">
   <file name="index.html">
   <yco-page-header></yco-page-header>
   </file>
   </example>
   *
   */
  angular
    .module('ycoNavigation')
    .directive('ycoPageHeader', ycoPageHeader);

  function ycoPageHeader() {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'yco-navigation/directives/yco-page-header-directive.tpl.html'
    };
  }
}());
