<div ng-message="required">A mező kitöltése kötelező</div>
<div ng-message="minlength">A mező tartalma nem elég hosszú</div>
<div ng-message="length">A mező tartalma nem elég hosszú</div>
<div ng-message="maxlength">A mező tartalma túl hosszú</div>
<div ng-message="email">A email cím formátuma nem megfelelő</div>
<div ng-message="min">A mező tartalma nem elég nagy</div>
<div ng-message="minBirthYear">2010 után született gyermekeket fogadunk el.</div>
<div ng-message="weigthOrWeek">36. hét előtt vagy 2500 gramm születési súly alatti gyermekeket fogadunk el.</div>
<div ng-message="max">A mező tartalma túl nagy</div>
<div ng-message="maxDate">A dátum túllépi az engedélyezett tartományt</div>
<div ng-message="number">A mező tartalma nem szám</div>
<div ng-message="passwordMatch">A beírt jelszavak nem egyeznek</div>
<div ng-message="nullType">A mező kitöltése kötelező</div>
