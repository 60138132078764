(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoUser.filter:userRole
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoUser')
    .filter('userRole', userRole);

  function userRole(UserRole, _) {
    return function (input) {
      return _.get(UserRole.getUserRoleById(input), 'name.singular', null);
    };
  }
}());
