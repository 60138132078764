(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:EditPublicationCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoPublication')
    .controller('EditPublicationCtrl', EditPublicationCtrl);

  function EditPublicationCtrl(FormControl, Publication, $q, $state, publication, _, YcoDialog, toastr, UserRole, Auth, appConfig) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.publication = publication.data;
    vm.availableRoles = UserRole.getUserRoles();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var publicationResource = _.defaultsDeep(new Publication(), vm.publication);
      deferred = $q.defer();

      formControl.setSuccessText('A kiadvány sikeresen frissítve.');

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listPublications');
        });

      publicationResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deletePublication = function deletePublication() {
      var publicationResource = _.defaultsDeep(new Publication(), vm.publication);
      deferred = $q.defer();

      YcoDialog.show({
        title: 'Valóban törli a kijelölt kiadványt?',
        content: 'A művelet végrehajtásával a kiadvány adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        publicationResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          toastr.success('A kiadvány sikeresen törölve.');
          $state.go('listPublications');
        });
    };

    /*
    File upload
     */
    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow, type) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      if (type === 'cover') {
        formControl.setSuccessText('A borítókép sikeresen frissítve.');
        vm.hasCoverImage = true;
        vm.publication.imageId = response.data.id;
      }

      if (type === 'file') {
        formControl.setSuccessText('A fájl sikeresen frissítve.');
        vm.hasPublicationFile = true;
        vm.publication.attachmentId = response.data.id;
      }
      deferred.resolve();
    };

    vm.cancelCoverImage = function cancelCoverImage($flow) {
      vm.hasCoverImage = false;
      $flow.cancel();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasPublicationFile = false;
      $flow.cancel();
    };

    vm.cancelPublicationThumbnail = function cancelPublicationThumbnail() {
      vm.publication.image = '';
    };

    vm.cancelPublicationAttachment = function cancelPublicationAttachment() {
      vm.publication.attachment = '';
    };

    vm.isPublicationFileImage = function isPublicationFileImage(file) {
      if (!vm.hasPublicationFile) {
        return false;
      }
      return isFileImage(file.name);
    };

    vm.getFileName = function getFileName() {
      if (!vm.publication.attachment) {
        return false;
      }

      return vm.publication.attachment.split('\\').pop().split('/').pop();
    };

    vm.isPublicationAttachmentImage = function isPublicationAttachmentImage() {
      if (!vm.publication.attachment) {
        return false;
      }

      return isFileImage(vm.publication.attachment);
    };

    function isFileImage(file) {
      var extension = file.split('\\').pop().split('.').pop();
      switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return true;
        default:
          return false;
      }
    }
  }
}());
