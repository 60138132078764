<form name="assignEnquiry.form" ng-submit="assignEnquiry.submit()" novalidate>
  <div class="modal-header">
    <h3>Mentor hozzárendelése</h3>
  </div>
  <div class="modal-body">
    <p>Kezdje el begépelni egy mentor nevét, majd válasszon a lenyíló listában megjelenő lehetőségek közül. A 'Mentés'
      gombra kattintva rendszerünk a kiválasztott mentorhoz rendeli a regisztrációs kérelmet, amelyről emailes
      értesítést kap. A hozzárendelést követően a mentor feladata áttekinteni és feldolgozni az aktuális regisztrációs
      kérelmet.</p>

    <div class="form-group">
      <yco-input-container>
        <label>Mentor</label>
        <ui-select append-to-body="true" name="mentor" ng-model="assignEnquiry.mentorId" required>
          <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}
          </ui-select-match>
          <ui-select-choices repeat="user.id as user in assignEnquiry.possibleMentors track by $index"
                             refresh="assignEnquiry.refreshPossibleMentors($select.search)"
                             refresh-delay="200"
                             minimum-input-length="3">
            <div class="inner-push-5 inner-push-5-t media">
              <div class="media-left">
                <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                     alt="{{user.lastName}} {{user.firstName}}">
              </div>
              <div class="media-right">
                <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
              </div>
              <div class="yco-enquiry__autocomplete__suggestion__email">
                <span>{{user.email}}</span>
              </div>
            </div>
          </ui-select-choices>
        </ui-select>
        <yco-messages form="assignEnquiry.form" server="assignEnquiry.getServerValidation()"
                      field-name="mentor"></yco-messages>
      </yco-input-container>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="assignEnquiry.close()">
      Mégse
    </button>
    <yco-button class="btn btn-success"
                type="submit"
                yco-is-processing="assignEnquiry.isProcessing">
      <i class="fa fa-save push-5-r"></i>Mentés
    </yco-button>
  </div>
</form>
