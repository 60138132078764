(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:ListPublicationsCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoPublication')
    .controller('ListPublicationsCtrl', ListPublicationsCtrl);

  function ListPublicationsCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, userRoles, loggedUser) {
    var vm = this;

    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'publications',
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító').notVisible()
      , DTColumnBuilder.newColumn('image').withTitle('Borítókép')
        .notSortable()
        .renderWith(function (data) {
          return '<img src="' + data + '" class="yco-publications__list__cover-img">';
        })
      , DTColumnBuilder.newColumn('title').withTitle('Cím')
      , DTColumnBuilder.newColumn('content').withTitle('Leírás').withOption('width', '40%')
      , DTColumnBuilder.newColumn('createdAt').withTitle('Létrehozva')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD.');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<a class="btn btn-default btn-xs push-5-r"' +
            'href="' + data.attachment + '" uib-tooltip="Letöltés" download>' +
            '<i class="fa fa-download"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="listPublications.loggedUser.roleId === listPublications.availableRoles.ADMIN.id"' +
            'ui-sref="editPublication({publicationId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());
