<yco-input-container ng-if="!readonly">
  <p class="font-w600 push-5" ng-class="{'yco-child__label__missing-value': field.value === null}">{{::field.name}}</p>

  <label class="css-input css-radio css-radio-default push-10-r">
    <input type="radio"
           name="{{::field.id}}"
           ng-model="field.value"
           ng-value="1">
    <span></span> Igen
  </label>

  <label class="css-input css-radio css-radio-default">
    <input type="radio"
           name="{{::field.id}}"
           ng-model="field.value"
           ng-value="0">
    <span></span> Nem
  </label>
</yco-input-container>

<div ng-if="readonly">
  <p class="font-w600 push-5">{{::field.name}}</p>

  <p>
    <span ng-if="field.prefix">{{::field.prefix}}</span>
    {{::field.value ? 'Igen' : 'Nem'}}
    <span ng-if="field.suffix">{{::field.suffix}}</span>
  </p>
</div>
