<yco-page-header>Új esemény létrehozása</yco-page-header>

<div class="content">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="block">
        <div class="block-header">
          <h3 class="block-title">Felhasználói adatok</h3>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <p>Az alábbi űrlapon lehetősége nyílik új eseményt létrehoznia a rendszerben.</p>

              <form name="newEvent.form" ng-submit="newEvent.submit()" novalidate>
                <yco-input-container>
                  <label>Esemény neve</label>
                  <input class="form-control" type="text" name="name" ng-model="newEvent.event.name" required>
                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"
                                field-name="name"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Születési dátum (éééé-hh-nn)</label>
                  <div class="input-group date">
                    <input class="form-control" type="text"
                           ng-model="newEvent.dateTime"
                           is-open="newEvent.popupDateIsOpen"
                           uib-datepicker-popup="{{newEvent.dateFormat}}"
                           close-text="Bezárás"
                           placeholder="Válasszon dátumot">
                    <span class="input-group-addon" ng-click="newEvent.openDatePopup()">
                      <span class="fa fa-calendar"></span>
                    </span>
                  </div>
                </yco-input-container>

                <yco-input-container>
                  <label>Időpont</label>
                  <div uib-timepicker
                       ng-model="newEvent.dateTime"
                       ng-change="newEvent.dateTimeOnlyChanged()"
                       show-meridian="ismeridian">
                  </div>
                </yco-input-container>

                <yco-input-container>
                  <label>Helyszín</label>
                  <input class="form-control" type="text" name="location" ng-model="newEvent.event.location" required>
                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"
                                field-name="location"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Leírás</label>
                  <textarea name="description" class="form-control" ng-model="newEvent.event.description"
                            required></textarea>
                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"
                                field="description"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label class="css-input switch switch-info">
                    <input type="checkbox"
                           class="form-control"
                           name="canApply"
                           ng-model="newEvent.event.canApply"
                           ng-true-value="1"
                           ng-false-value="0"
                           ng-checked="newEvent.event.canApply">
                    <span></span> Előzetes jelentkezés szükséges az eseményhez
                  </label>
                </yco-input-container>

                <yco-input-container>
                  <label class="css-input switch switch-info">
                    <input type="checkbox"
                           class="form-control"
                           name="public"
                           ng-model="newEvent.event.public"
                           ng-true-value="1"
                           ng-false-value="0"
                           ng-checked="newEvent.event.public"><span></span> Nyílvános
                  </label>
                </yco-input-container>
                <yco-input-container ng-if="!newEvent.event.public">
                  <label>Jogosultság</label>
                  <select name="roles" class="form-control" ng-model="newEvent.event.roleIds" multiple>
                    <option value="{{role.id}}"
                            ng-selected="role.id == newEvent.event.roleIds"
                            ng-repeat="role in newEvent.availableRoles">
                      {{role.name.singular}}
                    </option>
                  </select>
                  <div class="help-block">Az eseményt csak az itt megjelölt jogosultsági körrel
                    rendelkező felhasználók fogják tudni megtekinteni. A listából több jogosultság
                    is kiválasztható egyszerre. Amennyiben egy jogosultság sem kerül kiválasztásra,
                    a kiadvány bármely belépett felhasználó számára látható lesz.
                  </div>
                </yco-input-container>

                <hr>

                <div class="push text-center">
                  <yco-button class="btn btn-success" type="submit" yco-is-processing="newEvent.isProcessing()">
                    Esemény létrehozása
                  </yco-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
