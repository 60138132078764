(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:bnoField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <bno-field></bno-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('bnoField', bnoField);

  function bnoField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        container: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/bno-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function (Child, $scope, _, BnoService, $q, $rootScope) {
        var vm = this;

        vm.possibleBnos = [];

        if ($scope.field.value) {
          if (_.isArray($scope.field.value) && !_.isEmpty($scope.field.value)) {
            $q.all(_.map($scope.field.value, BnoService.getBnoById))
              .then(function (bnos) {
                vm.possibleBnos = bnos;
              });
          } else {
            BnoService.getBnoById($scope.field.value)
              .then(function (bno) {
                vm.possibleBnos = [bno];
              });
          }
        }

        vm.refreshPossibleBnos = function refreshPossibleBnos(searchTerm) {
          if (!searchTerm) {
            return;
          }

          return BnoService.searchBno(searchTerm)
            .then(function (bnos) {
              vm.possibleBnos = bnos;
            });
        };

        if ($scope.field.multiselect && !_.isEmpty($scope.field.exampleField)) {
          $scope.$watch(function () {
            return $scope.field.value;
          }, function (newVal, oldVal) {
            var changedElement = _.xor(newVal, oldVal);
            $rootScope.$broadcast('child.fieldWithAddByParentValueChanged', $scope.field.id, $scope.container, _.get($scope, 'field.value.length', 0), $scope.field.exampleField, changedElement);
          });
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
