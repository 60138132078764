(function () {
  'use strict';

  angular
    .module('ycoAuth')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/login',
        templateUrl: 'yco-auth/views/login.tpl.html',
        controller: 'LoginCtrl',
        controllerAs: 'login',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      })
      .state('logout', {
        url: '/logout',
        controller: 'LogoutCtrl',
        controllerAs: 'logout',
        data: {
          authenticatedOnly: true
        }
      })
      .state('requestPasswordChange', {
        url: '/request-password-change',
        templateUrl: 'yco-auth/views/request-password-change.tpl.html',
        controller: 'RequestPasswordChangeCtrl',
        controllerAs: 'requestPasswordChange',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      })
      .state('changePassword', {
        url: '/change-password/:token',
        templateUrl: 'yco-auth/views/change-password.tpl.html',
        controller: 'ChangePasswordCtrl',
        controllerAs: 'changePassword',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      });
  }
}());
