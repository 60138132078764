(function () {
  'use strict';

  angular
    .module('ycoUser')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/dashboard',
        templateUrl: 'yco-user/views/dashboard.tpl.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          statistics: function (Statistics) {
            return Statistics.getBasicStatistics().$promise;
          },
          mentorStatistics: function (Statistics) {
            return Statistics.getMentorStatistics().$promise;
          },
          unreadThreads: function (Thread) {
            return Thread.getUnreadThreads().$promise;
          },
          newEnquiries: function (Enquiry) {
            return Enquiry.getEnquiries({
              status: 'newEnquiry,waitingForApprovement',
              limit: -1
            }).$promise;
          },
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          },
          userRoles: function (UserRole) {
            return UserRole.getUserRoles();
          }
        }
      })
      .state('viewUser', {
        url: '/users/view/:userId',
        templateUrl: 'yco-user/views/view-user.tpl.html',
        controller: 'ViewUserCtrl',
        controllerAs: 'viewUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: function ($stateParams, LoggedUser, User) {
            var user;

            if ($stateParams.userId === 'me') {
              user = LoggedUser.getUser().$promise;
            } else {
              user = User.get({id: $stateParams.userId}).$promise;
            }

            return user;
          },
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('editUser', {
        url: '/users/edit/:userId',
        templateUrl: 'yco-user/views/edit-user.tpl.html',
        controller: 'EditUserCtrl',
        controllerAs: 'editUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: function ($stateParams, LoggedUser, User) {
            return User.get({id: $stateParams.userId, includes: 'mentor'}).$promise;
          },
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('listUsers', {
        url: '/users/list/:role',
        templateUrl: 'yco-user/views/list-users.tpl.html',
        controller: 'ListUsersCtrl',
        controllerAs: 'listUsers',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('newUser', {
        url: '/users/new/:role',
        templateUrl: 'yco-user/views/new-user.tpl.html',
        controller: 'NewUserCtrl',
        controllerAs: 'newUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          currentUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('newUserWithChild', {
        url: '/users/newWithChild',
        templateUrl: 'yco-user/views/new-user-with-child.tpl.html',
        controller: 'NewUserWithChildCtrl',
        controllerAs: 'newUserWithChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          currentUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      });
  }
}());
