(function () {
  'use strict';

  angular
    .module('ycoEnquiry')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listEnquiries', {
        url: '/enquiries/list',
        templateUrl: 'yco-enquiry/views/list-enquiries.tpl.html',
        controller: 'ListEnquiriesCtrl',
        controllerAs: 'listEnquiries',
        data: {
          authenticatedOnly: true
        }
      })
      .state('editEnquiry', {
        url: '/enquiries/edit/:enquiryId',
        templateUrl: 'yco-enquiry/views/edit-enquiry.tpl.html',
        controller: 'EditEnquiryCtrl',
        controllerAs: 'editEnquiry',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          enquiry: function ($stateParams, LoggedUser, Enquiry) {
            return Enquiry.getEnquiry({id: $stateParams.enquiryId}).$promise;
          },
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      });
  }
}());
