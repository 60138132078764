<div class="content bg-image ng-scope" style="background-image: url('images/user-bg.jpg');">
  <div class="push-50-t push-15 clearfix">
    <div class="push-15-r pull-left">
      <img class="img-avatar img-avatar-thumb" src="images/avatar.png" alt="">
    </div>
    <h1 class="h2 text-white push-5-t">{{::viewUser.user.lastName}} {{::viewUser.user.firstName}}</h1>

    <div>
      <a ui-sref="listUsers({role: viewUser.role.slug.plural})">
        <span class="badge badge-default">{{::viewUser.role.name.singular}}</span>
      </a>
    </div>
  </div>
</div>

<div class="content">
  <div class="block">
    <div
      class="block-content">
      <div class="push-20">
        <yco-button
          class="btn btn-default" type="button" ui-sref="listUsers({role: viewUser.role.slug.plural })">
          <i class="fa fa-arrow-left push-10-r"></i>Vissza a {{::viewUser.role.name.plural}} listára
        </yco-button>
        <yco-button
          ng-if="viewUser.user.roleId === viewUser.availableRoles.ADMIN.id
        || viewUser.isOwnAccount"
          class="btn btn-info" type="button" ui-sref="editUser({userId: viewUser.user.id})">
          <i class="fa fa-pencil push-10-r"></i>Szerkesztés
        </yco-button>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div class="block block-bordered">
    <div class="block-header">
      <h3 class="block-title">Felhasználói adatok</h3>
    </div>
    <div class="block-content">
      <div class="row">
        <div class="col-md-3">
          <p class="font-w600 push-5">Teljes név</p>

          <p>{{::viewUser.user.lastName}} {{::viewUser.user.firstName}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Email</p>

          <p>{{::viewUser.user.email}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Telefonszám</p>

          <p>{{::viewUser.user.phone}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Lakcím</p>

          <p>{{::viewUser.user.address}}</p>
        </div>

        <div class="col-md-3" ng-if="viewUser.user.county.length">
          <p class="font-w600 push-5">Megye</p>

          <p>{{::viewUser.user.county}}</p>
        </div>

        <div class="col-md-3"
             ng-if="viewUser.user.city.length">
          <p class="font-w600 push-5">Település</p>

          <p>{{::viewUser.user.city}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Jogosultság</p>

          <p>{{::viewUser.role.name.singular}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Regisztráció dátuma</p>

          <p>{{::viewUser.user.createdAt | moment : 'YYYY.MM.DD. HH:mm'}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Utolsó belépés dátuma</p>

          <p>{{::viewUser.user.lastLoginAt | moment : 'YYYY.MM.DD. HH:mm'}}</p>
        </div>

        <div class="col-md-3">
          <p class="font-w600 push-5">Bemutatkozás</p>

          <p>{{::viewUser.user.note}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
