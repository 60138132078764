(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoChild.filter:childStatus
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoChild')
    .filter('childStatus', childStatus);

  function childStatus(childStatuses) {
    return function (input) {
      return _.find(childStatuses, 'name', input);
    };
  }
}());
