<yco-page-header>{{::listUsers.roleFilter.name.plural || 'Összes felhasználó'}}</yco-page-header>

<div class="content ng-scope">
  <div class="block">
    <div class="block-header">
      <h3 class="block-title">Felhasználók</h3>
    </div>
    <div class="block-content">
      <a class="btn btn-success push-20"
         ng-if="listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"
         ui-sref="newUser({role:listUsers.currentRole})"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>
      <a class="btn btn-success push-20"
         ng-if="listUsers.isNewUserWithChildCreationAvailable
             && listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"
         ui-sref="newUserWithChild">
        <i class="fa fa-plus push-5-r"></i>Új szülő és mentorált felvitele
      </a>

      <table class="table table-bordered table-striped" datatable dt-options="listUsers.dtOptions"
             dt-columns="listUsers.dtColumns"></table>
    </div>
  </div>
</div>
