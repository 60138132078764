(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEvent.factory:Event
   *
   * @description
   *
   */
  angular
    .module('ycoEvent')
    .factory('Event', Event);

  function Event(ycoResource) {
    var url = 'events/'
      , EventBase;

    EventBase = ycoResource('event', url + ':id', {id: '@id'},
      {
        getEventWithParticipants: {
          method: 'GET',
          url: url + ':id',
          params: {
            includes: 'participants'
          }
        },
        applyForAnEvent: {
          method: 'POST',
          url: url + ':id/users'
        },
        deleteParticipantFromAnEvent: {
          method: 'DELETE',
          url: url + ':id/users/:userId'
        },
        query: {
          cache: false
        }
      }
    );

    return EventBase;
  }
}());
