<yco-page-header>Új felhasználó létrehozása</yco-page-header>

<div class="content">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="block">
        <div class="block-header">
          <h3 class="block-title">Felhasználói adatok</h3>
        </div>
        <div class="block-content">
          <div class="row">
            <div class="col-md-6 col-md-offset-3">
              <p>Az alábbi űrlapon lehetősége nyílik új felhasználót létrehoznia a rendszerben. Kérjük,
                maradéktalanul töltse ki az összes mezőt, különösen ügyelve, hogy az <em>Email</em>
                mezőbe megfelelő email cím kerüljön, hiszen a rendszer erre a címre fogja elküldeni az
                első belépéshez szükséges adatokat.</p>

              <form name="newUser.form" ng-submit="newUser.submit()" novalidate>
                <yco-input-container>
                  <label>Vezetéknév</label>
                  <input class="form-control" type="text" name="lastName" ng-model="newUser.user.lastName" required>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field-name="lastName"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Keresztnév</label>
                  <input class="form-control" type="text" name="firstName" ng-model="newUser.user.firstName" required>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field-name="firstName"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Email</label>
                  <input class="form-control" type="email" name="email" ng-model="newUser.user.email" required>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field-name="email"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Jogosultság</label>
                  <select name="roleId" class="form-control" ng-model="newUser.user.roleId" required>
                    <option value="{{role.id}}"
                            ng-selected="newUser.user.roleId === role.id"
                            ng-repeat="role in newUser.availableRoles">
                      {{role.name.singular}}
                    </option>
                  </select>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field="roleId"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Település</label>
                  <input class="form-control"
                         type="text"
                         name="city"
                         ng-model="newUser.user.city"
                         required>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field-name="city"></yco-messages>
                </yco-input-container>

                <yco-input-container ng-if="newUser.user.roleId == newUser.availableRoles.PARENT.id">
                  <label>Hozzárendelt mentor</label>

                  <ui-select append-to-body="true"
                             name="mentorId"
                             class="yco-user__autocomplete__select"
                             ng-disabled="newUser.currentUser.roleId === newUser.userRoles.MENTOR.id"
                             ng-model="newUser.user.mentorId" required>
                    <ui-select-match allow-clear="false">
                      {{$select.selected.lastName}} {{$select.selected.firstName}}
                    </ui-select-match>
                    <ui-select-choices repeat="user.id as user in newUser.possibleMentors track by $index"
                                       refresh="newUser.refreshPossibleMentors($select.search)"
                                       refresh-delay="200"
                                       minimum-input-length="3">
                      <div class="inner-push-5 inner-push-5-t media">
                        <div class="media-left">
                          <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                               alt="{{user.lastName}} {{user.firstName}}">
                        </div>
                        <div class="media-right">
                          <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                          <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                        </div>
                        <div class="yco-user__autocomplete__suggestion__email">
                          <span>{{user.email}}</span>
                        </div>
                      </div>
                    </ui-select-choices>
                  </ui-select>
                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"
                                field-name="mentorId"></yco-messages>
                </yco-input-container>

                <hr>

                <div class="push text-center">
                  <yco-button class="btn btn-success" type="submit" yco-is-processing="newUser.isProcessing()">
                    Felhasználó létrehozása
                  </yco-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
