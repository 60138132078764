(function () {
  'use strict';

  angular
    .module('ycoPublication')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listPublications', {
        url: '/publications',
        templateUrl: 'yco-publication/views/list-publications.tpl.html',
        controller: 'ListPublicationsCtrl',
        controllerAs: 'listPublications',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('newPublication', {
        url: '/publications/new',
        templateUrl: 'yco-publication/views/new-publication.tpl.html',
        controller: 'NewPublicationCtrl',
        controllerAs: 'newPublication',
        data: {
          authenticatedOnly: true
        }
      })
      .state('editPublication', {
        url: '/publications/:publicationId/edit',
        templateUrl: 'yco-publication/views/edit-publication.tpl.html',
        controller: 'EditPublicationCtrl',
        controllerAs: 'editPublication',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          publication: function ($stateParams, Publication) {
            var publicationId = $stateParams.publicationId;

            return Publication.get({id: publicationId}).$promise;
          }
        }
      });
  }
}());
