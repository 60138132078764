<div class="yco-auth">
  <div class="text-center">
    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>
  </div>

  <div class="content overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-md-offset-3">
          <div class="block block-themed">
            <div class="block-header bg-primary">
              <h3 class="block-title">Elfelejtett jelszó</h3>
            </div>
            <div class="block-content block-content-full block-content-narrow">
              <form class="push-30-t push-50"
                    name="changePassword.form"
                    ng-submit="changePassword.submit()"
                    novalidate>
                <p>A következő űrlapon beállíthatja fiókja új jelszavát. Az űrlap elküldését követően új
                  jelszavával fog tudni bejelentkezni a rendszerbe.</p>

                <yco-input-container>
                  <label>Új jelszó</label>
                  <input class="form-control"
                         type="password"
                         name="password"
                         ng-model="changePassword.password"
                         required>
                  <yco-messages form="changePassword.form"
                                server="changePassword.getServerValidation()"
                                field-name="password"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Új jelszó megerősítése</label>
                  <input class="form-control"
                         type="password"
                         name="confirmPassword"
                         ng-model="changePassword.passwordConfirmation"
                         required
                         match-password="password">
                  <yco-messages form="changePassword.form"
                                server="changePassword.getServerValidation()"
                                field-name="confirmPassword"></yco-messages>
                </yco-input-container>

                <div class="form-group text-center">
                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="changePassword.isProcessing()">
                    Elküldés
                  </yco-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
