(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:AddTextEntryDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('AddTextEntryDialogCtrl', AddTextEntryDialogCtrl);

  function AddTextEntryDialogCtrl($uibModalInstance) {
    var vm = this;

    vm.form = {};
    vm.entry = {};

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      $uibModalInstance.close(vm.entry);
    };
  }
}());
