(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:ChangePasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoAuth')
    .controller('ChangePasswordCtrl', ChangePasswordCtrl);

  function ChangePasswordCtrl(FormControl, Auth, $q, $stateParams, $state, toastr) {
    var vm = this
      , formControl = new FormControl()
      , token = $stateParams.token;

    vm.form = {};

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function () {
      var deferred = $q.defer()
        , options;

      if (vm.form.$invalid) {
        return;
      }

      options = {
        token: token,
        password: vm.password,
        passwordConfirmation: vm.passwordConfirmation
      };

      Auth.changePassword(options).then(deferred.resolve, deferred.reject);

      formControl
        .process(deferred.promise)
        .then(function () {
          toastr.success('Jelszavát sikeresen megváltoztattuk.');
          $state.go('login');
        })
        .catch(function (response) {
          if (response.status === 404) {
            toastr.error('Jelszóváltoztatási azonosítója elévült vagy érvénytelen. Kérjük, igényeljen új jelszóváltoztatási azonosítót az Elfelejtett jelszó oldalon.');
            $state.go('login');
          } else {
            toastr.error('Vártalan hiba történt jelszóváltoztatási kérelme feldolgozása közben. Kérjük, próbálja később.');
          }
        });
    };
  }
}());
