<yco-input-container ng-if="!readonly">
  <label for="{{::field.id}}" ng-class="{'yco-child__label__missing-value': field.value === null}">{{::field.name}}</label>

  <div ng-if="field.multiselect">
    <ui-select id="{{::field.id}}"
               name="{{::field.name}}"
               ng-model="field.value"
               multiple
               ng-disabled="field.readonly"
               ng-required="field.validation.required">
      <ui-select-match allow-clear="true">{{$item.code}} - {{$item.name}}</ui-select-match>
      <ui-select-choices repeat="bno.id as bno in fieldCtrl.possibleBnos track by $index"
                         refresh="fieldCtrl.refreshPossibleBnos($select.search)"
                         refresh-delay="200"
                         minimum-input-length="3">
        <div class="inner-push-5 inner-push-5-t">
          <span class="font-w600">{{bno.name}}</span><br/>
          <small>{{bno.code}}</small>
        </div>
      </ui-select-choices>
    </ui-select>
  </div>

  <div ng-if="!field.multiselect">
    <ui-select append-to-body="true"
               id="{{::field.id}}"
               name="{{::field.name}}"
               ng-model="field.value"
               ng-disabled="field.readonly"
               ng-required="field.validation.required">
      <ui-select-match allow-clear="true">{{$select.selected.code}} - {{$select.selected.name}}</ui-select-match>
      <ui-select-choices repeat="bno.id as bno in fieldCtrl.possibleBnos track by $index"
                         refresh="fieldCtrl.refreshPossibleBnos($select.search)"
                         refresh-delay="200"
                         minimum-input-length="3">
        <div class="inner-push-5 inner-push-5-t">
          <span class="font-w600">{{bno.name}}</span><br/>
          <small>{{bno.code}}</small>
        </div>
      </ui-select-choices>
    </ui-select>
  </div>
</yco-input-container>

<div ng-if="readonly">
  <p class="font-w600 push-5">{{::field.name}}</p>

  <ul>
    <li ng-repeat="row in fieldCtrl.possibleBnos">
      {{::row.code}} - {{::row.name}}
    </li>
  </ul>
</div>
