(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:NewChildCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('NewChildCtrl', NewChildCtrl);

  function NewChildCtrl(FormControl, Child, User, userRoles, toastr, $state, $q, _, $scope) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.child = new Child();
    vm.possibleParents = User.getParents().data;
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.child.parentId = _.get(vm.child, 'parent.data.id', null);

    formControl.setSuccessText('Gyermek létrehozása sikeresen megtörtént.');

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.datePickerOptions = {
      minDate: moment('2011').toDate(),
      maxDate: new Date()
    };

    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.child.dateOfBirth = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    $scope.$watch(function () {
      if (moment(vm.child.dateOfBirth).year() >= 2010) {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', true);
      } else {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', false);
      }
    }, vm.child.dateOfBirth);

    $scope.$watch(function () {
      if (vm.child.birthWeek > 36 && vm.child.birthWeight > 2500) {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', true);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', true);
      }
    }, vm.form.birthWeek);

    $scope.$watch(function () {
      if (vm.child.birthWeek > 36 && vm.child.birthWeight > 2500) {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', true);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', true);
      }
    }, vm.form.birthWeight);

    vm.submit = function submit($event) {
      $event.preventDefault();
      var deferred = $q.defer()
        , childResource;

      if (vm.form.$invalid) {
        return;
      }
      vm.child.birthWeek = parseInt(vm.child.birthWeek);
      vm.child.birthYear = parseInt(vm.child.birthYear);
      vm.child.birthWeight = parseInt(vm.child.birthWeight);
      childResource = _.assign(new Child(), vm.child);
      childResource.dateOfBirth = moment(vm.child.dateOfBirth).format('YYYY-MM-DD HH:mm:ss');
      childResource.birthYear = moment(vm.child.dateOfBirth).year();

      formControl.process(deferred.promise)
        .then(function () {
          $state.go('listChildren');
        })
        .catch(function () {
          toastr.error('Hiba történt a gyermek létrehozása során, kérem próbálja később!');
        });
      childResource.$save(deferred.resolve, deferred.reject);
    };

    vm.refreshPossibleParents = function refreshPossibleParents(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.PARENT.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParents = response.data;
        });
    };
  }
}());
