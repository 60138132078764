(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name koraszulottmentorExtranet.controller:AppCtrl
   *
   * @description
   *
   */
  angular
    .module('koraszulottmentorExtranet')
    .controller('AppCtrl', AppCtrl);

  function AppCtrl($scope, Auth, appConfig) {
    var vm = this;

    vm.appConfig = appConfig;

    Auth.initTokenExpiryCheck();

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    function onStateChangeSuccess(event, toState) {
      vm.hasNavigation = toState.data && toState.data.skipNavigation ? !toState.data.skipNavigation : true;
    }
  }
}());
