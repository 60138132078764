<yco-page-header>Esemény szerkesztése</yco-page-header>

<form name="editEvent.form" ng-submit="editEvent.submit()" novalidate>
  <div class="content">
    <div class="block">
      <div
        class="block-content">
        <div class="push-20">
          <yco-button
            class="btn btn-default" type="button" ui-sref="listEvents">
            <i class="fa fa-arrow-left push-10-r"></i>Vissza az Események listára
          </yco-button>
          <yco-button class="btn btn-success" type="submit" yco-is-processing="editEvent.isProcessing()">
            <i class="fa fa-save push-10-r"></i>Mentés
          </yco-button>

          <div class="pull-right">
            <yco-button class="btn btn-danger" type="button" ng-click="editEvent.deleteEvent()">
              <i class="fa fa-trash push-10-r"></i>Törlés
            </yco-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="row">
      <div class="col-md-8">
        <div class="block">
          <div class="block-header">
            <h3 class="block-title">Esemény adatok</h3>
          </div>
          <div class="block-content">
            <div class="row">

              <div class="col-md-4">
                <div class="form-group">
                  <label>Esemény neve</label>
                  <input type="text" class="form-control" name="name" ng-model="editEvent.event.name" required>
                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"
                                field="name"></yco-messages>
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label>Helyszín</label>
                  <input type="text" class="form-control" name="location" ng-model="editEvent.event.location"
                         required>
                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"
                                field="location"></yco-messages>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Dátum</label>

                  <div class="input-group date">
                    <input class="form-control" type="text" name="date"
                           ng-model="editEvent.dateTime"
                           is-open="editEvent.popupDateIsOpen"
                           uib-datepicker-popup="{{editEvent.dateFormat}}"
                           close-text="Bezárás"
                           placeholder="Válasszon dátumot">
                    <span class="input-group-addon" ng-click="editEvent.openDatePopup()">
                      <span class="fa fa-calendar"></span>
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label>Időpont</label>

                  <div uib-timepicker
                       ng-model="editEvent.dateTime"
                       ng-change="editEvent.dateTimeOnlyChanged()"
                       show-meridian="ismeridian">
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label>Leírás</label>
                  <textarea class="form-control" rows="10" name="description" ng-model="editEvent.event.description"
                            required></textarea>
                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"
                                field="description"></yco-messages>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="block">
          <div class="block-header">
            <h3 class="block-title">További adatok</h3>
          </div>
          <div class="block-content">
            <div class="form-group">
              <label class="css-input switch switch-info">
                <input type="checkbox"
                       class="form-control"
                       name="canApply"
                       ng-model="editEvent.event.canApply"
                       ng-true-value="1"
                       ng-false-value="0"
                       ng-checked="editEvent.event.canApply">
                <span></span> Előzetes jelentkezés szükséges az eseményhez
              </label>
            </div>
            <div class="form-group">
              <label class="css-input switch switch-info"
                     uib-tooltip="Amennyiben az esemény nyilvános, a rendszer bármely felhasználója láthatja azt. Ellenkező esetben az eseményhez való hozzáférés jogosultsági szinthez kötött, amelyeket a mező alatt megjelenő listában szabhat meg."
                     tooltip-placement="bottom">
                <input type="checkbox"
                       class="form-control"
                       name="public"
                       ng-model="editEvent.event.public"
                       ng-true-value="1"
                       ng-false-value="0"
                       ng-checked="editEvent.event.public">
                <span></span> Nyilvános <i class="fa fa-question-circle text-muted"></i>
              </label>
            </div>
            <div class="form-group" ng-if="!editEvent.event.public">
              <label>Jogosultság</label>

              <select name="roles" class="form-control" ng-model="editEvent.event.roleIds" multiple
                      ng-required="editEvent.event.public">
                <option value="{{role.id}}"
                        ng-selected="role.id == editEvent.event.roleIds"
                        ng-repeat="role in editEvent.availableRoles">
                  {{role.name.singular}}
                </option>
              </select>

              <div class="help-block">Kérjük, válassza ki a listából azokat a jogosultsági köröket, amellyel rendelkező
                felhasználók hozzáférhetnek az eseményhez.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="content">
  <div class="block">
    <div class="block-header">
      <ul class="block-options">
        <li><a href="{{editEvent.mailtoParticipantsLink}}" class="btn btn-xs"><i class="fa fa-reply-all push-5-r"></i>Email
          a résztvevőknek</a></li>
      </ul>
      <h3 class="block-title">Résztvevők</h3>
    </div>
    <div class="block-content">
      <p ng-if="!editEvent.hasParticipants()">Nincs jelentkező ehhez az eseményhez.</p>
      <table class="table table-bordered" ng-if="editEvent.hasParticipants()">
        <thead>
        <tr>
          <th>Név</th>
          <th>Email</th>
          <th>Törlés</th>
        </tr>
        </thead>
        <tbody>
        <tr ng-repeat="participant in editEvent.participants">
          <td>{{::participant.lastName}} {{::participant.firstName}}</td>
          <td>{{::participant.email}}</td>
          <td>
            <button class="btn btn-default btn-xs"
                    ng-click="editEvent.revokeApplication(participant.id)"
                    uib-tooltip="Törlés">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
