(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoResource
   * @description
   *
   */
  angular
    .module('ycoResource', [
      'ngResource',
      'lodash'
    ]);
}());
