(function () {
  'use strict';

  angular
    .module('koraszulottmentorExtranet')
    .config(configDebugLevel)
    .config(configApi)
    .config(configEnvironment)
    .config(configJwtHelper)
    .config(configLoadingBar)
    .config(configSelects)
    .config(configTooltips)
    .config(configToasts);

  function configDebugLevel($compileProvider, $logProvider, appConfig, $analyticsProvider) {
    $analyticsProvider.developerMode(appConfig.debug);
    $compileProvider.debugInfoEnabled(appConfig.debug);
    $logProvider.debugEnabled(appConfig.debug);
  }

  function configApi(appConfig, ycoResourceProvider) {
    ycoResourceProvider.setBaseUrl(appConfig.api.url);
  }

  function configEnvironment($httpProvider, appConfig) {
    if (appConfig.cors) {
      $httpProvider.defaults.withCredentials = true;
    }
  }

  function configJwtHelper(jwtInterceptorProvider, $httpProvider, jwtOptionsProvider, appConfig) {
    'ngInject';
    jwtInterceptorProvider.tokenGetter = function (store) {
      return store.get(appConfig.authTokenKey);
    };

    /* eslint-disable angular/ng_module_getter */
    /* eslint-disable angular/ng_di */
    jwtOptionsProvider.config({
      unauthenticatedRedirector: ['$state', function ($state) {
        $state.go('login');
      }],
      whiteListedDomains: appConfig.api.url
    });
    /* eslint-enable angular/ng_di */
    /* eslint-enable angular/ng_module_getter */

    $httpProvider.interceptors.push('jwtInterceptor');
  }

  function configLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }

  function configSelects(uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
  }

  function configTooltips($uibTooltipProvider) {
    $uibTooltipProvider.options({appendToBody: true});
  }

  function configToasts(toastrConfig) {
    angular.extend(toastrConfig, {
      extendedTimeOut: 5000,
      positionClass: 'toast-bottom-right',
      timeOut: 5000
    });
  }
}());
