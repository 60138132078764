(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoMessage.factory:Thread
   *
   * @description
   *
   */
  angular
    .module('ycoMessage')
    .factory('Thread', Thread);

  function Thread(ycoResource) {
    var url = 'message-threads/'
      , ThreadBase;

    ThreadBase = ycoResource('thread', url + ':id', {id: '@id'}, {
      getThreads: {
        cache: false,
        params: {
          order: 'desc',
          orderBy: 'createdAt',
          includes: 'participants,user,child',
          limit: -1
        }
      },
      getMessagesByThreadId: {
        url: url + ':id/messages',
        cache: false,
        params: {
          limit: -1
        }
      },
      addMessageToThread: {
        url: url + ':id/messages',
        method: 'POST'
      },
      removeParticipantFromThread: {
        url: url + ':threadId/users/:userId',
        method: 'DELETE'
      },
      getUnreadThreads: {
        url: url + 'unread',
        method: 'GET',
        cache: false,
        params: {
          order: 'desc',
          orderBy: 'createdAt',
          includes: 'participants,child'
        }
      },
      deleteMessageThread: {
        url: url + ':threadId',
        method: 'DELETE'
      }
    });

    return ThreadBase;
  }
}());
