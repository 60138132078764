<yco-page-header>Új mentorált létrehozása</yco-page-header>
<form name="newChild.form" ng-submit="newChild.submit($event)" novalidate>
  <div class="content">
    <div class="block block-themed">
      <div class="block-content">
        <div class="push-20">
          <yco-button class="btn btn-success"
                      type="submit"
                      yco-is-processing="newChild.isProcessing()">
            <i class="fa fa-save push-5-r"></i>Létrehozás
          </yco-button>

          <button class="btn btn-default"
                  type="button"
                  ui-sref="listChildren">
            Vissza a mentoráltak listára
          </button>
        </div>
      </div>
    </div>
    <div class="block block-themed">
      <div class="block-header bg-flat">
        <h3 class="block-title">
          Személyes adatok
        </h3>
      </div>

      <div class="block-content clearfix">
        <div class="row">
          <div class="col-md-3">
            <yco-input-container>
              <label>Vezetéknév</label>
              <input class="form-control mouseflow" type="text" name="lastName" ng-model="newChild.child.lastName"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="lastName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Keresztnév</label>
              <input class="form-control mouseflow" type="text" name="firstName" ng-model="newChild.child.firstName"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="firstName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Anyja neve</label>
              <input class="form-control mouseflow" type="text" name="motherName" ng-model="newChild.child.motherName"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="motherName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Születési dátum (éééé-hh-nn)</label>
              <div class="input-group date">
                <input class="form-control mouseflow" type="text"
                       name="dateOfBirth"
                       ng-model="newChild.child.dateOfBirth"
                       is-open="newChild.popupDateIsOpen"
                       uib-datepicker-popup="{{newChild.dateFormat}}"
                       datepicker-options="newChild.datePickerOptions"
                       close-text="Bezárás"
                       placeholder="Válasszon dátumot">
                <span class="input-group-addon" ng-click="newChild.openDatePopup()">
                      <span class="fa fa-calendar"></span>
                    </span>
              </div>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="dateOfBirth"></yco-messages>
            </yco-input-container>
          </div>
        </div>
        <div class="row">

          <div class="col-md-3">
            <yco-input-container>
              <label>Lakcím</label>
              <input class="form-control mouseflow" type="text" name="address" ng-model="newChild.child.address"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="address"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Születési hét</label>
              <input class="form-control mouseflow" type="number" name="birthWeek" ng-model="newChild.child.birthWeek"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="birthWeek"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Születési súly (g)</label>
              <input class="form-control mouseflow" type="number" name="birthWeight" ng-model="newChild.child.birthWeight"
                     required>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="birthWeight"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Szülő</label>
              <input class="form-control hidden" type="text" name="parentId" ng-model="newChild.child.parentId"
                     required>
              <ui-select append-to-body="true" name="parentId" class="mouseflow" ng-model="newChild.child.parentId" required>
                <ui-select-match allow-clear="true">
                  {{$select.selected.lastName}} {{$select.selected.firstName}}
                </ui-select-match>
                <ui-select-choices repeat="user.id as user in newChild.possibleParents track by $index"
                                   refresh="newChild.refreshPossibleParents($select.search)"
                                   refresh-delay="200"
                                   minimum-input-length="3">
                  <div class="inner-push-5 inner-push-5-t media">
                    <div class="media-left">
                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                           alt="{{user.lastName}} {{user.firstName}}">
                    </div>
                    <div class="media-right">
                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                    </div>
                    <div class="yco-child__autocomplete__suggestion__email">
                      <span>{{user.email}}</span>
                    </div>
                  </div>
                </ui-select-choices>
              </ui-select>
              <yco-messages form="newChild.form" server="newChild.getServerValidation()"
                            field-name="parentId"></yco-messages>
            </yco-input-container>
          </div>
        </div>
      </div>
    </div>
</form>
