(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:ListEnquiriesCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .controller('ListEnquiriesCtrl', ListEnquiriesCtrl);

  function ListEnquiriesCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, EnquiryService) {
    var vm = this;
    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'enquiries?includes=children,mentor',
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Szülő neve')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (enquiry) {
          return enquiry.lastName + ' ' + enquiry.firstName;
        })
      , DTColumnBuilder.newColumn('email').withTitle('Email')
      , DTColumnBuilder.newColumn('children').withTitle('Gyermekek száma')
        .renderWith(function (children) {
          return children.data.length;
        })
      , DTColumnBuilder.newColumn('status').withTitle('Státusz')
        .renderWith(function (statusName) {
          var status = EnquiryService.getStatusByStatusName(statusName);
          return '<span class="badge ' + status.class + '">' + status.label + '</span>';
        })
      , DTColumnBuilder.newColumn('mentor').withTitle('Mentor')
        .notSortable()
        .renderWith(function (mentor) {
          if (!mentor) {
            return '-';
          }

          return mentor.data.lastName + ' ' + mentor.data.firstName;
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (date) {
          return moment(date).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (enquiry) {
          return '<a class="btn btn-default btn-xs"' +
            'ui-sref="editEnquiry({enquiryId: ' + enquiry.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());
