<form name="listParticipants.form" ng-submit="listParticipants.submit()" novalidate>
  <div class="modal-header">
    <h3>Üzenetváltás résztvevői</h3>
  </div>
  <div class="modal-body">
    <p>Az alábbi listán láthatja az üzenetváltási szál résztvevőit. A nevek melleti gombra kattintva eltávolíthatja az
      adott résztvevőt az üzenetváltásból. Az eltávolítás végleges és visszavonhatatlan.</p>

    <ul>
      <li ng-repeat="participants in listParticipants.participants" class="push-5">
        {{participants.lastName}} {{participants.firstName}}
        <button type="button"
                class="btn btn-xs push-5-l"
                uib-tooltip="Eltávolítás"
                tooltip-placement="right"
                ng-disabled="listParticipants.participants.length < 2 || listParticipants.isProcessing"
                ng-click="listParticipants.remove($index)">
          <i class="fa fa-times"></i>
        </button>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn"
            type="button"
            ng-click="listParticipants.close()">
      Bezárás
    </button>
  </div>
</form>
