/* global moment */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name moment.constant:moment
   *
   * @description
   *
   */
  angular
    .module('moment')
    .constant('moment', moment);
}());
