(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:ListChildrenCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('ListChildrenCtrl', ListChildrenCtrl);

  function ListChildrenCtrl(YcoDataTables, $scope, DTColumnBuilder, moment, childStatuses, _, user, userRoles) {
    var vm = this;

    vm.user = user.data;
    vm.userRoles = userRoles;

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'children',
      params: {
        includes: 'parent.mentor'
      },
      scope: $scope
    });
    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Teljes név')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (child) {
          return child.lastName + ' ' + child.firstName;
        })
      , DTColumnBuilder.newColumn(null).withTitle('Szülő').notSortable()
        .renderWith(function (child) {
          return child.parent.data.lastName + ' ' + child.parent.data.firstName;
        })
      , DTColumnBuilder.newColumn('dateOfBirth').withTitle('Születési dátum')
        .renderWith(function (date) {
          return date ? moment(date).format('YYYY. MM. DD.') : '-';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Mentor').notSortable()
        .renderWith(function (child) {
          return child.parent.data.mentor ? child.parent.data.mentor.data.lastName + ' ' + child.parent.data.mentor.data.firstName : '-';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Adatlap státusz')
        .renderWith(function (child) {
          var status = _.find(childStatuses, 'name', child.dataSheetStatus) ? _.find(childStatuses, 'name', child.dataSheetStatus) : '';
          return '<span class="badge ' + status.class + ' push-5-l">' + status.label + '</span>';
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (date) {
          return moment(date).format('YYYY. MM. DD. HH:mm');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (child) {
          var status = _.find(childStatuses, 'name', child.dataSheetStatus) ? _.find(childStatuses, 'name', child.dataSheetStatus) : false,
            showEditButton = false;
          if (status.name === childStatuses.INACTIVE.name) {
            showEditButton = user.data.roleId === userRoles.ADMIN.id;
          } else {
            showEditButton = user.data.roleId !== userRoles.PARENT.id;
          }

          return '<a class="btn btn-default btn-xs push-5-r"' +
            'ui-sref="viewChild({childId: ' + child.id + '})" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="' + showEditButton + '"' +
            'ui-sref="editChild({childId: ' + child.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());
