(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:NewPublicationCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoPublication')
    .controller('NewPublicationCtrl', NewPublicationCtrl);

  function NewPublicationCtrl(FormControl, Publication, $q, $state, UserRole, appConfig, YcoDialog, Auth, _) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.publication = new Publication();
    vm.availableRoles = UserRole.getUserRoles();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      deferred = $q.defer();
      formControl.setSuccessText('A kiadvány sikeresen létrehozva.');

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listPublications');
        });

      vm.publication.$save(deferred.resolve, deferred.reject);
    };
    /*
    File uploader
     */
    vm.hasCoverImage = false;
    vm.hasPublicationFile = false;

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.invalidateCoverImage();

    vm.cancelCoverImage = function cancelCoverImage($flow) {
      vm.hasCoverImage = false;
      $flow.cancel();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasPublicationFile = false;
      $flow.cancel();
    };

    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow, type) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      if (type === 'cover') {
        formControl.setSuccessText('A borítókép sikeresen feltöltésre került.');
        vm.hasCoverImage = true;
        vm.publication.imageId = response.data.id;
      }

      if (type === 'file') {
        formControl.setSuccessText('A fájl sikeresen feltöltésre került.');
        vm.hasPublicationFile = true;
        vm.publication.attachmentId = response.data.id;
      }

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      deferred.resolve();
    };

    vm.isPublicationFileImage = function isPublicationFileImage(file) {
      if (!vm.hasPublicationFile) {
        return false;
      }

      switch (file.getExtension()) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return true;
        default:
          return false;
      }
    };
  }
}());
