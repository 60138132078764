(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:formControl
   * @restrict C
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <form-control></form-control>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('formControl', formControl);

  function formControl() {
    return {
      restrict: 'C',
      link: function (scope, element) {
        element.addClass('mouseflow');
      }
    };
  }
}());
