(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.service:Enquiry
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .service('EnquiryService', EnquiryService);

  function EnquiryService(enquiryStatuses, _) {
    var self = this;

    self.getStatusByStatusName = function getStatusByStatusName(name) {
      return _.find(enquiryStatuses, 'name', name);
    };
  }
}());
