(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:LogoutCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoAuth')
    .controller('LogoutCtrl', LogoutCtrl);

  function LogoutCtrl(Auth) {
    Auth.logout();
  }
}());
