<div class="content bg-image ng-scope" style="background-image: url('images/user-bg.jpg');">
  <div class="push-50-t push-15 clearfix">
    <div class="push-15-r pull-left">
      <img class="img-avatar img-avatar-thumb" src="images/avatar.png" alt="">
    </div>
    <h1 class="h2 text-white push-5-t">{{::editUser.user.lastName}} {{::editUser.user.firstName}}</h1>

    <div>
      <a ui-sref="listUsers({role: editUser.role.slug.plural})">
        <span class="badge badge-default">{{::editUser.role.name.singular}}</span>
      </a>
    </div>
  </div>
</div>

<form name="editUser.form" ng-submit="editUser.submit()" novalidate>
  <div class="content">
    <yco-button class="btn btn-success" type="submit" yco-is-processing="editUser.isProcessing()">
      <i class="fa fa-save push-10-r"></i>Mentés
    </yco-button>

    <div
      ng-if="editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id"
      class="pull-right">
      <yco-button class="btn btn-danger" type="button" ng-click="editUser.deleteUser()">
        <i class="fa fa-trash push-10-r"></i>Törlés
      </yco-button>
    </div>
  </div>

  <div class="content">
    <div class="block block-bordered">
      <div class="block-header">
        <h3 class="block-title">Felhasználói adatok</h3>
      </div>
      <div class="block-content">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vezetéknév</label>
              <input type="text" class="form-control" name="lastName" ng-model="editUser.user.lastName" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="lastName"></yco-messages>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Keresztnév</label>
              <input type="text" class="form-control" name="firstName" ng-model="editUser.user.firstName" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="firstName"></yco-messages>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" name="email" ng-model="editUser.user.email" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="email"></yco-messages>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Telefonszám</label>
              <input type="tel" class="form-control" name="phone" ng-model="editUser.user.phone" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="phone"></yco-messages>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Lakcím</label>
              <input type="text" class="form-control" name="address" ng-model="editUser.user.address" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="address"></yco-messages>
            </div>
          </div>

          <div class="col-md-3" ng-if="editUser.user.roleId === editUser.availableRoles.MENTOR.id">
            <div class="form-group">
              <label>Megye</label>
              <select name="county" class="form-control" ng-model="editUser.user.county" required
                      ng-disabled="!editUser.isCurrentUserAdmin">
                <option value="{{county.name}}"
                        ng-selected="county.name == editUser.user.county"
                        ng-repeat="county in editUser.countyList">
                  {{county.name}}
                </option>
              </select>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="county"></yco-messages>
            </div>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Település</label>
              <input class="form-control"
                     type="text"
                     name="city"
                     ng-minlength="2"
                     ng-model="editUser.user.city"
                     required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="city"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <yco-input-container>
              <label>Bemutatkozás</label>
              <textarea class="form-control"
                        name="note"
                        ng-minlength="0"
                        ng-maxlength="120"
                        ng-model="editUser.user.note"
                        required>

              </textarea>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="note"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Jogosultság</label>
              <select name="roleId" class="form-control" ng-model="editUser.user.roleId" required
                      ng-disabled="!editUser.isCurrentUserAdmin">
                <option value="{{role.id}}"
                        ng-selected="role.id == editUser.user.roleId"
                        ng-repeat="role in editUser.availableRoles">
                  {{role.name.singular}}
                </option>
              </select>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="roleId"></yco-messages>
            </div>
          </div>

          <div class="col-md-3"
               ng-if="editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id">
            <yco-input-container ng-if="editUser.user.roleId == editUser.availableRoles.PARENT.id">
              <label>Hozzárendelt mentor</label>

              <ui-select append-to-body="true"
                         name="mentorId"
                         class="yco-user__autocomplete__select"
                         ng-model="editUser.user.mentorId" required>
                <ui-select-match allow-clear="false">
                  {{$select.selected.lastName}} {{$select.selected.firstName}}
                </ui-select-match>
                <ui-select-choices repeat="user.id as user in editUser.possibleMentors track by $index"
                                   refresh="editUser.refreshPossibleMentors($select.search)"
                                   refresh-delay="200"
                                   minimum-input-length="3">
                  <div class="inner-push-5 inner-push-5-t media">
                    <div class="media-left">
                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                           alt="{{user.lastName}} {{user.firstName}}">
                    </div>
                    <div class="media-right">
                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                    </div>
                    <div class="yco-user__autocomplete__suggestion__email">
                      <span>{{user.email}}</span>
                    </div>
                  </div>
                </ui-select-choices>
              </ui-select>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="mentorId"></yco-messages>
            </yco-input-container>
          </div>
        </div>
      </div>
    </div>

    <div class="block block-bordered"
         ng-if="editUser.isOwnAccount
             || editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id">
      <div class="block-header">
        <h3 class="block-title">Jelszó megváltoztatása<label
          class="edit-user__password-switch css-input switch switch-info">
          <input type="checkbox"
                 class="form-control"
                 name="showExpired"
                 ng-model="editUser.showPassword"
                 ng-true-value="true"
                 ng-false-value="false"
                 ng-change="editUser.toggleShowPassword()"
                 ng-checked="editUser.showPassword">
          <span></span>
        </label></h3>
      </div>
      <div class="block-content"
           ng-if="editUser.showPassword">
        <div class="row">
          <div class="col-md-3">
            <yco-input-container>
              <label>Új jelszó</label>
              <input type="password" class="form-control" name="password" ng-model="editUser.user.password"
                     ng-minlength="5" required>
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="password"></yco-messages>
            </yco-input-container>
          </div>
          <div class="col-md-3">
            <yco-input-container>
              <label>Új jelszó még egyszer</label>
              <input type="password" class="form-control" name="passwordConfirmation"
                     ng-model="editUser.user.passwordConfirmation" match-password="password"
                     ng-required="editUser.user.password">
              <yco-messages form="editUser.form" server="editUser.getServerValidation()"
                            field-name="passwordConfirmation"></yco-messages>
            </yco-input-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</form>
