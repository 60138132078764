(function () {
  'use strict';

  angular
    .module('ycoEvent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listEvents', {
        url: '/events/list',
        templateUrl: 'yco-event/views/list-events.tpl.html',
        controller: 'ListEventsCtrl',
        controllerAs: 'listEvents',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }
        }
      })
      .state('editEvent', {
        url: '/events/edit/:eventId',
        templateUrl: 'yco-event/views/edit-event.tpl.html',
        controller: 'EditEventCtrl',
        controllerAs: 'editEvent',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          event: function ($stateParams, Event) {
            return Event.getEventWithParticipants({id: $stateParams.eventId}).$promise;
          }
        }
      })
      .state('newEvent', {
        url: '/events/new',
        templateUrl: 'yco-event/views/new-event.tpl.html',
        controller: 'NewEventCtrl',
        controllerAs: 'newEvent',
        data: {
          authenticatedOnly: true
        }
      });
  }
}());
