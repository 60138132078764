(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoAuth.service:Auth
   *
   * @description
   *
   */
  angular
    .module('ycoAuth')
    .service('Auth', Auth);

  function Auth($http, appConfig, store, $rootScope, toastr, $state, jwtHelper, moment, $interval, $log, jwtOptions, $q, $window) {
    var self = this
      , authTokenKey = 'authToken';

    /**
     * Returns the stored auth token.
     *
     * @returns {string} JWT token
     */
    self.getToken = function getToken() {
      return store.get(authTokenKey);
    };

    /**
     * Clears the stored auth token.
     */
    self.removeToken = function removeToken() {
      store.remove(authTokenKey);
    };

    /**
     * Persists the given token in the store.
     *
     * @param {string} token Token to save
     */
    self.setToken = function setToken(token) {
      store.set(authTokenKey, token);
    };

    /**
     * Returns a precomposed auth header object, with the header name as key, and the value as value. Can be used
     * to manually inject authentication details into API calls.
     *
     * @returns {object} Composed header object
     */
    self.getAuthHeader = function getAuthHeader() {
      var authHeaderName = jwtOptions.config.authHeader
        , authHeaderPrefix = jwtOptions.config.authPrefix
        , authHeader = {};

      authHeader[authHeaderName] = authHeaderPrefix + self.getToken();

      return authHeader;
    };

    /**
     * Returns whether there's a token available in the store.
     *
     * @returns {boolean} Whether there's a token available in the store.
     */
    self.isTokenSet = function isTokenSet() {
      return !!store.get(authTokenKey);
    };

    /**
     * Initiates the login process. Calls the API's login endpoint with the user credentials.
     *
     * @param {object} userCredentials Credentials of the user
     * @param {string} userCredentials.email Email of the user
     * @param {string} userCredentials.password Password of the user
     * @returns {Promise} Promise which resolves to the successful server response
     */
    self.login = function login(userCredentials) {
      return $http({
        url: appConfig.api.url + 'auth/login',
        method: 'POST',
        data: userCredentials
      }).then(function (response) {
        self.setAuthenticated(response.data.token);
        $rootScope.$broadcast('user.login');
        self.showLoginToast();
      })
    };

    /**
     * Logs out the user, unsetting the token, and redirecting to login state.
     */
    self.logout = function logout() {
      self.setAuthenticated(false);
      $rootScope.$broadcast('user.logout');
      $state.go('login');
      $window.location.reload();
    };

    self.setAuthenticated = function setAuthenticated(authToken) {
      self.removeToken();
      if (authToken) {
        self.setToken(authToken);
      }
    };

    self.isAuthenticated = function isAuthenticated() {
      return $q(function (resolve) {
        checkTokenExpiry();
        return resolve(!!self.getToken());
      });
    };

    self.showLoginToast = function showLoginToast() {
      toastr.success('Sikeres bejelentkezés!');
    };

    /**
     * Returns whether the token has already expired.
     *
     * @returns {boolean} Whether the token has already expired
     */
    function isTokenAlreadyExpired() {
      var token = self.getToken()
        , expiresAt = moment(jwtHelper.getTokenExpirationDate(token))
        , currentDate = moment();

      return currentDate.isSameOrAfter(expiresAt);
    }

    /**
     * Returns whether the token is going to expire in the next 1 minute.
     *
     * @returns {boolean} Whether the token is going to expire in the next 1 minute
     */
    function isTokenGoingToExpireSoon() {
      var token = self.getToken()
        , expiresAt = moment(jwtHelper.getTokenExpirationDate(token))
        , currentDate = moment();

      return currentDate.add(10, 'minutes').isSameOrAfter(expiresAt);
    }

    /**
     * Attempts to fetch a fresh auth token from the API.
     *
     * If successful, replaces the fresh token with the old token in the store.
     */
    function getFreshToken() {
      $log.debug('Starting to fetch auth refresh token');

      $http({
        url: appConfig.api.url + 'auth/refresh',
        method: 'GET'
      }).then(function (response) {
        self.setAuthenticated(response.data.token);
        $log.debug('Successfully fetched auth refresh token');
      }).catch(function (response) {
        $log.debug('Failed to fetch auth refresh token');
        $log.debug(response);
        toastr.error('Váratlan hiba történt munkamenete frissítése közben. Biztonsági okok ' +
          'végett kérjük, ismételje meg a bejelentkezést!');
        self.logout();
      });
    }

    function checkTokenExpiry() {
      if (appConfig.debug) {
        /**
         * Let's not run in development/mock environment.
         */
        return;
      }

      /**
       * Check if we have a token. We have nothing to do here if not.
       */
      if (!self.isTokenSet()) {
        return;
      }

      if (isTokenAlreadyExpired()) {
        /**
         * Token is already expired. We're not allowed to get a refresh token for alread expired
         * tokens.
         */
        toastr.error('Az Ön munkamenete hosszú ideig fellépő inaktivitás miatt biztonsági ' +
          'okokból elévült. Kérjük, a rendszer további használata érdekében ismételje meg a ' +
          'bejelentkezést!');
        self.logout();
      } else if (isTokenGoingToExpireSoon()) {
        /**
         * Our token will expire soon (1 minute, or sooner), it's time to go fetch a fresh token.
         */
        getFreshToken();
      }
    }

    /**
     * Initializer function, which runs checkTokenExpiry() first, then sets up the timer to run
     * it every 1 minute.
     */
    self.initTokenExpiryCheck = function initTokenExpiryCheck() {
      if (appConfig.debug) {
        /**
         * Let's not run in development/mock environment.
         */
        return;
      }

      checkTokenExpiry();

      $interval(function () {
        checkTokenExpiry();
      }, 1000 * 60 * 5);
    };

    self.requestPasswordChange = function requestPasswordChange(email) {
      return $http({
        url: appConfig.api.url + 'password/email',
        method: 'POST',
        data: {email: email}
      });
    };

    self.changePassword = function changePassword(data) {
      return $http({
        url: appConfig.api.url + 'password/reset',
        method: 'POST',
        data: data
      });
    };
  }
}());
