(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.service:BnoService
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .service('BnoService', BnoService);

  function BnoService(Child, _, $q, store) {
    var self = this
      , cacheStorageKey = 'bnoCache'
      , searchCacheStorageKey = 'bnoSearchCache'
      , bnos = store.get(cacheStorageKey) || {}
      , remoteSearchOptions
      , searchCache = store.get(searchCacheStorageKey) || {};

    remoteSearchOptions = {limit: 10};

    self.getBnoById = function getBnoById(id) {
      var idAsNumber;

      return $q(function (resolve) {
        if (!id) {
          return resolve(null);
        }

        idAsNumber = Number(id);

        if (_.has(bnos, idAsNumber)) {
          return resolve(bnos[idAsNumber]);
        }

        Child.getBnoById({bnoId: idAsNumber})
          .$promise
          .then(function (response) {
            bnos[id] = response.data;
            store.set(cacheStorageKey, bnos);
            return resolve(response.data);
          });
      });
    };

    self.searchBno = function searchBno(searchTerm) {
      var options = _.assign({}, remoteSearchOptions, {search: searchTerm});

      return $q(function (resolve) {
        if (!searchTerm) {
          return resolve([]);
        }

        if (_.has(searchCache, searchTerm)) {
          return resolve(_.map(searchCache[searchTerm], function (bnoId) {
            return bnos[bnoId];
          }));
        }

        return Child.searchBno(options)
          .$promise
          .then(function (response) {
            var bnosCached = [];

            if (!response.data.length) {
              return resolve([]);
            }

            _.forEach(response.data, function (bnoEntry) {
              var idAsNumber = Number(bnoEntry.id);

              if (_.has(bnos, idAsNumber)) {
                return;
              }

              bnos[idAsNumber] = bnoEntry;
              bnosCached.push(idAsNumber);
              searchCache[searchTerm] = bnosCached;

              store.set(cacheStorageKey, bnos);
              store.set(searchCacheStorageKey, searchCache);
            });

            return resolve(response.data);
          });
      });
    };
  }
}());
