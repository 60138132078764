<yco-page-header>Mentoráltak</yco-page-header>

<div class="content ng-scope">
  <div class="block">
    <div class="block-header">
      <h3 class="block-title">Mentoráltak</h3>
    </div>
    <div class="block-content">
      <a ng-if="listChildren.user.roleId === listChildren.userRoles.ADMIN.id
             || listChildren.user.roleId === listChildren.userRoles.MENTOR.id"
         class="btn btn-success push-20"
         ui-sref="newUserWithChild">
        <i class="fa fa-plus push-5-r"></i>Új mentorált és szülő felvitele
      </a>
      <a
        ng-if="listChildren.user.roleId === listChildren.userRoles.ADMIN.id
            || listChildren.user.roleId === listChildren.userRoles.MENTOR.id"
        ui-sref="newChild"
        class="btn btn-success push-20" ui-sref="newChild">
        <i class="fa fa-plus push-5-r"></i> Új mentorált hozzáadása meglévő szülőhöz
      </a>
      <table class="table table-bordered table-striped"
             datatable
             dt-options="listChildren.dtOptions"
             dt-columns="listChildren.dtColumns"></table>
    </div>
  </div>
</div>
