(function () {
  'use strict';

  angular
    .module('ycoMessage')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('messages', {
        url: '/messages/:userId/:threadId',
        templateUrl: 'yco-message/views/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'messages',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          initialThreads: function (Thread) {
            return Thread.getThreads().$promise;
          },
          initialMessages: function (Thread, $stateParams, initialThreads, _, $q) {
            return $q(function (resolve) {
              if (!_.has(initialThreads, 'data[0]')) {
                return resolve({data: []});
              }

              return Thread.getMessagesByThreadId({
                id: $stateParams.threadId || initialThreads.data[0].id
              }).$promise
                .then(function (response) {
                  return resolve(response);
                });
            });
          },
          user: function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          },
          recipient: function ($stateParams, User) {
            if (!$stateParams.userId) {
              return;
            }
            return User.get({id: $stateParams.userId}).$promise;
          }
        }
      });
  }
}());
