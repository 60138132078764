(function () {
  'use strict';

  /* @ngdoc object
   * @name koraszulottmentorExtranet
   * @description
   *
   */
  activateLogs.$inject = ["$log", "$rootScope", "_", "$window"];
  setupAuthentication.$inject = ["$rootScope", "$state", "Auth", "_"];
  setDateLocale.$inject = ["moment"];
  angular
    .module('koraszulottmentorExtranet', [
      'ngAria',
      'ngAnimate',
      'ngCookies',
      'ngPassword',
      'ngResource',
      'ngSanitize',
      'datatables',
      'datatables.bootstrap',
      'luegg.directives',
      'ng-form-group',
      'ngTextareaEnter',
      'ui.router',
      'ui.bootstrap',
      'ui.select',
      'angular-jwt',
      'angular-loading-bar',
      'angular-storage',
      'flow',
      'lodash',
      'moment',
      'oneui',
      'toastr',
      'ycoAuth',
      'ycoForm',
      'ycoNavigation',
      'ycoResource',
      'ycoUser',
      'ycoPublication',
      'ycoMessage',
      'ycoEnquiry',
      'ycoChild',
      'ycoEvent',
      'angulartics',
      'angulartics.google.analytics'
    ])
    .run(activateLogs)
    .run(setupAuthentication)
    .run(setDateLocale);

  function activateLogs($log, $rootScope, _, $window) {
    $log.debug('APPLICATION START');
    $rootScope.$on('$destroy', function () {
      $log.debug('APPLICATION STOP');
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeSuccess from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeStart from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $log.error('$stateChangeError from: %s(%s) to: %s(%s), error: %s',
        fromState.name, fromState.url, toState.name, toState.url, _.has(error, 'message') ? error.message : error);

      if (fromState.name === 'login' && toState.name === 'dashboard') {
        $window.location.reload();
      }
    });
  }

  function setupAuthentication($rootScope, $state, Auth, _) {
    $rootScope.$on('$stateChangeStart', function (event, toState) {
      Auth.isAuthenticated()
        .then(function (authenticated) {
          if (_.get(toState, 'data.authenticatedOnly') && !authenticated) {
            event.preventDefault();
            $state.go('login');
          }

          if (_.get(toState, 'data.notForAuthenticated') && authenticated) {
            event.preventDefault();
            $state.go('dashboard');
          }
        });
    });
  }

  function setDateLocale(moment) {
    moment.locale('hu');
  }
}());

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/view-user.tpl.html',
    '<div class="content bg-image ng-scope" style="background-image: url(\'images/user-bg.jpg\');">\n' +
    '  <div class="push-50-t push-15 clearfix">\n' +
    '    <div class="push-15-r pull-left">\n' +
    '      <img class="img-avatar img-avatar-thumb" src="images/avatar.png" alt="">\n' +
    '    </div>\n' +
    '    <h1 class="h2 text-white push-5-t">{{::viewUser.user.lastName}} {{::viewUser.user.firstName}}</h1>\n' +
    '\n' +
    '    <div>\n' +
    '      <a ui-sref="listUsers({role: viewUser.role.slug.plural})">\n' +
    '        <span class="badge badge-default">{{::viewUser.role.name.singular}}</span>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="block">\n' +
    '    <div\n' +
    '      class="block-content">\n' +
    '      <div class="push-20">\n' +
    '        <yco-button\n' +
    '          class="btn btn-default" type="button" ui-sref="listUsers({role: viewUser.role.slug.plural })">\n' +
    '          <i class="fa fa-arrow-left push-10-r"></i>Vissza a {{::viewUser.role.name.plural}} listára\n' +
    '        </yco-button>\n' +
    '        <yco-button\n' +
    '          ng-if="viewUser.user.roleId === viewUser.availableRoles.ADMIN.id\n' +
    '        || viewUser.isOwnAccount"\n' +
    '          class="btn btn-info" type="button" ui-sref="editUser({userId: viewUser.user.id})">\n' +
    '          <i class="fa fa-pencil push-10-r"></i>Szerkesztés\n' +
    '        </yco-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="block block-bordered">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Felhasználói adatok</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Teljes név</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.lastName}} {{::viewUser.user.firstName}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Email</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.email}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Telefonszám</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.phone}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Lakcím</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.address}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3" ng-if="viewUser.user.county.length">\n' +
    '          <p class="font-w600 push-5">Megye</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.county}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3"\n' +
    '             ng-if="viewUser.user.city.length">\n' +
    '          <p class="font-w600 push-5">Település</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.city}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Jogosultság</p>\n' +
    '\n' +
    '          <p>{{::viewUser.role.name.singular}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Regisztráció dátuma</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.createdAt | moment : \'YYYY.MM.DD. HH:mm\'}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Utolsó belépés dátuma</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.lastLoginAt | moment : \'YYYY.MM.DD. HH:mm\'}}</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="col-md-3">\n' +
    '          <p class="font-w600 push-5">Bemutatkozás</p>\n' +
    '\n' +
    '          <p>{{::viewUser.user.note}}</p>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/new-user.tpl.html',
    '<yco-page-header>Új felhasználó létrehozása</yco-page-header>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6 col-md-offset-3">\n' +
    '      <div class="block">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Felhasználói adatok</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-6 col-md-offset-3">\n' +
    '              <p>Az alábbi űrlapon lehetősége nyílik új felhasználót létrehoznia a rendszerben. Kérjük,\n' +
    '                maradéktalanul töltse ki az összes mezőt, különösen ügyelve, hogy az <em>Email</em>\n' +
    '                mezőbe megfelelő email cím kerüljön, hiszen a rendszer erre a címre fogja elküldeni az\n' +
    '                első belépéshez szükséges adatokat.</p>\n' +
    '\n' +
    '              <form name="newUser.form" ng-submit="newUser.submit()" novalidate>\n' +
    '                <yco-input-container>\n' +
    '                  <label>Vezetéknév</label>\n' +
    '                  <input class="form-control" type="text" name="lastName" ng-model="newUser.user.lastName" required>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field-name="lastName"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Keresztnév</label>\n' +
    '                  <input class="form-control" type="text" name="firstName" ng-model="newUser.user.firstName" required>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field-name="firstName"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Email</label>\n' +
    '                  <input class="form-control" type="email" name="email" ng-model="newUser.user.email" required>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field-name="email"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Jogosultság</label>\n' +
    '                  <select name="roleId" class="form-control" ng-model="newUser.user.roleId" required>\n' +
    '                    <option value="{{role.id}}"\n' +
    '                            ng-selected="newUser.user.roleId === role.id"\n' +
    '                            ng-repeat="role in newUser.availableRoles">\n' +
    '                      {{role.name.singular}}\n' +
    '                    </option>\n' +
    '                  </select>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field="roleId"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Település</label>\n' +
    '                  <input class="form-control"\n' +
    '                         type="text"\n' +
    '                         name="city"\n' +
    '                         ng-model="newUser.user.city"\n' +
    '                         required>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field-name="city"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container ng-if="newUser.user.roleId == newUser.availableRoles.PARENT.id">\n' +
    '                  <label>Hozzárendelt mentor</label>\n' +
    '\n' +
    '                  <ui-select append-to-body="true"\n' +
    '                             name="mentorId"\n' +
    '                             class="yco-user__autocomplete__select"\n' +
    '                             ng-disabled="newUser.currentUser.roleId === newUser.userRoles.MENTOR.id"\n' +
    '                             ng-model="newUser.user.mentorId" required>\n' +
    '                    <ui-select-match allow-clear="false">\n' +
    '                      {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                    </ui-select-match>\n' +
    '                    <ui-select-choices repeat="user.id as user in newUser.possibleMentors track by $index"\n' +
    '                                       refresh="newUser.refreshPossibleMentors($select.search)"\n' +
    '                                       refresh-delay="200"\n' +
    '                                       minimum-input-length="3">\n' +
    '                      <div class="inner-push-5 inner-push-5-t media">\n' +
    '                        <div class="media-left">\n' +
    '                          <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                               alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                        </div>\n' +
    '                        <div class="media-right">\n' +
    '                          <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                          <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                        </div>\n' +
    '                        <div class="yco-user__autocomplete__suggestion__email">\n' +
    '                          <span>{{user.email}}</span>\n' +
    '                        </div>\n' +
    '                      </div>\n' +
    '                    </ui-select-choices>\n' +
    '                  </ui-select>\n' +
    '                  <yco-messages form="newUser.form" server="newUser.getServerValidation()"\n' +
    '                                field-name="mentorId"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <hr>\n' +
    '\n' +
    '                <div class="push text-center">\n' +
    '                  <yco-button class="btn btn-success" type="submit" yco-is-processing="newUser.isProcessing()">\n' +
    '                    Felhasználó létrehozása\n' +
    '                  </yco-button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/new-user-with-child.tpl.html',
    '<yco-page-header>Összevont felhasználó létrehozás</yco-page-header>\n' +
    '\n' +
    '<form name="newUserWithChild.form" ng-submit="newUserWithChild.submit()" novalidate>\n' +
    '  <div class="content ng-scope">\n' +
    '    <div class="col-md-8 col-md-offset-2">\n' +
    '      <div class="block block-bordered">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Adatok</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content tab-content">\n' +
    '          <uib-tabset active="newUserWithChild.activeTab" justified="true" class="block">\n' +
    '            <uib-tab index="0" heading="1. Email cím ellenőrzés" disable="true">\n' +
    '              <div class="col-md-6 col-md-offset-3 push-30-t">\n' +
    '                <div class="push-50">\n' +
    '                  <p>Írja be a regisztrálni kívánt email címet az alábbi mezőbe, majd kattintson az <em>Ellenőrzés</em>\n' +
    '                    gombra. Rendszerünk ezáltal ellenőrzi, hogy a regisztrálni kívánt email címmel létezik-e már\n' +
    '                    felhasználó a rendszerben.\n' +
    '                  </p>\n' +
    '\n' +
    '                  <yco-input-container>\n' +
    '                    <label>Email</label>\n' +
    '                    <input class="form-control"\n' +
    '                           type="email"\n' +
    '                           name="email"\n' +
    '                           ng-disabled="newUserWithChild.isCheckEmailDisabled"\n' +
    '                           ng-model="newUserWithChild.user.email"\n' +
    '                           required>\n' +
    '                    <yco-messages form="newUserWithChild.form" server="newUserWithChild.getServerValidation()"\n' +
    '                                  field-name="email"></yco-messages>\n' +
    '                    <div class="block block-content text-center">\n' +
    '                      <yco-button ng-if="!newUserWithChild.canRegister"\n' +
    '                                  class="btn btn-info"\n' +
    '                                  type="button"\n' +
    '                                  yco-is-processing="newUserWithChild.isProcessing"\n' +
    '                                  ng-click="newUserWithChild.checkEmail()">\n' +
    '                        Ellenőrzés\n' +
    '                      </yco-button>\n' +
    '                      <button ng-if="newUserWithChild.canRegister" ng-click="newUserWithChild.goToNextTab()"\n' +
    '                              type="button" class="btn btn-success">Tovább\n' +
    '                      </button>\n' +
    '                    </div>\n' +
    '                  </yco-input-container>\n' +
    '                  <div ng-if="newUserWithChild.canRegister" class="alert alert-success">\n' +
    '                    <p>A megadott email cím még nincs használatban. A <strong>Tovább</strong> gomb megnyomásával\n' +
    '                      folytathatja a felhasználó létrehozást.</p>\n' +
    '                  </div>\n' +
    '                  <div ng-if="newUserWithChild.showWarning" class="alert alert-warning">\n' +
    '                    <h3 class="font-w300 push-15">Figyelem</h3>\n' +
    '\n' +
    '                    <p>Ezzel az email címmel már létezik felhasználó a rendszerben. További teendők egyeztetése végett\n' +
    '                      kérjük,\n' +
    '                      lépjen kapcsolatba a projektirodával.</p>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </uib-tab>\n' +
    '            <uib-tab index="1" heading="2. Szülő adatai" disable="true">\n' +
    '              <ng-form name="newUserWithChild.parentForm" novalidate>\n' +
    '                <div class="col-md-6 col-md-offset-3 push-30-t">\n' +
    '                  <div class="push-50">\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Vezetéknév</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="lastName"\n' +
    '                             ng-model="newUserWithChild.user.lastName"\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="lastName"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Keresztnév</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="firstName"\n' +
    '                             ng-model="newUserWithChild.user.firstName"\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="firstName"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Email</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="email"\n' +
    '                             ng-model="newUserWithChild.user.email"\n' +
    '                             disabled\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="email"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Telefonszám</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="phone"\n' +
    '                             ng-model="newUserWithChild.user.phone"\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="phone"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Lakcím</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="address"\n' +
    '                             ng-model="newUserWithChild.user.address"\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="address"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Település</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="city"\n' +
    '                             ng-minlength="2"\n' +
    '                             ng-model="newUserWithChild.user.city"\n' +
    '                             required>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="city"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Hozzárendelt mentor</label>\n' +
    '\n' +
    '                      <ui-select append-to-body="true"\n' +
    '                                 name="mentorId"\n' +
    '                                 class="yco-user__autocomplete__select"\n' +
    '                                 ng-disabled="newUserWithChild.currentUser.roleId === newUserWithChild.userRoles.MENTOR.id"\n' +
    '                                 ng-model="newUserWithChild.user.mentorId" required>\n' +
    '                        <ui-select-match allow-clear="false">\n' +
    '                          {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                        </ui-select-match>\n' +
    '                        <ui-select-choices repeat="user.id as user in newUserWithChild.possibleMentors track by $index"\n' +
    '                                           refresh="newUserWithChild.refreshPossibleMentors($select.search)"\n' +
    '                                           refresh-delay="200"\n' +
    '                                           minimum-input-length="3">\n' +
    '                          <div class="inner-push-5 inner-push-5-t media">\n' +
    '                            <div class="media-left">\n' +
    '                              <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                                   alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                            </div>\n' +
    '                            <div class="media-right">\n' +
    '                              <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                              <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                            </div>\n' +
    '                            <div class="yco-newuser__autocomplete__suggestion__email">\n' +
    '                              <span>{{user.email}}</span>\n' +
    '                            </div>\n' +
    '                          </div>\n' +
    '                        </ui-select-choices>\n' +
    '                      </ui-select>\n' +
    '                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"\n' +
    '                                    field-name="mentorId"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </ng-form>\n' +
    '            </uib-tab>\n' +
    '            <uib-tab index="2" heading="3. Gyermekek adatai" disable="true">\n' +
    '              <ng-form name="newUserWithChild.childrenForm" novalidate>\n' +
    '                <table class="table table-bordered push-30-t">\n' +
    '                  <thead>\n' +
    '                  <tr>\n' +
    '                    <th>Vezetéknév</th>\n' +
    '                    <th>Keresztnév</th>\n' +
    '                    <th>Születési év</th>\n' +
    '                    <th>Születési hét</th>\n' +
    '                    <th>Születési súly</th>\n' +
    '                    <th>Törlés</th>\n' +
    '                  </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                  <tr ng-repeat="child in newUserWithChild.children">\n' +
    '                    <td>\n' +
    '                      <yco-input-container>\n' +
    '                        <input class="form-control mouseflow" type="text" name="lastName" ng-model="child.lastName"\n' +
    '                               ng-disabled="$index!==0"\n' +
    '                               required>\n' +
    '                        <yco-messages form="newUserWithChild.childrenForm"\n' +
    '                                      server="newUserWithChild.getServerValidation()"\n' +
    '                                      field-name="lastName"></yco-messages>\n' +
    '                      </yco-input-container>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <yco-input-container>\n' +
    '                        <input class="form-control mouseflow" type="text" name="firstName" ng-model="child.firstName"\n' +
    '                               ng-disabled="$index!==0"\n' +
    '                               required>\n' +
    '                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"\n' +
    '                                      field-name="firstName"></yco-messages>\n' +
    '                      </yco-input-container>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <yco-input-container>\n' +
    '                        <input class="form-control" type="number" name="birthYear" ng-model="child.birthYear"\n' +
    '                               min="2010-01-01" required\n' +
    '                               ng-disabled="$index!==0">\n' +
    '                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"\n' +
    '                                      field-name="birthYear"></yco-messages>\n' +
    '                      </yco-input-container>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <yco-input-container>\n' +
    '                        <input class="form-control" type="number" name="birthWeek" ng-model="child.birthWeek" required\n' +
    '                               ng-disabled="$index!==0">\n' +
    '                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"\n' +
    '                                      field-name="birthWeek"></yco-messages>\n' +
    '                      </yco-input-container>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <div class="input-group">\n' +
    '                        <yco-input-container>\n' +
    '                          <div class="input-group">\n' +
    '                            <input class="form-control" type="number" name="birthWeight" ng-model="child.birthWeight"\n' +
    '                                   ng-disabled="$index!==0"\n' +
    '                                   required>\n' +
    '                            <div class="input-group-addon">g</div>\n' +
    '                          </div>\n' +
    '                          <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"\n' +
    '                                        field-name="birthWeight"></yco-messages>\n' +
    '                        </yco-input-container>\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <button type="button" class="btn btn-danger"\n' +
    '                              ng-click="newUserWithChild.removeChildRow($index)">\n' +
    '                        Gyermek törlése\n' +
    '                      </button>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '                <div class="text-right">\n' +
    '                  <button type="button" class="btn btn-default" ng-click="newUserWithChild.addChildRow()"\n' +
    '                          ng-disabled="newUserWithChild.form.$invalid">Új Gyermek\n' +
    '                    hozzáadása\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </ng-form>\n' +
    '            </uib-tab>\n' +
    '          </uib-tabset>\n' +
    '        </div>\n' +
    '        <div class="block-content block-content-mini block-content-full border-t">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-6">\n' +
    '              <button class="btn btn-default"\n' +
    '                      type="button"\n' +
    '                      ng-disabled="newUserWithChild.activeTab <= 1"\n' +
    '                      ng-click="newUserWithChild.goToPrevTab()">\n' +
    '                <i class="fa fa-arrow-left"></i>\n' +
    '                Vissza\n' +
    '              </button>\n' +
    '            </div>\n' +
    '            <div class="col-md-6 text-right">\n' +
    '              <button class="btn btn-default"\n' +
    '                      type="button"\n' +
    '                      ng-disabled="newUserWithChild.isMoreButtonDisabled()"\n' +
    '                      ng-if="newUserWithChild.activeTab <= 1"\n' +
    '                      ng-click="newUserWithChild.goToNextTab()">\n' +
    '                Tovább <i class="fa fa-arrow-right"></i></button>\n' +
    '              <button class="btn btn-success"\n' +
    '                      type="submit"\n' +
    '                      ng-disabled="newUserWithChild.form.$invalid || newUserWithChild.children.length===0"\n' +
    '                      ng-if="newUserWithChild.activeTab === 2">\n' +
    '                Létrehozás\n' +
    '              </button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/list-users.tpl.html',
    '<yco-page-header>{{::listUsers.roleFilter.name.plural || \'Összes felhasználó\'}}</yco-page-header>\n' +
    '\n' +
    '<div class="content ng-scope">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Felhasználók</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <a class="btn btn-success push-20"\n' +
    '         ng-if="listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"\n' +
    '         ui-sref="newUser({role:listUsers.currentRole})"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>\n' +
    '      <a class="btn btn-success push-20"\n' +
    '         ng-if="listUsers.isNewUserWithChildCreationAvailable\n' +
    '             && listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"\n' +
    '         ui-sref="newUserWithChild">\n' +
    '        <i class="fa fa-plus push-5-r"></i>Új szülő és mentorált felvitele\n' +
    '      </a>\n' +
    '\n' +
    '      <table class="table table-bordered table-striped" datatable dt-options="listUsers.dtOptions"\n' +
    '             dt-columns="listUsers.dtColumns"></table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/edit-user.tpl.html',
    '<div class="content bg-image ng-scope" style="background-image: url(\'images/user-bg.jpg\');">\n' +
    '  <div class="push-50-t push-15 clearfix">\n' +
    '    <div class="push-15-r pull-left">\n' +
    '      <img class="img-avatar img-avatar-thumb" src="images/avatar.png" alt="">\n' +
    '    </div>\n' +
    '    <h1 class="h2 text-white push-5-t">{{::editUser.user.lastName}} {{::editUser.user.firstName}}</h1>\n' +
    '\n' +
    '    <div>\n' +
    '      <a ui-sref="listUsers({role: editUser.role.slug.plural})">\n' +
    '        <span class="badge badge-default">{{::editUser.role.name.singular}}</span>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<form name="editUser.form" ng-submit="editUser.submit()" novalidate>\n' +
    '  <div class="content">\n' +
    '    <yco-button class="btn btn-success" type="submit" yco-is-processing="editUser.isProcessing()">\n' +
    '      <i class="fa fa-save push-10-r"></i>Mentés\n' +
    '    </yco-button>\n' +
    '\n' +
    '    <div\n' +
    '      ng-if="editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id"\n' +
    '      class="pull-right">\n' +
    '      <yco-button class="btn btn-danger" type="button" ng-click="editUser.deleteUser()">\n' +
    '        <i class="fa fa-trash push-10-r"></i>Törlés\n' +
    '      </yco-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content">\n' +
    '    <div class="block block-bordered">\n' +
    '      <div class="block-header">\n' +
    '        <h3 class="block-title">Felhasználói adatok</h3>\n' +
    '      </div>\n' +
    '      <div class="block-content">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Vezetéknév</label>\n' +
    '              <input type="text" class="form-control" name="lastName" ng-model="editUser.user.lastName" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="lastName"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Keresztnév</label>\n' +
    '              <input type="text" class="form-control" name="firstName" ng-model="editUser.user.firstName" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="firstName"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Email</label>\n' +
    '              <input type="email" class="form-control" name="email" ng-model="editUser.user.email" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="email"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Telefonszám</label>\n' +
    '              <input type="tel" class="form-control" name="phone" ng-model="editUser.user.phone" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="phone"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Lakcím</label>\n' +
    '              <input type="text" class="form-control" name="address" ng-model="editUser.user.address" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="address"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3" ng-if="editUser.user.roleId === editUser.availableRoles.MENTOR.id">\n' +
    '            <div class="form-group">\n' +
    '              <label>Megye</label>\n' +
    '              <select name="county" class="form-control" ng-model="editUser.user.county" required\n' +
    '                      ng-disabled="!editUser.isCurrentUserAdmin">\n' +
    '                <option value="{{county.name}}"\n' +
    '                        ng-selected="county.name == editUser.user.county"\n' +
    '                        ng-repeat="county in editUser.countyList">\n' +
    '                  {{county.name}}\n' +
    '                </option>\n' +
    '              </select>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="county"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Település</label>\n' +
    '              <input class="form-control"\n' +
    '                     type="text"\n' +
    '                     name="city"\n' +
    '                     ng-minlength="2"\n' +
    '                     ng-model="editUser.user.city"\n' +
    '                     required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="city"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Bemutatkozás</label>\n' +
    '              <textarea class="form-control"\n' +
    '                        name="note"\n' +
    '                        ng-minlength="0"\n' +
    '                        ng-maxlength="120"\n' +
    '                        ng-model="editUser.user.note"\n' +
    '                        required>\n' +
    '\n' +
    '              </textarea>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="note"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <div class="form-group">\n' +
    '              <label>Jogosultság</label>\n' +
    '              <select name="roleId" class="form-control" ng-model="editUser.user.roleId" required\n' +
    '                      ng-disabled="!editUser.isCurrentUserAdmin">\n' +
    '                <option value="{{role.id}}"\n' +
    '                        ng-selected="role.id == editUser.user.roleId"\n' +
    '                        ng-repeat="role in editUser.availableRoles">\n' +
    '                  {{role.name.singular}}\n' +
    '                </option>\n' +
    '              </select>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="roleId"></yco-messages>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3"\n' +
    '               ng-if="editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id">\n' +
    '            <yco-input-container ng-if="editUser.user.roleId == editUser.availableRoles.PARENT.id">\n' +
    '              <label>Hozzárendelt mentor</label>\n' +
    '\n' +
    '              <ui-select append-to-body="true"\n' +
    '                         name="mentorId"\n' +
    '                         class="yco-user__autocomplete__select"\n' +
    '                         ng-model="editUser.user.mentorId" required>\n' +
    '                <ui-select-match allow-clear="false">\n' +
    '                  {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                </ui-select-match>\n' +
    '                <ui-select-choices repeat="user.id as user in editUser.possibleMentors track by $index"\n' +
    '                                   refresh="editUser.refreshPossibleMentors($select.search)"\n' +
    '                                   refresh-delay="200"\n' +
    '                                   minimum-input-length="3">\n' +
    '                  <div class="inner-push-5 inner-push-5-t media">\n' +
    '                    <div class="media-left">\n' +
    '                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                           alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                    </div>\n' +
    '                    <div class="media-right">\n' +
    '                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                    </div>\n' +
    '                    <div class="yco-user__autocomplete__suggestion__email">\n' +
    '                      <span>{{user.email}}</span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </ui-select-choices>\n' +
    '              </ui-select>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="mentorId"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="block block-bordered"\n' +
    '         ng-if="editUser.isOwnAccount\n' +
    '             || editUser.loggedUser.roleId === editUser.availableRoles.ADMIN.id">\n' +
    '      <div class="block-header">\n' +
    '        <h3 class="block-title">Jelszó megváltoztatása<label\n' +
    '          class="edit-user__password-switch css-input switch switch-info">\n' +
    '          <input type="checkbox"\n' +
    '                 class="form-control"\n' +
    '                 name="showExpired"\n' +
    '                 ng-model="editUser.showPassword"\n' +
    '                 ng-true-value="true"\n' +
    '                 ng-false-value="false"\n' +
    '                 ng-change="editUser.toggleShowPassword()"\n' +
    '                 ng-checked="editUser.showPassword">\n' +
    '          <span></span>\n' +
    '        </label></h3>\n' +
    '      </div>\n' +
    '      <div class="block-content"\n' +
    '           ng-if="editUser.showPassword">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Új jelszó</label>\n' +
    '              <input type="password" class="form-control" name="password" ng-model="editUser.user.password"\n' +
    '                     ng-minlength="5" required>\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="password"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Új jelszó még egyszer</label>\n' +
    '              <input type="password" class="form-control" name="passwordConfirmation"\n' +
    '                     ng-model="editUser.user.passwordConfirmation" match-password="password"\n' +
    '                     ng-required="editUser.user.password">\n' +
    '              <yco-messages form="editUser.form" server="editUser.getServerValidation()"\n' +
    '                            field-name="passwordConfirmation"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-user/views/dashboard.tpl.html',
    '<div class="content bg-image overflow-hidden yco-dashboard__header ng-scope"\n' +
    '     style="background-image: url(\'images/user-bg.jpg\');">\n' +
    '  <div class="row">\n' +
    '    <div class="col-sm-6">\n' +
    '      <div class="push-50-t push-15">\n' +
    '        <h1 class="h2 text-white">Áttekintés</h1>\n' +
    '\n' +
    '        <h2 class="h5 text-white-op">Koraszülött Mentor Extranet</h2>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-3 col-sm-offset-3">\n' +
    '      <div\n' +
    '        ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id\n' +
    '        || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"\n' +
    '        class="yco-dashboard__admin">\n' +
    '        <div class="yco-dashboard__admin__name">Serényi Ági</div>\n' +
    '        <div class="yco-dashboard__admin__role">honlap adminisztrátor</div>\n' +
    '        <div class="yco-dashboard__admin__tel"><a href="tel:+36304625880">06 30 824 7718</a></div>\n' +
    '        <div class="yco-dashboard__admin__email"><a\n' +
    '          href="mailto:info@koraszulottmentor.hu">info@koraszulottmentor.hu</a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div\n' +
    '    ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id\n' +
    '        || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"\n' +
    '    class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="block block-themed">\n' +
    '        <div class="block-content">\n' +
    '          <div class="push-20">\n' +
    '            <a\n' +
    '              class="btn btn-success"\n' +
    '              ui-sref="newUserWithChild">\n' +
    '              <i class="fa fa-plus push-5-r"></i>Új mentorált és szülő felvitele\n' +
    '            </a>\n' +
    '            <a\n' +
    '              ui-sref="newChild"\n' +
    '              class="btn btn-success push-5-l" ui-sref="newChild">\n' +
    '              <i class="fa fa-plus push-5-r"></i> Új mentorált hozzáadása meglévő szülőhöz\n' +
    '            </a>\n' +
    '            <!-- hide it for a specific user -->\n' +
    '            <yco-button\n' +
    '              ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id &&\n' +
    '                     dashboard.user.id !== 65"\n' +
    '              ng-click="dashboard.processStatFile()"\n' +
    '              class="yco-dashboard__statistics-download btn btn-info push-5-l"\n' +
    '              type="button"\n' +
    '              yco-disabled="dashboard.statFileData.url.length"\n' +
    '              yco-is-processing="dashboard.showStatFileLoader">\n' +
    '              <i class="fa fa-line-chart push-5-r"></i> Havi statisztika előállítása\n' +
    '            </yco-button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '  </div>\n' +
    '  <div\n' +
    '    ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id"\n' +
    '    class="row">\n' +
    '    <div\n' +
    '      class="col-sm-6 col-lg-3"\n' +
    '      ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id\n' +
    '      ">\n' +
    '      <a class="block block-bordered">\n' +
    '        <table class="block-table text-center">\n' +
    '          <tbody>\n' +
    '          <tr>\n' +
    '            <td class="bg-gray-lighter border-r" style="width: 35%;">\n' +
    '              <div class="push-30 push-30-t">\n' +
    '                <i class="fa fa-wpforms fa-3x text-black-op"></i>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td style="width: 65%;">\n' +
    '              <div class="h1 font-w700">{{::dashboard.stats.allEnquiries}}</div>\n' +
    '              <div class="h5 text-muted text-uppercase push-5-t">Jelentkezés összesen</div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="col-sm-6 col-lg-3">\n' +
    '      <a class="block block-bordered">\n' +
    '        <table class="block-table text-center">\n' +
    '          <tbody>\n' +
    '          <tr>\n' +
    '            <td class="bg-gray-lighter border-r" style="width: 35%;">\n' +
    '              <div class="push-30 push-30-t">\n' +
    '                <i class="fa fa-check-circle fa-3x"></i>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td style="width: 65%;">\n' +
    '              <div class="h1 font-w700">{{::dashboard.stats.approvedEnquiries}}\n' +
    '              </div>\n' +
    '              <div class="h5 text-muted text-uppercase push-5-t">Elfogadott jelentkezés</div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="col-sm-6 col-lg-3">\n' +
    '      <a class="block block-bordered">\n' +
    '        <table class="block-table text-center">\n' +
    '          <tbody>\n' +
    '          <tr>\n' +
    '            <td class="bg-gray-lighter border-r" style="width: 35%;">\n' +
    '              <div class="push-30 push-30-t">\n' +
    '                <i class="fa fa-child fa-3x text-black-op"></i>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td style="width: 65%;">\n' +
    '              <div class="h1 font-w700">{{::dashboard.stats.allChildren}}</div>\n' +
    '              <div class="h5 text-muted text-uppercase push-5-t">Gyermek összesen</div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '    <div class="col-sm-6 col-lg-3">\n' +
    '      <a class="block block-bordered">\n' +
    '        <table class="block-table text-center">\n' +
    '          <tbody>\n' +
    '          <tr>\n' +
    '            <td class="bg-gray-lighter border-r" style="width: 35%;">\n' +
    '              <div class="push-30 push-30-t">\n' +
    '                <i class="fa fa-check-circle fa-3x"></i>\n' +
    '              </div>\n' +
    '            </td>\n' +
    '            <td style="width: 65%;">\n' +
    '              <div class="h1 font-w700">{{::dashboard.stats.approvedEnquiryChildren}}</div>\n' +
    '              <div class="h5 text-muted text-uppercase push-5-t">Regisztrált gyermek</div>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </a>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6">\n' +
    '      <div class="yco-dashboard__unread-threads block block-bordered"\n' +
    '           ng-if="dashboard.user.roleId !== dashboard.userRoles.ADMIN.id">\n' +
    '        <div class="block-header">\n' +
    '          <ul class="block-options">\n' +
    '            <li>\n' +
    '              <a ui-sref="messages">Összes üzenet</a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '          <h3 class="block-title">Olvasatlan üzenetváltások</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content remove-padding">\n' +
    '          <table class="block-table text-center bg-gray-lighter">\n' +
    '            <tbody>\n' +
    '            <tr>\n' +
    '              <td>\n' +
    '                <div class="h1 font-w700">{{::dashboard.unreadThreads.length}}</div>\n' +
    '                <div class="h5 text-muted text-uppercase push-5-t">olvasatlan üzenetváltás</div>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '          <div class="text-center" ng-if="!dashboard.unreadThreads.length">\n' +
    '            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs olvasatlan üzenete.</div>\n' +
    '          </div>\n' +
    '          <div class="list-group" ng-if="dashboard.unreadThreads.length">\n' +
    '            <div class="yco-dashboard__unread-threads__list__item list-group-item"\n' +
    '                 ng-repeat="thread in dashboard.unreadThreads" ui-sref="messages({threadId: thread.id})">\n' +
    '              <p class="pull-right text-muted" title="{{::thread.createdAt | moment : \'YYYY.MM.DD. HH:mm:ss\'}}">\n' +
    '                {{::thread.createdAt | fromNow}}\n' +
    '              </p>\n' +
    '\n' +
    '              <h4 class="list-group-item-heading">{{thread.subject}}</h4>\n' +
    '\n' +
    '              <p class="list-group-item-text push-5-t push-5">\n' +
    '                <small>\n' +
    '                  <i class="fa fa-fw push-5-r"\n' +
    '                     ng-class="{\'fa-user\': thread.participants.data.length === 1, \'fa-users\': thread.participants.data.length > 1}"></i>\n' +
    '                  {{::thread.participants.data[0].user.data.lastName}}\n' +
    '                  {{::thread.participants.data[0].user.data.firstName}}\n' +
    '                  <span ng-if="thread.participants.data.length > 1">\n' +
    '                    és további {{::thread.participants.data.length - 1}} személy\n' +
    '                  </span>\n' +
    '                </small>\n' +
    '              </p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="yco-dashboard__mentor-stats block block-bordered"\n' +
    '           ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id">\n' +
    '        <table class="table table-bordered table-striped">\n' +
    '          <thead>\n' +
    '          <tr>\n' +
    '            <th>Teljes név</th>\n' +
    '            <th class="text-center">Összesen</th>\n' +
    '            <th class="text-center">Inaktív</th>\n' +
    '            <th class="text-center">Kitöltetlen</th>\n' +
    '            <th class="text-center">Hiányos</th>\n' +
    '            <th class="text-center">Kitöltött</th>\n' +
    '          </tr>\n' +
    '          </thead>\n' +
    '          <tbody>\n' +
    '          <tr ng-repeat="mentor in dashboard.mentorStats">\n' +
    '            <td>{{::mentor.fullName}}</td>\n' +
    '            <td class="text-center"><span>{{::mentor.sum}}</span></td>\n' +
    '            <td class="text-center"><span class="badge badge-warning">{{::mentor.inactive}}</span></td>\n' +
    '            <td class="text-center"><span class="badge badge-default">{{::mentor.unfilled}}</span></td>\n' +
    '            <td class="text-center"><span class="badge badge-danger">{{::mentor.deficient}}</span></td>\n' +
    '            <td class="text-center"><span class="badge badge-success">{{::mentor.filledOut}}</span></td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div\n' +
    '      ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id\n' +
    '          || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"\n' +
    '      class="col-md-6">\n' +
    '      <div class="block block-bordered">\n' +
    '        <div class="block-header">\n' +
    '          <ul class="block-options">\n' +
    '            <li>\n' +
    '              <a ui-sref="listEnquiries">Összes regisztrációs kérelem</a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '          <h3 class="block-title">Új regisztrációs kérelmek</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content remove-padding">\n' +
    '          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">\n' +
    '            <table class="table table-condensed" ng-if="dashboard.newEnquiries.length">\n' +
    '              <thead>\n' +
    '              <tr>\n' +
    '                <th>Szülő neve</th>\n' +
    '                <th>Státusz</th>\n' +
    '                <th>Regisztráció dátuma</th>\n' +
    '                <th class="text-center">Műveletek</th>\n' +
    '              </tr>\n' +
    '              </thead>\n' +
    '              <tbody>\n' +
    '              <tr ng-repeat="enquiry in dashboard.newEnquiries">\n' +
    '                <td>{{::enquiry.lastName}} {{::enquiry.firstName}}</td>\n' +
    '                <td ng-bind-html="dashboard.getEnquiryStatus(enquiry.status)"></td>\n' +
    '                <td>{{::enquiry.createdAt | moment : \'YYYY.MM.DD. HH:mm\'}}</td>\n' +
    '                <td class="text-center">\n' +
    '                  <div class="btn-group">\n' +
    '                    <a class="btn btn-default btn-xs"\n' +
    '                       ui-sref="editEnquiry({enquiryId: enquiry.id})"\n' +
    '                       uib-tooltip="Szerkesztés">\n' +
    '                      <i class="fa fa-pencil"></i>\n' +
    '                    </a>\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="text-center" ng-if="!dashboard.newEnquiries.length">\n' +
    '            <table class="block-table text-center bg-gray-lighter">\n' +
    '              <tbody>\n' +
    '              <tr>\n' +
    '                <td>\n' +
    '                  <div class="h1 font-w700 ng-binding">0</div>\n' +
    '                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '\n' +
    '            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div\n' +
    '      ng-if="dashboard.user.roleId === dashboard.userRoles.DOCTOR.id"\n' +
    '      class="col-md-6">\n' +
    '      <div class="block block-bordered">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Mentoráltjaim</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content remove-padding">\n' +
    '          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">\n' +
    '            <table class="table table-condensed" ng-if="dashboard.childrenByDoctor.length">\n' +
    '              <thead>\n' +
    '              <tr>\n' +
    '                <th>Szülő neve</th>\n' +
    '                <th>Anyja neve</th>\n' +
    '                <th>Gyermek neve</th>\n' +
    '                <th>Születési dátuma</th>\n' +
    '                <th class="text-center">Műveletek</th>\n' +
    '              </tr>\n' +
    '              </thead>\n' +
    '              <tbody>\n' +
    '              <tr ng-repeat="child in dashboard.childrenByDoctor">\n' +
    '                <td>{{::child.parent.data.lastName}} {{::child.parent.data.firstName}}</td>\n' +
    '                <td>{{::child.motherName}}</td>\n' +
    '                <td>{{::child.lastName}} {{::child.firstName}}</td>\n' +
    '                <td>{{::child.dateOfBirth | moment : \'YYYY.MM.DD. HH:mm\'}}</td>\n' +
    '                <td class="text-center">\n' +
    '                  <div class="btn-group">\n' +
    '                    <a class="btn btn-default btn-xs"\n' +
    '                       ui-sref="editChild({childId: child.id})"\n' +
    '                       uib-tooltip="Szerkesztés">\n' +
    '                      <i class="fa fa-pencil"></i>\n' +
    '                    </a>\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="text-center" ng-if="!dashboard.newEnquiries.length">\n' +
    '            <table class="block-table text-center bg-gray-lighter">\n' +
    '              <tbody>\n' +
    '              <tr>\n' +
    '                <td>\n' +
    '                  <div class="h1 font-w700 ng-binding">0</div>\n' +
    '                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '\n' +
    '            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div\n' +
    '      ng-if="dashboard.user.roleId === dashboard.userRoles.PARENT.id"\n' +
    '      class="col-md-6">\n' +
    '      <div class="block block-bordered">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Mentor, Korai fejlesztők, Orvosok listája</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content remove-padding">\n' +
    '          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">\n' +
    '            <table class="table table-condensed" ng-if="dashboard.users.length">\n' +
    '              <thead>\n' +
    '              <tr>\n' +
    '                <th>Név</th>\n' +
    '                <th>Szerepkör</th>\n' +
    '                <th class="text-center">Műveletek</th>\n' +
    '              </tr>\n' +
    '              </thead>\n' +
    '              <tbody>\n' +
    '              <tr ng-repeat="user in dashboard.users">\n' +
    '                <td>{{::user.lastName}} {{::user.firstName}}</td>\n' +
    '                <td ng-bind-html="dashboard.getUserRoleById(user.roleId)"></td>\n' +
    '                <td class="text-center">\n' +
    '                  <div class="btn-group">\n' +
    '                    <a class="btn btn-default btn-xs push-5-r"\n' +
    '                       ui-sref="messages({userId: user.id})"\n' +
    '                       uib-tooltip="Üzenetküldés">\n' +
    '                      <i class="fa fa-envelope-o"></i>\n' +
    '                    </a>\n' +
    '                    <a class="btn btn-default btn-xs"\n' +
    '                       ui-sref="viewUser({userId: user.id})"\n' +
    '                       uib-tooltip="Megtekintés">\n' +
    '                      <i class="fa fa-eye"></i>\n' +
    '                    </a>\n' +
    '                  </div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="text-center" ng-if="!dashboard.newEnquiries.length">\n' +
    '            <table class="block-table text-center bg-gray-lighter">\n' +
    '              <tbody>\n' +
    '              <tr>\n' +
    '                <td>\n' +
    '                  <div class="h1 font-w700 ng-binding">0</div>\n' +
    '                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '\n' +
    '            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-publication/views/new-publication.tpl.html',
    '<yco-page-header>Új kiadvány létrehozása</yco-page-header>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6 col-md-offset-3">\n' +
    '      <div class="block">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Kiadvány adatai</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-6 col-md-offset-3">\n' +
    '              <form name="newPublication.form" ng-submit="newPublication.submit()" novalidate\n' +
    '                    flow-init>\n' +
    '                <yco-input-container>\n' +
    '                  <label>Cím</label>\n' +
    '                  <input class="form-control" type="text" name="title"\n' +
    '                         ng-model="newPublication.publication.title" required>\n' +
    '                  <yco-messages form="newPublication.form"\n' +
    '                                server="newPublication.getServerValidation()"\n' +
    '                                field-name="title"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Rövid leírás</label>\n' +
    '                  <textarea class="form-control" name="content"\n' +
    '                            ng-model="newPublication.publication.content" rows="10"\n' +
    '                            required></textarea>\n' +
    '                  <yco-messages form="newPublication.form"\n' +
    '                                server="newPublication.getServerValidation()"\n' +
    '                                field-name="content"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <div class="yco-publications__new__upload__wrapper form-group">\n' +
    '                  <label>Borítókép</label>\n' +
    '\n' +
    '                  <div\n' +
    '                    flow-init="newPublication.flowOptions"\n' +
    '                    flow-file-added="!!{png:1,gif:1,jpg:1,jpeg:1}[$file.getExtension()]"\n' +
    '                    flow-files-submitted="newPublication.sendToAPI($file, $event, $flow)"\n' +
    '                    flow-file-error="newPublication.handleAPIErrors($file, $message, $flow)"\n' +
    '                    flow-file-success="newPublication.handleAPISuccess($file, $message, $flow, \'cover\')"\n' +
    '                  >\n' +
    '                    <div class="push-10-t push-10 position-relative" ng-if="newPublication.hasCoverImage">\n' +
    '                      <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                              ng-click="newPublication.cancelCoverImage($flow)"><i class="fa fa-times"></i></button>\n' +
    '                      <img class="image-responsive" flow-img="$flow.files[0]">\n' +
    '                    </div>\n' +
    '                    <input ng-if="!newPublication.hasCoverImage" type="file" name="image" flow-btn>\n' +
    '                  </div>\n' +
    '                  <yco-messages form="newPublication.form"\n' +
    '                                server="newPublication.getServerValidation()"\n' +
    '                                field-name="image"></yco-messages>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="yco-publications__new__upload__wrapper form-group">\n' +
    '                  <label>Fájl</label>\n' +
    '\n' +
    '                  <div\n' +
    '                    flow-init="newPublication.flowOptions"\n' +
    '                    flow-file-added="!!{jpg:1,jpeg:1,png:1,gif:1,pdf:1,xls:1,xlsx:1,doc:1,docx:1,ppt:1,pptx:1,zip:1}[$file.getExtension()]"\n' +
    '                    flow-files-submitted="newPublication.sendToAPI($file, $event, $flow)"\n' +
    '                    flow-file-error="newPublication.handleAPIErrors($file, $message, $flow)"\n' +
    '                    flow-file-success="newPublication.handleAPISuccess($file, $message, $flow, \'file\')"\n' +
    '                  >\n' +
    '                    <div class="push-10-t push-10 position-relative" ng-if="newPublication.hasPublicationFile">\n' +
    '                      <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                              ng-click="newPublication.cancelFile($flow)"><i class="fa fa-times"></i></button>\n' +
    '                      <img ng-if="newPublication.isPublicationFileImage($flow.files[0])" class="image-responsive" flow-img="$flow.files[0]">\n' +
    '                      <span ng-if="!newPublication.isPublicationFileImage($flow.files[0])">{{$flow.files[0].name}}</span>\n' +
    '                    </div>\n' +
    '                    <input ng-if="!newPublication.hasPublicationFile" type="file" name="file" flow-btn>\n' +
    '                    <yco-messages form="newPublication.form"\n' +
    '                                  server="newPublication.getServerValidation()"\n' +
    '                                  field-name="file"></yco-messages>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Jogosultság</label>\n' +
    '                  <select name="roles" class="form-control" ng-model="newPublication.publication.roles" multiple>\n' +
    '                    <option value="{{role.id}}"\n' +
    '                            ng-selected="role.id == newPublication.publication.roles"\n' +
    '                            ng-repeat="role in newPublication.availableRoles">\n' +
    '                      {{role.name.singular}}\n' +
    '                    </option>\n' +
    '                  </select>\n' +
    '                  <div class="help-block">A kiadványt csak az itt megjelölt jogosultsági körrel\n' +
    '                    rendelkező felhasználók fogják tudni megtekinteni. A listából több jogosultság\n' +
    '                    is kiválasztható egyszerre. Amennyiben egy jogosultság sem kerül kiválasztásra,\n' +
    '                    a kiadvány bármely belépett felhasználó számára látható lesz.\n' +
    '                  </div>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <hr>\n' +
    '\n' +
    '                <div class="push text-center">\n' +
    '                  <yco-button class="btn btn-success" type="submit"\n' +
    '                              yco-is-processing="newPublication.isProcessing()">\n' +
    '                    <i class="fa fa-save push-5-r"></i>\n' +
    '                    Mentés\n' +
    '                  </yco-button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-publication/views/list-publications.tpl.html',
    '<yco-page-header>Kiadványok</yco-page-header>\n' +
    '\n' +
    '<div class="content ng-scope">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Kiadványok</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <a\n' +
    '        ng-if="listPublications.loggedUser.roleId === listPublications.availableRoles.ADMIN.id"\n' +
    '        class="btn btn-success push-20" ui-sref="newPublication"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>\n' +
    '\n' +
    '      <table class="table table-bordered table-striped" datatable\n' +
    '             dt-options="listPublications.dtOptions"\n' +
    '             dt-columns="listPublications.dtColumns"></table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-publication/views/edit-publication.tpl.html',
    '<yco-page-header>Kiadvány szerkesztése</yco-page-header>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6 col-md-offset-3">\n' +
    '      <div class="block">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Kiadvány adatai</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-6 col-md-offset-3">\n' +
    '              <form name="editPublication.form" ng-submit="editPublication.submit()" novalidate\n' +
    '                    flow-init>\n' +
    '                <yco-input-container>\n' +
    '                  <label>Cím</label>\n' +
    '                  <input class="form-control" type="text" name="title"\n' +
    '                         ng-model="editPublication.publication.title" required>\n' +
    '                  <yco-messages form="editPublication.form"\n' +
    '                                server="editPublication.getServerValidation()"\n' +
    '                                field-name="title"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Rövid leírás</label>\n' +
    '                  <textarea class="form-control" name="content"\n' +
    '                            ng-model="editPublication.publication.content" rows="10"\n' +
    '                            required></textarea>\n' +
    '                  <yco-messages form="editPublication.form"\n' +
    '                                server="editPublication.getServerValidation()"\n' +
    '                                field-name="content"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <div class="yco-publications__new__upload__wrapper form-group">\n' +
    '                  <label>Borítókép</label>\n' +
    '\n' +
    '                  <div\n' +
    '                    ng-if="!editPublication.publication.image"\n' +
    '                    flow-init="editPublication.flowOptions"\n' +
    '                    flow-file-added="!!{png:1,gif:1,jpg:1,jpeg:1}[$file.getExtension()]"\n' +
    '                    flow-files-submitted="editPublication.sendToAPI($file, $event, $flow)"\n' +
    '                    flow-file-error="editPublication.handleAPIErrors($file, $message, $flow)"\n' +
    '                    flow-file-success="editPublication.handleAPISuccess($file, $message, $flow, \'cover\')"\n' +
    '                  >\n' +
    '                    <div class="push-10-t push-10 position-relative" ng-if="editPublication.hasCoverImage">\n' +
    '                      <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                              ng-click="editPublication.cancelCoverImage($flow)"><i class="fa fa-times"></i></button>\n' +
    '                      <img class="image-responsive" flow-img="$flow.files[0]">\n' +
    '                    </div>\n' +
    '                    <input ng-if="!editPublication.hasCoverImage" type="file" name="image" flow-btn>\n' +
    '                  </div>\n' +
    '                  <div class="push-10-t push-10 position-relative" ng-if="editPublication.publication.image">\n' +
    '                    <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                            ng-click="editPublication.cancelPublicationThumbnail()"><i class="fa fa-times"></i></button>\n' +
    '                    <img class="image-responsive" ng-src="{{editPublication.publication.image}}">\n' +
    '                  </div>\n' +
    '                  <yco-messages form="editPublication.form"\n' +
    '                                server="editPublication.getServerValidation()"\n' +
    '                                field-name="image"></yco-messages>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="yco-publications__new__upload__wrapper form-group">\n' +
    '                  <label>Fájl</label>\n' +
    '\n' +
    '                  <div\n' +
    '                    ng-if="!editPublication.publication.attachment"\n' +
    '                    flow-init="editPublication.flowOptions"\n' +
    '                    flow-file-added="!!{jpg:1,jpeg:1,png:1,gif:1,pdf:1,xls:1,xlsx:1,doc:1,docx:1,ppt:1,pptx:1,zip:1}[$file.getExtension()]"\n' +
    '                    flow-files-submitted="editPublication.sendToAPI($file, $event, $flow)"\n' +
    '                    flow-file-error="editPublication.handleAPIErrors($file, $message, $flow)"\n' +
    '                    flow-file-success="editPublication.handleAPISuccess($file, $message, $flow, \'file\')"\n' +
    '                  >\n' +
    '                    <div class="push-10-t push-10 position-relative" ng-if="editPublication.hasPublicationFile">\n' +
    '                      <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                              ng-click="editPublication.cancelFile($flow)"><i class="fa fa-times"></i></button>\n' +
    '                      <img ng-if="editPublication.isPublicationFileImage($flow.files[0])" class="image-responsive"\n' +
    '                           flow-img="$flow.files[0]">\n' +
    '                      <span\n' +
    '                        ng-if="!editPublication.isPublicationFileImage($flow.files[0])">{{$flow.files[0].name}}</span>\n' +
    '                    </div>\n' +
    '                    <input ng-if="!editPublication.hasPublicationFile" type="file" name="image" flow-btn>\n' +
    '                  </div>\n' +
    '                  <div class="push-10-t push-10 position-relative" ng-if="editPublication.publication.attachment">\n' +
    '                    <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                            ng-click="editPublication.cancelPublicationAttachment()"><i class="fa fa-times"></i>\n' +
    '                    </button>\n' +
    '                    <img ng-if="editPublication.isPublicationAttachmentImage()" class="image-responsive"\n' +
    '                         ng-src="{{editPublication.publication.attachment}}">\n' +
    '                    <span\n' +
    '                      ng-if="!editPublication.isPublicationAttachmentImage()">{{editPublication.getFileName()}}</span>\n' +
    '                    <a ng-if="!editPublication.isPublicationAttachmentImage()"\n' +
    '                       class="btn-default btn-xs push-5-r"\n' +
    '                       ng-href="{{editPublication.publication.attachment}}"\n' +
    '                       uib-tooltip="Letöltés" download><i class="fa fa-download"></i>\n' +
    '                    </a>\n' +
    '                  </div>\n' +
    '                  <yco-messages form="editPublication.form"\n' +
    '                                server="editPublication.getServerValidation()"\n' +
    '                                field-name="file"></yco-messages>\n' +
    '                </div>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Jogosultság</label>\n' +
    '                  <select name="roles" class="form-control" ng-model="editPublication.publication.roles" multiple>\n' +
    '                    <option value="{{role.id}}"\n' +
    '                            ng-selected="role.id == editPublication.publication.roles"\n' +
    '                            ng-repeat="role in editPublication.availableRoles">\n' +
    '                      {{role.name.singular}}\n' +
    '                    </option>\n' +
    '                  </select>\n' +
    '                  <div class="help-block">A kiadványt csak az itt megjelölt jogosultsági körrel\n' +
    '                    rendelkező felhasználók fogják tudni megtekinteni. A listából több jogosultság\n' +
    '                    is kiválasztható egyszerre. Amennyiben egy jogosultság sem kerül kiválasztásra,\n' +
    '                    a kiadvány bármely belépett felhasználó számára látható lesz.\n' +
    '                  </div>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <hr>\n' +
    '\n' +
    '                <div class="push text-center">\n' +
    '                  <yco-button class="btn btn-success" type="submit"\n' +
    '                              yco-is-processing="editPublication.isProcessing()">\n' +
    '                    <i class="fa fa-save push-5-r"></i>\n' +
    '                    Mentés\n' +
    '                  </yco-button>\n' +
    '                  <button class="btn btn-danger" type="button"\n' +
    '                          ng-click="editPublication.deletePublication()">\n' +
    '                    <i class="fa fa-trash push-5-r"></i>\n' +
    '                    Törlés\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-navigation/views/yco-dialog.tpl.html',
    '<div class="modal-header" ng-if="modal.title">\n' +
    '  <h3>{{modal.title}}</h3>\n' +
    '</div>\n' +
    '<div class="modal-body" ng-if="modal.content">\n' +
    '  <p ng-bind-html="modal.content"></p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn"\n' +
    '          type="button"\n' +
    '          ng-click="modal.close()">\n' +
    '    {{modal.closeButtonText}}\n' +
    '  </button>\n' +
    '  <button class="btn btn-success"\n' +
    '          type="button"\n' +
    '          ng-click="modal.ok()">\n' +
    '    {{modal.actionButtonText}}\n' +
    '  </button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-navigation/directives/yco-sidebar-directive.tpl.html',
    '<nav id="sidebar" class="yco-sidebar">\n' +
    '  <div id="sidebar-scroll">\n' +
    '    <div class="sidebar-content">\n' +
    '      <div class="side-header side-content bg-white-op">\n' +
    '        <a class="h5 text-white" ui-sref="dashboard">\n' +
    '          <img class="yco-sidebar__logo" src="images/logo_symbol.svg" alt="Koraszülött Mentor Program">\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="side-content side-content-full">\n' +
    '        <ul class="nav-main" data-js-main-nav>\n' +
    '          <li class="nav-main-heading">Menü</li>\n' +
    '          <li>\n' +
    '            <a ui-sref="dashboard" ui-sref-active="active">\n' +
    '              <i class="fa fa-fw fa-tachometer"></i>Áttekintés\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <a ui-sref="messages" ui-sref-active="active">\n' +
    '            <i class="fa fa-fw fa-envelope"></i>Üzenetek\n' +
    '            <span ng-if="ycoSidebar.unreadThreads.data.length"\n' +
    '                  class="badge">\n' +
    '              {{ycoSidebar.unreadThreads.data.length}}\n' +
    '            </span>\n' +
    '          </a>\n' +
    '          </li>\n' +
    '          <li\n' +
    '            ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id"\n' +
    '            class="nav-main-heading">Résztvevők\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ui-sref="listChildren"\n' +
    '               ui-sref-active="active">\n' +
    '              Mentoráltak\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.PARENT.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.PARENT.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li\n' +
    '            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id\n' +
    '                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.EARLY_DEV.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.EARLY_DEV.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li\n' +
    '            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id\n' +
    '                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.DOCTOR.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.DOCTOR.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="ycoSidebar.user.roleId !== ycoSidebar.userRoles.PARENT.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.LEAD_DOCTOR.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.LEAD_DOCTOR.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.MENTOR.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.MENTOR.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">\n' +
    '            <a ui-sref="listUsers({role: \'{{::ycoSidebar.userRoles.ADMIN.slug.plural}}\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              {{::ycoSidebar.userRoles.ADMIN.name.plural}}\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id">\n' +
    '            <a ui-sref="listUsers({role: \'\'})"\n' +
    '               ui-sref-active="active">\n' +
    '              Összes felhasználó\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li class="nav-main-heading">Mentor Program</li>\n' +
    '          <li\n' +
    '            ng-if="ycoSidebar.user.roleId === ycoSidebar.userRoles.ADMIN.id\n' +
    '                || ycoSidebar.user.roleId === ycoSidebar.userRoles.MENTOR.id">\n' +
    '            <a ui-sref="listEnquiries"\n' +
    '               ui-sref-active="active">\n' +
    '              <i class="fa fa-pencil-square-o"></i>Regisztrációk kezelése\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ui-sref="listEvents" ui-sref-active="active">\n' +
    '              <i class="fa fa-fw fa-calendar"></i>Események\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ui-sref="listPublications()" ui-sref-active="active">\n' +
    '              <i class="fa fa-fw fa-book"></i>Kiadványok\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li class="nav-main-heading">Fiók</li>\n' +
    '          <li>\n' +
    '            <a ui-sref="viewUser({userId: \'me\'})" ui-sref-active="active">\n' +
    '              <i class="fa fa-fw fa-user"></i>Profil\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <a ui-sref="logout">\n' +
    '              <i class="fa fa-fw fa-sign-out"></i>Kijelentkezés\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</nav>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-navigation/directives/yco-page-header-directive.tpl.html',
    '<div class="content bg-gray-lighter ng-scope">\n' +
    '  <div class="row items-push">\n' +
    '    <div class="col-md-6">\n' +
    '      <h1 class="page-heading">\n' +
    '        <ng-transclude></ng-transclude>\n' +
    '      </h1>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-navigation/directives/yco-header-directive.tpl.html',
    '<header id="header-navbar" class="content-mini content-mini-full">\n' +
    '  <div ng-if="ycoHeader.appConfig.debug"\n' +
    '       class="yco-header__notification text-center">\n' +
    '    Ez egy teszt környezet. Az éles rendszerhez kattintson ide: <a\n' +
    '    href="https://portal.koraszulottmentor.hu/"\n' +
    '    target="_blank">Belépés</a>\n' +
    '  </div>\n' +
    '  <ul class="nav-header pull-right">\n' +
    '    <li>\n' +
    '      <div class="btn-group" data-on-toggle="toggled(open)" data-uib-dropdown>\n' +
    '        <button class="btn btn-default btn-image" type="button" data-uib-dropdown-toggle>\n' +
    '          <img src="images/avatar.png" alt="Felhasználó">\n' +
    '          {{ycoHeader.user.data.lastName}} {{ycoHeader.user.data.firstName}}\n' +
    '          <span class="caret"></span>\n' +
    '        </button>\n' +
    '        <ul class="dropdown-menu-right" data-uib-dropdown-menu>\n' +
    '          <li class="dropdown-header">Fiók</li>\n' +
    '          <li>\n' +
    '            <a ui-sref="viewUser({userId: \'me\'})">\n' +
    '              <i class="fa fa-fw fa-user pull-right"></i>Profil\n' +
    '            </a>\n' +
    '          </li>\n' +
    '          <li class="divider"></li>\n' +
    '          <li>\n' +
    '            <a ui-sref="logout">\n' +
    '              <i class="fa fa-fw fa-sign-out pull-right"></i>Kijelentkezés\n' +
    '            </a>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '</header>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-message/views/new-thread-dialog.tpl.html',
    '<form name="newThread.form" ng-submit="newThread.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    Új üzenetváltás\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <yco-input-container>\n' +
    '      <label>\n' +
    '        Címzett(ek)\n' +
    '        <i class="fa fa-question-circle text-muted push-5-l"\n' +
    '           uib-tooltip="Üzenetéhez több címzettet is kiválaszthat. Kezdje el begépelni a kívánt címzett nevét, majd\n' +
    '           miután rendszerünk felkínálta a találatokat, válasszon egy lehetőséget a lenyíló listából."\n' +
    '           tooltip-placement="right"\n' +
    '           tooltip-append-to-body="true"></i>\n' +
    '      </label>\n' +
    '      <ui-select class="yco-messages__new-thread__autocomplete"\n' +
    '                 multiple\n' +
    '                 required\n' +
    '                 name="participantIds"\n' +
    '                 ng-disabled="newThread.hasRecipient"\n' +
    '                 ng-model="newThread.thread.participantIds">\n' +
    '        <ui-select-match>{{$item.lastName}} {{$item.firstName}}</ui-select-match>\n' +
    '        <ui-select-choices repeat="user.id as user in newThread.possibleParticipants track by $index"\n' +
    '                           refresh="newThread.refreshPossibleParticipants($select.search)"\n' +
    '                           refresh-delay="200"\n' +
    '                           minimum-input-length="3">\n' +
    '          <div class="yco-messages__new-thread__autocomplete__suggestion media">\n' +
    '            <div class="media-left">\n' +
    '              <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                   alt="{{user.lastName}} {{user.firstName}}">\n' +
    '            </div>\n' +
    '            <div class="media-right">\n' +
    '              <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '              <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '            </div>\n' +
    '            <div class="yco-messages__new-thread__autocomplete__suggestion__email">\n' +
    '              <span>{{user.email}}</span>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </ui-select-choices>\n' +
    '      </ui-select>\n' +
    '      <yco-messages form="newThread.form" server="newThread.getServerValidation()"\n' +
    '                    field-name="participantIds"></yco-messages>\n' +
    '    </yco-input-container>\n' +
    '\n' +
    '    <yco-input-container>\n' +
    '      <label>\n' +
    '        Tárgy\n' +
    '      </label>\n' +
    '      <input class="form-control" type="text" name="subject" ng-model="newThread.thread.subject" required>\n' +
    '      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="subject"></yco-messages>\n' +
    '    </yco-input-container>\n' +
    '\n' +
    '    <yco-input-container>\n' +
    '      <label>\n' +
    '        Kapcsolódó profil (opcionális)\n' +
    '        <i class="fa fa-question-circle text-muted push-5-l"\n' +
    '           uib-tooltip="Lehetősége van mentorált profilt csatolni az üzenethez. A csatolt profil az üzenetváltás\n' +
    '           többi résztvevője számára is megjelenik, így kontextusba helyezve az adott mentoráltról szóló\n' +
    '           párbeszédet."\n' +
    '           tooltip-placement="right"\n' +
    '           tooltip-append-to-body="true"></i>\n' +
    '      </label>\n' +
    '      <ui-select append-to-body="true"\n' +
    '                 class="yco-messages__new-thread__autocomplete"\n' +
    '                 ng-model="newThread.thread.childId">\n' +
    '        <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '        </ui-select-match>\n' +
    '        <ui-select-choices repeat="child.id as child in newThread.possibleChildren track by $index"\n' +
    '                           refresh="newThread.refreshPossibleChildren($select.search)"\n' +
    '                           refresh-delay="200"\n' +
    '                           minimum-input-length="3">\n' +
    '          <div class="yco-messages__new-thread__autocomplete__suggestion media">\n' +
    '            <div class="media-left">\n' +
    '              <img class="media-object img-avatar img-avatar32" ng-src="{{child.image}}"\n' +
    '                   alt="{{child.lastName}} {{child.firstName}}">\n' +
    '            </div>\n' +
    '            <div class="media-right">\n' +
    '              <h5 class="media-heading">{{child.lastName}} {{child.firstName}}</h5>\n' +
    '\n' +
    '              <div>Szülő: {{child.parent.data.lastName}} {{child.parent.data.firstName}}</div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </ui-select-choices>\n' +
    '      </ui-select>\n' +
    '      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="childId"></yco-messages>\n' +
    '    </yco-input-container>\n' +
    '\n' +
    '    <yco-input-container>\n' +
    '      <label>Üzenet</label>\n' +
    '      <textarea class="form-control" name="message" ng-model="newThread.thread.message" rows="5"\n' +
    '                placeholder="Írja be üzenetét" required></textarea>\n' +
    '      <yco-messages form="newThread.form" server="newThread.getServerValidation()" field-name="message"></yco-messages>\n' +
    '    </yco-input-container>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="newThread.close()">\n' +
    '      Bezárás\n' +
    '    </button>\n' +
    '    <yco-button class="btn btn-success" yco-is-processing="newThread.isProcessing()">Küldés</yco-button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-message/views/messages.tpl.html',
    '<yco-page-header>Üzenetek</yco-page-header>\n' +
    '\n' +
    '<div class="yco-messages content">\n' +
    '  <div class="yco-messages__inner">\n' +
    '    <div class="yco-messages__thread-list block block-bordered remove-margin-b">\n' +
    '      <div class="block-header bg-gray-lighter">\n' +
    '        <ul class="block-options">\n' +
    '          <li>\n' +
    '            <button type="button"\n' +
    '                    class="btn btn-success btn-xs"\n' +
    '                    ng-click="messages.showNewThreadDialog()">\n' +
    '              <i class="fa fa-plus push-5-r"></i>\n' +
    '              Új üzenetváltás\n' +
    '            </button>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <button type="button"\n' +
    '                    uib-tooltip="Üzenetváltás lista frissítése"\n' +
    '                    ng-disabled="messages.isThreadListReloading"\n' +
    '                    ng-click="messages.reloadThreads()">\n' +
    '              <i class="fa fa-refresh"\n' +
    '                 ng-class="{\'fa-spin\': messages.isThreadListReloading}"></i>\n' +
    '            </button>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <h3 class="block-title">Üzenetváltások</h3>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="block-content remove-padding">\n' +
    '        <div class="yco-messages__thread-list__list list-group overflow-y-auto"\n' +
    '             ng-class="{\'yco-messages__thread-list__list--empty\': !messages.threads.length}">\n' +
    '          <div class="list-group-item"\n' +
    '               ng-if="messages.threads.length"\n' +
    '               ng-repeat="thread in messages.threads"\n' +
    '               ng-class="{\'yco-messages__thread-list__list__item--active\': thread.id === messages.activeThread.id,\n' +
    '                          \'yco-messages__thread-list__list__item--hasUnread\': thread.hasUnread}"\n' +
    '               ng-click="messages.selectThread($index)">\n' +
    '            <p class="pull-right text-muted"\n' +
    '               title="{{thread.createdAt | moment : \'YYYY.MM.DD. HH:mm:ss\'}}">\n' +
    '              {{thread.createdAt | fromNow}}\n' +
    '            </p>\n' +
    '            <h4 class="list-group-item-heading">{{thread.subject}}</h4>\n' +
    '\n' +
    '            <p class="list-group-item-text push-5-t push-5" ng-if="thread.child">\n' +
    '              <small>\n' +
    '                <i class="fa fa-file-text fa-fw push-5-r"></i>\n' +
    '                <strong>Mentorált:</strong> {{thread.child.data.lastName}} {{thread.child.data.firstName}}\n' +
    '              </small>\n' +
    '            </p>\n' +
    '\n' +
    '            <p class="list-group-item-text push-5-t push-5">\n' +
    '              <small>\n' +
    '                <i class="fa fa-fw push-5-r"\n' +
    '                   ng-class="{\'fa-user\': thread.participants.data.length === 1, \'fa-users\': thread.participants.data.length > 1}"></i>\n' +
    '                {{thread.participants.data[0].user.data.lastName}}\n' +
    '                {{thread.participants.data[0].user.data.firstName}}\n' +
    '                <span ng-if="thread.participants.data.length > 1"\n' +
    '                      uib-tooltip-html="messages.getParticipantTooltipContent($index, thread.participants.data[0].user.data.id)">\n' +
    '                    és további {{thread.participants.data.length - 1}} személy\n' +
    '                  </span>\n' +
    '              </small>\n' +
    '            </p>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="yco-messages__thread-list__list__skeleton"\n' +
    '               ng-if="!messages.threads.length">\n' +
    '            <i class="yco-messages__thread-list__list__skeleton__icon fa fa-commenting-o fa-5x push-10"></i>\n' +
    '\n' +
    '            <p class="yco-messages__thread-list__list__skeleton__title push-10">Nincs üzenetváltás</p>\n' +
    '\n' +
    '            <p class="yco-messages__thread-list__list__skeleton__subtitle push-20">\n' +
    '              Kattintson az alábbi gombra, vagy a doboz tetején lévő \'Új üzenetváltás\' feliratra, hogy\n' +
    '              üzenetváltást kezdeményezzen.</p>\n' +
    '\n' +
    '            <button type="button"\n' +
    '                    class="btn btn-success"\n' +
    '                    ng-click="messages.showNewThreadDialog()">\n' +
    '              <i class="fa fa-plus push-5-r"></i>Új üzenetváltás\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="yco-messages__message-list block block-bordered remove-margin-b"\n' +
    '         ng-if="messages.activeThread && messages.activeThread.id !== -1">\n' +
    '      <div class="block-header bg-gray-lighter">\n' +
    '        <ul class="block-options">\n' +
    '          <li>\n' +
    '            <button type="button"\n' +
    '                    class="btn btn-xs"\n' +
    '                    uib-tooltip="Résztvevők megtekintése"\n' +
    '                    ng-disabled="messages.isThreadReloading"\n' +
    '                    ng-click="messages.showParticipantsDialog()">\n' +
    '              <i class="fa fa-users"></i>\n' +
    '            </button>\n' +
    '          </li>\n' +
    '          <li>\n' +
    '            <button type="button"\n' +
    '                    class="btn btn-xs"\n' +
    '                    uib-tooltip="Üzenetek frissítése"\n' +
    '                    ng-disabled="messages.isThreadReloading"\n' +
    '                    ng-click="messages.reloadMessages()">\n' +
    '              <i class="fa fa-refresh"\n' +
    '                 ng-class="{\'fa-spin\': messages.isThreadReloading}"></i>\n' +
    '            </button>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <h3 class="block-title">{{messages.activeThread.subject}}</h3>\n' +
    '        <div class="yco-messages__message-list__child" ng-if="messages.activeThread.child.data"><span>Mentorált: </span>\n' +
    '          <a ui-sref="viewChild({childId:messages.activeThread.child.data.id})">{{messages.activeThread.child.data.lastName}}\n' +
    '            {{messages.activeThread.child.data.firstName}}</a></div>\n' +
    '      </div>\n' +
    '      <div class="block-content">\n' +
    '        <div class="yco-messages__message-list__list block-content block-content-full overflow-y-auto"\n' +
    '             scroll-glue-bottom>\n' +
    '          <div class="block block-rounded block-transparent push-15"\n' +
    '               title="Küldve {{message.user.data.lastName}} {{message.user.data.firstName}} által, ekkor: {{message.createdAt | moment : \'YYYY.MM.DD. HH:mm:ss\'}}"\n' +
    '               ng-repeat="message in messages.messages"\n' +
    '               ng-class="{\'push-50-l yco-messages__message-list__message yco-messages__message-list__message--own\': messages.isOwnMessage($index), \'push-50-r yco-messages__message-list__message\': !messages.isOwnMessage($index)}">\n' +
    '            <img uib-tooltip="{{message.user.data.lastName}} {{message.user.data.firstName}}"\n' +
    '                 class="yco-messages__message-list__message__author" ng-src="{{message.user.data.image}}" alt="Avatar">\n' +
    '            <div class="yco-messages__message-list__message__text"\n' +
    '                 ng-bind-html="message.message | filterEnter">\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="block-content block-content-full block-content-mini">\n' +
    '          <form name="messages.newMessageForm"\n' +
    '                ng-submit="messages.sendMessage()"\n' +
    '                novalidate>\n' +
    '        <textarea class="form-control yco-messages__message-textarea"\n' +
    '                  id="yco-messages__message-textarea"\n' +
    '                  autofocus\n' +
    '                  ng-disabled="messages.isThreadReloading"\n' +
    '                  ng-model="messages.newMessage.message"\n' +
    '                  ng-textarea-enter="messages.submitTextarea()"\n' +
    '                  placeholder="Írja be üzenetét, majd nyomjon Enter-t a küldéshez..."></textarea>\n' +
    '            <button ng-disabled="messages.isSubmitDisabled()" type="submit"\n' +
    '                    class="btn btn-success pull-right">Elküld\n' +
    '            </button>\n' +
    '          </form>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="yco-messages__message-list__skeleton"\n' +
    '         ng-if="!messages.activeThread || messages.activeThread.id === -1">\n' +
    '      <p class="yco-messages__message-list__skeleton__title push-10">Nincs megjeleníthető üzenet</p>\n' +
    '\n' +
    '      <p class="yco-messages__message-list__skeleton__subtitle">\n' +
    '        Jelenleg nincs megjeleníthető üzenet. Választhat egy meglévő üzenetváltási szálat a bal oldali listából,\n' +
    '        vagy létrehozhat egy új beszélgetést a mentorprogram további résztvevőivel.</p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-message/views/list-participants-dialog.tpl.html',
    '<form name="listParticipants.form" ng-submit="listParticipants.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    <h3>Üzenetváltás résztvevői</h3>\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <p>Az alábbi listán láthatja az üzenetváltási szál résztvevőit. A nevek melleti gombra kattintva eltávolíthatja az\n' +
    '      adott résztvevőt az üzenetváltásból. Az eltávolítás végleges és visszavonhatatlan.</p>\n' +
    '\n' +
    '    <ul>\n' +
    '      <li ng-repeat="participants in listParticipants.participants" class="push-5">\n' +
    '        {{participants.lastName}} {{participants.firstName}}\n' +
    '        <button type="button"\n' +
    '                class="btn btn-xs push-5-l"\n' +
    '                uib-tooltip="Eltávolítás"\n' +
    '                tooltip-placement="right"\n' +
    '                ng-disabled="listParticipants.participants.length < 2 || listParticipants.isProcessing"\n' +
    '                ng-click="listParticipants.remove($index)">\n' +
    '          <i class="fa fa-times"></i>\n' +
    '        </button>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="listParticipants.close()">\n' +
    '      Bezárás\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-form/views/yco-messages-include.tpl.html',
    '<div ng-message="required">A mező kitöltése kötelező</div>\n' +
    '<div ng-message="minlength">A mező tartalma nem elég hosszú</div>\n' +
    '<div ng-message="length">A mező tartalma nem elég hosszú</div>\n' +
    '<div ng-message="maxlength">A mező tartalma túl hosszú</div>\n' +
    '<div ng-message="email">A email cím formátuma nem megfelelő</div>\n' +
    '<div ng-message="min">A mező tartalma nem elég nagy</div>\n' +
    '<div ng-message="minBirthYear">2010 után született gyermekeket fogadunk el.</div>\n' +
    '<div ng-message="weigthOrWeek">36. hét előtt vagy 2500 gramm születési súly alatti gyermekeket fogadunk el.</div>\n' +
    '<div ng-message="max">A mező tartalma túl nagy</div>\n' +
    '<div ng-message="maxDate">A dátum túllépi az engedélyezett tartományt</div>\n' +
    '<div ng-message="number">A mező tartalma nem szám</div>\n' +
    '<div ng-message="passwordMatch">A beírt jelszavak nem egyeznek</div>\n' +
    '<div ng-message="nullType">A mező kitöltése kötelező</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-form/directives/yco-messages-directive.tpl.html',
    '<div class="yco-input-container__messages help-block">\n' +
    '  <ng-messages class="ng-messages" for="ycoMessages.form[ycoMessages.fieldName].$error" role="alert">\n' +
    '    <ng-messages-include src="{{::ycoMessages.messagesSrc}}"></ng-messages-include>\n' +
    '  </ng-messages>\n' +
    '  <ng-messages class="ng-messages" ng-hide="ycoMessages.form[ycoMessages.fieldName].$invalid" for="ycoMessages.server[ycoMessages.fieldName]" role="alert">\n' +
    '    <ng-messages-include src="{{::ycoMessages.messagesSrc}}"></ng-messages-include>\n' +
    '  </ng-messages>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-form/directives/yco-button-directive.tpl.html',
    '<button class="yco-button"\n' +
    '        ng-disabled="ycoIsProcessing() || ycoDisabled()"\n' +
    '        ng-class="{\'yco-button--is-processing\': ycoIsProcessing()}">\n' +
    '  <i class="fa fa-spinner fa-pulse fa-fw" ng-if="ycoIsProcessing()"></i>\n' +
    '  <ng-transclude ng-if="!ycoIsProcessing()"></ng-transclude>\n' +
    '</button>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-event/views/view-event.tpl.html',
    '<div class="modal-header">\n' +
    '  <h3>{{::viewEvent.event.name}}</h3>\n' +
    '</div>\n' +
    '<div class="modal-body yco-events__view__modal__body">\n' +
    '  <table class="table table-bordered">\n' +
    '    <tbody>\n' +
    '    <tr>\n' +
    '      <td><i class="fa fa-calendar"></i><br>Időpont</td>\n' +
    '      <td>{{::viewEvent.event.date}}</td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td><i class="fa fa-map-marker"></i><br>Helyszín</td>\n' +
    '      <td>{{::viewEvent.event.location}}</td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '      <td><i class="fa fa-info-circle"></i><br>Leírás</td>\n' +
    '      <td>{{::viewEvent.event.description}}</td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '  </table>\n' +
    '</div>\n' +
    '<div class="modal-footer" ng-if="!viewEvent.event.canApply">\n' +
    '  Az eseményen való részvétel nem igényel előzetes jelentkezést.\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '  <button class="btn btn-default"\n' +
    '          type="button"\n' +
    '          ng-click="viewEvent.close()">\n' +
    '    Bezárás\n' +
    '  </button>\n' +
    '  <button class="btn btn-success"\n' +
    '          type="button"\n' +
    '          ng-if="viewEvent.event.canApply && !viewEvent.hasUserApplied"\n' +
    '          ng-click="viewEvent.apply()">\n' +
    '    Jelentkezés az eseményre\n' +
    '  </button>\n' +
    '  <button class="btn btn-danger"\n' +
    '          type="button"\n' +
    '          ng-if="viewEvent.event.canApply && viewEvent.hasUserApplied"\n' +
    '          ng-click="viewEvent.revokeApplication()">\n' +
    '    Jelentkezés visszavonása\n' +
    '  </button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-event/views/new-event.tpl.html',
    '<yco-page-header>Új esemény létrehozása</yco-page-header>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-6 col-md-offset-3">\n' +
    '      <div class="block">\n' +
    '        <div class="block-header">\n' +
    '          <h3 class="block-title">Felhasználói adatok</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-6 col-md-offset-3">\n' +
    '              <p>Az alábbi űrlapon lehetősége nyílik új eseményt létrehoznia a rendszerben.</p>\n' +
    '\n' +
    '              <form name="newEvent.form" ng-submit="newEvent.submit()" novalidate>\n' +
    '                <yco-input-container>\n' +
    '                  <label>Esemény neve</label>\n' +
    '                  <input class="form-control" type="text" name="name" ng-model="newEvent.event.name" required>\n' +
    '                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"\n' +
    '                                field-name="name"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Születési dátum (éééé-hh-nn)</label>\n' +
    '                  <div class="input-group date">\n' +
    '                    <input class="form-control" type="text"\n' +
    '                           ng-model="newEvent.dateTime"\n' +
    '                           is-open="newEvent.popupDateIsOpen"\n' +
    '                           uib-datepicker-popup="{{newEvent.dateFormat}}"\n' +
    '                           close-text="Bezárás"\n' +
    '                           placeholder="Válasszon dátumot">\n' +
    '                    <span class="input-group-addon" ng-click="newEvent.openDatePopup()">\n' +
    '                      <span class="fa fa-calendar"></span>\n' +
    '                    </span>\n' +
    '                  </div>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Időpont</label>\n' +
    '                  <div uib-timepicker\n' +
    '                       ng-model="newEvent.dateTime"\n' +
    '                       ng-change="newEvent.dateTimeOnlyChanged()"\n' +
    '                       show-meridian="ismeridian">\n' +
    '                  </div>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Helyszín</label>\n' +
    '                  <input class="form-control" type="text" name="location" ng-model="newEvent.event.location" required>\n' +
    '                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"\n' +
    '                                field-name="location"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Leírás</label>\n' +
    '                  <textarea name="description" class="form-control" ng-model="newEvent.event.description"\n' +
    '                            required></textarea>\n' +
    '                  <yco-messages form="newEvent.form" server="newEvent.getServerValidation()"\n' +
    '                                field="description"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label class="css-input switch switch-info">\n' +
    '                    <input type="checkbox"\n' +
    '                           class="form-control"\n' +
    '                           name="canApply"\n' +
    '                           ng-model="newEvent.event.canApply"\n' +
    '                           ng-true-value="1"\n' +
    '                           ng-false-value="0"\n' +
    '                           ng-checked="newEvent.event.canApply">\n' +
    '                    <span></span> Előzetes jelentkezés szükséges az eseményhez\n' +
    '                  </label>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label class="css-input switch switch-info">\n' +
    '                    <input type="checkbox"\n' +
    '                           class="form-control"\n' +
    '                           name="public"\n' +
    '                           ng-model="newEvent.event.public"\n' +
    '                           ng-true-value="1"\n' +
    '                           ng-false-value="0"\n' +
    '                           ng-checked="newEvent.event.public"><span></span> Nyílvános\n' +
    '                  </label>\n' +
    '                </yco-input-container>\n' +
    '                <yco-input-container ng-if="!newEvent.event.public">\n' +
    '                  <label>Jogosultság</label>\n' +
    '                  <select name="roles" class="form-control" ng-model="newEvent.event.roleIds" multiple>\n' +
    '                    <option value="{{role.id}}"\n' +
    '                            ng-selected="role.id == newEvent.event.roleIds"\n' +
    '                            ng-repeat="role in newEvent.availableRoles">\n' +
    '                      {{role.name.singular}}\n' +
    '                    </option>\n' +
    '                  </select>\n' +
    '                  <div class="help-block">Az eseményt csak az itt megjelölt jogosultsági körrel\n' +
    '                    rendelkező felhasználók fogják tudni megtekinteni. A listából több jogosultság\n' +
    '                    is kiválasztható egyszerre. Amennyiben egy jogosultság sem kerül kiválasztásra,\n' +
    '                    a kiadvány bármely belépett felhasználó számára látható lesz.\n' +
    '                  </div>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <hr>\n' +
    '\n' +
    '                <div class="push text-center">\n' +
    '                  <yco-button class="btn btn-success" type="submit" yco-is-processing="newEvent.isProcessing()">\n' +
    '                    Esemény létrehozása\n' +
    '                  </yco-button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-event/views/list-events.tpl.html',
    '<yco-page-header>Események</yco-page-header>\n' +
    '\n' +
    '<div class="content ng-scope">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Események</h3>\n' +
    '      <div class="form-group pull-right">\n' +
    '        <label class="css-input switch switch-info">\n' +
    '          Múltbéli események megjelenítése\n' +
    '          <input type="checkbox"\n' +
    '                 class="form-control"\n' +
    '                 name="showExpired"\n' +
    '                 ng-model="listEvents.showExpired"\n' +
    '                 ng-true-value="true"\n' +
    '                 ng-false-value="false"\n' +
    '                 ng-change="listEvents.toggleEvents()"\n' +
    '                 ng-checked="listEvents.showExpired">\n' +
    '          <span></span>\n' +
    '        </label>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <a\n' +
    '        ng-if="listEvents.loggedUser.roleId === listEvents.availableRoles.ADMIN.id"\n' +
    '        class="btn btn-success push-20" ui-sref="newEvent"><i class="fa fa-plus push-5-r"></i> Új létrehozása</a>\n' +
    '      <table class="table table-bordered table-striped"\n' +
    '             datatable\n' +
    '             dt-options="listEvents.dtOptions"\n' +
    '             dt-columns="listEvents.dtColumns"\n' +
    '             dt-instance="listEvents.dtInstance"></table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-event/views/edit-event.tpl.html',
    '<yco-page-header>Esemény szerkesztése</yco-page-header>\n' +
    '\n' +
    '<form name="editEvent.form" ng-submit="editEvent.submit()" novalidate>\n' +
    '  <div class="content">\n' +
    '    <div class="block">\n' +
    '      <div\n' +
    '        class="block-content">\n' +
    '        <div class="push-20">\n' +
    '          <yco-button\n' +
    '            class="btn btn-default" type="button" ui-sref="listEvents">\n' +
    '            <i class="fa fa-arrow-left push-10-r"></i>Vissza az Események listára\n' +
    '          </yco-button>\n' +
    '          <yco-button class="btn btn-success" type="submit" yco-is-processing="editEvent.isProcessing()">\n' +
    '            <i class="fa fa-save push-10-r"></i>Mentés\n' +
    '          </yco-button>\n' +
    '\n' +
    '          <div class="pull-right">\n' +
    '            <yco-button class="btn btn-danger" type="button" ng-click="editEvent.deleteEvent()">\n' +
    '              <i class="fa fa-trash push-10-r"></i>Törlés\n' +
    '            </yco-button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content">\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-8">\n' +
    '        <div class="block">\n' +
    '          <div class="block-header">\n' +
    '            <h3 class="block-title">Esemény adatok</h3>\n' +
    '          </div>\n' +
    '          <div class="block-content">\n' +
    '            <div class="row">\n' +
    '\n' +
    '              <div class="col-md-4">\n' +
    '                <div class="form-group">\n' +
    '                  <label>Esemény neve</label>\n' +
    '                  <input type="text" class="form-control" name="name" ng-model="editEvent.event.name" required>\n' +
    '                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"\n' +
    '                                field="name"></yco-messages>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="col-md-8">\n' +
    '                <div class="form-group">\n' +
    '                  <label>Helyszín</label>\n' +
    '                  <input type="text" class="form-control" name="location" ng-model="editEvent.event.location"\n' +
    '                         required>\n' +
    '                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"\n' +
    '                                field="location"></yco-messages>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="col-md-4">\n' +
    '                <div class="form-group">\n' +
    '                  <label>Dátum</label>\n' +
    '\n' +
    '                  <div class="input-group date">\n' +
    '                    <input class="form-control" type="text" name="date"\n' +
    '                           ng-model="editEvent.dateTime"\n' +
    '                           is-open="editEvent.popupDateIsOpen"\n' +
    '                           uib-datepicker-popup="{{editEvent.dateFormat}}"\n' +
    '                           close-text="Bezárás"\n' +
    '                           placeholder="Válasszon dátumot">\n' +
    '                    <span class="input-group-addon" ng-click="editEvent.openDatePopup()">\n' +
    '                      <span class="fa fa-calendar"></span>\n' +
    '                    </span>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                  <label>Időpont</label>\n' +
    '\n' +
    '                  <div uib-timepicker\n' +
    '                       ng-model="editEvent.dateTime"\n' +
    '                       ng-change="editEvent.dateTimeOnlyChanged()"\n' +
    '                       show-meridian="ismeridian">\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="col-md-8">\n' +
    '                <div class="form-group">\n' +
    '                  <label>Leírás</label>\n' +
    '                  <textarea class="form-control" rows="10" name="description" ng-model="editEvent.event.description"\n' +
    '                            required></textarea>\n' +
    '                  <yco-messages form="editEvent.form" server="editEvent.getServerValidation()"\n' +
    '                                field="description"></yco-messages>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-md-4">\n' +
    '        <div class="block">\n' +
    '          <div class="block-header">\n' +
    '            <h3 class="block-title">További adatok</h3>\n' +
    '          </div>\n' +
    '          <div class="block-content">\n' +
    '            <div class="form-group">\n' +
    '              <label class="css-input switch switch-info">\n' +
    '                <input type="checkbox"\n' +
    '                       class="form-control"\n' +
    '                       name="canApply"\n' +
    '                       ng-model="editEvent.event.canApply"\n' +
    '                       ng-true-value="1"\n' +
    '                       ng-false-value="0"\n' +
    '                       ng-checked="editEvent.event.canApply">\n' +
    '                <span></span> Előzetes jelentkezés szükséges az eseményhez\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group">\n' +
    '              <label class="css-input switch switch-info"\n' +
    '                     uib-tooltip="Amennyiben az esemény nyilvános, a rendszer bármely felhasználója láthatja azt. Ellenkező esetben az eseményhez való hozzáférés jogosultsági szinthez kötött, amelyeket a mező alatt megjelenő listában szabhat meg."\n' +
    '                     tooltip-placement="bottom">\n' +
    '                <input type="checkbox"\n' +
    '                       class="form-control"\n' +
    '                       name="public"\n' +
    '                       ng-model="editEvent.event.public"\n' +
    '                       ng-true-value="1"\n' +
    '                       ng-false-value="0"\n' +
    '                       ng-checked="editEvent.event.public">\n' +
    '                <span></span> Nyilvános <i class="fa fa-question-circle text-muted"></i>\n' +
    '              </label>\n' +
    '            </div>\n' +
    '            <div class="form-group" ng-if="!editEvent.event.public">\n' +
    '              <label>Jogosultság</label>\n' +
    '\n' +
    '              <select name="roles" class="form-control" ng-model="editEvent.event.roleIds" multiple\n' +
    '                      ng-required="editEvent.event.public">\n' +
    '                <option value="{{role.id}}"\n' +
    '                        ng-selected="role.id == editEvent.event.roleIds"\n' +
    '                        ng-repeat="role in editEvent.availableRoles">\n' +
    '                  {{role.name.singular}}\n' +
    '                </option>\n' +
    '              </select>\n' +
    '\n' +
    '              <div class="help-block">Kérjük, válassza ki a listából azokat a jogosultsági köröket, amellyel rendelkező\n' +
    '                felhasználók hozzáférhetnek az eseményhez.\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<div class="content">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <ul class="block-options">\n' +
    '        <li><a href="{{editEvent.mailtoParticipantsLink}}" class="btn btn-xs"><i class="fa fa-reply-all push-5-r"></i>Email\n' +
    '          a résztvevőknek</a></li>\n' +
    '      </ul>\n' +
    '      <h3 class="block-title">Résztvevők</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <p ng-if="!editEvent.hasParticipants()">Nincs jelentkező ehhez az eseményhez.</p>\n' +
    '      <table class="table table-bordered" ng-if="editEvent.hasParticipants()">\n' +
    '        <thead>\n' +
    '        <tr>\n' +
    '          <th>Név</th>\n' +
    '          <th>Email</th>\n' +
    '          <th>Törlés</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '        <tr ng-repeat="participant in editEvent.participants">\n' +
    '          <td>{{::participant.lastName}} {{::participant.firstName}}</td>\n' +
    '          <td>{{::participant.email}}</td>\n' +
    '          <td>\n' +
    '            <button class="btn btn-default btn-xs"\n' +
    '                    ng-click="editEvent.revokeApplication(participant.id)"\n' +
    '                    uib-tooltip="Törlés">\n' +
    '              <i class="fa fa-trash"></i>\n' +
    '            </button>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-enquiry/views/reject-enquiry-dialog.tpl.html',
    '<form name="rejectEnquiry.form" ng-submit="rejectEnquiry.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    <h3>Regisztáció elutasítása</h3>\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <p>Az elutasítás alkalmával a regisztrációt véglegesen és visszafordíthatatlanul töröljük a rendszerből. Kérjük,\n' +
    '      indokolja meg a regisztráció elutasítását az alábbi mezőben. Az elutasításról és annak indoklásáról a\n' +
    '      regisztrációt kezdeményező szülő/gondviselő emailes értesítést kap.</p>\n' +
    '\n' +
    '    <div class="form-group">\n' +
    '      <yco-input-container>\n' +
    '        <label>Indoklás</label>\n' +
    '        <textarea class="yco-enquiry__modal__reject__message form-control"\n' +
    '                  name="statusMessage"\n' +
    '                  rows="5"\n' +
    '                  ng-model="rejectEnquiry.statusMessage"\n' +
    '                  ng-minlength="5"\n' +
    '                  required>\n' +
    '      </textarea>\n' +
    '        <yco-messages form="rejectEnquiry.form" server="rejectEnquiry.getServerValidation()"\n' +
    '                      field-name="statusMessage"></yco-messages>\n' +
    '      </yco-input-container>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="rejectEnquiry.close()">\n' +
    '      Mégse\n' +
    '    </button>\n' +
    '    <yco-button class="btn btn-danger"\n' +
    '                type="submit"\n' +
    '                yco-is-processing="rejectEnquiry.isProcessing">\n' +
    '      Elutasítás\n' +
    '    </yco-button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-enquiry/views/list-enquiries.tpl.html',
    '<yco-page-header>Regisztrációk kezelése</yco-page-header>\n' +
    '\n' +
    '<div class="content ng-scope">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Regisztrációk</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <table class="table table-bordered table-striped" datatable dt-options="listEnquiries.dtOptions"\n' +
    '             dt-columns="listEnquiries.dtColumns"></table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-enquiry/views/edit-enquiry.tpl.html',
    '<yco-page-header>Regisztráció kezelése</yco-page-header>\n' +
    '\n' +
    '<form name="editEnquiry.form" ng-submit="editEnquiry.submit()" novalidate>\n' +
    '  <div class="content ng-scope">\n' +
    '    <div class="block">\n' +
    '      <div\n' +
    '        class="block-content">\n' +
    '        <div class="push-20">\n' +
    '          <button type="button" class="btn btn-default" ui-sref="listEnquiries">\n' +
    '            <i class="fa fa-arrow-left push-5-r"></i>Vissza a regisztrációkhoz\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-9">\n' +
    '        <div class="block block-bordered">\n' +
    '          <div class="block-header">\n' +
    '            <h3 class="block-title">Adatok</h3>\n' +
    '          </div>\n' +
    '          <div class="block-content tab-content">\n' +
    '            <uib-tabset active="editEnquiry.activeTab" justified="true" class="block">\n' +
    '              <uib-tab index="0" heading="1. Alapadatok">\n' +
    '                <div class="col-md-6 col-md-offset-3 push-30-t">\n' +
    '                  <div class="push-50">\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Vezetéknév</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="lastName"\n' +
    '                             ng-model="editEnquiry.enquiry.lastName"\n' +
    '                             required>\n' +
    '                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                                    field-name="lastName"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Keresztnév</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="firstName"\n' +
    '                             ng-model="editEnquiry.enquiry.firstName"\n' +
    '                             required>\n' +
    '                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                                    field-name="firstName"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Email</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="email"\n' +
    '                             ng-model="editEnquiry.enquiry.email"\n' +
    '                             required>\n' +
    '                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                                    field-name="email"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Telefonszám</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="phone"\n' +
    '                             ng-model="editEnquiry.enquiry.phone"\n' +
    '                             required>\n' +
    '                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                                    field-name="phone"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                    <yco-input-container>\n' +
    '                      <label>Település</label>\n' +
    '                      <input class="form-control"\n' +
    '                             type="text"\n' +
    '                             name="city"\n' +
    '                             ng-minlength="2"\n' +
    '                             ng-model="editEnquiry.enquiry.city"\n' +
    '                             required>\n' +
    '                      <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                                    field-name="city"></yco-messages>\n' +
    '                    </yco-input-container>\n' +
    '\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </uib-tab>\n' +
    '              <uib-tab index="1" heading="2. Gyermekek adatai">\n' +
    '                <table class="table table-bordered push-30-t">\n' +
    '                  <thead>\n' +
    '                  <tr>\n' +
    '                    <th>Vezetéknév</th>\n' +
    '                    <th>Keresztnév</th>\n' +
    '                    <th>Születési év</th>\n' +
    '                    <th>Születési hét</th>\n' +
    '                    <th>Születési súly</th>\n' +
    '                  </tr>\n' +
    '                  </thead>\n' +
    '                  <tbody>\n' +
    '                  <tr ng-repeat="child in editEnquiry.children">\n' +
    '                    <td>\n' +
    '                      <input class="form-control" type="text" ng-model="child.lastName" disabled>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <input class="form-control" type="text" ng-model="child.firstName" disabled>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <input class="form-control" type="text" ng-model="child.birthYear" disabled>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <input class="form-control" type="text" ng-model="child.birthWeek" disabled>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                      <div class="input-group">\n' +
    '                        <input class="form-control" type="text" ng-model="child.birthWeight" disabled>\n' +
    '                        <div class="input-group-addon">g</div>\n' +
    '                      </div>\n' +
    '                    </td>\n' +
    '                  </tr>\n' +
    '                  </tbody>\n' +
    '                </table>\n' +
    '              </uib-tab>\n' +
    '            </uib-tabset>\n' +
    '          </div>\n' +
    '          <div class="block-content block-content-mini block-content-full border-t">\n' +
    '            <div class="row">\n' +
    '              <div class="col-md-6">\n' +
    '                <button class="btn btn-default"\n' +
    '                        type="button"\n' +
    '                        ng-disabled="editEnquiry.activeTab === 0"\n' +
    '                        ng-click="editEnquiry.setActiveTab(0)">\n' +
    '                  <i class="fa fa-arrow-left"></i>\n' +
    '                  Vissza\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <div class="col-md-6 text-right">\n' +
    '                <button class="btn btn-default"\n' +
    '                        type="button"\n' +
    '                        ng-disabled="editEnquiry.activeTab === 1"\n' +
    '                        ng-click="editEnquiry.setActiveTab(1)">\n' +
    '                  Tovább <i class="fa fa-arrow-right"></i></button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="col-md-3">\n' +
    '        <div class="btn-group btn-block push-30" ng-if="editEnquiry.isNewEnquiry()">\n' +
    '          <button class="btn btn-info btn-lg btn-block" type="button" ng-click="editEnquiry.showAssignEnquiryDialog()">\n' +
    '            <i class="fa fa-user"></i><br>Mentor hozzárendelése\n' +
    '          </button>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="btn-group btn-group-justified push-30">\n' +
    '          <div class="btn-group">\n' +
    '            <button class="btn btn-success btn-lg"\n' +
    '                    type="button"\n' +
    '                    ng-disabled="!editEnquiry.enquiry.mentorId"\n' +
    '                    ng-click="editEnquiry.showApproveEnquiryDialog()">\n' +
    '              <i class="fa fa-check"></i><br>Elfogadás\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <div class="btn-group">\n' +
    '            <button class="btn btn-danger btn-lg" type="button" ng-click="editEnquiry.showRejectEnquiryDialog()">\n' +
    '              <i class="fa fa-times"></i><br>Elutasítás\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="block block-bordered">\n' +
    '          <div class="block-header">\n' +
    '            <h3 class="block-title">Regisztráció részletek</h3>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="block-content block-content-full">\n' +
    '            <div>\n' +
    '              <p class="font-w600 push-5">Azonosító</p>\n' +
    '\n' +
    '              <p>{{editEnquiry.enquiry.id}}</p>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '              <p class="font-w600 push-5">Regisztráció dátuma</p>\n' +
    '\n' +
    '              <p>{{editEnquiry.enquiry.createdAt | moment : \'YYYY.MM.DD. HH:mm\'}}</p>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '              <p class="font-w600 push-5">Státusz</p>\n' +
    '\n' +
    '              <p>{{editEnquiry.enquiry.status | enquiryStatus}}</p>\n' +
    '            </div>\n' +
    '            <div ng-if="!editEnquiry.isNewEnquiry()">\n' +
    '              <p class="font-w600 push-5">Hozzárendelt mentor</p>\n' +
    '\n' +
    '              <ui-select append-to-body="true"\n' +
    '                         name="mentorId"\n' +
    '                         class="yco-enquiry__autocomplete__select"\n' +
    '                         ng-disabled="editEnquiry.loggedUser.roleId === editEnquiry.availableRoles.MENTOR.id"\n' +
    '                         ng-model="editEnquiry.enquiry.mentorId">\n' +
    '                <ui-select-match allow-clear="false">\n' +
    '                  {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                </ui-select-match>\n' +
    '                <ui-select-choices repeat="user.id as user in editEnquiry.possibleMentors track by $index"\n' +
    '                                   refresh="editEnquiry.refreshPossibleMentors($select.search)"\n' +
    '                                   refresh-delay="200"\n' +
    '                                   minimum-input-length="3">\n' +
    '                  <div class="inner-push-5 inner-push-5-t media">\n' +
    '                    <div class="media-left">\n' +
    '                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                           alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                    </div>\n' +
    '                    <div class="media-right">\n' +
    '                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                    </div>\n' +
    '                    <div class="yco-enquiry__autocomplete__suggestion__email">\n' +
    '                      <span>{{user.email}}</span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </ui-select-choices>\n' +
    '              </ui-select>\n' +
    '              <yco-messages form="editEnquiry.form" server="editEnquiry.getServerValidation()"\n' +
    '                            field-name="mentorId"></yco-messages>\n' +
    '            </div>\n' +
    '            <div class="push-20-t clearfix">\n' +
    '              <yco-button class="btn btn-success pull-right" type="submit"\n' +
    '                          yco-is-processing="editEnquiry.isProcessing()">\n' +
    '                <i class="fa fa-save push-5-r"></i> Mentés\n' +
    '              </yco-button>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-enquiry/views/assign-enquiry-dialog.tpl.html',
    '<form name="assignEnquiry.form" ng-submit="assignEnquiry.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    <h3>Mentor hozzárendelése</h3>\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <p>Kezdje el begépelni egy mentor nevét, majd válasszon a lenyíló listában megjelenő lehetőségek közül. A \'Mentés\'\n' +
    '      gombra kattintva rendszerünk a kiválasztott mentorhoz rendeli a regisztrációs kérelmet, amelyről emailes\n' +
    '      értesítést kap. A hozzárendelést követően a mentor feladata áttekinteni és feldolgozni az aktuális regisztrációs\n' +
    '      kérelmet.</p>\n' +
    '\n' +
    '    <div class="form-group">\n' +
    '      <yco-input-container>\n' +
    '        <label>Mentor</label>\n' +
    '        <ui-select append-to-body="true" name="mentor" ng-model="assignEnquiry.mentorId" required>\n' +
    '          <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '          </ui-select-match>\n' +
    '          <ui-select-choices repeat="user.id as user in assignEnquiry.possibleMentors track by $index"\n' +
    '                             refresh="assignEnquiry.refreshPossibleMentors($select.search)"\n' +
    '                             refresh-delay="200"\n' +
    '                             minimum-input-length="3">\n' +
    '            <div class="inner-push-5 inner-push-5-t media">\n' +
    '              <div class="media-left">\n' +
    '                <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                     alt="{{user.lastName}} {{user.firstName}}">\n' +
    '              </div>\n' +
    '              <div class="media-right">\n' +
    '                <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '              </div>\n' +
    '              <div class="yco-enquiry__autocomplete__suggestion__email">\n' +
    '                <span>{{user.email}}</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </ui-select-choices>\n' +
    '        </ui-select>\n' +
    '        <yco-messages form="assignEnquiry.form" server="assignEnquiry.getServerValidation()"\n' +
    '                      field-name="mentor"></yco-messages>\n' +
    '      </yco-input-container>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="assignEnquiry.close()">\n' +
    '      Mégse\n' +
    '    </button>\n' +
    '    <yco-button class="btn btn-success"\n' +
    '                type="submit"\n' +
    '                yco-is-processing="assignEnquiry.isProcessing">\n' +
    '      <i class="fa fa-save push-5-r"></i>Mentés\n' +
    '    </yco-button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/view-child.tpl.html',
    '<div class="content bg-image" style="background-image: url(\'images/user-bg.jpg\');">\n' +
    '  <div class="push-50-t push-15 clearfix">\n' +
    '    <div class="push-15-r pull-left">\n' +
    '      <img class="img-avatar img-avatar-thumb" ng-src="{{viewChild.child.image}}">\n' +
    '    </div>\n' +
    '    <h1 class="h2 text-white push-5-t">{{viewChild.child.lastName}} {{viewChild.child.firstName}}</h1>\n' +
    '\n' +
    '    <h2 class="h5 text-white-op">Szülő: {{viewChild.child.parent.data.lastName}}\n' +
    '      {{viewChild.child.parent.data.firstName}}</h2>\n' +
    '  </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="yco-child__view content">\n' +
    '  <div class="block">\n' +
    '    <div\n' +
    '      class="block-content">\n' +
    '      <div class="push-20">\n' +
    '        <button type="button" class="btn btn-default" ui-sref="listChildren">\n' +
    '          <i class="fa fa-arrow-left push-5-r"></i>Vissza a mentoráltak listára\n' +
    '        </button>\n' +
    '        <button\n' +
    '          ng-if="viewChild.canEditChild"\n' +
    '          type="button" class="btn btn-info" ui-sref="editChild({childId: viewChild.child.id})">\n' +
    '          <i class="fa fa-pencil push-5-r"></i>Szerkesztés\n' +
    '        </button>\n' +
    '        <button class="btn btn-danger"\n' +
    '          ng-if="!viewChild.isDataSheetClosed"\n' +
    '          type="button"\n' +
    '          ng-click="viewChild.manageChildDataSheetState({isClosed: true})">\n' +
    '          <i class="fa fa-ban push-5-r"></i>Adatlap lezárása\n' +
    '        </button>\n' +
    '        <button class="btn btn-success"\n' +
    '          ng-if="viewChild.isDataSheetClosed"\n' +
    '          type="button"\n' +
    '          ng-click="viewChild.manageChildDataSheetState({isClosed: false})">\n' +
    '          <i class="fa fa-unlock push-5-r"></i>Adatlap újranyitása\n' +
    '        </button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-md-4">\n' +
    '      <div class="yco-child__activities block block-themed">\n' +
    '        <div class="block-header bg-info">\n' +
    '          <ul class="block-options">\n' +
    '            <li ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id">\n' +
    '              <button class="btn btn-default" type="button" ng-click="viewChild.showAddTextEntryDialog()"\n' +
    '                      ng-disabled="!viewChild.canEditChild">\n' +
    '                <i class="fa fa-plus push-5-r"></i>Új szöveges bejegyzés\n' +
    '              </button>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '          <h3 class="block-title">Tevékenységek</h3>\n' +
    '        </div>\n' +
    '        <div class="block-content block-content-full">\n' +
    '          <ul class="list list-timeline push" ng-if="viewChild.latestActivities.length">\n' +
    '            <yco-child-activity ng-repeat="activity in viewChild.latestActivities"\n' +
    '                                activity="activity"></yco-child-activity>\n' +
    '          </ul>\n' +
    '          <div class="yco-child__activities__skeleton text-center" ng-if="!viewChild.latestActivities.length">\n' +
    '            <i class="yco-child__activities__skeleton__icon fa fa-4x fa-file-text-o"></i>\n' +
    '            <h4 class="yco-child__activities__skeleton__title">Nincs tevékenység</h4>\n' +
    '\n' +
    '            <p class="yco-child__activities__skeleton__legend">Jelenleg nincs megjeleníthető tevékenység az adatlapon.\n' +
    '              Módosítson adatokat, vagy vigyen fel szöveges bejegyzést, ami feljegyzésre kerül ebben a listában.</p>\n' +
    '\n' +
    '            <button class="btn btn-success"\n' +
    '                    type="button"\n' +
    '                    ng-click="viewChild.showAddTextEntryDialog()"\n' +
    '            >\n' +
    '              <i class="fa fa-plus push-5-r"></i>\n' +
    '              Új szöveges bejegyzés\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="col-md-8">\n' +
    '      <div class="block block-themed">\n' +
    '        <div class="block-header bg-warning">\n' +
    '          <ul class="block-options">\n' +
    '            <li ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id">\n' +
    '              <button\n' +
    '                class="btn btn-default"\n' +
    '                type="button"\n' +
    '                ng-disabled="!viewChild.canEditChild"\n' +
    '                ng-click="viewChild.showAddParticipantDialog()">\n' +
    '                <i class="fa fa-plus push-5-r"></i>Új hozzáadása\n' +
    '              </button>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '          <h3 class="block-title">\n' +
    '            Adatlaphoz köthető személyek\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '        <div class="block-content clearfix">\n' +
    '          <p ng-if="!viewChild.participants.length">Nincs az adatlaphoz köthető személy.</p>\n' +
    '\n' +
    '          <table class="table" ng-if="viewChild.participants.length">\n' +
    '            <thead>\n' +
    '            <tr>\n' +
    '              <th>Név</th>\n' +
    '              <th>Jogcím</th>\n' +
    '              <th class="text-center">Műveletek</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '            <tr ng-repeat="user in viewChild.participants">\n' +
    '              <td class="yco-child__view__participant">{{user.lastName}} {{user.firstName}} <a href="#"\n' +
    '                                                                                               ng-if="user.note.length"\n' +
    '                                                                                               uib-tooltip="{{user.note}}"><i\n' +
    '                class="fa fa-info-circle"></i></a></td>\n' +
    '              <td><span class="badge badge-default">{{user.roleId | userRole}}</span></td>\n' +
    '              <td class="text-center">\n' +
    '                <a ui-sref="viewUser({userId: user.id})"\n' +
    '                   class="btn btn-default btn-xs">\n' +
    '                  <i class="fa fa-user push-5-r"></i>Profil\n' +
    '                </a>\n' +
    '                <a ui-sref="messages({userId: user.id})"\n' +
    '                   class="btn btn-default btn-xs"\n' +
    '                   ng-disabled="!viewChild.canParticipantBeMessaged($index)">\n' +
    '                  <i class="fa fa-envelope push-5-r"></i>Üzenet\n' +
    '                </a>\n' +
    '                <button type="button"\n' +
    '                        class="btn btn-danger btn-xs"\n' +
    '                        ng-if="viewChild.currentUser.roleId !== viewChild.availableRoles.PARENT.id"\n' +
    '                        ng-click="viewChild.removeParticipant($index)"\n' +
    '                        ng-disabled="!viewChild.canParticipantBeRemoved($index) || !viewChild.canEditChild">\n' +
    '                  <i class="fa fa-trash push-5-r"></i>Eltávolítás\n' +
    '                </button>\n' +
    '              </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="block block-themed">\n' +
    '        <div class="block-header bg-modern">\n' +
    '          <h3 class="block-title">\n' +
    '            Dokumentumok\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '        <div class="yco-child__documents block-content clearfix">\n' +
    '          <div ng-if="viewChild.childDocuments.length">\n' +
    '            <table class="table table-striped">\n' +
    '              <thead>\n' +
    '              <tr>\n' +
    '                <th>Fájlnév</th>\n' +
    '                <th class="text-center">Letöltés</th>\n' +
    '              </tr>\n' +
    '              </thead>\n' +
    '              <tbody>\n' +
    '              <tr ng-repeat="document in viewChild.childDocuments">\n' +
    '                <td>{{document.name}}</td>\n' +
    '                <td class="text-center">\n' +
    '                  <a class="btn btn-info push-5-r"\n' +
    '                     ng-href="{{document.url}}"\n' +
    '                     uib-tooltip="Letöltés" download target="_blank"><i class="fa fa-download"></i>\n' +
    '                    Letöltés\n' +
    '                  </a>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '          <div ng-if="!viewChild.childDocuments.length">\n' +
    '            Jelenleg nincs feltöltött dokumentum\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="block block-themed">\n' +
    '        <div class="block-header bg-flat">\n' +
    '          <h3 class="block-title">\n' +
    '            Személyes adatok\n' +
    '            <span class="badge {{(viewChild.child.dataSheetStatus | childStatus).class}} push-5-l">{{(viewChild.child.dataSheetStatus | childStatus).label}}</span>\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '        <div class="block-content clearfix">\n' +
    '          <div class="row">\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Azonosító</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.id}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Név</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.lastName}} {{viewChild.child.firstName}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Anyja neve</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.motherName}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Születési dátum</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.dateOfBirth | moment : \'YYYY. MM. DD. HH:mm\'}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Lakcím</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.address}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Születési hét</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.birthWeek}}.</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Születési súly</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.birthWeight}} g</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">Regisztráció dátuma</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.createdAt | moment : \'YYYY. MM. DD. HH:mm\'}}</p>\n' +
    '            </div>\n' +
    '            <div class="col-md-3">\n' +
    '              <p class="font-w600 push-5">BMI</p>\n' +
    '\n' +
    '              <p>{{viewChild.child.bmi | number : 2}}</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="yco-child__field-group block block-themed"\n' +
    '           ng-repeat="fieldGroup in viewChild.child.fields.data.groups">\n' +
    '        <div class="block-header bg-flat">\n' +
    '          <h3 class="block-title">\n' +
    '            {{::fieldGroup.name}}\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '        <div class="block-content">\n' +
    '          <div class="row" ng-if="fieldGroup.fields.length">\n' +
    '            <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-3 clearfix"\n' +
    '                 ng-repeat="field in fieldGroup.fields | filter : viewChild.emptyFieldFilter"\n' +
    '                 ng-class="{\'yco-child__field-group__wrapper--child\': !!field.parent}"\n' +
    '                 ng-if="viewChild.checkConditionalDisplay($index, fieldGroup.fields)">\n' +
    '              <div ng-switch="field.type">\n' +
    '                <text-field field="field"\n' +
    '                            ng-switch-default\n' +
    '                            readonly="true"></text-field>\n' +
    '                <number-field field="field"\n' +
    '                              ng-switch-when="number"\n' +
    '                              readonly="true"></number-field>\n' +
    '                <select-field field="field"\n' +
    '                              ng-switch-when="select"\n' +
    '                              readonly="true"></select-field>\n' +
    '                <select-field field="field"\n' +
    '                              ng-switch-when="multiselect"\n' +
    '                              readonly="true"></select-field>\n' +
    '                <date-field field="field"\n' +
    '                            ng-switch-when="date"\n' +
    '                            readonly="true"></date-field>\n' +
    '                <bno-field field="field"\n' +
    '                           ng-switch-when="bno"\n' +
    '                           readonly="true"></bno-field>\n' +
    '                <boolean-field field="field"\n' +
    '                               ng-switch-when="boolean"\n' +
    '                               readonly="true"></boolean-field>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--has-tabs"\n' +
    '             ng-if="!(fieldGroup.tabs | isEmpty)">\n' +
    '          <div class="inner-push-20-l inner-push-20-r">\n' +
    '            <uib-tabset>\n' +
    '              <uib-tab ng-repeat="(tabKey, tab) in fieldGroup.tabs" index="$index" heading="{{::tab.name}}">\n' +
    '                <div class="row inner-push-20-t">\n' +
    '                  <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-3 clearfix"\n' +
    '                       ng-repeat="field in tab.fields | filter : viewChild.emptyFieldFilter"\n' +
    '                       ng-class="{\'yco-child__field-group__wrapper--child\': !!field.parent}"\n' +
    '                       ng-if="viewChild.checkConditionalDisplay($index, tab.fields)">\n' +
    '                    <div ng-switch="field.type">\n' +
    '                      <text-field field="field"\n' +
    '                                  ng-switch-default\n' +
    '                                  readonly="true"></text-field>\n' +
    '                      <number-field field="field"\n' +
    '                                    ng-switch-when="number"\n' +
    '                                    readonly="true"></number-field>\n' +
    '                      <select-field field="field"\n' +
    '                                    ng-switch-when="select"\n' +
    '                                    readonly="true"></select-field>\n' +
    '                      <select-field field="field"\n' +
    '                                    ng-switch-when="multiselect"\n' +
    '                                    readonly="true"></select-field>\n' +
    '                      <date-field field="field"\n' +
    '                                  ng-switch-when="date"\n' +
    '                                  readonly="true"></date-field>\n' +
    '                      <bno-field field="field"\n' +
    '                                 ng-switch-when="bno"\n' +
    '                                 readonly="true"></bno-field>\n' +
    '                      <boolean-field field="field"\n' +
    '                                     ng-switch-when="boolean"\n' +
    '                                     readonly="true"></boolean-field>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </uib-tab>\n' +
    '            </uib-tabset>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/new-child.tpl.html',
    '<yco-page-header>Új mentorált létrehozása</yco-page-header>\n' +
    '<form name="newChild.form" ng-submit="newChild.submit($event)" novalidate>\n' +
    '  <div class="content">\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-content">\n' +
    '        <div class="push-20">\n' +
    '          <yco-button class="btn btn-success"\n' +
    '                      type="submit"\n' +
    '                      yco-is-processing="newChild.isProcessing()">\n' +
    '            <i class="fa fa-save push-5-r"></i>Létrehozás\n' +
    '          </yco-button>\n' +
    '\n' +
    '          <button class="btn btn-default"\n' +
    '                  type="button"\n' +
    '                  ui-sref="listChildren">\n' +
    '            Vissza a mentoráltak listára\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-header bg-flat">\n' +
    '        <h3 class="block-title">\n' +
    '          Személyes adatok\n' +
    '        </h3>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="block-content clearfix">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Vezetéknév</label>\n' +
    '              <input class="form-control mouseflow" type="text" name="lastName" ng-model="newChild.child.lastName"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="lastName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Keresztnév</label>\n' +
    '              <input class="form-control mouseflow" type="text" name="firstName" ng-model="newChild.child.firstName"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="firstName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Anyja neve</label>\n' +
    '              <input class="form-control mouseflow" type="text" name="motherName" ng-model="newChild.child.motherName"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="motherName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési dátum (éééé-hh-nn)</label>\n' +
    '              <div class="input-group date">\n' +
    '                <input class="form-control mouseflow" type="text"\n' +
    '                       name="dateOfBirth"\n' +
    '                       ng-model="newChild.child.dateOfBirth"\n' +
    '                       is-open="newChild.popupDateIsOpen"\n' +
    '                       uib-datepicker-popup="{{newChild.dateFormat}}"\n' +
    '                       datepicker-options="newChild.datePickerOptions"\n' +
    '                       close-text="Bezárás"\n' +
    '                       placeholder="Válasszon dátumot">\n' +
    '                <span class="input-group-addon" ng-click="newChild.openDatePopup()">\n' +
    '                      <span class="fa fa-calendar"></span>\n' +
    '                    </span>\n' +
    '              </div>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="dateOfBirth"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Lakcím</label>\n' +
    '              <input class="form-control mouseflow" type="text" name="address" ng-model="newChild.child.address"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="address"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési hét</label>\n' +
    '              <input class="form-control mouseflow" type="number" name="birthWeek" ng-model="newChild.child.birthWeek"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="birthWeek"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési súly (g)</label>\n' +
    '              <input class="form-control mouseflow" type="number" name="birthWeight" ng-model="newChild.child.birthWeight"\n' +
    '                     required>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="birthWeight"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Szülő</label>\n' +
    '              <input class="form-control hidden" type="text" name="parentId" ng-model="newChild.child.parentId"\n' +
    '                     required>\n' +
    '              <ui-select append-to-body="true" name="parentId" class="mouseflow" ng-model="newChild.child.parentId" required>\n' +
    '                <ui-select-match allow-clear="true">\n' +
    '                  {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                </ui-select-match>\n' +
    '                <ui-select-choices repeat="user.id as user in newChild.possibleParents track by $index"\n' +
    '                                   refresh="newChild.refreshPossibleParents($select.search)"\n' +
    '                                   refresh-delay="200"\n' +
    '                                   minimum-input-length="3">\n' +
    '                  <div class="inner-push-5 inner-push-5-t media">\n' +
    '                    <div class="media-left">\n' +
    '                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                           alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                    </div>\n' +
    '                    <div class="media-right">\n' +
    '                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                    </div>\n' +
    '                    <div class="yco-child__autocomplete__suggestion__email">\n' +
    '                      <span>{{user.email}}</span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </ui-select-choices>\n' +
    '              </ui-select>\n' +
    '              <yco-messages form="newChild.form" server="newChild.getServerValidation()"\n' +
    '                            field-name="parentId"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/list-children.tpl.html',
    '<yco-page-header>Mentoráltak</yco-page-header>\n' +
    '\n' +
    '<div class="content ng-scope">\n' +
    '  <div class="block">\n' +
    '    <div class="block-header">\n' +
    '      <h3 class="block-title">Mentoráltak</h3>\n' +
    '    </div>\n' +
    '    <div class="block-content">\n' +
    '      <a ng-if="listChildren.user.roleId === listChildren.userRoles.ADMIN.id\n' +
    '             || listChildren.user.roleId === listChildren.userRoles.MENTOR.id"\n' +
    '         class="btn btn-success push-20"\n' +
    '         ui-sref="newUserWithChild">\n' +
    '        <i class="fa fa-plus push-5-r"></i>Új mentorált és szülő felvitele\n' +
    '      </a>\n' +
    '      <a\n' +
    '        ng-if="listChildren.user.roleId === listChildren.userRoles.ADMIN.id\n' +
    '            || listChildren.user.roleId === listChildren.userRoles.MENTOR.id"\n' +
    '        ui-sref="newChild"\n' +
    '        class="btn btn-success push-20" ui-sref="newChild">\n' +
    '        <i class="fa fa-plus push-5-r"></i> Új mentorált hozzáadása meglévő szülőhöz\n' +
    '      </a>\n' +
    '      <table class="table table-bordered table-striped"\n' +
    '             datatable\n' +
    '             dt-options="listChildren.dtOptions"\n' +
    '             dt-columns="listChildren.dtColumns"></table>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/edit-child.tpl.html',
    '<form name="editChild.form" ng-submit="editChild.submit()" novalidate>\n' +
    '  <div class="content bg-image" style="background-image: url(\'images/user-bg.jpg\');">\n' +
    '    <div class="push-50-t push-15 clearfix">\n' +
    '      <div class="push-15-r pull-left">\n' +
    '        <img class="img-avatar img-avatar-thumb" ng-src="{{editChild.child.image}}">\n' +
    '      </div>\n' +
    '      <h1 class="h2 text-white push-5-t">{{editChild.child.lastName}} {{editChild.child.firstName}}</h1>\n' +
    '\n' +
    '      <h2 class="h5 text-white-op">Szülő: {{editChild.child.parent.data.lastName}}\n' +
    '        {{editChild.child.parent.data.firstName}}</h2>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content">\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-content">\n' +
    '        <div class="push-20">\n' +
    '          <button class="btn btn-danger pull-right"\n' +
    '                  type="button"\n' +
    '                  ng-if="editChild.loggedUser.roleId === editChild.availableRoles.ADMIN.id"\n' +
    '                  ng-click="editChild.showDeleteDialog()">\n' +
    '            <i class="fa fa-trash push-5-r"></i>Adatlap törlése\n' +
    '          </button>\n' +
    '\n' +
    '          <yco-button class="btn btn-success"\n' +
    '                      type="submit"\n' +
    '                      yco-is-processing="editChild.isProcessing()">\n' +
    '            <i class="fa fa-save push-5-r"></i>Mentés\n' +
    '          </yco-button>\n' +
    '\n' +
    '          <button class="btn btn-default"\n' +
    '                  type="button"\n' +
    '                  ui-sref="viewChild({childId: editChild.child.id})">\n' +
    '            Vissza az adatlapra\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-header bg-flat">\n' +
    '        <h3 class="block-title">\n' +
    '          Személyes adatok\n' +
    '        </h3>\n' +
    '      </div>\n' +
    '      <div class="block-content clearfix">\n' +
    '        <div class="row">\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Vezetéknév</label>\n' +
    '              <input class="form-control" type="text" name="lastName" ng-model="editChild.child.lastName" required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="lastName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Keresztnév</label>\n' +
    '              <input class="form-control" type="text" name="firstName" ng-model="editChild.child.firstName" required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="firstName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Anyja neve</label>\n' +
    '              <input class="form-control" type="text" name="motherName" ng-model="editChild.child.motherName" required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="motherName"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési dátum (éééé-hh-nn)</label>\n' +
    '\n' +
    '              <div class="input-group date">\n' +
    '                <input class="form-control" type="text"\n' +
    '                       name="dateOfBirth"\n' +
    '                       ng-model="editChild.dateOfBirth"\n' +
    '                       is-open="editChild.popupDateIsOpen"\n' +
    '                       uib-datepicker-popup="{{editChild.dateFormat}}"\n' +
    '                       datepicker-options="editChild.datePickerOptions"\n' +
    '                       close-text="Bezárás"\n' +
    '                       placeholder="Válasszon dátumot">\n' +
    '                <span class="input-group-addon" ng-click="editChild.openDatePopup()">\n' +
    '                      <span class="fa fa-calendar"></span>\n' +
    '                    </span>\n' +
    '              </div>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="dateOfBirth"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Lakcím</label>\n' +
    '              <input class="form-control" type="text" name="address" ng-model="editChild.child.address" required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="address"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési hét</label>\n' +
    '              <input class="form-control" type="text" name="birthWeek" ng-model="editChild.child.birthWeek" required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="birthWeek"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Születési súly (g)</label>\n' +
    '              <input class="form-control" type="text" name="birthWeight" ng-model="editChild.child.birthWeight"\n' +
    '                     required>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="birthWeight"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="col-md-4 col-lg-3">\n' +
    '            <yco-input-container>\n' +
    '              <label>Szülő</label>\n' +
    '              <ui-select append-to-body="true" class="yco-child__autocomplete__select" name="parentId"\n' +
    '                         ng-model="editChild.child.parentId"\n' +
    '                         required>\n' +
    '                <ui-select-match allow-clear="false">\n' +
    '                  {{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '                </ui-select-match>\n' +
    '                <ui-select-choices repeat="user.id as user in editChild.possibleParents track by $index"\n' +
    '                                   refresh="editChild.refreshPossibleParents($select.search)"\n' +
    '                                   refresh-delay="200"\n' +
    '                                   minimum-input-length="3">\n' +
    '                  <div class="inner-push-5 inner-push-5-t media">\n' +
    '                    <div class="media-left">\n' +
    '                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                           alt="{{user.lastName}} {{user.firstName}}">\n' +
    '                    </div>\n' +
    '                    <div class="media-right">\n' +
    '                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '                    </div>\n' +
    '                    <div class="yco-child__autocomplete__suggestion__email">\n' +
    '                      <span>{{user.email}}</span>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </ui-select-choices>\n' +
    '              </ui-select>\n' +
    '              <yco-messages form="editChild.form" server="editChild.getServerValidation()"\n' +
    '                            field-name="parentId"></yco-messages>\n' +
    '            </yco-input-container>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div>\n' +
    '      <div class="block block-themed" ng-repeat="fieldGroup in editChild.child.fields.data.groups">\n' +
    '        <div class="block-header bg-flat">\n' +
    '          <h3 class="block-title">\n' +
    '            {{::fieldGroup.name}}\n' +
    '          </h3>\n' +
    '        </div>\n' +
    '        <div class="block-content clearfix">\n' +
    '          <div class="row" ng-if="fieldGroup.fields.length">\n' +
    '            <div\n' +
    '              class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-4 col-lg-3 clearfix"\n' +
    '              ng-repeat="field in fieldGroup.fields track by field.id"\n' +
    '              ng-class="{\'yco-child__field-group__wrapper--child\': !!field.parent}"\n' +
    '              ng-if="editChild.checkConditionalDisplay($index, fieldGroup.fields)">\n' +
    '              <div ng-switch="field.type">\n' +
    '                <text-field form="editChild.form"\n' +
    '                            field="field"\n' +
    '                            container="fieldGroup.fields"\n' +
    '                            ng-switch-default\n' +
    '                            readonly="false"></text-field>\n' +
    '                <number-field form="editChild.form"\n' +
    '                              field="field"\n' +
    '                              container="fieldGroup.fields"\n' +
    '                              ng-switch-when="number"\n' +
    '                              readonly="false"></number-field>\n' +
    '                <select-field form="editChild.form"\n' +
    '                              field="field"\n' +
    '                              container="fieldGroup.fields"\n' +
    '                              ng-switch-when="select"\n' +
    '                              readonly="false"></select-field>\n' +
    '                <select-field form="editChild.form"\n' +
    '                              field="field"\n' +
    '                              container="fieldGroup.fields"\n' +
    '                              ng-switch-when="multiselect"\n' +
    '                              readonly="false"></select-field>\n' +
    '                <date-field form="editChild.form"\n' +
    '                            field="field"\n' +
    '                            container="fieldGroup.fields"\n' +
    '                            ng-switch-when="date"\n' +
    '                            readonly="false"></date-field>\n' +
    '                <bno-field form="editChild.form"\n' +
    '                           field="field"\n' +
    '                           container="fieldGroup.fields"\n' +
    '                           ng-switch-when="bno"\n' +
    '                           readonly="false"></bno-field>\n' +
    '                <boolean-field form="editChild.form"\n' +
    '                               field="field"\n' +
    '                               container="fieldGroup.fields"\n' +
    '                               ng-switch-when="boolean"\n' +
    '                               readonly="false"></boolean-field>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--has-tabs"\n' +
    '               ng-if="!(fieldGroup.tabs | isEmpty)">\n' +
    '            <uib-tabset>\n' +
    '              <uib-tab ng-repeat="(tabKey, tab) in fieldGroup.tabs"\n' +
    '                       index="$index"\n' +
    '                       heading="{{::tab.name}}"\n' +
    '                       classes="yco-child__field-group__tab {{editChild.hasMissingField(tab) ? \'yco-child__field-group__tab--invalid\' : \'yco-child__field-group__tab--valid\' }}">\n' +
    '                <div class="row inner-push-20-t">\n' +
    '                  <div\n' +
    '                    class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-4 col-lg-3 clearfix"\n' +
    '                    ng-repeat="field in tab.fields track by field.id"\n' +
    '                    ng-class="{\'yco-child__field-group__wrapper--child\': !!field.parent}"\n' +
    '                    ng-if="editChild.checkConditionalDisplay($index, tab.fields)">\n' +
    '                    <div ng-switch="field.type">\n' +
    '                      <text-field form="editChild.form"\n' +
    '                                  field="field"\n' +
    '                                  container="tab.fields"\n' +
    '                                  ng-switch-default\n' +
    '                                  readonly="false"></text-field>\n' +
    '                      <number-field form="editChild.form"\n' +
    '                                    field="field"\n' +
    '                                    container="tab.fields"\n' +
    '                                    ng-switch-when="number"\n' +
    '                                    readonly="false"></number-field>\n' +
    '                      <select-field form="editChild.form"\n' +
    '                                    field="field"\n' +
    '                                    container="tab.fields"\n' +
    '                                    ng-switch-when="select"\n' +
    '                                    readonly="false"></select-field>\n' +
    '                      <select-field form="editChild.form"\n' +
    '                                    field="field"\n' +
    '                                    container="tab.fields"\n' +
    '                                    ng-switch-when="multiselect"\n' +
    '                                    readonly="false"></select-field>\n' +
    '                      <date-field form="editChild.form"\n' +
    '                                  field="field"\n' +
    '                                  container="tab.fields"\n' +
    '                                  ng-switch-when="date"\n' +
    '                                  readonly="false"></date-field>\n' +
    '                      <bno-field form="editChild.form"\n' +
    '                                 field="field"\n' +
    '                                 container="tab.fields"\n' +
    '                                 ng-switch-when="bno"\n' +
    '                                 readonly="false"></bno-field>\n' +
    '                      <boolean-field form="editChild.form"\n' +
    '                                     field="field"\n' +
    '                                     container="tab.fields"\n' +
    '                                     ng-switch-when="boolean"\n' +
    '                                     readonly="false"></boolean-field>\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '              </uib-tab>\n' +
    '            </uib-tabset>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content">\n' +
    '\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-header bg-flat">\n' +
    '        <h3 class="block-title">\n' +
    '          Dokumentumok\n' +
    '        </h3>\n' +
    '      </div>\n' +
    '      <div class="yco-child__documents block-content clearfix">\n' +
    '        <div class="row">\n' +
    '          <div class="col-sm-4">\n' +
    '            <div\n' +
    '              flow-init="editChild.flowOptions"\n' +
    '              flow-file-added="!!{jpg:1,jpeg:1,png:1,gif:1,pdf:1,xls:1,xlsx:1,doc:1,docx:1,ppt:1,pptx:1,zip:1}[$file.getExtension()]"\n' +
    '              flow-files-submitted="editChild.sendToAPI($file, $event, $flow)"\n' +
    '              flow-file-error="editChild.handleAPIErrors($file, $message, $flow)"\n' +
    '              flow-file-success="editChild.handleAPISuccess($file, $message, $flow)"\n' +
    '            >\n' +
    '              <div class="push-10-t push-10 position-relative" ng-if="editChild.hasFile">\n' +
    '                <button class="btn btn-rounded btn-danger" type="button"\n' +
    '                        ng-click="editChild.cancelFile($flow)"><i class="fa fa-times"></i></button>\n' +
    '                <img ng-if="editChild.isPublicationFileImage($flow.files[0])" class="image-responsive"\n' +
    '                     flow-img="$flow.files[0]">\n' +
    '                <span\n' +
    '                  ng-if="!editChild.isPublicationFileImage($flow.files[0])">{{$flow.files[0].name}}</span>\n' +
    '                </br>\n' +
    '                <button class="btn btn-info push-15-t"\n' +
    '                        ng-click="editChild.attachFilesToChild($flow)"\n' +
    '                        type="button">\n' +
    '                  <i class="fa fa-upload push-5-r"></i>\n' +
    '                  Dokumentum hozzáadása az adatlaphoz\n' +
    '                </button>\n' +
    '              </div>\n' +
    '              <input ng-if="!editChild.hasFile" type="file" name="image" flow-btn>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <div ng-if="editChild.childDocuments.length" class="col-sm-8">\n' +
    '            <table class="table table-striped">\n' +
    '              <thead>\n' +
    '              <tr>\n' +
    '                <th>Fájlnév</th>\n' +
    '                <th class="text-center">Letöltés</th>\n' +
    '                <th class="text-center">Törlés</th>\n' +
    '              </tr>\n' +
    '              </thead>\n' +
    '              <tbody>\n' +
    '              <tr ng-repeat="document in editChild.childDocuments">\n' +
    '                <td>{{document.name}}</td>\n' +
    '                <td class="text-center">\n' +
    '                  <a ng-if="!editPublication.isPublicationAttachmentImage()"\n' +
    '                     class="btn btn-info"\n' +
    '                     ng-href="{{document.url}}"\n' +
    '                     uib-tooltip="Letöltés" download target="_blank"><i class="fa fa-download"></i>\n' +
    '                    Letöltés\n' +
    '                  </a>\n' +
    '                </td>\n' +
    '                <td class="text-center">\n' +
    '                  <button class="btn btn-danger" type="button"\n' +
    '                          ng-click="editChild.deleteDocument(document.id)">\n' +
    '                    <i class="fa fa-trash push-5-r"></i>\n' +
    '                    Törlés\n' +
    '                  </button>\n' +
    '                </td>\n' +
    '              </tr>\n' +
    '              </tbody>\n' +
    '            </table>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content">\n' +
    '    <div class="block block-themed">\n' +
    '      <div class="block-content">\n' +
    '        <div class="push-20">\n' +
    '          <yco-button class="btn btn-success"\n' +
    '                      type="submit"\n' +
    '                      yco-is-processing="editChild.isProcessing()">\n' +
    '            <i class="fa fa-save push-5-r"></i>Mentés\n' +
    '          </yco-button>\n' +
    '\n' +
    '          <button class="btn btn-default"\n' +
    '                  type="button"\n' +
    '                  ui-sref="viewChild({childId: editChild.child.id})">\n' +
    '            Vissza az adatlapra\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/add-text-entry-dialog.tpl.html',
    '<form name="addTextEntry.form" ng-submit="addTextEntry.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    <h3>Új szöveges bejegyzés</h3>\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <yco-input-container>\n' +
    '      <label>Szöveg</label>\n' +
    '      <textarea class="form-control" name="content" ng-model="addTextEntry.entry.content" rows="5" required></textarea>\n' +
    '      <yco-messages form="addTextEntry.form" field-name="content"></yco-messages>\n' +
    '    </yco-input-container>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="addTextEntry.close()">\n' +
    '      Mégse\n' +
    '    </button>\n' +
    '    <button class="btn btn-success"\n' +
    '            type="submit">\n' +
    '      Küldés\n' +
    '    </button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/views/add-participant-dialog.tpl.html',
    '<form name="addParticipant.form" ng-submit="addParticipant.submit()" novalidate>\n' +
    '  <div class="modal-header">\n' +
    '    <h3>Új személy hozzáadása</h3>\n' +
    '  </div>\n' +
    '  <div class="modal-body">\n' +
    '    <p>Kezdje el begépelni egy programban részt vevő személy nevét, majd válasszon a lenyíló listában megjelenő\n' +
    '      lehetőségek közül. A gyermek adatlapjához csak Orvost vagy Korai fejlesztőt adhat hozzá.</p>\n' +
    '\n' +
    '    <div class="form-group">\n' +
    '      <yco-input-container>\n' +
    '        <label>Felhasználó</label>\n' +
    '        <ui-select append-to-body="true" name="participant" ng-model="addParticipant.participantId" required>\n' +
    '          <ui-select-match allow-clear="true">{{$select.selected.lastName}} {{$select.selected.firstName}}\n' +
    '          </ui-select-match>\n' +
    '          <ui-select-choices repeat="user.id as user in addParticipant.possibleParticipants track by $index"\n' +
    '                             refresh="addParticipant.refreshPossibleParticipants($select.search)"\n' +
    '                             refresh-delay="200"\n' +
    '                             minimum-input-length="3">\n' +
    '            <div class="inner-push-5 inner-push-5-t media">\n' +
    '              <div class="media-left">\n' +
    '                <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"\n' +
    '                     alt="{{user.lastName}} {{user.firstName}}">\n' +
    '              </div>\n' +
    '              <div class="media-right">\n' +
    '                <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>\n' +
    '\n' +
    '                <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>\n' +
    '              </div>\n' +
    '              <div class="yco-child__autocomplete__suggestion__email">\n' +
    '                <span>{{user.email}}</span>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </ui-select-choices>\n' +
    '        </ui-select>\n' +
    '        <yco-messages form="addParticipant.form" server="addParticipant.getServerValidation()"\n' +
    '                      field-name="participant"></yco-messages>\n' +
    '      </yco-input-container>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="modal-footer">\n' +
    '    <button class="btn"\n' +
    '            type="button"\n' +
    '            ng-click="addParticipant.close()">\n' +
    '      Mégse\n' +
    '    </button>\n' +
    '    <yco-button class="btn btn-success"\n' +
    '                type="submit"\n' +
    '                yco-is-processing="addParticipant.isProcessing">\n' +
    '      Hozzáadás\n' +
    '    </yco-button>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/yco-child-activity-directive.tpl.html',
    '<li>\n' +
    '  <div class="list-timeline-time" title="{{ycoChildActivity.activity.createdAt | moment : \'YYYY. MM. DD. HH:mm:ss\'}}">\n' +
    '    {{ycoChildActivity.activity.createdAt | fromNow}}\n' +
    '  </div>\n' +
    '  <i class="{{ycoChildActivity.activityType.class}} list-timeline-icon"></i>\n' +
    '\n' +
    '  <div class="list-timeline-content">\n' +
    '    <p class="font-w600">{{ycoChildActivity.activityType.label}}</p>\n' +
    '\n' +
    '    <p class="push-5-t push-5 font-s12">\n' +
    '      <i class="fa fa-user push-5-r"></i>{{ycoChildActivity.activity.user.data.lastName}}\n' +
    '      {{ycoChildActivity.activity.user.data.firstName}}\n' +
    '    </p>\n' +
    '\n' +
    '    <ul class="nav-users" ng-if="ycoChildActivity.activity.staffChange">\n' +
    '      <li ng-if="ycoChildActivity.activity.staffChange.added.data"\n' +
    '          ng-repeat="user in ycoChildActivity.activity.staffChange.added.data">\n' +
    '        <a ui-sref="viewUser({userId: user.id})">\n' +
    '          <img class="img-avatar" ng-src="{{::user.image}}" alt="">\n' +
    '          <i class="fa fa-plus-circle text-success"></i> {{::user.lastName}} {{::user.firstName}}\n' +
    '          <div class="font-w400 text-muted">\n' +
    '            <small>{{::user.roleId | userRole}}</small>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '      <li ng-if="ycoChildActivity.activity.staffChange.removed.data"\n' +
    '          ng-repeat="user in ycoChildActivity.activity.staffChange.removed.data">\n' +
    '        <a ui-sref="viewUser({userId: user.id})">\n' +
    '          <img class="img-avatar" ng-src="{{::user.image}}" alt="">\n' +
    '          <i class="fa fa-minus-circle text-danger"></i> {{::user.lastName}} {{::user.firstName}}\n' +
    '          <div class="font-w400 text-muted">\n' +
    '            <small>{{::user.roleId | userRole}}</small>\n' +
    '          </div>\n' +
    '        </a>\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <ul class="font-s13" ng-if="ycoChildActivity.activity.dataChange">\n' +
    '      <li ng-repeat="(key,change) in ycoChildActivity.activity.dataChange"\n' +
    '          ng-if="key != \'imageId\'">\n' +
    '        <strong>{{::change.field.name || ycoChildActivity.getActivityLabel(key)}}:</strong>\n' +
    '        <strike>{{::change.old}}</strike> {{::change.new}}\n' +
    '      </li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <blockquote class="push-5 push-5-t font-s13" ng-if="ycoChildActivity.activity.content">\n' +
    '      {{ycoChildActivity.activity.content}}\n' +
    '    </blockquote>\n' +
    '  </div>\n' +
    '</li>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/text-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <label for="{{::field.id}}" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</label>\n' +
    '\n' +
    '  <div class="input-group">\n' +
    '    <div class="input-group-addon" ng-if="field.prefix">{{::field.prefix}}</div>\n' +
    '\n' +
    '    <input class="form-control"\n' +
    '           type="text"\n' +
    '           name="{{::field.id}}"\n' +
    '           id="{{::field.id}}"\n' +
    '           ng-model="field.value"\n' +
    '           ng-disabled="field.readonly"\n' +
    '           ng-minlength="field.validation.minlength"\n' +
    '           ng-maxlength="field.validation.maxlength">\n' +
    '\n' +
    '    <div class="input-group-addon" ng-if="field.suffix">{{::field.suffix}}</div>\n' +
    '  </div>\n' +
    '\n' +
    '  <yco-messages form="form" field-name="{{::field.id}}"></yco-messages>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">{{::field.name}}</p>\n' +
    '\n' +
    '  <p>\n' +
    '    <span ng-if="field.prefix">{{::field.prefix}}</span>\n' +
    '    {{::field.value}}\n' +
    '    <span ng-if="field.suffix">{{::field.suffix}}</span>\n' +
    '  </p>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/select-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <label for="{{::field.id}}" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</label>\n' +
    '  <ui-select id="{{::field.id}}" name="{{::field.id}}" ng-model="field.value" ng-disabled="field.readonly"\n' +
    '             ng-required="field.validation.required">\n' +
    '    <ui-select-match>{{$select.selected.value}}</ui-select-match>\n' +
    '    <ui-select-choices repeat="choice.key as (key, choice) in field.values">\n' +
    '      <div>{{choice.value}}</div>\n' +
    '    </ui-select-choices>\n' +
    '  </ui-select>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">{{::field.name}}</p>\n' +
    '\n' +
    '  <p>\n' +
    '    <span ng-if="field.prefix">{{::field.prefix}}</span>\n' +
    '    {{::field.values[field.value]}}\n' +
    '    <span ng-if="field.suffix">{{::field.suffix}}</span>\n' +
    '  </p>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/number-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <label for="{{::field.id}}" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</label>\n' +
    '\n' +
    '  <div class="input-group">\n' +
    '    <div class="input-group-addon" ng-if="field.prefix">{{::field.prefix}}</div>\n' +
    '\n' +
    '    <input class="form-control"\n' +
    '           type="number"\n' +
    '           name="{{::field.id}}"\n' +
    '           id="{{::field.id}}"\n' +
    '           ng-model="field.value"\n' +
    '           ng-disabled="field.readonly"\n' +
    '           ng-min="field.validation.min"\n' +
    '           ng-max="field.validation.max">\n' +
    '\n' +
    '    <div class="input-group-addon" ng-if="field.suffix">{{::field.suffix}}</div>\n' +
    '  </div>\n' +
    '\n' +
    '  <yco-messages form="form" field-name="field.id"></yco-messages>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">{{::field.name}}</p>\n' +
    '\n' +
    '  <p>\n' +
    '    <span ng-if="field.prefix">{{::field.prefix}}</span>\n' +
    '    {{::field.value}}\n' +
    '    <span ng-if="field.suffix">{{::field.suffix}}</span>\n' +
    '  </p>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/date-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <label for="{{::field.id}}" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</label>\n' +
    '\n' +
    '  <div class="input-group date">\n' +
    '    <input class="form-control"\n' +
    '           type="text"\n' +
    '           id="{{::field.id}}"\n' +
    '           name="{{::field.id}}"\n' +
    '           ng-model="fieldCtrl.nativeDateValue"\n' +
    '           ng-required="field.validation.required"\n' +
    '           is-open="fieldCtrl.isDatepickerPopupOpen"\n' +
    '           uib-datepicker-popup="yyyy-MM-dd"\n' +
    '           datepicker-options="fieldCtrl.datePickerOptions"\n' +
    '           close-text="Bezárás"\n' +
    '           placeholder="Válasszon dátumot">\n' +
    '    <span class="input-group-addon" ng-click="fieldCtrl.toggleDatepickerPopup()">\n' +
    '              <span class="fa fa-calendar"></span>\n' +
    '            </span>\n' +
    '  </div>\n' +
    '  <yco-messages form="form" field-name="{{::field.id}}"></yco-messages>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">\n' +
    '    {{::field.name}}\n' +
    '  </p>\n' +
    '\n' +
    '  <p>\n' +
    '    <span ng-if="field.prefix">{{::field.prefix}}</span>\n' +
    '    {{::field.value | moment : \'YYYY-MM-DD\'}}\n' +
    '    <span ng-if="field.suffix">{{::field.suffix}}</span>\n' +
    '  </p>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/boolean-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <p class="font-w600 push-5" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</p>\n' +
    '\n' +
    '  <label class="css-input css-radio css-radio-default push-10-r">\n' +
    '    <input type="radio"\n' +
    '           name="{{::field.id}}"\n' +
    '           ng-model="field.value"\n' +
    '           ng-value="1">\n' +
    '    <span></span> Igen\n' +
    '  </label>\n' +
    '\n' +
    '  <label class="css-input css-radio css-radio-default">\n' +
    '    <input type="radio"\n' +
    '           name="{{::field.id}}"\n' +
    '           ng-model="field.value"\n' +
    '           ng-value="0">\n' +
    '    <span></span> Nem\n' +
    '  </label>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">{{::field.name}}</p>\n' +
    '\n' +
    '  <p>\n' +
    '    <span ng-if="field.prefix">{{::field.prefix}}</span>\n' +
    '    {{::field.value ? \'Igen\' : \'Nem\'}}\n' +
    '    <span ng-if="field.suffix">{{::field.suffix}}</span>\n' +
    '  </p>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-child/directives/bno-field-directive.tpl.html',
    '<yco-input-container ng-if="!readonly">\n' +
    '  <label for="{{::field.id}}" ng-class="{\'yco-child__label__missing-value\': field.value === null}">{{::field.name}}</label>\n' +
    '\n' +
    '  <div ng-if="field.multiselect">\n' +
    '    <ui-select id="{{::field.id}}"\n' +
    '               name="{{::field.name}}"\n' +
    '               ng-model="field.value"\n' +
    '               multiple\n' +
    '               ng-disabled="field.readonly"\n' +
    '               ng-required="field.validation.required">\n' +
    '      <ui-select-match allow-clear="true">{{$item.code}} - {{$item.name}}</ui-select-match>\n' +
    '      <ui-select-choices repeat="bno.id as bno in fieldCtrl.possibleBnos track by $index"\n' +
    '                         refresh="fieldCtrl.refreshPossibleBnos($select.search)"\n' +
    '                         refresh-delay="200"\n' +
    '                         minimum-input-length="3">\n' +
    '        <div class="inner-push-5 inner-push-5-t">\n' +
    '          <span class="font-w600">{{bno.name}}</span><br/>\n' +
    '          <small>{{bno.code}}</small>\n' +
    '        </div>\n' +
    '      </ui-select-choices>\n' +
    '    </ui-select>\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="!field.multiselect">\n' +
    '    <ui-select append-to-body="true"\n' +
    '               id="{{::field.id}}"\n' +
    '               name="{{::field.name}}"\n' +
    '               ng-model="field.value"\n' +
    '               ng-disabled="field.readonly"\n' +
    '               ng-required="field.validation.required">\n' +
    '      <ui-select-match allow-clear="true">{{$select.selected.code}} - {{$select.selected.name}}</ui-select-match>\n' +
    '      <ui-select-choices repeat="bno.id as bno in fieldCtrl.possibleBnos track by $index"\n' +
    '                         refresh="fieldCtrl.refreshPossibleBnos($select.search)"\n' +
    '                         refresh-delay="200"\n' +
    '                         minimum-input-length="3">\n' +
    '        <div class="inner-push-5 inner-push-5-t">\n' +
    '          <span class="font-w600">{{bno.name}}</span><br/>\n' +
    '          <small>{{bno.code}}</small>\n' +
    '        </div>\n' +
    '      </ui-select-choices>\n' +
    '    </ui-select>\n' +
    '  </div>\n' +
    '</yco-input-container>\n' +
    '\n' +
    '<div ng-if="readonly">\n' +
    '  <p class="font-w600 push-5">{{::field.name}}</p>\n' +
    '\n' +
    '  <ul>\n' +
    '    <li ng-repeat="row in fieldCtrl.possibleBnos">\n' +
    '      {{::row.code}} - {{::row.name}}\n' +
    '    </li>\n' +
    '  </ul>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-auth/views/request-password-change.tpl.html',
    '<div class="yco-auth">\n' +
    '  <div class="text-center">\n' +
    '    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content overflow-hidden">\n' +
    '    <div class="container">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-6 col-md-offset-3">\n' +
    '          <div class="block block-themed">\n' +
    '            <div class="block-header bg-primary">\n' +
    '              <h3 class="block-title">Elfelejtett jelszó</h3>\n' +
    '            </div>\n' +
    '            <div class="block-content block-content-full block-content-narrow">\n' +
    '              <form class="push-30-t push-50"\n' +
    '                    name="requestPasswordChange.form"\n' +
    '                    ng-submit="requestPasswordChange.submit()"\n' +
    '                    novalidate\n' +
    '                    ng-if="!requestPasswordChange.isSubmitted">\n' +
    '                <p>Amennyiben elfelejtette jelszavát, az alábbi űrlapon lehetősége van újat igényelni. Kérjük, írja be\n' +
    '                  fiókjához tartozó email címét, majd az űrlap elküldése után kövesse a levélben kapott\n' +
    '                  instrukciókat.</p>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Email</label>\n' +
    '                  <input class="form-control" type="email" name="email" ng-model="requestPasswordChange.email" required>\n' +
    '                  <yco-messages form="requestPasswordChange.form" server="requestPasswordChange.getServerValidation()" field-name="email"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <div class="form-group text-center">\n' +
    '                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="requestPasswordChange.isProcessing()">\n' +
    '                    Elküldés\n' +
    '                  </yco-button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '\n' +
    '              <p ng-if="requestPasswordChange.isSubmitted" class="push-50 push-50-t">Rendszerünk sikeresen fogadta jelszó\n' +
    '                változtatási kérelmét. Ellenőrizze postafiókját, majd kövesse a levélben kapott instrukciókat.</p>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-auth/views/login.tpl.html',
    '<div class="yco-auth">\n' +
    '  <div class="text-center">\n' +
    '    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content overflow-hidden">\n' +
    '    <div class="container">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-6 col-md-offset-3">\n' +
    '          <div class="block block-themed">\n' +
    '            <div class="block-header bg-primary">\n' +
    '              <h3 class="block-title">Bejelentkezés</h3>\n' +
    '            </div>\n' +
    '            <div class="block-content block-content-full block-content-narrow">\n' +
    '              <form class="push-30-t push-50" name="login.loginForm" ng-submit="login.submit($event)" novalidate>\n' +
    '                <div class="form-group" ng-if="login.isLoginError">\n' +
    '                  <div class="alert alert-danger">\n' +
    '                    <strong>Sikertelen bejelentkezés!</strong> A megadott email cím vagy jelszó hibás.\n' +
    '                  </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Email</label>\n' +
    '                  <input class="form-control" type="email" name="email" ng-model="login.user.email" required>\n' +
    '                  <yco-messages form="login.loginForm" server="login.getServerValidation()"\n' +
    '                                field-name="email"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Jelszó</label>\n' +
    '                  <input class="form-control" type="password" name="password" ng-model="login.user.password" required\n' +
    '                         ng-minlength="6">\n' +
    '                  <yco-messages form="login.loginForm" server="login.getServerValidation()"\n' +
    '                                field-name="password"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="login.isProcessing()">\n' +
    '                    <i class="fa fa-sign-in push-5-r"></i>\n' +
    '                    Bejelentkezés\n' +
    '                  </yco-button>\n' +
    '\n' +
    '                  <a class="btn pull-right" ui-sref="requestPasswordChange">Elfelejtett jelszó?</a>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('koraszulottmentorExtranet').run(['$templateCache', function($templateCache) {
  $templateCache.put('yco-auth/views/change-password.tpl.html',
    '<div class="yco-auth">\n' +
    '  <div class="text-center">\n' +
    '    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="content overflow-hidden">\n' +
    '    <div class="container">\n' +
    '      <div class="row">\n' +
    '        <div class="col-md-6 col-md-offset-3">\n' +
    '          <div class="block block-themed">\n' +
    '            <div class="block-header bg-primary">\n' +
    '              <h3 class="block-title">Elfelejtett jelszó</h3>\n' +
    '            </div>\n' +
    '            <div class="block-content block-content-full block-content-narrow">\n' +
    '              <form class="push-30-t push-50"\n' +
    '                    name="changePassword.form"\n' +
    '                    ng-submit="changePassword.submit()"\n' +
    '                    novalidate>\n' +
    '                <p>A következő űrlapon beállíthatja fiókja új jelszavát. Az űrlap elküldését követően új\n' +
    '                  jelszavával fog tudni bejelentkezni a rendszerbe.</p>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Új jelszó</label>\n' +
    '                  <input class="form-control"\n' +
    '                         type="password"\n' +
    '                         name="password"\n' +
    '                         ng-model="changePassword.password"\n' +
    '                         required>\n' +
    '                  <yco-messages form="changePassword.form"\n' +
    '                                server="changePassword.getServerValidation()"\n' +
    '                                field-name="password"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <yco-input-container>\n' +
    '                  <label>Új jelszó megerősítése</label>\n' +
    '                  <input class="form-control"\n' +
    '                         type="password"\n' +
    '                         name="confirmPassword"\n' +
    '                         ng-model="changePassword.passwordConfirmation"\n' +
    '                         required\n' +
    '                         match-password="password">\n' +
    '                  <yco-messages form="changePassword.form"\n' +
    '                                server="changePassword.getServerValidation()"\n' +
    '                                field-name="confirmPassword"></yco-messages>\n' +
    '                </yco-input-container>\n' +
    '\n' +
    '                <div class="form-group text-center">\n' +
    '                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="changePassword.isProcessing()">\n' +
    '                    Elküldés\n' +
    '                  </yco-button>\n' +
    '                </div>\n' +
    '              </form>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoUser
   * @description
   *
   */
  angular
    .module('ycoUser', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.service:UserRole
   *
   * @description
   *
   */
  UserRole.$inject = ["userRoles", "_"];
  angular
    .module('ycoUser')
    .service('UserRole', UserRole);

  function UserRole(userRoles, _) {
    var self = this;

    self.getUserRoles = function () {
      return userRoles;
    };

    self.getUserRoleBySlug = function getUserRoleBySlug(slug) {
      return _.find(userRoles, function (role) {
        return role.slug.plural === slug || role.slug.singular === slug;
      });
    };

    self.getUserRoleById = function getUserRoleById(roleId) {
      return _.find(userRoles, function (role) {
        return role.id === roleId;
      });
    };

    self.getUserRoles = function getUserRoles() {
      return userRoles;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.service:LoggedUser
   *
   * @description
   *
   */
  LoggedUser.$inject = ["User"];
  angular
    .module('ycoUser')
    .service('LoggedUser', LoggedUser);

  function LoggedUser(User) {
    var user;

    this.getUser = function getUser() {
      return user;
    };

    this.setLoggedUser = function setLoggedUser() {
      user = User.getLoggedInUser();
    };

    this.setLoggedUser();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoUser.filter:userRole
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  userRole.$inject = ["UserRole", "_"];
  angular
    .module('ycoUser')
    .filter('userRole', userRole);

  function userRole(UserRole, _) {
    return function (input) {
      return _.get(UserRole.getUserRoleById(input), 'name.singular', null);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.factory:User
   *
   * @description
   *
   */
  User.$inject = ["ycoResource", "userRoles"];
  angular
    .module('ycoUser')
    .factory('User', User);

  function User(ycoResource, userRoles) {
    var url = 'users/'
      , UserBase;

    UserBase = ycoResource('user', url + ':id', {id: '@id'},
      {
        getLoggedInUser: {
          method: 'GET',
          url: url + 'me'
        },
        getUserByEmail: {
          method: 'GET',
          url: url + 'by-email',
        },
        getChildrenByDoctor: {
          method: 'GET',
          url: url + ':userId/children',
          params: {
            limit: -1,
            includes: 'parent'
          }
        },
        getParents: {
          method: 'GET',
          url: url,
          params: {
            roleIds: [userRoles.PARENT.id]
          }
        },
        query: {
          cache: false
        }
      }
    );

    return UserBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.factory:Statistics
   *
   * @description
   *
   */
  Statistics.$inject = ["ycoResource"];
  angular
    .module('ycoUser')
    .factory('Statistics', Statistics);

  function Statistics(ycoResource) {
    var url = 'stats/'
      , StatisticsBase;

    StatisticsBase = ycoResource('statistics', url, {}, {
      getBasicStatistics: {
        method: 'GET',
        url: url + 'basic',
        cache: false
      },
      getMentorStatistics: {
        method: 'GET',
        url: url + 'mentor',
        cache: false
      },
      getStatFileData: {
        method: 'GET',
        url: url + 'overallMonthlyStats',
        cache: false
      }
    });

    return StatisticsBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:ViewUserCtrl
   *
   * @description
   *
   */
  ViewUserCtrl.$inject = ["user", "UserRole", "loggedUser"];
  angular
    .module('ycoUser')
    .controller('ViewUserCtrl', ViewUserCtrl);

  function ViewUserCtrl(user, UserRole, loggedUser) {
    var vm = this;

    vm.user = user.data;
    vm.role = UserRole.getUserRoleById(vm.user.roleId);
    vm.isOwnAccount = user.data.id === loggedUser.data.id;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:NewUserWithChildCtrl
   *
   * @description
   *
   */
  NewUserWithChildCtrl.$inject = ["FormControl", "$q", "User", "Child", "toastr", "$state", "userRoles", "currentUser", "moment", "$scope", "_"];
  angular
    .module('ycoUser')
    .controller('NewUserWithChildCtrl', NewUserWithChildCtrl);

  function NewUserWithChildCtrl(FormControl, $q, User, Child, toastr, $state, userRoles, currentUser, moment, $scope, _) {
    var vm = this
      , formControl = new FormControl();

    vm.user = new User();
    vm.currentUser = currentUser.data;
    vm.userRoles = userRoles;
    vm.canRegister = false;
    vm.showWarning = false;
    vm.isCheckEmailDisabled = false;
    vm.parentId = null;
    vm.possibleMentors = [];
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.addChildRow = function addChildRow() {
      vm.children.unshift(new Child());
    };

    vm.removeChildRow = function removeChildRow(index) {
      vm.children.splice(index, 1);
    };

    vm.children = [
      new Child()
    ];


    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      vm.user.roleId = vm.userRoles.PARENT.id;
      vm.user.status = 'approved';

      formControl
        .process(deferred.promise)
        .then(function (data) {
          vm.parentId = data.data.id;
          toastr.success('A szülő sikeresen létrejött');
          vm.createChildren();
        })
        .catch(function (data) {
          toastr.error('Nem sikerült a létrehozás. Kérjük próbálja később.');
        });

      vm.user.$save(deferred.resolve, deferred.reject);
    };

    vm.checkEmail = function checkEmail() {
      var email = vm.user.email;

      if (!email) {
        return;
      }

      vm.isProcessing = true;
      User.getUserByEmail({email: email}).$promise
        .then(function () {
          vm.showWarning = true;
        })
        .catch(function (data) {
          if (data.status === 404) {
            vm.showWarning = false;
            vm.canRegister = true;
            vm.isCheckEmailDisabled = true;
            vm.user.roleId = 6; //set role to Parent
          }
        })
        .finally(function () {
          vm.isProcessing = false;
        });
    };

    vm.isMoreButtonDisabled = function isMoreButtonDisabled() {
      if (vm.activeTab === 1 && vm.parentForm.$invalid) {
        return true;
      }
      return vm.activeTab === 2 || !vm.canRegister;
    };

    vm.goToPrevTab = function getPrevTab() {
      vm.activeTab--;
    };

    vm.goToNextTab = function getNextTab() {
      vm.activeTab++;
    };

    if (vm.currentUser.roleId === vm.userRoles.MENTOR.id) {
      vm.possibleMentors.push(vm.currentUser);

      vm.user.mentorId = vm.currentUser.id;
    }

    $scope.$watch(function () {
      if (vm.children[0].birthWeek > 36 && vm.children[0].birthWeight > 2500) {
        vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        if (vm.childrenForm) {
          vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', true);
          vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', true);
        }
      }
    }, function () {
      return _.get(vm, 'children[0].birthWeek');
    });

    $scope.$watch(function () {
      if (vm.children[0].birthWeek > 36 && vm.children[0].birthWeight > 2500) {
        vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        if (vm.childrenForm) {
          vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', true);
          vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', true);
        }
      }
    }, function () {
      return _.get(vm, 'children[0].birthWeight');
    });

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.createChildren = function createChildren() {
      var promises = [];

      _.forEach(vm.children, function (child) {
        child.parentId = vm.parentId;
        child.dateOfBirth = moment(child.birthYear.toString()).format('YYYY-MM-DD HH:mm:ss');
        promises.push(child.$save().promise);
      });

      formControl
        .process($q.all(promises))
        .then(function () {
          toastr.success('A gyermek(ek) sikeresen létrejöttek.');
          $state.go('listUsers');
        })
        .catch(function () {
          toastr.error('Hiba történt a gyermek(ek) létrehozásakor.');
        });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:NewUserCtrl
   *
   * @description
   *
   */
  NewUserCtrl.$inject = ["FormControl", "User", "$q", "$state", "UserRole", "$stateParams", "currentUser", "userRoles", "toastr", "$scope"];
  angular
    .module('ycoUser')
    .controller('NewUserCtrl', NewUserCtrl);

  function NewUserCtrl(FormControl, User, $q, $state, UserRole, $stateParams, currentUser, userRoles, toastr, $scope) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('A felhasználói fiók sikeresen létrejött. Az első belépéshez ' +
      'szükséges információkat rendszerünk elküldte a megadott email címre.');

    vm.user = new User();
    vm.currentUser = currentUser.data;
    vm.user.roleId = $stateParams.role ? UserRole.getUserRoleBySlug($stateParams.role).id : undefined;
    vm.availableRoles = UserRole.getUserRoles();
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      if (vm.user.roleId === vm.availableRoles.PARENT.id) {
        vm.user.status = 'approved';
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listUsers');
        })
        .catch(function (err) {
          if (err.status === 422 && err.data.error.email.unique) {
            toastr.error('Ezzel az e-mail címmel már regisztráltak a rendszerben.')
          }
        });

      vm.user.$save(deferred.resolve, deferred.reject);
    };

    if (vm.currentUser.roleId === vm.availableRoles.MENTOR.id) {
      vm.possibleMentors.push(vm.currentUser);

      vm.user.mentorId = vm.currentUser.id;
    }

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:ListUsersCtrl
   *
   * @description
   *
   */
  ListUsersCtrl.$inject = ["DTColumnBuilder", "YcoDataTables", "$scope", "$stateParams", "UserRole", "_", "moment", "loggedUser"];
  angular
    .module('ycoUser')
    .controller('ListUsersCtrl', ListUsersCtrl);

  function ListUsersCtrl(DTColumnBuilder, YcoDataTables, $scope, $stateParams, UserRole, _, moment, loggedUser) {
    var vm = this
      , roleFilter = $stateParams.role ? UserRole.getUserRoleBySlug($stateParams.role) : undefined;

    vm.roleFilter = roleFilter;
    vm.currentRole = $stateParams.role;
    vm.isNewUserWithChildCreationAvailable = _.get(roleFilter, 'slug.singular') === 'parent';
    vm.loggedUser = loggedUser.data;
    vm.userRoles = UserRole.getUserRoles();

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'users',
      params: {
        roleIds: _.get(roleFilter, 'id')
      },
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Teljes név')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (user) {
          return user.lastName + ' ' + user.firstName;
        })
      , DTColumnBuilder.newColumn('email').withTitle('Email')
      , DTColumnBuilder.newColumn('roleId').withTitle('Jogosultság')
        .renderWith(function (roleId) {
          var role = UserRole.getUserRoleById(roleId);
          return '<a ui-sref="listUsers({role: \'' + role.slug.plural + '\'})">' +
            '<span class="badge badge-default">' + role.name.singular + '</span>' +
            '</a>';
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn('lastLoginAt').withTitle('Utolsó belépés dátuma')
        .renderWith(function (data) {
          return data ? moment(data).format('YYYY.MM.DD. HH:mm') : 'Még nem lépett be.';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<a class="btn btn-default btn-xs push-5-r"' +
            'ui-sref="viewUser({userId: ' + data.id + '})" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs push-5-r"' +
            'ng-if="listUsers.loggedUser.roleId === listUsers.userRoles.ADMIN.id"' +
            'ui-sref="editUser({userId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-disabled="listUsers.loggedUser.id === ' + data.id + '"' +
            'ui-sref="messages({userId: ' + data.id + '})" uib-tooltip="Üzenet">' +
            '<i class="fa fa-envelope"></i>' +
            '</a>';
        })
    ];
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:EditUserCtrl
   *
   * @description
   *
   */
  EditUserCtrl.$inject = ["user", "FormControl", "$q", "$state", "User", "_", "YcoDialog", "UserRole", "loggedUser", "userRoles", "$scope", "toastr", "countyConstant"];
  angular
    .module('ycoUser')
    .controller('EditUserCtrl', EditUserCtrl);

  function EditUserCtrl(user, FormControl, $q, $state, User, _, YcoDialog, UserRole, loggedUser, userRoles, $scope, toastr, countyConstant) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('Felhasználói adatok sikeresen frissítve!');

    vm.form = {};
    vm.user = user.data;
    vm.role = UserRole.getUserRoleById(vm.user.roleId);
    vm.availableRoles = UserRole.getUserRoles();
    vm.isCurrentUserAdmin = loggedUser.data.roleId === userRoles.ADMIN.id;
    vm.isOwnAccount = user.data.id === loggedUser.data.id;
    vm.loggedUser = loggedUser.data;
    vm.possibleMentors = [];
    vm.canNavigate = false;
    vm.showPassword = false;
    vm.countyList = countyConstant;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , userResource = _.defaultsDeep(new User(), vm.user);

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('viewUser', {userId: vm.user.id});
        });

      userResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deleteUser = function deleteUser() {
      var deferred = $q.defer()
        , userResource = _.defaultsDeep(new User(), vm.user);

      YcoDialog.show({
        title: 'Valóban törli a kijelölt felhasználót?',
        content: 'A törléssel a felhasználó adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        userResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          $state.go('listUsers');
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });
    };

    if (vm.user.mentor) {
      vm.possibleMentors.push(vm.user.mentor.data);

      vm.user.mentorId = vm.user.mentor.data.id;
    }

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.togglePassword = function togglePassword() {
      vm.showPassword = !vm.showPassword;
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:DashboardCtrl
   *
   * @description
   *
   */
  DashboardCtrl.$inject = ["statistics", "mentorStatistics", "unreadThreads", "newEnquiries", "EnquiryService", "user", "UserRole", "User", "Statistics", "$window"];
  angular
    .module('ycoUser')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(statistics, mentorStatistics, unreadThreads, newEnquiries, EnquiryService, user, UserRole, User, Statistics, $window) {
    var vm = this;

    vm.stats = statistics.data;
    vm.mentorStats = mentorStatistics.data;
    vm.unreadThreads = unreadThreads.data;
    vm.newEnquiries = newEnquiries.data;
    vm.user = user.data;
    vm.userRoles = UserRole.getUserRoles();
    vm.showStatFileLoader = false;

    if (vm.user.roleId === vm.userRoles.DOCTOR.id) {
      User.getChildrenByDoctor({userId: vm.user.id}).$promise
        .then(function (children) {
          vm.childrenByDoctor = children.data;
        });
    }

    if (vm.user.roleId === vm.userRoles.PARENT.id) {
      User.get().$promise
        .then(function (users) {
          vm.users = users.data;
        });
    }

    vm.getEnquiryStatus = function getEnquiryStatus(statusName) {
      var status = EnquiryService.getStatusByStatusName(statusName);
      return '<span class="badge ' + status.class + '">' + status.label + '</span>';
    };

    vm.getUserRoleById = function (roleId) {
      var role = UserRole.getUserRoleById(roleId).name.singular;
      return '<span class="badge">' + role + '</span>';
    };

    vm.processStatFile = function processStatFile() {
      vm.showStatFileLoader = true;
      Statistics.getStatFileData().$promise
        .then(function (data) {
          vm.statFileData = data.data;
          vm.showStatFileLoader = false;
          $window.open(
            data.data.url,
            '_self'
          );
        })
        .catch(function (err) {
          console.error(err.message);
          vm.showStatFileLoader = false;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.constant:userRoles
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .constant('userRoles', {
      ADMIN: {
        id: 1,
        name: {
          plural: 'Adminisztrátorok',
          singular: 'Adminisztrátor'
        },
        slug: {
          plural: 'admins',
          singular: 'admin'
        }
      },
      LEAD_DOCTOR: {
        id: 2,
        name: {
          plural: 'Orvos szakmai vezetők',
          singular: 'Orvos szakmai vezető'
        },
        slug: {
          plural: 'lead-doctors',
          singular: 'lead-doctor'
        }
      },
      DOCTOR: {
        id: 3,
        name: {
          plural: 'Orvosok',
          singular: 'Orvos'
        },
        slug: {
          plural: 'doctors',
          singular: 'doctor'
        }
      },
      MENTOR: {
        id: 4,
        name: {
          plural: 'Mentorok',
          singular: 'Mentor'
        },
        slug: {
          plural: 'mentors',
          singular: 'mentor'
        }
      },
      EARLY_DEV: {
        id: 5,
        name: {
          plural: 'Korai fejlesztők',
          singular: 'Korai fejlesztő'
        },
        slug: {
          plural: 'early-devs',
          singular: 'early-dev'
        }
      },
      PARENT: {
        id: 6,
        name: {
          plural: 'Szülők / Gondviselők',
          singular: 'Szülő / Gondviselő'
        },
        slug: {
          plural: 'parents',
          singular: 'parent'
        }
      }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.constant:countyConstant
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .constant('countyConstant', [
      {
        name: 'Baranya'
      },
      {
        name: 'Somogy'
      },
      {
        name: 'Tolna'
      }
    ]);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoResource
   * @description
   *
   */
  angular
    .module('ycoResource', [
      'ngResource',
      'lodash'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoResource.service:YcoDataTables
   *
   * @description
   *
   */
  YcoDataTables.$inject = ["DTOptionsBuilder", "datatablesI18n", "appConfig", "_", "$compile", "Auth", "$state"];
  angular
    .module('ycoResource')
    .service('YcoDataTables', YcoDataTables);

  function YcoDataTables(DTOptionsBuilder, datatablesI18n, appConfig, _, $compile, Auth, $state) {
    var self = this;
    self.getOptions = function getOptions(options) {
      return DTOptionsBuilder.newOptions()
        .withLanguage(datatablesI18n)
        .withBootstrap()
        .withOption('bSort', true)
        .withOption('stateSave', true)
        .withOption('stateSaveCallback', function (settings, data) {
          var key = ($state.params && $state.params.role) ? $state.params.role : $state.current.url;
          localStorage.setItem(key, JSON.stringify(data))
        })
        .withOption('stateLoadCallback', function (settings) {
          var key = ($state.params && $state.params.role) ? $state.params.role : $state.current.url;
          return JSON.parse(localStorage.getItem(key))
        })
        .withOption('ajax', {
          dataSrc: 'data',
          url: appConfig.api.url + options.apiEndpoint,
          headers: Auth.getAuthHeader(),
          dataFilter: function (rawResponse) {
            var response = angular.fromJson(rawResponse)
              , data = {};

            data.data = response.data;
            data.recordsTotal = response.meta.pagination.total;
            data.recordsFiltered = response.meta.pagination.total;

            return angular.toJson(data);
          },
          data: function (data, dtInstance) {
            var dtData = _.assign({}, data)
              , itemsPerPage = dtData.length
              , currentPage = dtData.start / itemsPerPage + 1
              , orderPredicateColumnIndex
              , orderPredicateColumn
              , orderDirection;

            data = {
              page: currentPage,
              limit: itemsPerPage
            };

            if (dtData.search && dtData.search.value) {
              data.search = dtData.search.value;
            }

            if (dtData.order && dtData.order.length) {
              orderPredicateColumnIndex = dtData.order[0].column;
              orderPredicateColumn = dtInstance.aoColumns[orderPredicateColumnIndex];
              orderDirection = dtData.order[0].dir;

              data.order = orderDirection;
              data.orderBy = orderPredicateColumn.compoundOf ? orderPredicateColumn.compoundOf.join(',') : orderPredicateColumn.mData;
            }

            if (options.dynamicParams && typeof options.dynamicParams === 'function') {
              data = _.assign(data, options.dynamicParams());
            }

            data = _.assign(data, options.params);

            return data;
          }
        })
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withOption('createdRow', function createdRow(row) {
          $compile(angular.element(row).contents())(options.scope);
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoResource.provider:YcoResource
   *
   * @description
   *
   */
  angular
    .module('ycoResource')
    .provider('ycoResource', ycoResource);

  function ycoResource() {
    get.$inject = ["$resource", "_", "$cacheFactory", "$log"];
    var baseUrl = '';

    return {
      setBaseUrl: setBaseUrl,
      $get: get
    };

    function get($resource, _, $cacheFactory, $log) {
      function ycoResourceBase(name, url, paramDefaults, actions) {
        var resource;

        paramDefaults = paramDefaults || {};
        actions = actions || {};
        actions = _.defaultsDeep(actions, {
          query: {method: 'GET', cache: false},
          get: {method: 'GET', cache: false},
          save: {method: 'POST'},
          update: {method: 'PUT'},
          remove: {method: 'DELETE'},
          delete: {method: 'DELETE'}
        });
        resource = getResource();
        resource = addResourceMethods(resource);

        return resource;

        function getResource(params) {
          params = params || {};
          params.parentUrl = params.parentUrl || '';
          actions = addBaseUrlToActions(actions);

          return $resource(baseUrl + params.parentUrl + url, paramDefaults, actions);
        }

        function addResourceMethods(resourceWithoutMethods) {
          return angular.extend(resourceWithoutMethods, {
            getAsSub: function getAsSub(parentUrl) {
              /**
               * we need to merge resourceWithoutMethods prototype with the new sub-resourceWithoutMethods
               * this will add methods, defined in resourceWithoutMethods factory to the sub-resourceWithoutMethods
               */
              var newResource = getResource({parentUrl: parentUrl});
              _.defaults(newResource.prototype, resourceWithoutMethods.prototype);
              return newResource;
            }
          });
        }

        function addBaseUrlToActions(rawActions) {
          _.forEach(rawActions, function (action) {
            if (_.has(action, 'url') && !_.startsWith(action.url, baseUrl)) {
              action.url = baseUrl + action.url;
            }
          });
          return rawActions;
        }

        function getInterceptors() {
          return {
            response: function (response) {
              return response;
            },
            responseError: function (response) {
              return response;
            }
          };
        }
      }

      return ycoResourceBase;
    }

    function setBaseUrl(newBaseUrl) {
      baseUrl = newBaseUrl;
    }
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoPublication
   * @description
   *
   */
  angular
    .module('ycoPublication', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoPublication.factory:Publication
   *
   * @description
   *
   */
  Publication.$inject = ["ycoResource"];
  angular
    .module('ycoPublication')
    .factory('Publication', Publication);

  function Publication(ycoResource) {
    var url = 'publications/'
      , PublicationBase;

    PublicationBase = ycoResource('publication', url + ':id', {id: '@id'});

    return PublicationBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:NewPublicationCtrl
   *
   * @description
   *
   */
  NewPublicationCtrl.$inject = ["FormControl", "Publication", "$q", "$state", "UserRole", "appConfig", "YcoDialog", "Auth", "_"];
  angular
    .module('ycoPublication')
    .controller('NewPublicationCtrl', NewPublicationCtrl);

  function NewPublicationCtrl(FormControl, Publication, $q, $state, UserRole, appConfig, YcoDialog, Auth, _) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.publication = new Publication();
    vm.availableRoles = UserRole.getUserRoles();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      deferred = $q.defer();
      formControl.setSuccessText('A kiadvány sikeresen létrehozva.');

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listPublications');
        });

      vm.publication.$save(deferred.resolve, deferred.reject);
    };
    /*
    File uploader
     */
    vm.hasCoverImage = false;
    vm.hasPublicationFile = false;

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.invalidateCoverImage();

    vm.cancelCoverImage = function cancelCoverImage($flow) {
      vm.hasCoverImage = false;
      $flow.cancel();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasPublicationFile = false;
      $flow.cancel();
    };

    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow, type) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      if (type === 'cover') {
        formControl.setSuccessText('A borítókép sikeresen feltöltésre került.');
        vm.hasCoverImage = true;
        vm.publication.imageId = response.data.id;
      }

      if (type === 'file') {
        formControl.setSuccessText('A fájl sikeresen feltöltésre került.');
        vm.hasPublicationFile = true;
        vm.publication.attachmentId = response.data.id;
      }

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      deferred.resolve();
    };

    vm.isPublicationFileImage = function isPublicationFileImage(file) {
      if (!vm.hasPublicationFile) {
        return false;
      }

      switch (file.getExtension()) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return true;
        default:
          return false;
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:ListPublicationsCtrl
   *
   * @description
   *
   */
  ListPublicationsCtrl.$inject = ["DTColumnBuilder", "YcoDataTables", "$scope", "moment", "userRoles", "loggedUser"];
  angular
    .module('ycoPublication')
    .controller('ListPublicationsCtrl', ListPublicationsCtrl);

  function ListPublicationsCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, userRoles, loggedUser) {
    var vm = this;

    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'publications',
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító').notVisible()
      , DTColumnBuilder.newColumn('image').withTitle('Borítókép')
        .notSortable()
        .renderWith(function (data) {
          return '<img src="' + data + '" class="yco-publications__list__cover-img">';
        })
      , DTColumnBuilder.newColumn('title').withTitle('Cím')
      , DTColumnBuilder.newColumn('content').withTitle('Leírás').withOption('width', '40%')
      , DTColumnBuilder.newColumn('createdAt').withTitle('Létrehozva')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD.');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<a class="btn btn-default btn-xs push-5-r"' +
            'href="' + data.attachment + '" uib-tooltip="Letöltés" download>' +
            '<i class="fa fa-download"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="listPublications.loggedUser.roleId === listPublications.availableRoles.ADMIN.id"' +
            'ui-sref="editPublication({publicationId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoPublication.controller:EditPublicationCtrl
   *
   * @description
   *
   */
  EditPublicationCtrl.$inject = ["FormControl", "Publication", "$q", "$state", "publication", "_", "YcoDialog", "toastr", "UserRole", "Auth", "appConfig"];
  angular
    .module('ycoPublication')
    .controller('EditPublicationCtrl', EditPublicationCtrl);

  function EditPublicationCtrl(FormControl, Publication, $q, $state, publication, _, YcoDialog, toastr, UserRole, Auth, appConfig) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.publication = publication.data;
    vm.availableRoles = UserRole.getUserRoles();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var publicationResource = _.defaultsDeep(new Publication(), vm.publication);
      deferred = $q.defer();

      formControl.setSuccessText('A kiadvány sikeresen frissítve.');

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listPublications');
        });

      publicationResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deletePublication = function deletePublication() {
      var publicationResource = _.defaultsDeep(new Publication(), vm.publication);
      deferred = $q.defer();

      YcoDialog.show({
        title: 'Valóban törli a kijelölt kiadványt?',
        content: 'A művelet végrehajtásával a kiadvány adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        publicationResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          toastr.success('A kiadvány sikeresen törölve.');
          $state.go('listPublications');
        });
    };

    /*
    File upload
     */
    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow, type) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      if (type === 'cover') {
        formControl.setSuccessText('A borítókép sikeresen frissítve.');
        vm.hasCoverImage = true;
        vm.publication.imageId = response.data.id;
      }

      if (type === 'file') {
        formControl.setSuccessText('A fájl sikeresen frissítve.');
        vm.hasPublicationFile = true;
        vm.publication.attachmentId = response.data.id;
      }
      deferred.resolve();
    };

    vm.cancelCoverImage = function cancelCoverImage($flow) {
      vm.hasCoverImage = false;
      $flow.cancel();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasPublicationFile = false;
      $flow.cancel();
    };

    vm.cancelPublicationThumbnail = function cancelPublicationThumbnail() {
      vm.publication.image = '';
    };

    vm.cancelPublicationAttachment = function cancelPublicationAttachment() {
      vm.publication.attachment = '';
    };

    vm.isPublicationFileImage = function isPublicationFileImage(file) {
      if (!vm.hasPublicationFile) {
        return false;
      }
      return isFileImage(file.name);
    };

    vm.getFileName = function getFileName() {
      if (!vm.publication.attachment) {
        return false;
      }

      return vm.publication.attachment.split('\\').pop().split('/').pop();
    };

    vm.isPublicationAttachmentImage = function isPublicationAttachmentImage() {
      if (!vm.publication.attachment) {
        return false;
      }

      return isFileImage(vm.publication.attachment);
    };

    function isFileImage(file) {
      var extension = file.split('\\').pop().split('.').pop();
      switch (extension) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return true;
        default:
          return false;
      }
    }
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoNavigation
   * @description
   *
   */
  angular
    .module('ycoNavigation', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoNavigation.service:YcoDialog
   *
   * @description
   *
   */
  YcoDialog.$inject = ["$uibModal", "_"];
  angular
    .module('ycoNavigation')
    .service('YcoDialog', YcoDialog);

  function YcoDialog($uibModal, _) {
    var customDefaultOptions
      , modalDefaultOptions;

    customDefaultOptions = {
      title: '',
      content: '',
      closeButtonText: 'Mégse',
      actionButtonText: 'OK'
    };

    modalDefaultOptions = {
      templateUrl: 'yco-navigation/views/yco-dialog.tpl.html'
    };

    this.show = function show(userCustomOptions, userModalOptions) {
      var customOptions = _.assign({}, customDefaultOptions, userCustomOptions)
        , modalOptions = _.assign({}, modalDefaultOptions, userModalOptions);

      if (!modalOptions.controller) {
        modalOptions.controller = function ($scope, $uibModalInstance) {
          $scope.modal = _.assign({}, customOptions);

          $scope.modal.ok = function (result) {
            $uibModalInstance.close(result);
          };

          $scope.modal.close = function () {
            $uibModalInstance.dismiss('cancel');
          };
        };
      }

      return $uibModal.open(modalOptions).result;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoNavigation.directive:ycoSidebar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoNavigation">
   <file name="index.html">
   <yco-sidebar></yco-sidebar>
   </file>
   </example>
   *
   */
  angular
    .module('ycoNavigation')
    .directive('ycoSidebar', ycoSidebar);

  function ycoSidebar() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'yco-navigation/directives/yco-sidebar-directive.tpl.html',
      replace: false,
      controllerAs: 'ycoSidebar',
      controller: ["LoggedUser", "UserRole", "Thread", function (LoggedUser, UserRole, Thread) {
        var vm = this;

        vm.user = LoggedUser.getUser().data;
        vm.userRoles = UserRole.getUserRoles();
        vm.unreadThreads = Thread.getUnreadThreads();
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoNavigation.directive:ycoPageHeader
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoNavigation">
   <file name="index.html">
   <yco-page-header></yco-page-header>
   </file>
   </example>
   *
   */
  angular
    .module('ycoNavigation')
    .directive('ycoPageHeader', ycoPageHeader);

  function ycoPageHeader() {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'yco-navigation/directives/yco-page-header-directive.tpl.html'
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoNavigation.directive:ycoHeader
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoNavigation">
   <file name="index.html">
   <yco-header></yco-header>
   </file>
   </example>
   *
   */
  ycoHeader.$inject = ["appConfig"];
  angular
    .module('ycoNavigation')
    .directive('ycoHeader', ycoHeader);

  function ycoHeader(appConfig) {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'yco-navigation/directives/yco-header-directive.tpl.html',
      replace: false,
      controllerAs: 'ycoHeader',
      controller: ["LoggedUser", function (LoggedUser) {
        var vm = this;

        vm.user = LoggedUser.getUser();
        vm.appConfig = appConfig;
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoMessage
   * @description
   *
   */
  angular
    .module('ycoMessage', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoMessage.filter:parseUrl
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoMessage')
    .filter('parseUrl', parseUrl);

  function parseUrl() {
    var urls = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var emails = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;

    return function (text) {
      if (text.match(urls)) {
        text = text.replace(urls, "<a href=\"$1\" target=\"_blank\">$1</a>");
      }
      if (text.match(emails)) {
        text = text.replace(emails, "<a href=\"mailto:$1\">$1</a>");
      }

      return text;
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoMessage.filter:filterEnter
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('ycoMessage')
    .filter('filterEnter', filterEnter);

  function filterEnter() {
    return function (input) {
      return input.replace(/\n/g, '<br>');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoMessage.factory:Thread
   *
   * @description
   *
   */
  Thread.$inject = ["ycoResource"];
  angular
    .module('ycoMessage')
    .factory('Thread', Thread);

  function Thread(ycoResource) {
    var url = 'message-threads/'
      , ThreadBase;

    ThreadBase = ycoResource('thread', url + ':id', {id: '@id'}, {
      getThreads: {
        cache: false,
        params: {
          order: 'desc',
          orderBy: 'createdAt',
          includes: 'participants,user,child',
          limit: -1
        }
      },
      getMessagesByThreadId: {
        url: url + ':id/messages',
        cache: false,
        params: {
          limit: -1
        }
      },
      addMessageToThread: {
        url: url + ':id/messages',
        method: 'POST'
      },
      removeParticipantFromThread: {
        url: url + ':threadId/users/:userId',
        method: 'DELETE'
      },
      getUnreadThreads: {
        url: url + 'unread',
        method: 'GET',
        cache: false,
        params: {
          order: 'desc',
          orderBy: 'createdAt',
          includes: 'participants,child'
        }
      },
      deleteMessageThread: {
        url: url + ':threadId',
        method: 'DELETE'
      }
    });

    return ThreadBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:NewThreadCtrl
   *
   * @description
   *
   */
  NewThreadCtrl.$inject = ["$uibModalInstance", "User", "FormControl", "Thread", "$q", "toastr", "Child", "hasRecipient", "recipient", "$rootScope"];
  angular
    .module('ycoMessage')
    .controller('NewThreadCtrl', NewThreadCtrl);

  function NewThreadCtrl($uibModalInstance, User, FormControl, Thread, $q, toastr, Child, hasRecipient, recipient, $rootScope) {
    var vm = this
      , formControl = new FormControl();

    vm.thread = {};
    vm.form = {};
    vm.possibleParticipants = [];
    vm.possibleChildren = [];

    if (hasRecipient) {
      vm.hasRecipient = true;
      vm.possibleParticipants.push(recipient);
      vm.thread.participantIds = [recipient.id];
    }

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise);

      Thread.save(vm.thread)
        .$promise
        .then(function (response) {
          return Thread.addMessageToThread({id: response.data.id}, vm.thread);
        })
        .then(deferred.resolve, deferred.reject)
        .then(function () {
          vm.close();
          toastr.success('Az üzenetváltási szál sikeresen létrejött.');
          $rootScope.$broadcast('newThreadCreated');
        });
    };

    vm.refreshPossibleParticipants = function refreshPossibleParticipants(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParticipants = response.data;
        });
    };

    vm.refreshPossibleChildren = function refreshPossibleChildren(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , includes: 'parent'
      };

      return Child.query(options)
        .$promise
        .then(function (response) {
          vm.possibleChildren = response.data;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:MessagesCtrl
   *
   * @description
   *
   */
  MessagesCtrl.$inject = ["initialThreads", "Thread", "initialMessages", "user", "recipient", "$uibModal", "_", "$rootScope", "$filter", "$window"];
  angular
    .module('ycoMessage')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl(initialThreads, Thread, initialMessages, user, recipient, $uibModal, _, $rootScope, $filter, $window) {
    var vm = this
      , threads = initialThreads
      , messages = initialMessages
      , currentUserId = user.data.id
      , uppercaseFilter = $filter('parseUrl');

    vm.newMessage = {};
    vm.newThread = {};
    vm.threads = threads.data;
    vm.messages = messages.data;
    vm.activeThread = threads.data[0];
    vm.isThreadReloading = false;
    vm.isThreadListReloading = false;
    vm.currentUserId = currentUserId;

    if (recipient) {
      vm.recipient = recipient.data;
    }

    vm.selectThread = function selectThread(index) {
      vm.activeThread = threads.data[index];
      vm.reloadMessages();
    };

    vm.reloadThreads = function reloadThreads() {
      vm.isThreadListReloading = true;

      return Thread.getThreads()
        .$promise
        .then(function (response) {
          threads = response;
          vm.threads = response.data;
        })
        .finally(function () {
          vm.isThreadListReloading = false;
        });
    };

    vm.reloadActiveThread = function reloadActiveThread() {
      vm.isThreadReloading = true;

      return Thread.get({id: vm.activeThread.id})
        .$promise
        .then(function (response) {
          vm.activeThread = response.data;
        })
        .finally(function () {
          vm.isThreadReloading = false;
        });
    };

    vm.reloadMessages = function reloadMessages() {
      vm.isThreadReloading = true;

      return Thread.getMessagesByThreadId({id: vm.activeThread.id})
        .$promise
        .then(function (response) {
          messages = response;
          vm.messages = response.data;
        })
        .finally(function () {
          vm.isThreadReloading = false;
        });
    };

    vm.isSubmitDisabled = function () {
      return vm.messages[0].user.data.firstName == 'Rendszerüzenet';
    };

    vm.sendMessage = function sendMessage() {
      if (vm.isSubmitDisabled()) {
        return;
      }

      vm.isThreadReloading = true;
      var message = _.assign({}, vm.newMessage);
      message.message = uppercaseFilter(message.message);

      Thread.addMessageToThread({id: vm.activeThread.id}, message)
        .$promise
        .then(function () {
          return vm.reloadMessages();
        })
        .then(function () {
          vm.newMessage = {};
          vm.isThreadReloading = false;
          vm.setFocus();
        });
    };

    vm.setFocus = function setFocus() {
      setTimeout(function () {
        $window.document.getElementById('yco-messages__message-textarea').focus();
      }, 0);
    };

    vm.submitTextarea = function submitTextarea() {
      vm.sendMessage();
    };

    vm.isOwnMessage = function isOwnMessage(index) {
      return messages.data[index].user.data.id === currentUserId;
    };

    vm.showNewThreadDialog = function showNewThreadDialog(hasRecipient) {
      $uibModal
        .open({
          controllerAs: 'newThread',
          templateUrl: 'yco-message/views/new-thread-dialog.tpl.html',
          controller: 'NewThreadCtrl',
          resolve: {
            recipient: function () {
              return vm.recipient;
            },
            hasRecipient: function () {
              return hasRecipient;
            }
          }
        })
        .result
        .then(function () {
          return vm.reloadThreads();
        })
        .then(function () {
          vm.selectThread(0);
        });
    };

    vm.getParticipantTooltipContent = function getParticipantTooltipContent(index, excludedParticipantId) {
      var participants
        , participantNames;

      participants = _.filter(threads.data[index].participants.data, function (participant) {
        return participant.user.data.id !== excludedParticipantId;
      });

      participantNames = _.map(participants, function (participant) {
        return participant.user.data.lastName + ' ' + participant.user.data.firstName;
      });

      return participantNames.join('<br />');
    };

    vm.showParticipantsDialog = function showParticipantsDialog() {
      $uibModal
        .open({
          templateUrl: 'yco-message/views/list-participants-dialog.tpl.html',
          bindToController: true,
          controllerAs: 'listParticipants',
          controller: 'ListParticipantsCtrl',
          resolve: {
            thread: function () {
              return vm.activeThread;
            }
          }
        });
    };

    $rootScope.$on('newThreadCreated', function () {
      vm.reloadThreads();
    });

    $rootScope.$on('participantsChanged', function () {
      vm.reloadThreads();
      vm.reloadActiveThread();
      vm.reloadMessages();
    });

    if (vm.recipient) {
      vm.showNewThreadDialog(true);
    }

    vm.setFocus();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:ListParticipantsCtrl
   *
   * @description
   *
   */
  ListParticipantsCtrl.$inject = ["$uibModalInstance", "thread", "Thread", "_", "$rootScope"];
  angular
    .module('ycoMessage')
    .controller('ListParticipantsCtrl', ListParticipantsCtrl);

  function ListParticipantsCtrl($uibModalInstance, thread, Thread, _, $rootScope) {
    var vm = this;

    vm.isProcessing = false;

    vm.participants = _.map(thread.participants.data, function (participant) {
      return participant.user.data;
    });

    vm.remove = function remove($index) {
      var removedParticipant;

      vm.isProcessing = true;

      removedParticipant = vm.participants.splice($index, 1);

      Thread.removeParticipantFromThread({
        threadId: thread.id,
        userId: removedParticipant[0].id
      })
        .$promise
        .then(function () {
          vm.isProcessing = false;
          $rootScope.$broadcast('participantsChanged');
        });
    };

    vm.close = function close() {
      $uibModalInstance.close();
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoForm
   * @description
   *
   */
  angular
    .module('ycoForm', [
      'ngMessages',
      'validation.match'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoForm.factory:FormControl
   *
   * @description
   *
   */
  FormControl.$inject = ["toastr"];
  angular
    .module('ycoForm')
    .factory('FormControl', FormControl);

  function FormControl(toastr) {
    function FormControlBase() {
      var processing = false
        , serverValidation = {}
        , successText = '';

      this.isProcessing = function isProcessing() {
        return processing;
      };

      this.process = function process(promise) {
        setProcessing();

        promise.then(onSuccess)
          .then(clearValidation)
          .catch(onFail)
          .finally(clearProcessing);

        return promise;
      };

      this.getServerValidation = function getServerValidation() {
        return serverValidation;
      };

      this.setSuccessText = function setSuccessText(newSuccessText) {
        successText = newSuccessText;
      };

      function onSuccess() {
        if (successText) {
          showToast(successText);
        }
      }

      function showToast(content) {
        toastr.success(content);
      }

      function onFail(response) {
        if (response.status >= 400 && response.status < 500) {
          serverValidation = response.data.error;
        }
      }

      function clearProcessing() {
        processing = false;
      }

      function setProcessing() {
        processing = true;
      }

      function clearValidation() {
        serverValidation = {};
      }
    }

    return FormControlBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoMessages
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-messages form="formObject" field-name="fieldName"></yco-messages>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoMessages', ycoMessages);

  function ycoMessages() {
    var templateFolder = 'yco-form/';
    return {
      restrict: 'EA',
      scope: {
        fieldName: '@',
        form: '=',
        server: '='
      },
      templateUrl: templateFolder + 'directives/yco-messages-directive.tpl.html',
      replace: true,
      controllerAs: 'ycoMessages',
      bindToController: true,
      controller: ["$scope", "_", function ($scope, _) {
        var vm = this;
        vm.messagesSrc = templateFolder + 'views/yco-messages-include.tpl.html';

        $scope.$watch(function () {
          return vm.server;
        }, function (newVal, oldVal) {
          var keyDiff = _.difference(_.keys(newVal), _.keys(oldVal));
          if (_.isEmpty(newVal) && _.isEmpty(keyDiff)) {
            return;
          }

          _.forEach(newVal, function (error, key) {
            _.forEach(error, function (isInvalid, errorType) {
              vm.form[key].$setValidity(errorType, !isInvalid);
            });
          });
        });

        $scope.$watch(function () {
          if (typeof vm.form[vm.fieldName] !== 'undefined') {
            return vm.form[vm.fieldName].$modelValue;
          }
        }, function (newVal, oldVal) {
          if (!_.isEmpty(vm.server)) {
            _.forEach(vm.server, function (error, key) {
              _.forEach(error, function (item, errorType) {
                vm.form[key].$setValidity(errorType, true);
                delete vm.server[key];
              });
            });
          }
        });
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoInputContainer
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-input-container></yco-input-container>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoInputContainer', ycoInputContainer);

  function ycoInputContainer() {
    return {
      restrict: 'E',
      template: '<div class="yco-input-container form-group" ng-transclude></div>',
      replace: true,
      transclude: true
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoButton
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-button yco-is-processing="isProcessing()"></yco-button>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoButton', ycoButton);

  function ycoButton() {
    return {
      restrict: 'E',
      scope: {
        ycoIsProcessing: '&',
        ycoDisabled: '&'
      },
      templateUrl: 'yco-form/directives/yco-button-directive.tpl.html',
      replace: true,
      transclude: true
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:formControl
   * @restrict C
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <form-control></form-control>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('formControl', formControl);

  function formControl() {
    return {
      restrict: 'C',
      link: function (scope, element) {
        element.addClass('mouseflow');
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoEvent
   * @description
   *
   */
  angular
    .module('ycoEvent', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEvent.factory:Event
   *
   * @description
   *
   */
  Event.$inject = ["ycoResource"];
  angular
    .module('ycoEvent')
    .factory('Event', Event);

  function Event(ycoResource) {
    var url = 'events/'
      , EventBase;

    EventBase = ycoResource('event', url + ':id', {id: '@id'},
      {
        getEventWithParticipants: {
          method: 'GET',
          url: url + ':id',
          params: {
            includes: 'participants'
          }
        },
        applyForAnEvent: {
          method: 'POST',
          url: url + ':id/users'
        },
        deleteParticipantFromAnEvent: {
          method: 'DELETE',
          url: url + ':id/users/:userId'
        },
        query: {
          cache: false
        }
      }
    );

    return EventBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:ViewEventCtrl
   *
   * @description
   *
   */
  ViewEventCtrl.$inject = ["event", "Event", "user", "$uibModalInstance", "_", "toastr"];
  angular
    .module('ycoEvent')
    .controller('ViewEventCtrl', ViewEventCtrl);

  function ViewEventCtrl(event, Event, user, $uibModalInstance, _, toastr) {
    var vm = this
      , participantIds;

    vm.event = event.data;
    vm.user = user.data;

    participantIds = _.map(vm.event.participants.data, 'id');
    vm.hasUserApplied = _.includes(participantIds, vm.user.id);

    vm.apply = function apply() {
      vm.event.users = [vm.user.id];
      Event.applyForAnEvent({id: vm.event.id}, vm.event).$promise
        .then(function () {
          toastr.success('A jelentkezés sikerült.');
          $uibModalInstance.close();
        });
    };

    vm.revokeApplication = function revokeApplication() {
      Event.deleteParticipantFromAnEvent({id: vm.event.id}).$promise
        .then(function () {
          toastr.success('A jelentkezés visszavonása sikerült.');
          $uibModalInstance.close();
        });
    };

    vm.close = function close() {
      $uibModalInstance.close();
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:NewEventCtrl
   *
   * @description
   *
   */
  NewEventCtrl.$inject = ["FormControl", "Event", "$q", "$state", "UserRole", "moment", "$scope"];
  angular
    .module('ycoEvent')
    .controller('NewEventCtrl', NewEventCtrl);

  function NewEventCtrl(FormControl, Event, $q, $state, UserRole, moment, $scope) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('Az esemény sikeresen létrejött!');

    vm.event = new Event();
    vm.availableRoles = UserRole.getUserRoles();

    vm.dateTime = new Date();
    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.event.date = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    vm.event.canApply = vm.event.canApply ? 1 : 0;
    vm.event.public = 1;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listEvents');
        });

      vm.event.$save(deferred.resolve, deferred.reject);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:ListEventsCtrl
   *
   * @description
   *
   */
  ListEventsCtrl.$inject = ["DTColumnBuilder", "YcoDataTables", "$scope", "moment", "$uibModal", "Event", "toastr", "LoggedUser", "loggedUser", "userRoles"];
  angular
    .module('ycoEvent')
    .controller('ListEventsCtrl', ListEventsCtrl);

  function ListEventsCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, $uibModal, Event, toastr, LoggedUser, loggedUser, userRoles) {
    var vm = this;

    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;
    vm.showExpired = false;

    vm.viewEvent = function viewEvent(eventId) {
      $uibModal.open({
        templateUrl: 'yco-event/views/view-event.tpl.html',
        controllerAs: 'viewEvent',
        controller: 'ViewEventCtrl',
        resolve: {
          event: function () {
            return Event.getEventWithParticipants({id: eventId}).$promise;
          },
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      });
    };


    vm.dtInstance = {};

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'events',
      scope: $scope,
      dynamicParams: function () {
        return {
          withExpired: vm.showExpired
        }
      }
    }).withOption('order', [2, 'desc']);

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('name').withTitle('Név')
      , DTColumnBuilder.newColumn('date').withTitle('Dátum')
        .renderWith(function (data) {
          return moment(data).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn('location').withTitle('Helyszín')
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (data) {
          return '<button class="btn btn-default btn-xs push-5-r"' +
            'ng-click="listEvents.viewEvent(' + data.id + ')" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</button>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="listEvents.loggedUser.roleId === listEvents.availableRoles.ADMIN.id"' +
            'ui-sref="editEvent({eventId: ' + data.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];

    vm.toggleEvents = function toggleEvents() {
      vm.dtInstance.reloadData();
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:EditEventCtrl
   *
   * @description
   *
   */
  EditEventCtrl.$inject = ["event", "FormControl", "Event", "YcoDialog", "_", "$q", "$state", "toastr", "moment", "$scope", "UserRole"];
  angular
    .module('ycoEvent')
    .controller('EditEventCtrl', EditEventCtrl);

  function EditEventCtrl(event, FormControl, Event, YcoDialog, _, $q, $state, toastr, moment, $scope, UserRole) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.event = event.data;
    vm.participants = vm.event.participants.data;
    vm.dateTime = new Date(vm.event.date);
    vm.dateFormat = 'yyyy-MM-dd';
    vm.availableRoles = UserRole.getUserRoles();
    vm.mailtoParticipantsLink = getMailtoParticipantsLink();

    vm.event.canApply = vm.event.canApply ? 1 : 0;
    vm.event.public = vm.event.public ? 1 : 0;

    vm.updateEventDate = function (data) {
      vm.event.date = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.hasParticipants = function hasParticipants() {
      return vm.participants.length > 0;
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , eventResource;

      if (vm.form.$invalid) {
        return;
      }

      vm.event.participants.data = vm.participants;
      eventResource = _.defaultsDeep(new Event(), vm.event);

      formControl
        .process(deferred.promise)
        .then(function () {
          toastr.success('Az esemény mentése sikerült.');
        });

      eventResource.$update(deferred.resolve, deferred.reject);
    };

    vm.deleteEvent = function deleteEvent() {
      var deferred = $q.defer()
        , eventResource = _.defaultsDeep(new Event(), vm.event);

      YcoDialog.show({
        title: 'Valóban törli a kijelölt eseményt?',
        content: 'A törléssel az esemény adatai véglegesen törlésre kerülnek.'
      }).then(function () {
        eventResource.$delete(deferred.resolve, deferred.reject);
      });

      deferred.promise
        .then(function () {
          $state.go('listEvents');
        });
    };

    vm.revokeApplication = function revokeApplication(userId) {
      Event.deleteParticipantFromAnEvent({id: vm.event.id, userId: userId}).$promise
        .then(function () {
          return Event.getEventWithParticipants({id: vm.event.id}).$promise;
        })
        .then(function (eventWithParticipants) {
          vm.participants = eventWithParticipants.data.participants.data;
          toastr.success('A jelentkezés visszavonása sikerült.');
        });
    };

    function getMailtoParticipantsLink() {
      var link;

      link = 'mailto:';

      link += vm.participants
        .map(function (participant) {
          return participant.email;
        })
        .join(';');

      return link;
    }
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoEnquiry
   * @description
   *
   */
  angular
    .module('ycoEnquiry', [
      'ui.router',
      'ui.bootstrap'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.service:Enquiry
   *
   * @description
   *
   */
  EnquiryService.$inject = ["enquiryStatuses", "_"];
  angular
    .module('ycoEnquiry')
    .service('EnquiryService', EnquiryService);

  function EnquiryService(enquiryStatuses, _) {
    var self = this;

    self.getStatusByStatusName = function getStatusByStatusName(name) {
      return _.find(enquiryStatuses, 'name', name);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoEnquiry.filter:enquiryStatus
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  enquiryStatus.$inject = ["enquiryStatuses", "_"];
  angular
    .module('ycoEnquiry')
    .filter('enquiryStatus', enquiryStatus);

  function enquiryStatus(enquiryStatuses, _) {
    return function (input) {
      return _.result(_.find(enquiryStatuses, 'name', input), 'label');
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.factory:Enquiry
   *
   * @description
   *
   */
  Enquiry.$inject = ["ycoResource"];
  angular
    .module('ycoEnquiry')
    .factory('Enquiry', Enquiry);

  function Enquiry(ycoResource) {
    var url = 'enquiries/'
      , EnquiryBase;

    EnquiryBase = ycoResource('enquiry', url + ':id', {id: '@id'},
      {
        getEnquiry: {
          method: 'GET',
          url: url + ':id',
          params: {
            includes: 'children,mentor'
          },
          cache: false
        },
        getEnquiries: {
          method: 'GET',
          url: url,
          cache: false
        }
      }
    );

    return EnquiryBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:RejectEnquiryDialogCtrl
   *
   * @description
   *
   */
  RejectEnquiryDialogCtrl.$inject = ["$uibModalInstance"];
  angular
    .module('ycoEnquiry')
    .controller('RejectEnquiryDialogCtrl', RejectEnquiryDialogCtrl);

  function RejectEnquiryDialogCtrl($uibModalInstance) {
    var vm = this;

    vm.form = {};
    vm.statusMessage = '';
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close(vm.statusMessage);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:ListEnquiriesCtrl
   *
   * @description
   *
   */
  ListEnquiriesCtrl.$inject = ["DTColumnBuilder", "YcoDataTables", "$scope", "moment", "EnquiryService"];
  angular
    .module('ycoEnquiry')
    .controller('ListEnquiriesCtrl', ListEnquiriesCtrl);

  function ListEnquiriesCtrl(DTColumnBuilder, YcoDataTables, $scope, moment, EnquiryService) {
    var vm = this;
    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'enquiries?includes=children,mentor',
      scope: $scope
    });

    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Szülő neve')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (enquiry) {
          return enquiry.lastName + ' ' + enquiry.firstName;
        })
      , DTColumnBuilder.newColumn('email').withTitle('Email')
      , DTColumnBuilder.newColumn('children').withTitle('Gyermekek száma')
        .renderWith(function (children) {
          return children.data.length;
        })
      , DTColumnBuilder.newColumn('status').withTitle('Státusz')
        .renderWith(function (statusName) {
          var status = EnquiryService.getStatusByStatusName(statusName);
          return '<span class="badge ' + status.class + '">' + status.label + '</span>';
        })
      , DTColumnBuilder.newColumn('mentor').withTitle('Mentor')
        .notSortable()
        .renderWith(function (mentor) {
          if (!mentor) {
            return '-';
          }

          return mentor.data.lastName + ' ' + mentor.data.firstName;
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (date) {
          return moment(date).format('YYYY.MM.DD. HH:mm');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (enquiry) {
          return '<a class="btn btn-default btn-xs"' +
            'ui-sref="editEnquiry({enquiryId: ' + enquiry.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:EditEnquiryCtrl
   *
   * @description
   *
   */
  EditEnquiryCtrl.$inject = ["enquiry", "FormControl", "Enquiry", "YcoDialog", "_", "$q", "$uibModal", "User", "userRoles", "$state", "enquiryStatuses", "loggedUser", "$scope", "toastr"];
  angular
    .module('ycoEnquiry')
    .controller('EditEnquiryCtrl', EditEnquiryCtrl);

  function EditEnquiryCtrl(enquiry, FormControl, Enquiry, YcoDialog, _, $q, $uibModal, User, userRoles, $state, enquiryStatuses, loggedUser, $scope, toastr) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('A regisztrációs kérelem frissítésre került.');

    vm.enquiry = enquiry.data;
    vm.children = vm.enquiry.children.data;
    vm.activeTab = 0;
    vm.possibleMentors = [];
    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    if (_.has(vm.enquiry, 'mentor.data')) {
      vm.possibleMentors.push(vm.enquiry.mentor.data);
    }

    vm.enquiry.mentorId = _.get(vm.enquiry, 'mentor.data.id', null);

    $scope.$watch(function () {
      vm.canNavigate = false;
    }, vm.enquiry.mentorId);

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.setActiveTab = function setActiveTab(tab) {
      vm.activeTab = tab;
    };

    vm.isNewEnquiry = function isNewEnquiry() {
      return vm.enquiry.status === enquiryStatuses.NEW.name;
    };

    vm.showRejectEnquiryDialog = function showRejectEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem elutasításra került.');

      $uibModal.open({
        templateUrl: 'yco-enquiry/views/reject-enquiry-dialog.tpl.html',
        controllerAs: 'rejectEnquiry',
        controller: 'RejectEnquiryDialogCtrl'
      })
        .result
        .then(function (rejectionReason) {
          var deferred = $q.defer()
            , enquiryResource;

          vm.enquiry.status = enquiryStatuses.REJECTED.name;
          vm.enquiry.statusMessage = rejectionReason;

          enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

          formControl
            .process(deferred.promise)
            .then(function () {
              $state.go('listEnquiries');
            });

          enquiryResource.$update(deferred.resolve, deferred.reject);
        });
    };

    vm.showApproveEnquiryDialog = function showApproveEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem elfogadásra került.');

      YcoDialog.show({
        title: 'Regisztráció elfogadása',
        content: 'Biztosan elfogadja a regisztrációt? Elfogadás után az adatokat rögzítjük a rendszerben, amelyről a jelentkező emailes értesítést kap. Ezzel létrejön a regisztrációt kezdeményező szülő/gondviselő felhasználói fiókja a rendszerbe.',
        actionButtonText: 'Elfogadás'
      }).then(function () {
        var deferred = $q.defer()
          , enquiryResource;

        vm.enquiry.status = enquiryStatuses.APPROVED.name;

        enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

        formControl
          .process(deferred.promise)
          .then(function () {
            $state.go('listEnquiries');
          });

        enquiryResource.$update(deferred.resolve, deferred.reject);
      });
    };

    vm.showAssignEnquiryDialog = function showAssignEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem hozzárendelésre került a kiválasztott mentorhoz.');

      $uibModal.open({
        templateUrl: 'yco-enquiry/views/assign-enquiry-dialog.tpl.html',
        controllerAs: 'assignEnquiry',
        controller: 'AssignEnquiryDialogCtrl'
      })
        .result
        .then(function (dialogResult) {
          var deferred = $q.defer()
            , enquiryResource;

          vm.enquiry.status = enquiryStatuses.WAITING_FOR_APPROVEMENT.name;
          vm.possibleMentors = dialogResult.possibleMentors;
          vm.enquiry.mentorId = dialogResult.mentorId;

          enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

          formControl
            .process(deferred.promise)
            .then(function () {
            })
            .catch(function (err) {
              if (err.data) {
                toastr.error(err.data.detail);
              }
            });

          enquiryResource.$update(deferred.resolve, deferred.reject);
        });
    };

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listEnquiries');
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });

      enquiryResource.$update(deferred.resolve, deferred.reject);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:AssignEnquiryDialogCtrl
   *
   * @description
   *
   */
  AssignEnquiryDialogCtrl.$inject = ["$uibModalInstance", "userRoles", "User"];
  angular
    .module('ycoEnquiry')
    .controller('AssignEnquiryDialogCtrl', AssignEnquiryDialogCtrl);

  function AssignEnquiryDialogCtrl($uibModalInstance, userRoles, User) {
    var vm = this;

    vm.form = {};
    vm.mentorId = null;
    vm.possibleMentors = [];
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close({mentorId: vm.mentorId, possibleMentors: vm.possibleMentors});
    };

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoEnquiry.constant:enquiryStatuses
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .constant('enquiryStatuses', {
      NEW: {
        name: 'newEnquiry',
        label: 'Új',
        class: 'badge-info'
      },
      WAITING_FOR_APPROVEMENT: {
        name: 'waitingForApprovement',
        label: 'Elfogadásra vár',
        class: 'badge-warning'
      },
      APPROVED: {
        name: 'approved',
        label: 'Elfogadott',
        class: 'badge-success'
      },
      REJECTED: {
        name: 'rejected',
        label: 'Elutasított',
        class: 'badge-default'
      }
    });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoChild
   * @description
   *
   */
  angular
    .module('ycoChild', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.service:BnoService
   *
   * @description
   *
   */
  BnoService.$inject = ["Child", "_", "$q", "store"];
  angular
    .module('ycoChild')
    .service('BnoService', BnoService);

  function BnoService(Child, _, $q, store) {
    var self = this
      , cacheStorageKey = 'bnoCache'
      , searchCacheStorageKey = 'bnoSearchCache'
      , bnos = store.get(cacheStorageKey) || {}
      , remoteSearchOptions
      , searchCache = store.get(searchCacheStorageKey) || {};

    remoteSearchOptions = {limit: 10};

    self.getBnoById = function getBnoById(id) {
      var idAsNumber;

      return $q(function (resolve) {
        if (!id) {
          return resolve(null);
        }

        idAsNumber = Number(id);

        if (_.has(bnos, idAsNumber)) {
          return resolve(bnos[idAsNumber]);
        }

        Child.getBnoById({bnoId: idAsNumber})
          .$promise
          .then(function (response) {
            bnos[id] = response.data;
            store.set(cacheStorageKey, bnos);
            return resolve(response.data);
          });
      });
    };

    self.searchBno = function searchBno(searchTerm) {
      var options = _.assign({}, remoteSearchOptions, {search: searchTerm});

      return $q(function (resolve) {
        if (!searchTerm) {
          return resolve([]);
        }

        if (_.has(searchCache, searchTerm)) {
          return resolve(_.map(searchCache[searchTerm], function (bnoId) {
            return bnos[bnoId];
          }));
        }

        return Child.searchBno(options)
          .$promise
          .then(function (response) {
            var bnosCached = [];

            if (!response.data.length) {
              return resolve([]);
            }

            _.forEach(response.data, function (bnoEntry) {
              var idAsNumber = Number(bnoEntry.id);

              if (_.has(bnos, idAsNumber)) {
                return;
              }

              bnos[idAsNumber] = bnoEntry;
              bnosCached.push(idAsNumber);
              searchCache[searchTerm] = bnosCached;

              store.set(cacheStorageKey, bnos);
              store.set(searchCacheStorageKey, searchCache);
            });

            return resolve(response.data);
          });
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name ycoChild.filter:childStatus
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  childStatus.$inject = ["childStatuses"];
  angular
    .module('ycoChild')
    .filter('childStatus', childStatus);

  function childStatus(childStatuses) {
    return function (input) {
      return _.find(childStatuses, 'name', input);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.factory:Child
   *
   * @description
   *
   */
  Child.$inject = ["ycoResource"];
  angular
    .module('ycoChild')
    .factory('Child', Child);

  function Child(ycoResource) {
    var url = 'children/'
      , ChildBase;

    ChildBase = ycoResource('child', url + ':id', {id: '@id'}, {
      getLatestActivities: {
        url: url + ':id/activities',
        cache: false,
        params: {
          limit: -1
        }
      },
      getParticipants: {
        url: url + ':id/participants',
        cache: false,
        params: {
          limit: -1
        }
      },
      addParticipant: {
        url: url + ':childId/participants/:userId',
        method: 'POST'
      },
      removeParticipant: {
        url: url + ':childId/participants/:userId',
        method: 'DELETE'
      },
      createTextEntry: {
        url: url + ':childId/entries',
        method: 'POST'
      },
      searchBno: {
        url: 'bnos',
        method: 'GET'
      },
      getBnoById: {
        url: 'bnos/:bnoId',
        method: 'GET'
      },
      getFilesForChild: {
        url: url + ':childId/files',
        method: 'GET'
      },
      attachFilesToChild: {
        url: url + ':childId/files',
        method: 'POST'
      },
      deleteFilesFromChild: {
        url: url + ':childId/files/:fileIds',
        method: 'DELETE'
      }
    });

    return ChildBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:ycoChildActivity
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <yco-child-activity></yco-child-activity>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('ycoChildActivity', ycoChildActivity);

  function ycoChildActivity() {
    return {
      restrict: 'E',
      scope: {
        activity: '='
      },
      templateUrl: 'yco-child/directives/yco-child-activity-directive.tpl.html',
      replace: true,
      controllerAs: 'ycoChildActivity',
      controller: ["$scope", "childActivityTypes", "childBasicFields", "_", function ($scope, childActivityTypes, childBasicFields, _) {
        var vm = this;

        vm.activity = $scope.activity;
        vm.activityType = vm.activity.type === 'dataChange' && vm.activity.staffChange ? childActivityTypes.STAFF_CHANGE : _.find(childActivityTypes, 'name', vm.activity.type);

        vm.getActivityLabel = function (change) {
          return _.find(childBasicFields, 'name', change) ? _.find(childBasicFields, 'name', change).label : '';
        }
      }]
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:textField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <text-field></text-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('textField', textField);

  function textField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        form: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/text-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: ["$scope", function ($scope) {
        var vm = this;
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:selectField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <select-field></select-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('selectField', selectField);

  function selectField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        container: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/select-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: ["$scope", "$rootScope", "_", function ($scope, $rootScope, _) {
        if ($scope.field.multiselect && !_.isEmpty($scope.field.exampleField)) {
          $scope.$watch(function () {
            return $scope.field.value;
          }, function (newVal, oldVal) {
            var changedElement = _.xor(newVal, oldVal);
            $rootScope.$broadcast('child.fieldWithAddByParentValueChanged', $scope.field.id, $scope.container, _.get($scope, 'field.value.length', 0), $scope.field.exampleField, changedElement);
          });
        }
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:numberField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <number-field></number-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('numberField', numberField);

  function numberField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        container: '=',
        form: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/number-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: ["$scope", "$rootScope", "_", function ($scope, $rootScope, _) {
        if (!_.isEmpty($scope.field.exampleField)) {
          $scope.$watch(function () {
            return $scope.field.value;
          }, function () {
            $rootScope.$broadcast('child.fieldWithAddByParentValueChanged', $scope.field.id, $scope.container, $scope.field.value, $scope.field.exampleField);
          });
        }
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:dateField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <date-field></date-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('dateField', dateField);

  function dateField() {
    return {
      restrict: 'EA',
      scope: {
        form: '=',
        field: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/date-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: ["$scope", "moment", "_", function ($scope, moment, _) {
        var vm = this;

        vm.nativeDateValue = $scope.field.value ? moment($scope.field.value).toDate() : null;
        vm.isDatepickerPopupOpen = false;

        vm.toggleDatepickerPopup = function toggleDatepickerPopup() {
          vm.isDatepickerPopupOpen = !vm.isDatepickerPopupOpen;
        };

        vm.datePickerOptions = {
          maxDate: new Date()
        };

        $scope.$watch(function () {
          return vm.nativeDateValue;
        }, function (value) {
          if (_.isNull(vm.nativeDateValue) || !_.has($scope, 'form[' + $scope.field.id + ']')) {
            return;
          }
          $scope.form[$scope.field.id].$setValidity('maxDate', (moment(vm.nativeDateValue).isSameOrBefore(new Date())));
          $scope.field.value = moment(value).format('YYYY-MM-DD HH:mm:ss');
        });
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:booleanField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="ycoChild">
       <file name="index.html">
        <boolean-field></boolean-field>
       </file>
     </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('booleanField', booleanField);

  function booleanField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/boolean-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'booleanField';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:bnoField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <bno-field></bno-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('bnoField', bnoField);

  function bnoField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        container: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/bno-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: ["Child", "$scope", "_", "BnoService", "$q", "$rootScope", function (Child, $scope, _, BnoService, $q, $rootScope) {
        var vm = this;

        vm.possibleBnos = [];

        if ($scope.field.value) {
          if (_.isArray($scope.field.value) && !_.isEmpty($scope.field.value)) {
            $q.all(_.map($scope.field.value, BnoService.getBnoById))
              .then(function (bnos) {
                vm.possibleBnos = bnos;
              });
          } else {
            BnoService.getBnoById($scope.field.value)
              .then(function (bno) {
                vm.possibleBnos = [bno];
              });
          }
        }

        vm.refreshPossibleBnos = function refreshPossibleBnos(searchTerm) {
          if (!searchTerm) {
            return;
          }

          return BnoService.searchBno(searchTerm)
            .then(function (bnos) {
              vm.possibleBnos = bnos;
            });
        };

        if ($scope.field.multiselect && !_.isEmpty($scope.field.exampleField)) {
          $scope.$watch(function () {
            return $scope.field.value;
          }, function (newVal, oldVal) {
            var changedElement = _.xor(newVal, oldVal);
            $rootScope.$broadcast('child.fieldWithAddByParentValueChanged', $scope.field.id, $scope.container, _.get($scope, 'field.value.length', 0), $scope.field.exampleField, changedElement);
          });
        }
      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:ViewChildCtrl
   *
   * @description
   *
   */
  ViewChildCtrl.$inject = ["child", "YcoDialog", "$uibModal", "FormControl", "$state", "latestActivities", "participants", "Child", "user", "userRoles", "_", "toastr", "childStatuses", "$window", "$stateParams"];
  angular
    .module('ycoChild')
    .controller('ViewChildCtrl', ViewChildCtrl);

  function ViewChildCtrl(child, YcoDialog, $uibModal, FormControl, $state, latestActivities, participants, Child, user, userRoles, _, toastr, childStatuses, $window, $stateParams) {
    $window.scrollTo(0, 0);

    var vm = this
      , formControl = new FormControl();

    vm.child = child.data;
    vm.participants = participants.data;
    vm.latestActivities = latestActivities.data;
    vm.currentUser = user.data;
    vm.availableRoles = userRoles;
    vm.childDocuments = [];
    vm.canEditChild = false;

    var status = _.find(childStatuses, 'name', vm.child.dataSheetStatus) ? _.find(childStatuses, 'name', vm.child.dataSheetStatus) : false;

    if (status.name === childStatuses.INACTIVE.name) {
      vm.canEditChild = vm.currentUser.roleId === userRoles.ADMIN.id;
    } else {
      vm.canEditChild = vm.currentUser.roleId !== userRoles.PARENT.id;
    }

    vm.isDataSheetClosed = status.name === childStatuses.CLOSED.name;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.checkConditionalDisplay = function checkConditionalDisplay($index, container) {
      var currentField = container[$index]
        , dependencies = _.get(currentField, 'visibleIf')
        , shouldDisplay = true;

      if (!dependencies || _.isEmpty(dependencies)) {
        return shouldDisplay;
      }

      shouldDisplay = _.reduce(dependencies, function (acc, value, key) {
        acc = !!_.filter(container, function (field) {
          return field.id === key && field.value === value;
        }).length;

        return acc;
      }, shouldDisplay);

      return shouldDisplay;
    };

    vm.removeParticipant = function removeParticipant($index) {
      YcoDialog.show({
        title: 'Személy eltávolítása az adatlapról',
        content: 'Biztosan eltávolítja <strong>' + vm.participants[$index].lastName + ' ' + vm.participants[$index].firstName + '</strong> személyt az adatlapról?</strong>',
        actionButtonText: 'Eltávolítás'
      }).then(function () {
        return Child.removeParticipant({
          childId: vm.child.id,
          userId: vm.participants[$index].id
        }).$promise;
      }).then(function () {
        $state.reload();
      });
    };

    vm.showAddParticipantDialog = function showAddParticipantDialog() {
      formControl.setSuccessText('A kiválasztott személyt hozzáadásra került.');

      $uibModal.open({
        templateUrl: 'yco-child/views/add-participant-dialog.tpl.html',
        controllerAs: 'addParticipant',
        controller: 'AddParticipantDialogCtrl'
      }).result
        .then(function (userId) {
          return Child.addParticipant({
            childId: vm.child.id,
            userId: userId
          }, {}).$promise;
        })
        .then(function () {
          $state.reload();
        });
    };

    vm.canParticipantBeMessaged = function canParticipantBeMessaged($index) {
      var participant = vm.participants[$index];
      return participant.id !== vm.currentUser.id;
    };

    vm.canParticipantBeRemoved = function canParticipantBeRemoved($index) {
      var participant = vm.participants[$index]
        , unremovableUserRoleIds = [userRoles.MENTOR.id, userRoles.PARENT.id];

      return participant.id !== vm.currentUser.id && !_.includes(unremovableUserRoleIds, participant.roleId);
    };

    vm.showAddTextEntryDialog = function showAddTextEntryDialog() {
      $uibModal.open({
        templateUrl: 'yco-child/views/add-text-entry-dialog.tpl.html',
        controllerAs: 'addTextEntry',
        controller: 'AddTextEntryDialogCtrl'
      }).result
        .then(function (entry) {
          return Child.createTextEntry({childId: vm.child.id}, entry).$promise;
        })
        .then(function () {
          toastr.success('A bejegyzést sikeresen létrejött.');
          $state.reload();
        });
    };

    vm.emptyFieldFilter = function emptyFieldFilter(field) {
      return !_.isUndefined(field.rawValue) && !_.isNull(field.rawValue);
    };

    vm.getChildDocuments = function getChildDocuments() {
      Child.getFilesForChild({childId: vm.child.id}).$promise
        .then(function (data) {
          vm.childDocuments = data.data;
        });
    };

    vm.getChildDocuments();

    vm.manageChildDataSheetState = function manageChildDataSheetState(state) {
      return Child.update({close: state.isClosed, id: $stateParams.childId}, vm.child).$promise
        .then(function () {
          vm.isDataSheetClosed = state.isClosed;
          toastr.success(vm.isDataSheetClosed ? 'Az adatlap lezárásra került.' : 'Az adatlap újranyitásra került.');
          $state.go('listChildren');
        })
        .catch(function (error) {
          if (error.data) {
            toastr.error(error.data.detail);
          } else {
            toastr.error(vm.isDataSheetClosed ? 'Hiba az adatlap lezárása során.' : 'Hiba az adatlap újranyitása során.');
          }
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:NewChildCtrl
   *
   * @description
   *
   */
  NewChildCtrl.$inject = ["FormControl", "Child", "User", "userRoles", "toastr", "$state", "$q", "_", "$scope"];
  angular
    .module('ycoChild')
    .controller('NewChildCtrl', NewChildCtrl);

  function NewChildCtrl(FormControl, Child, User, userRoles, toastr, $state, $q, _, $scope) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.child = new Child();
    vm.possibleParents = User.getParents().data;
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.child.parentId = _.get(vm.child, 'parent.data.id', null);

    formControl.setSuccessText('Gyermek létrehozása sikeresen megtörtént.');

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.datePickerOptions = {
      minDate: moment('2011').toDate(),
      maxDate: new Date()
    };

    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.child.dateOfBirth = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    $scope.$watch(function () {
      if (moment(vm.child.dateOfBirth).year() >= 2010) {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', true);
      } else {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', false);
      }
    }, vm.child.dateOfBirth);

    $scope.$watch(function () {
      if (vm.child.birthWeek > 36 && vm.child.birthWeight > 2500) {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', true);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', true);
      }
    }, vm.form.birthWeek);

    $scope.$watch(function () {
      if (vm.child.birthWeek > 36 && vm.child.birthWeight > 2500) {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        vm.form['birthWeek'].$setValidity('weigthOrWeek', true);
        vm.form['birthWeight'].$setValidity('weigthOrWeek', true);
      }
    }, vm.form.birthWeight);

    vm.submit = function submit($event) {
      $event.preventDefault();
      var deferred = $q.defer()
        , childResource;

      if (vm.form.$invalid) {
        return;
      }
      vm.child.birthWeek = parseInt(vm.child.birthWeek);
      vm.child.birthYear = parseInt(vm.child.birthYear);
      vm.child.birthWeight = parseInt(vm.child.birthWeight);
      childResource = _.assign(new Child(), vm.child);
      childResource.dateOfBirth = moment(vm.child.dateOfBirth).format('YYYY-MM-DD HH:mm:ss');
      childResource.birthYear = moment(vm.child.dateOfBirth).year();

      formControl.process(deferred.promise)
        .then(function () {
          $state.go('listChildren');
        })
        .catch(function () {
          toastr.error('Hiba történt a gyermek létrehozása során, kérem próbálja később!');
        });
      childResource.$save(deferred.resolve, deferred.reject);
    };

    vm.refreshPossibleParents = function refreshPossibleParents(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.PARENT.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParents = response.data;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:ListChildrenCtrl
   *
   * @description
   *
   */
  ListChildrenCtrl.$inject = ["YcoDataTables", "$scope", "DTColumnBuilder", "moment", "childStatuses", "_", "user", "userRoles"];
  angular
    .module('ycoChild')
    .controller('ListChildrenCtrl', ListChildrenCtrl);

  function ListChildrenCtrl(YcoDataTables, $scope, DTColumnBuilder, moment, childStatuses, _, user, userRoles) {
    var vm = this;

    vm.user = user.data;
    vm.userRoles = userRoles;

    vm.dtOptions = YcoDataTables.getOptions({
      apiEndpoint: 'children',
      params: {
        includes: 'parent.mentor'
      },
      scope: $scope
    });
    vm.dtColumns = [
      DTColumnBuilder.newColumn('id').withTitle('Azonosító')
      , DTColumnBuilder.newColumn('lastName').withTitle('Vezetéknév').notVisible()
      , DTColumnBuilder.newColumn('firstName').withTitle('Keresztnév').notVisible()
      , DTColumnBuilder.newColumn(null).withTitle('Teljes név')
        .withOption('compoundOf', ['lastName', 'firstName'])
        .renderWith(function (child) {
          return child.lastName + ' ' + child.firstName;
        })
      , DTColumnBuilder.newColumn(null).withTitle('Szülő').notSortable()
        .renderWith(function (child) {
          return child.parent.data.lastName + ' ' + child.parent.data.firstName;
        })
      , DTColumnBuilder.newColumn('dateOfBirth').withTitle('Születési dátum')
        .renderWith(function (date) {
          return date ? moment(date).format('YYYY. MM. DD.') : '-';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Mentor').notSortable()
        .renderWith(function (child) {
          return child.parent.data.mentor ? child.parent.data.mentor.data.lastName + ' ' + child.parent.data.mentor.data.firstName : '-';
        })
      , DTColumnBuilder.newColumn(null).withTitle('Adatlap státusz')
        .renderWith(function (child) {
          var status = _.find(childStatuses, 'name', child.dataSheetStatus) ? _.find(childStatuses, 'name', child.dataSheetStatus) : '';
          return '<span class="badge ' + status.class + ' push-5-l">' + status.label + '</span>';
        })
      , DTColumnBuilder.newColumn('createdAt').withTitle('Regisztráció dátuma')
        .renderWith(function (date) {
          return moment(date).format('YYYY. MM. DD. HH:mm');
        })
      , DTColumnBuilder.newColumn(null).withTitle('Műveletek').notSortable()
        .withClass('text-center').renderWith(function (child) {
          var status = _.find(childStatuses, 'name', child.dataSheetStatus) ? _.find(childStatuses, 'name', child.dataSheetStatus) : false,
            showEditButton = false;
          if (status.name === childStatuses.INACTIVE.name) {
            showEditButton = user.data.roleId === userRoles.ADMIN.id;
          } else {
            showEditButton = user.data.roleId !== userRoles.PARENT.id;
          }

          return '<a class="btn btn-default btn-xs push-5-r"' +
            'ui-sref="viewChild({childId: ' + child.id + '})" uib-tooltip="Megtekintés">' +
            '<i class="fa fa-eye"></i>' +
            '</a>' +
            '<a class="btn btn-default btn-xs"' +
            'ng-if="' + showEditButton + '"' +
            'ui-sref="editChild({childId: ' + child.id + '})" uib-tooltip="Szerkesztés">' +
            '<i class="fa fa-pencil"></i>' +
            '</a>';
        })
    ];
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:EditChildCtrl
   *
   * @description
   *
   */
  EditChildCtrl.$inject = ["child", "FormControl", "_", "userRoles", "User", "$q", "Child", "YcoDialog", "$state", "$scope", "moment", "user", "$window", "toastr", "$rootScope", "Auth", "appConfig"];
  angular
    .module('ycoChild')
    .controller('EditChildCtrl', EditChildCtrl);

  function EditChildCtrl(child, FormControl, _, userRoles, User, $q, Child, YcoDialog, $state, $scope, moment, user, $window, toastr, $rootScope, Auth, appConfig) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.form = {};
    vm.child = child.data;
    vm.availableRoles = userRoles;
    vm.loggedUser = user.data;
    vm.canNavigate = false;
    vm.possibleParents = [];
    vm.childDocuments = [];
    vm.newDocuments = [];
    vm.hasFile = false;
    $scope.fields = vm.child.fields;

    $window.onbeforeunload = function (event) {
      if (vm.form.$dirty && !vm.form.$submitted) {
        return 'Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?';
      }
    };

    $rootScope.$on('child.fieldWithAddByParentValueChanged', function (event, fieldId, container, targetNumber, fieldTemplate, targetFieldId) {
      var existingFields
        , numberOfExistingFields
        , parentFieldIndex
        , childFieldPredicate;

      childFieldPredicate = function (field) {
        return field.addByParentValue && field.parent === fieldId;
      };
      existingFields = _.filter(container, childFieldPredicate);
      numberOfExistingFields = existingFields.length;
      parentFieldIndex = _.findIndex(container, function (field) {
        return field.id === fieldId;
      });

      if (!_.isNumber(targetNumber) || targetNumber < 0) {
        return;
      }

      if (!targetNumber) {
        _.remove(container, childFieldPredicate);
        return;
      }

      if (numberOfExistingFields < targetNumber) {
        while (numberOfExistingFields < targetNumber) {
          container.splice(parentFieldIndex + numberOfExistingFields + 1, 0, generateFieldFromTemplate(targetFieldId || numberOfExistingFields, fieldTemplate));
          numberOfExistingFields++;
        }
      } else if (numberOfExistingFields > targetNumber) {
        if (targetFieldId) {
          _.remove(container, function (field) {
            return field.addByParentValue && field.parent === fieldId && _.endsWith(field.id, targetFieldId);
          });
        } else {
          while (numberOfExistingFields > targetNumber) {
            container.splice(parentFieldIndex + numberOfExistingFields, 1);

            numberOfExistingFields--;
          }
        }
      }
    });

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    if (_.has(vm.child, 'parent.data')) {
      vm.possibleParents.push(vm.child.parent.data);
    }

    vm.child.parentId = _.get(vm.child, 'parent.data.id', null);

    formControl.setSuccessText('A gyermek sikeresen frissítve.');

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.dateOfBirth = moment(vm.child.dateOfBirth).toDate();

    vm.datePickerOptions = {
      minDate: moment('2010').toDate(),
      maxDate: new Date()
    };

    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.child.dateOfBirth = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    $scope.$watch(function () {
      if (moment(vm.dateOfBirth).year() >= 2010) {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', true);
      } else {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', false);
      }
    }, vm.dateOfBirth);


    vm.submit = function submit() {
      var deferred = $q.defer()
        , childResource;

      if (vm.form.$invalid) {
        return;
      }
      vm.child.dateOfBirth = moment(vm.dateOfBirth).format('YYYY-MM-DD HH:mm:ss');
      vm.child.birthWeek = parseInt(vm.child.birthWeek, 10);
      vm.child.birthWeight = parseInt(vm.child.birthWeight, 10);
      childResource = _.assign(new Child(), vm.child, transformFields(_.assign({}, vm.child)));
      childResource.birthYear = moment(vm.child.dateOfBirth).year();

      formControl.process(deferred.promise)
        .then(function () {
          $state.go('viewChild', {childId: vm.child.id});
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });
      childResource.$update(deferred.resolve, deferred.reject);
    };

    vm.refreshPossibleParents = function refreshPossibleParents(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.PARENT.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParents = response.data;
        });
    };

    vm.showDeleteDialog = function showDeleteDialog() {
      formControl.setSuccessText('A gyermek adatlapot eltávolítottuk a rendszerből.');

      YcoDialog.show({
        title: 'Adatlap törlés',
        content: 'Biztosan törli az adatlapot? Törlés után az adatlaphoz köthető adatokat véglegesen eltávolítjuk rendszerünkből, mely művelet nem visszavonható.',
        actionButtonText: 'Törlés'
      }).then(function () {
        var deferred = $q.defer()
          , childResource = _.assign(new Child(), vm.child);

        formControl
          .process(deferred.promise)
          .then(function () {
            $state.go('listChildren');
          })
          .catch(function (err) {
            if (err.data) {
              toastr.error(err.data.detail);
            }
          });

        childResource.$delete(deferred.resolve, deferred.reject);
      });
    };

    vm.getParentField = function getParentField($index, container) {
      console.log(container[$index]);
    };

    vm.checkConditionalDisplay = function checkConditionalDisplay($index, container) {
      var currentField = container[$index]
        , dependencies = _.get(currentField, 'visibleIf')
        , shouldDisplay = true;

      if (!dependencies || _.isEmpty(dependencies)) {
        return shouldDisplay;
      }

      shouldDisplay = _.reduce(dependencies, function (acc, value, key) {
        acc = !!_.filter(container, function (field) {
          return field.id === key && field.value === value;
        }).length;

        return acc;
      }, shouldDisplay);

      return shouldDisplay;
    };

    function transformFields(childObject) {
      var outerFields = _.get(childObject, 'fields.data.fields')
        , groups = _.get(childObject, 'fields.data.groups');

      childObject.fields = {};

      if (!_.isEmpty(outerFields)) {
        childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(outerFields));
      }

      if (!_.isEmpty(groups)) {
        _.forEach(_.keys(groups), function (key) {
          var groupFields = _.get(groups[key], 'fields')
            , tabs = _.get(groups[key], 'tabs');

          if (!_.isEmpty(groupFields)) {
            childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(groupFields));
          }

          if (!_.isEmpty(tabs)) {
            _.forEach(groups[key].tabs, function (tab) {
              var tabFields = _.get(tab, 'fields');

              if (_.isEmpty(tabFields)) {
                return;
              }

              childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(tabFields));
            });
          }
        });
      }

      return childObject;
    }

    /**
     * Iterates over an array of child fields, returning an object with field IDs as keys, and field values as values.
     *
     * @param {array} fields Child fields as an array
     * @returns {object} Child fields as an object
     */
    function getFieldValuesToObject(fields) {
      var fieldsArray = fields
        , fieldsObject;

      fieldsObject = fieldsArray.reduce(function (accumulator, field) {
        accumulator[field.id] = field.value;
        return accumulator;
      }, {});

      return fieldsObject;
    }

    function generateFieldFromTemplate(id, template) {
      var field = _.assign({}, template);

      field.id += id;

      return field;
    }

    /**
     * Shows whether a tab has untouched fields
     *
     * @param tab - Current tab
     * @returns {boolean} Current tab has unfilled fields
     */
    vm.hasMissingField = function markTab(tab) {
      return tab.fields.filter(function (item) {
        return item.parent === null && item.value === null
      }).length > 0;
    };

    /*
    File upload
     */
    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      formControl.setSuccessText('A fájl sikeresen kiválasztva.');
      vm.hasFile = true;
      vm.newDocuments.push(response.data.id);
      deferred.resolve();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasFile = false;
      $flow.cancel();
    };

    vm.cancelPublicationAttachment = function cancelPublicationAttachment() {
      vm.attachment = '';
    };

    vm.getFileName = function getFileName() {
      if (!vm.attachment) {
        return false;
      }

      return vm.attachment.split('\\').pop().split('/').pop();
    };

    vm.getChildDocuments = function getChildDocuments() {
      Child.getFilesForChild({childId: vm.child.id}).$promise
        .then(function (data) {
          vm.childDocuments = data.data;
        });
    };

    vm.attachFilesToChild = function attachFilesToChild($flow) {
      Child.attachFilesToChild({childId: vm.child.id}, {
        fileIds: vm.newDocuments
      }).$promise
        .then(function () {
          toastr.success('A fájl adatlaphoz csatolása sikerült.');
          vm.newDocuments = [];
          vm.getChildDocuments();
          vm.cancelFile($flow);
        });
    };

    vm.deleteDocument = function deleteDocument(documentId) {
      console.log(documentId);
      Child.deleteFilesFromChild({
        childId: vm.child.id,
        fileIds: [documentId]
      }).$promise
        .then(function () {
          toastr.success('A fájl törlése sikerült.');
          vm.getChildDocuments();
        })
    };

    vm.getChildDocuments();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:AddTextEntryDialogCtrl
   *
   * @description
   *
   */
  AddTextEntryDialogCtrl.$inject = ["$uibModalInstance"];
  angular
    .module('ycoChild')
    .controller('AddTextEntryDialogCtrl', AddTextEntryDialogCtrl);

  function AddTextEntryDialogCtrl($uibModalInstance) {
    var vm = this;

    vm.form = {};
    vm.entry = {};

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      $uibModalInstance.close(vm.entry);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:AddParticipantDialogCtrl
   *
   * @description
   *
   */
  AddParticipantDialogCtrl.$inject = ["$uibModalInstance", "User", "userRoles"];
  angular
    .module('ycoChild')
    .controller('AddParticipantDialogCtrl', AddParticipantDialogCtrl);

  function AddParticipantDialogCtrl($uibModalInstance, User, userRoles) {
    var vm = this;

    vm.form = {};
    vm.participantId = null;
    vm.possibleParticipants = [];
    vm.isProcessing = false;

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.submit = function submit() {
      if (vm.form.$invalid) {
        return;
      }

      vm.isProcessing = true;
      $uibModalInstance.close(vm.participantId);
    };

    vm.refreshPossibleParticipants = function refreshPossibleParticipants(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: [userRoles.DOCTOR.id, userRoles.EARLY_DEV.id].join()
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParticipants = response.data;
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childStatuses
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childStatuses', {
      UNFILLED: {
        name: 'unfilled',
        label: 'Kitöltetlen',
        class: 'badge-default'
      },
      DEFICIENT: {
        name: 'deficient',
        label: 'Hiányos',
        class: 'badge-danger'
      },
      FILLED_OUT: {
        name: 'filledOut',
        label: 'Kitöltött',
        class: 'badge-success'
      },
      INACTIVE: {
        name: 'inactive',
        label: 'Inaktív',
        class: 'badge-warning'
      },
      CLOSED: {
        name: 'closed',
        label: 'Lezárt',
        class: 'badge-warning'
      }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childBasicFields
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childBasicFields', {
      FIRSTNAME: {
        name: 'firstName',
        label: 'Keresztnév'
      },
      LASTNAME: {
        name: 'lastName',
        label: 'Vezetéknév'
      },
      PARENT: {
        name: 'parentId',
        label: 'Szülő / Gondviselő'
      },
      MOTHERNAME: {
        name: 'motherName',
        label: 'Anyja neve'
      },
      BIRTH_WEEK: {
        name: 'birthWeek',
        label: 'Születési hét'
      },
      BIRTH_WEIGHT: {
        name: 'birthWeight',
        label: 'Születési súly'
      },
      BIRTH_DATE: {
        name: 'dateOfBirth',
        label: 'Születési dátum'
      },
      BIRTH_YEAR: {
        name: 'birthYear',
        label: 'Születési év'
      },
      IMAGE: {
        name: 'imageId',
        label: ''
      },
      ADDRESS: {
        name: 'address',
        label: 'Cím'
      }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childActivityTypes
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childActivityTypes', {
      TEXTUAL: {
        name: 'textEntry',
        label: 'Szöveges bejegyzés',
        class: 'fa fa-pencil bg-amethyst'
      },
      DATA_CHANGE: {
        name: 'dataChange',
        label: 'Adatmódosítás',
        class: 'fa fa-file-text bg-gray-dark'
      },
      STAFF_CHANGE: {
        name: 'staffChange',
        label: 'Szakmai stáb módosult',
        class: 'fa fa-user-md bg-flat'
      }
    });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name ycoAuth
   * @description
   *
   */
  angular
    .module('ycoAuth', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoAuth.service:Auth
   *
   * @description
   *
   */
  Auth.$inject = ["$http", "appConfig", "store", "$rootScope", "toastr", "$state", "jwtHelper", "moment", "$interval", "$log", "jwtOptions", "$q", "$window"];
  angular
    .module('ycoAuth')
    .service('Auth', Auth);

  function Auth($http, appConfig, store, $rootScope, toastr, $state, jwtHelper, moment, $interval, $log, jwtOptions, $q, $window) {
    var self = this
      , authTokenKey = 'authToken';

    /**
     * Returns the stored auth token.
     *
     * @returns {string} JWT token
     */
    self.getToken = function getToken() {
      return store.get(authTokenKey);
    };

    /**
     * Clears the stored auth token.
     */
    self.removeToken = function removeToken() {
      store.remove(authTokenKey);
    };

    /**
     * Persists the given token in the store.
     *
     * @param {string} token Token to save
     */
    self.setToken = function setToken(token) {
      store.set(authTokenKey, token);
    };

    /**
     * Returns a precomposed auth header object, with the header name as key, and the value as value. Can be used
     * to manually inject authentication details into API calls.
     *
     * @returns {object} Composed header object
     */
    self.getAuthHeader = function getAuthHeader() {
      var authHeaderName = jwtOptions.config.authHeader
        , authHeaderPrefix = jwtOptions.config.authPrefix
        , authHeader = {};

      authHeader[authHeaderName] = authHeaderPrefix + self.getToken();

      return authHeader;
    };

    /**
     * Returns whether there's a token available in the store.
     *
     * @returns {boolean} Whether there's a token available in the store.
     */
    self.isTokenSet = function isTokenSet() {
      return !!store.get(authTokenKey);
    };

    /**
     * Initiates the login process. Calls the API's login endpoint with the user credentials.
     *
     * @param {object} userCredentials Credentials of the user
     * @param {string} userCredentials.email Email of the user
     * @param {string} userCredentials.password Password of the user
     * @returns {Promise} Promise which resolves to the successful server response
     */
    self.login = function login(userCredentials) {
      return $http({
        url: appConfig.api.url + 'auth/login',
        method: 'POST',
        data: userCredentials
      }).then(function (response) {
        self.setAuthenticated(response.data.token);
        $rootScope.$broadcast('user.login');
        self.showLoginToast();
      })
    };

    /**
     * Logs out the user, unsetting the token, and redirecting to login state.
     */
    self.logout = function logout() {
      self.setAuthenticated(false);
      $rootScope.$broadcast('user.logout');
      $state.go('login');
      $window.location.reload();
    };

    self.setAuthenticated = function setAuthenticated(authToken) {
      self.removeToken();
      if (authToken) {
        self.setToken(authToken);
      }
    };

    self.isAuthenticated = function isAuthenticated() {
      return $q(function (resolve) {
        checkTokenExpiry();
        return resolve(!!self.getToken());
      });
    };

    self.showLoginToast = function showLoginToast() {
      toastr.success('Sikeres bejelentkezés!');
    };

    /**
     * Returns whether the token has already expired.
     *
     * @returns {boolean} Whether the token has already expired
     */
    function isTokenAlreadyExpired() {
      var token = self.getToken()
        , expiresAt = moment(jwtHelper.getTokenExpirationDate(token))
        , currentDate = moment();

      return currentDate.isSameOrAfter(expiresAt);
    }

    /**
     * Returns whether the token is going to expire in the next 1 minute.
     *
     * @returns {boolean} Whether the token is going to expire in the next 1 minute
     */
    function isTokenGoingToExpireSoon() {
      var token = self.getToken()
        , expiresAt = moment(jwtHelper.getTokenExpirationDate(token))
        , currentDate = moment();

      return currentDate.add(10, 'minutes').isSameOrAfter(expiresAt);
    }

    /**
     * Attempts to fetch a fresh auth token from the API.
     *
     * If successful, replaces the fresh token with the old token in the store.
     */
    function getFreshToken() {
      $log.debug('Starting to fetch auth refresh token');

      $http({
        url: appConfig.api.url + 'auth/refresh',
        method: 'GET'
      }).then(function (response) {
        self.setAuthenticated(response.data.token);
        $log.debug('Successfully fetched auth refresh token');
      }).catch(function (response) {
        $log.debug('Failed to fetch auth refresh token');
        $log.debug(response);
        toastr.error('Váratlan hiba történt munkamenete frissítése közben. Biztonsági okok ' +
          'végett kérjük, ismételje meg a bejelentkezést!');
        self.logout();
      });
    }

    function checkTokenExpiry() {
      if (appConfig.debug) {
        /**
         * Let's not run in development/mock environment.
         */
        return;
      }

      /**
       * Check if we have a token. We have nothing to do here if not.
       */
      if (!self.isTokenSet()) {
        return;
      }

      if (isTokenAlreadyExpired()) {
        /**
         * Token is already expired. We're not allowed to get a refresh token for alread expired
         * tokens.
         */
        toastr.error('Az Ön munkamenete hosszú ideig fellépő inaktivitás miatt biztonsági ' +
          'okokból elévült. Kérjük, a rendszer további használata érdekében ismételje meg a ' +
          'bejelentkezést!');
        self.logout();
      } else if (isTokenGoingToExpireSoon()) {
        /**
         * Our token will expire soon (1 minute, or sooner), it's time to go fetch a fresh token.
         */
        getFreshToken();
      }
    }

    /**
     * Initializer function, which runs checkTokenExpiry() first, then sets up the timer to run
     * it every 1 minute.
     */
    self.initTokenExpiryCheck = function initTokenExpiryCheck() {
      if (appConfig.debug) {
        /**
         * Let's not run in development/mock environment.
         */
        return;
      }

      checkTokenExpiry();

      $interval(function () {
        checkTokenExpiry();
      }, 1000 * 60 * 5);
    };

    self.requestPasswordChange = function requestPasswordChange(email) {
      return $http({
        url: appConfig.api.url + 'password/email',
        method: 'POST',
        data: {email: email}
      });
    };

    self.changePassword = function changePassword(data) {
      return $http({
        url: appConfig.api.url + 'password/reset',
        method: 'POST',
        data: data
      });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:RequestPasswordChangeCtrl
   *
   * @description
   *
   */
  RequestPasswordChangeCtrl.$inject = ["FormControl", "Auth", "$q", "toastr", "$state"];
  angular
    .module('ycoAuth')
    .controller('RequestPasswordChangeCtrl', RequestPasswordChangeCtrl);

  function RequestPasswordChangeCtrl(FormControl, Auth, $q, toastr, $state) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.isSubmitted = false;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function () {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      Auth.requestPasswordChange(vm.email).then(deferred.resolve, deferred.reject);

      formControl
        .process(deferred.promise)
        .then(function () {
          vm.isSubmitted = true;
          toastr.success('Jelszó igénylését elküldtük az e-mail címére.');
          $state.go('login');
        })
        .catch(function (response) {
          toastr.error('Vártalan hiba történt jelszóváltoztatási kérelme feldolgozása közben. Kérjük, próbálja később.');
          $state.go('login');
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:LogoutCtrl
   *
   * @description
   *
   */
  LogoutCtrl.$inject = ["Auth"];
  angular
    .module('ycoAuth')
    .controller('LogoutCtrl', LogoutCtrl);

  function LogoutCtrl(Auth) {
    Auth.logout();
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:LoginCtrl
   *
   * @description
   *
   */
  LoginCtrl.$inject = ["FormControl", "$state", "Auth", "toastr"];
  angular
    .module('ycoAuth')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl(FormControl, $state, Auth, toastr) {
    var vm = this
      , formControl = new FormControl();

    vm.loginForm = {};
    vm.isLoginError = false;

    vm.user = {
      email: '',
      password: ''
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.showSystemErrorToast = function showSystemErrorToast() {
      toastr.error('Rendszerhiba történt a bejelentkezés során. Kérjük, próbálja újra később!');
    };

    vm.submit = function ($event) {
      $event.preventDefault();
      vm.isLoginError = false;

      if (vm.loginForm.$invalid) {
        toastr.error('Az űrlap hibásan kitöltött mezőket tartalmaz. Kérjük, ellenőrizze az adatokat!');
        return;
      }

      formControl
        .process(Auth.login(vm.user))
        .then(function () {
          $state.go('dashboard');
        })
        .catch(function (data) {
          vm.isLoginError = true;
          if (data.status === 400) {
            toastr.error('A megadott email cím vagy jelszó hibás.');
          } else {
            vm.showSystemErrorToast();
          }
        });
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:ChangePasswordCtrl
   *
   * @description
   *
   */
  ChangePasswordCtrl.$inject = ["FormControl", "Auth", "$q", "$stateParams", "$state", "toastr"];
  angular
    .module('ycoAuth')
    .controller('ChangePasswordCtrl', ChangePasswordCtrl);

  function ChangePasswordCtrl(FormControl, Auth, $q, $stateParams, $state, toastr) {
    var vm = this
      , formControl = new FormControl()
      , token = $stateParams.token;

    vm.form = {};

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function () {
      var deferred = $q.defer()
        , options;

      if (vm.form.$invalid) {
        return;
      }

      options = {
        token: token,
        password: vm.password,
        passwordConfirmation: vm.passwordConfirmation
      };

      Auth.changePassword(options).then(deferred.resolve, deferred.reject);

      formControl
        .process(deferred.promise)
        .then(function () {
          toastr.success('Jelszavát sikeresen megváltoztattuk.');
          $state.go('login');
        })
        .catch(function (response) {
          if (response.status === 404) {
            toastr.error('Jelszóváltoztatási azonosítója elévült vagy érvénytelen. Kérjük, igényeljen új jelszóváltoztatási azonosítót az Elfelejtett jelszó oldalon.');
            $state.go('login');
          } else {
            toastr.error('Vártalan hiba történt jelszóváltoztatási kérelme feldolgozása közben. Kérjük, próbálja később.');
          }
        });
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name moment
   * @description
   *
   */
  angular
    .module('moment', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name moment.filter:moment
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  momentFilter.$inject = ["moment"];
  angular
    .module('moment')
    .filter('moment', momentFilter);

  function momentFilter(moment) {
    return function (input, format) {
      return moment(input).format(format);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name moment.filter:fromNow
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  fromNow.$inject = ["moment"];
  angular
    .module('moment')
    .filter('fromNow', fromNow);

  function fromNow(moment) {
    return function (input) {
      return moment(input).fromNow();
    };
  }
}());

/* global moment */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name moment.constant:moment
   *
   * @description
   *
   */
  angular
    .module('moment')
    .constant('moment', moment);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name lodash
   * @description
   *
   */
  angular
    .module('lodash', []);
}());

/* global _ */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lodash.constant:lodash
   *
   * @description
   *
   */
  angular
    .module('lodash')
    .constant('_', _);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoUser')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/dashboard',
        templateUrl: 'yco-user/views/dashboard.tpl.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          statistics: ["Statistics", function (Statistics) {
            return Statistics.getBasicStatistics().$promise;
          }],
          mentorStatistics: ["Statistics", function (Statistics) {
            return Statistics.getMentorStatistics().$promise;
          }],
          unreadThreads: ["Thread", function (Thread) {
            return Thread.getUnreadThreads().$promise;
          }],
          newEnquiries: ["Enquiry", function (Enquiry) {
            return Enquiry.getEnquiries({
              status: 'newEnquiry,waitingForApprovement',
              limit: -1
            }).$promise;
          }],
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }],
          userRoles: ["UserRole", function (UserRole) {
            return UserRole.getUserRoles();
          }]
        }
      })
      .state('viewUser', {
        url: '/users/view/:userId',
        templateUrl: 'yco-user/views/view-user.tpl.html',
        controller: 'ViewUserCtrl',
        controllerAs: 'viewUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: ["$stateParams", "LoggedUser", "User", function ($stateParams, LoggedUser, User) {
            var user;

            if ($stateParams.userId === 'me') {
              user = LoggedUser.getUser().$promise;
            } else {
              user = User.get({id: $stateParams.userId}).$promise;
            }

            return user;
          }],
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('editUser', {
        url: '/users/edit/:userId',
        templateUrl: 'yco-user/views/edit-user.tpl.html',
        controller: 'EditUserCtrl',
        controllerAs: 'editUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: ["$stateParams", "LoggedUser", "User", function ($stateParams, LoggedUser, User) {
            return User.get({id: $stateParams.userId, includes: 'mentor'}).$promise;
          }],
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('listUsers', {
        url: '/users/list/:role',
        templateUrl: 'yco-user/views/list-users.tpl.html',
        controller: 'ListUsersCtrl',
        controllerAs: 'listUsers',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('newUser', {
        url: '/users/new/:role',
        templateUrl: 'yco-user/views/new-user.tpl.html',
        controller: 'NewUserCtrl',
        controllerAs: 'newUser',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          currentUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('newUserWithChild', {
        url: '/users/newWithChild',
        templateUrl: 'yco-user/views/new-user-with-child.tpl.html',
        controller: 'NewUserWithChildCtrl',
        controllerAs: 'newUserWithChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          currentUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoPublication')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listPublications', {
        url: '/publications',
        templateUrl: 'yco-publication/views/list-publications.tpl.html',
        controller: 'ListPublicationsCtrl',
        controllerAs: 'listPublications',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('newPublication', {
        url: '/publications/new',
        templateUrl: 'yco-publication/views/new-publication.tpl.html',
        controller: 'NewPublicationCtrl',
        controllerAs: 'newPublication',
        data: {
          authenticatedOnly: true
        }
      })
      .state('editPublication', {
        url: '/publications/:publicationId/edit',
        templateUrl: 'yco-publication/views/edit-publication.tpl.html',
        controller: 'EditPublicationCtrl',
        controllerAs: 'editPublication',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          publication: ["$stateParams", "Publication", function ($stateParams, Publication) {
            var publicationId = $stateParams.publicationId;

            return Publication.get({id: publicationId}).$promise;
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoMessage')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('messages', {
        url: '/messages/:userId/:threadId',
        templateUrl: 'yco-message/views/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'messages',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          initialThreads: ["Thread", function (Thread) {
            return Thread.getThreads().$promise;
          }],
          initialMessages: ["Thread", "$stateParams", "initialThreads", "_", "$q", function (Thread, $stateParams, initialThreads, _, $q) {
            return $q(function (resolve) {
              if (!_.has(initialThreads, 'data[0]')) {
                return resolve({data: []});
              }

              return Thread.getMessagesByThreadId({
                id: $stateParams.threadId || initialThreads.data[0].id
              }).$promise
                .then(function (response) {
                  return resolve(response);
                });
            });
          }],
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }],
          recipient: ["$stateParams", "User", function ($stateParams, User) {
            if (!$stateParams.userId) {
              return;
            }
            return User.get({id: $stateParams.userId}).$promise;
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoEvent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listEvents', {
        url: '/events/list',
        templateUrl: 'yco-event/views/list-events.tpl.html',
        controller: 'ListEventsCtrl',
        controllerAs: 'listEvents',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('editEvent', {
        url: '/events/edit/:eventId',
        templateUrl: 'yco-event/views/edit-event.tpl.html',
        controller: 'EditEventCtrl',
        controllerAs: 'editEvent',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          event: ["$stateParams", "Event", function ($stateParams, Event) {
            return Event.getEventWithParticipants({id: $stateParams.eventId}).$promise;
          }]
        }
      })
      .state('newEvent', {
        url: '/events/new',
        templateUrl: 'yco-event/views/new-event.tpl.html',
        controller: 'NewEventCtrl',
        controllerAs: 'newEvent',
        data: {
          authenticatedOnly: true
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoEnquiry')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listEnquiries', {
        url: '/enquiries/list',
        templateUrl: 'yco-enquiry/views/list-enquiries.tpl.html',
        controller: 'ListEnquiriesCtrl',
        controllerAs: 'listEnquiries',
        data: {
          authenticatedOnly: true
        }
      })
      .state('editEnquiry', {
        url: '/enquiries/edit/:enquiryId',
        templateUrl: 'yco-enquiry/views/edit-enquiry.tpl.html',
        controller: 'EditEnquiryCtrl',
        controllerAs: 'editEnquiry',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          enquiry: ["$stateParams", "LoggedUser", "Enquiry", function ($stateParams, LoggedUser, Enquiry) {
            return Enquiry.getEnquiry({id: $stateParams.enquiryId}).$promise;
          }],
          loggedUser: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoChild')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('listChildren', {
        url: '/children/list',
        templateUrl: 'yco-child/views/list-children.tpl.html',
        controller: 'ListChildrenCtrl',
        controllerAs: 'listChildren',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('newChild', {
        url: '/children/new',
        templateUrl: 'yco-child/views/new-child.tpl.html',
        controller: 'NewChildCtrl',
        controllerAs: 'newChild',
        data: {
          authenticatedOnly: true
        }
      })
      .state('viewChild', {
        url: '/children/view/:childId',
        templateUrl: 'yco-child/views/view-child.tpl.html',
        controller: 'ViewChildCtrl',
        controllerAs: 'viewChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          child: ["Child", "$stateParams", function (Child, $stateParams) {
            return Child.get({
              id: $stateParams.childId,
              includes: 'parent,fields'
            }).$promise;
          }],
          latestActivities: ["Child", "$stateParams", function (Child, $stateParams) {
            return Child.getLatestActivities({id: $stateParams.childId}).$promise;
          }],
          participants: ["Child", "$stateParams", function (Child, $stateParams) {
            return Child.getParticipants({id: $stateParams.childId,}).$promise;
          }],
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      })
      .state('editChild', {
        url: '/children/edit/:childId',
        templateUrl: 'yco-child/views/edit-child.tpl.html',
        controller: 'EditChildCtrl',
        controllerAs: 'editChild',
        data: {
          authenticatedOnly: true
        },
        resolve: {
          child: ["Child", "$stateParams", function (Child, $stateParams) {
            return Child.get({
              cache: false,
              id: $stateParams.childId,
              includes: 'parent,fields'
            }).$promise;
          }],
          user: ["LoggedUser", function (LoggedUser) {
            return LoggedUser.getUser().$promise;
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('ycoAuth')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/login',
        templateUrl: 'yco-auth/views/login.tpl.html',
        controller: 'LoginCtrl',
        controllerAs: 'login',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      })
      .state('logout', {
        url: '/logout',
        controller: 'LogoutCtrl',
        controllerAs: 'logout',
        data: {
          authenticatedOnly: true
        }
      })
      .state('requestPasswordChange', {
        url: '/request-password-change',
        templateUrl: 'yco-auth/views/request-password-change.tpl.html',
        controller: 'RequestPasswordChangeCtrl',
        controllerAs: 'requestPasswordChange',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      })
      .state('changePassword', {
        url: '/change-password/:token',
        templateUrl: 'yco-auth/views/change-password.tpl.html',
        controller: 'ChangePasswordCtrl',
        controllerAs: 'changePassword',
        data: {
          notForAuthenticated: true,
          skipNavigation: true
        }
      });
  }
}());

(function () {
  'use strict';

  angular
    .module('koraszulottmentorExtranet');
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name oneui
   * @description
   *
   */
  angular
    .module('oneui', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('oneui')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('oneui', {
        url: '/oneui',
        templateUrl: 'oneui/views/oneui.tpl.html',
        controller: 'OneuiCtrl',
        controllerAs: 'oneui'
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name koraszulottmentorExtranet.filter:isEmpty
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  isEmpty.$inject = ["_"];
  angular
    .module('koraszulottmentorExtranet')
    .filter('isEmpty', isEmpty);

  function isEmpty(_) {
    return function (input) {
      return _.isEmpty(input);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name koraszulottmentorExtranet.controller:AppCtrl
   *
   * @description
   *
   */
  AppCtrl.$inject = ["$scope", "Auth", "appConfig"];
  angular
    .module('koraszulottmentorExtranet')
    .controller('AppCtrl', AppCtrl);

  function AppCtrl($scope, Auth, appConfig) {
    var vm = this;

    vm.appConfig = appConfig;

    Auth.initTokenExpiryCheck();

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    function onStateChangeSuccess(event, toState) {
      vm.hasNavigation = toState.data && toState.data.skipNavigation ? !toState.data.skipNavigation : true;
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name koraszulottmentorExtranet.constant:datatablesI18n
   *
   * @description
   *
   */
  angular
    .module('koraszulottmentorExtranet')
    .constant('datatablesI18n', {
      sEmptyTable: 'Nincs rendelkezésre álló adat',
      sInfo: 'Találatok: _START_ - _END_ Összesen: _TOTAL_',
      sInfoEmpty: 'Nulla találat',
      sInfoFiltered: '(_MAX_ összes rekord közül szűrve)',
      sInfoPostFix: '',
      sInfoThousands: ' ',
      sLengthMenu: '_MENU_ találat oldalanként',
      sLoadingRecords: 'Betöltés...',
      sProcessing: 'Feldolgozás...',
      sSearch: 'Keresés:',
      sZeroRecords: 'Nincs a keresésnek megfelelő találat',
      oPaginate: {
        sFirst: 'Első',
        sPrevious: 'Előző',
        sNext: 'Következő',
        sLast: 'Utolsó'
      },
      oAria: {
        sSortAscending: ': aktiválja a növekvő rendezéshez',
        sSortDescending: ': aktiválja a csökkenő rendezéshez'
      }
    });
}());

(function () {
  'use strict';

  config.$inject = ["$urlRouterProvider"];
  angular
    .module('koraszulottmentorExtranet')
    .config(config);

  function config($urlRouterProvider) {
    $urlRouterProvider.otherwise('/login');
  }
}());

(function () {
  'use strict';

  configDebugLevel.$inject = ["$compileProvider", "$logProvider", "appConfig", "$analyticsProvider"];
  configApi.$inject = ["appConfig", "ycoResourceProvider"];
  configEnvironment.$inject = ["$httpProvider", "appConfig"];
  configJwtHelper.$inject = ["jwtInterceptorProvider", "$httpProvider", "jwtOptionsProvider", "appConfig"];
  configLoadingBar.$inject = ["cfpLoadingBarProvider"];
  configSelects.$inject = ["uiSelectConfig"];
  configTooltips.$inject = ["$uibTooltipProvider"];
  configToasts.$inject = ["toastrConfig"];
  angular
    .module('koraszulottmentorExtranet')
    .config(configDebugLevel)
    .config(configApi)
    .config(configEnvironment)
    .config(configJwtHelper)
    .config(configLoadingBar)
    .config(configSelects)
    .config(configTooltips)
    .config(configToasts);

  function configDebugLevel($compileProvider, $logProvider, appConfig, $analyticsProvider) {
    $analyticsProvider.developerMode(appConfig.debug);
    $compileProvider.debugInfoEnabled(appConfig.debug);
    $logProvider.debugEnabled(appConfig.debug);
  }

  function configApi(appConfig, ycoResourceProvider) {
    ycoResourceProvider.setBaseUrl(appConfig.api.url);
  }

  function configEnvironment($httpProvider, appConfig) {
    if (appConfig.cors) {
      $httpProvider.defaults.withCredentials = true;
    }
  }

  function configJwtHelper(jwtInterceptorProvider, $httpProvider, jwtOptionsProvider, appConfig) {
    'ngInject';
    jwtInterceptorProvider.tokenGetter = function (store) {
      return store.get(appConfig.authTokenKey);
    };

    /* eslint-disable angular/ng_module_getter */
    /* eslint-disable angular/ng_di */
    jwtOptionsProvider.config({
      unauthenticatedRedirector: ['$state', function ($state) {
        $state.go('login');
      }],
      whiteListedDomains: appConfig.api.url
    });
    /* eslint-enable angular/ng_di */
    /* eslint-enable angular/ng_module_getter */

    $httpProvider.interceptors.push('jwtInterceptor');
  }

  function configLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
  }

  function configSelects(uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
  }

  function configTooltips($uibTooltipProvider) {
    $uibTooltipProvider.options({appendToBody: true});
  }

  function configToasts(toastrConfig) {
    angular.extend(toastrConfig, {
      extendedTimeOut: 5000,
      positionClass: 'toast-bottom-right',
      timeOut: 5000
    });
  }
}());
