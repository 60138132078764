(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoNavigation.service:YcoDialog
   *
   * @description
   *
   */
  angular
    .module('ycoNavigation')
    .service('YcoDialog', YcoDialog);

  function YcoDialog($uibModal, _) {
    var customDefaultOptions
      , modalDefaultOptions;

    customDefaultOptions = {
      title: '',
      content: '',
      closeButtonText: 'Mégse',
      actionButtonText: 'OK'
    };

    modalDefaultOptions = {
      templateUrl: 'yco-navigation/views/yco-dialog.tpl.html'
    };

    this.show = function show(userCustomOptions, userModalOptions) {
      var customOptions = _.assign({}, customDefaultOptions, userCustomOptions)
        , modalOptions = _.assign({}, modalDefaultOptions, userModalOptions);

      if (!modalOptions.controller) {
        modalOptions.controller = function ($scope, $uibModalInstance) {
          $scope.modal = _.assign({}, customOptions);

          $scope.modal.ok = function (result) {
            $uibModalInstance.close(result);
          };

          $scope.modal.close = function () {
            $uibModalInstance.dismiss('cancel');
          };
        };
      }

      return $uibModal.open(modalOptions).result;
    };
  }
}());
