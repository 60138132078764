(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:RequestPasswordChangeCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoAuth')
    .controller('RequestPasswordChangeCtrl', RequestPasswordChangeCtrl);

  function RequestPasswordChangeCtrl(FormControl, Auth, $q, toastr, $state) {
    var vm = this
      , formControl = new FormControl();

    vm.form = {};
    vm.isSubmitted = false;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function () {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      Auth.requestPasswordChange(vm.email).then(deferred.resolve, deferred.reject);

      formControl
        .process(deferred.promise)
        .then(function () {
          vm.isSubmitted = true;
          toastr.success('Jelszó igénylését elküldtük az e-mail címére.');
          $state.go('login');
        })
        .catch(function (response) {
          toastr.error('Vártalan hiba történt jelszóváltoztatási kérelme feldolgozása közben. Kérjük, próbálja később.');
          $state.go('login');
        });
    };
  }
}());
