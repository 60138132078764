<yco-page-header>Regisztrációk kezelése</yco-page-header>

<div class="content ng-scope">
  <div class="block">
    <div class="block-header">
      <h3 class="block-title">Regisztrációk</h3>
    </div>
    <div class="block-content">
      <table class="table table-bordered table-striped" datatable dt-options="listEnquiries.dtOptions"
             dt-columns="listEnquiries.dtColumns"></table>
    </div>
  </div>
</div>
