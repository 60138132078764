(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:ycoChildActivity
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <yco-child-activity></yco-child-activity>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('ycoChildActivity', ycoChildActivity);

  function ycoChildActivity() {
    return {
      restrict: 'E',
      scope: {
        activity: '='
      },
      templateUrl: 'yco-child/directives/yco-child-activity-directive.tpl.html',
      replace: true,
      controllerAs: 'ycoChildActivity',
      controller: function ($scope, childActivityTypes, childBasicFields, _) {
        var vm = this;

        vm.activity = $scope.activity;
        vm.activityType = vm.activity.type === 'dataChange' && vm.activity.staffChange ? childActivityTypes.STAFF_CHANGE : _.find(childActivityTypes, 'name', vm.activity.type);

        vm.getActivityLabel = function (change) {
          return _.find(childBasicFields, 'name', change) ? _.find(childBasicFields, 'name', change).label : '';
        }
      }
    };
  }
}());
