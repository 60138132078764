(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoAuth.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoAuth')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl(FormControl, $state, Auth, toastr) {
    var vm = this
      , formControl = new FormControl();

    vm.loginForm = {};
    vm.isLoginError = false;

    vm.user = {
      email: '',
      password: ''
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.showSystemErrorToast = function showSystemErrorToast() {
      toastr.error('Rendszerhiba történt a bejelentkezés során. Kérjük, próbálja újra később!');
    };

    vm.submit = function ($event) {
      $event.preventDefault();
      vm.isLoginError = false;

      if (vm.loginForm.$invalid) {
        toastr.error('Az űrlap hibásan kitöltött mezőket tartalmaz. Kérjük, ellenőrizze az adatokat!');
        return;
      }

      formControl
        .process(Auth.login(vm.user))
        .then(function () {
          $state.go('dashboard');
        })
        .catch(function (data) {
          vm.isLoginError = true;
          if (data.status === 400) {
            toastr.error('A megadott email cím vagy jelszó hibás.');
          } else {
            vm.showSystemErrorToast();
          }
        });
    };
  }
}());
