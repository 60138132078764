(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:NewEventCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEvent')
    .controller('NewEventCtrl', NewEventCtrl);

  function NewEventCtrl(FormControl, Event, $q, $state, UserRole, moment, $scope) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('Az esemény sikeresen létrejött!');

    vm.event = new Event();
    vm.availableRoles = UserRole.getUserRoles();

    vm.dateTime = new Date();
    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.event.date = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    vm.event.canApply = vm.event.canApply ? 1 : 0;
    vm.event.public = 1;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listEvents');
        });

      vm.event.$save(deferred.resolve, deferred.reject);
    };
  }
}());
