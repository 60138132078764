(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name moment.filter:fromNow
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('moment')
    .filter('fromNow', fromNow);

  function fromNow(moment) {
    return function (input) {
      return moment(input).fromNow();
    };
  }
}());
