(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.constant:childActivityTypes
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .constant('childActivityTypes', {
      TEXTUAL: {
        name: 'textEntry',
        label: 'Szöveges bejegyzés',
        class: 'fa fa-pencil bg-amethyst'
      },
      DATA_CHANGE: {
        name: 'dataChange',
        label: 'Adatmódosítás',
        class: 'fa fa-file-text bg-gray-dark'
      },
      STAFF_CHANGE: {
        name: 'staffChange',
        label: 'Szakmai stáb módosult',
        class: 'fa fa-user-md bg-flat'
      }
    });
}());
