<div class="yco-auth">
  <div class="text-center">
    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>
  </div>

  <div class="content overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-md-offset-3">
          <div class="block block-themed">
            <div class="block-header bg-primary">
              <h3 class="block-title">Bejelentkezés</h3>
            </div>
            <div class="block-content block-content-full block-content-narrow">
              <form class="push-30-t push-50" name="login.loginForm" ng-submit="login.submit($event)" novalidate>
                <div class="form-group" ng-if="login.isLoginError">
                  <div class="alert alert-danger">
                    <strong>Sikertelen bejelentkezés!</strong> A megadott email cím vagy jelszó hibás.
                  </div>
                </div>

                <yco-input-container>
                  <label>Email</label>
                  <input class="form-control" type="email" name="email" ng-model="login.user.email" required>
                  <yco-messages form="login.loginForm" server="login.getServerValidation()"
                                field-name="email"></yco-messages>
                </yco-input-container>

                <yco-input-container>
                  <label>Jelszó</label>
                  <input class="form-control" type="password" name="password" ng-model="login.user.password" required
                         ng-minlength="6">
                  <yco-messages form="login.loginForm" server="login.getServerValidation()"
                                field-name="password"></yco-messages>
                </yco-input-container>

                <div class="form-group">
                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="login.isProcessing()">
                    <i class="fa fa-sign-in push-5-r"></i>
                    Bejelentkezés
                  </yco-button>

                  <a class="btn pull-right" ui-sref="requestPasswordChange">Elfelejtett jelszó?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
