(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:ListParticipantsCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoMessage')
    .controller('ListParticipantsCtrl', ListParticipantsCtrl);

  function ListParticipantsCtrl($uibModalInstance, thread, Thread, _, $rootScope) {
    var vm = this;

    vm.isProcessing = false;

    vm.participants = _.map(thread.participants.data, function (participant) {
      return participant.user.data;
    });

    vm.remove = function remove($index) {
      var removedParticipant;

      vm.isProcessing = true;

      removedParticipant = vm.participants.splice($index, 1);

      Thread.removeParticipantFromThread({
        threadId: thread.id,
        userId: removedParticipant[0].id
      })
        .$promise
        .then(function () {
          vm.isProcessing = false;
          $rootScope.$broadcast('participantsChanged');
        });
    };

    vm.close = function close() {
      $uibModalInstance.close();
    };
  }
}());
