<div class="yco-auth">
  <div class="text-center">
    <a ui-sref="login"><img class="yco-auth__logo" src="images/logo.svg" alt="Koraszülött Mentor Program"></a>
  </div>

  <div class="content overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-md-offset-3">
          <div class="block block-themed">
            <div class="block-header bg-primary">
              <h3 class="block-title">Elfelejtett jelszó</h3>
            </div>
            <div class="block-content block-content-full block-content-narrow">
              <form class="push-30-t push-50"
                    name="requestPasswordChange.form"
                    ng-submit="requestPasswordChange.submit()"
                    novalidate
                    ng-if="!requestPasswordChange.isSubmitted">
                <p>Amennyiben elfelejtette jelszavát, az alábbi űrlapon lehetősége van újat igényelni. Kérjük, írja be
                  fiókjához tartozó email címét, majd az űrlap elküldése után kövesse a levélben kapott
                  instrukciókat.</p>

                <yco-input-container>
                  <label>Email</label>
                  <input class="form-control" type="email" name="email" ng-model="requestPasswordChange.email" required>
                  <yco-messages form="requestPasswordChange.form" server="requestPasswordChange.getServerValidation()" field-name="email"></yco-messages>
                </yco-input-container>

                <div class="form-group text-center">
                  <yco-button class="btn btn-primary" type="submit" yco-is-processing="requestPasswordChange.isProcessing()">
                    Elküldés
                  </yco-button>
                </div>
              </form>

              <p ng-if="requestPasswordChange.isSubmitted" class="push-50 push-50-t">Rendszerünk sikeresen fogadta jelszó
                változtatási kérelmét. Ellenőrizze postafiókját, majd kövesse a levélben kapott instrukciókat.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
