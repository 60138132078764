<form name="editChild.form" ng-submit="editChild.submit()" novalidate>
  <div class="content bg-image" style="background-image: url('images/user-bg.jpg');">
    <div class="push-50-t push-15 clearfix">
      <div class="push-15-r pull-left">
        <img class="img-avatar img-avatar-thumb" ng-src="{{editChild.child.image}}">
      </div>
      <h1 class="h2 text-white push-5-t">{{editChild.child.lastName}} {{editChild.child.firstName}}</h1>

      <h2 class="h5 text-white-op">Szülő: {{editChild.child.parent.data.lastName}}
        {{editChild.child.parent.data.firstName}}</h2>
    </div>
  </div>

  <div class="content">
    <div class="block block-themed">
      <div class="block-content">
        <div class="push-20">
          <button class="btn btn-danger pull-right"
                  type="button"
                  ng-if="editChild.loggedUser.roleId === editChild.availableRoles.ADMIN.id"
                  ng-click="editChild.showDeleteDialog()">
            <i class="fa fa-trash push-5-r"></i>Adatlap törlése
          </button>

          <yco-button class="btn btn-success"
                      type="submit"
                      yco-is-processing="editChild.isProcessing()">
            <i class="fa fa-save push-5-r"></i>Mentés
          </yco-button>

          <button class="btn btn-default"
                  type="button"
                  ui-sref="viewChild({childId: editChild.child.id})">
            Vissza az adatlapra
          </button>
        </div>
      </div>
    </div>

    <div class="block block-themed">
      <div class="block-header bg-flat">
        <h3 class="block-title">
          Személyes adatok
        </h3>
      </div>
      <div class="block-content clearfix">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Vezetéknév</label>
              <input class="form-control" type="text" name="lastName" ng-model="editChild.child.lastName" required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="lastName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Keresztnév</label>
              <input class="form-control" type="text" name="firstName" ng-model="editChild.child.firstName" required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="firstName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Anyja neve</label>
              <input class="form-control" type="text" name="motherName" ng-model="editChild.child.motherName" required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="motherName"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Születési dátum (éééé-hh-nn)</label>

              <div class="input-group date">
                <input class="form-control" type="text"
                       name="dateOfBirth"
                       ng-model="editChild.dateOfBirth"
                       is-open="editChild.popupDateIsOpen"
                       uib-datepicker-popup="{{editChild.dateFormat}}"
                       datepicker-options="editChild.datePickerOptions"
                       close-text="Bezárás"
                       placeholder="Válasszon dátumot">
                <span class="input-group-addon" ng-click="editChild.openDatePopup()">
                      <span class="fa fa-calendar"></span>
                    </span>
              </div>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="dateOfBirth"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Lakcím</label>
              <input class="form-control" type="text" name="address" ng-model="editChild.child.address" required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="address"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Születési hét</label>
              <input class="form-control" type="text" name="birthWeek" ng-model="editChild.child.birthWeek" required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="birthWeek"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Születési súly (g)</label>
              <input class="form-control" type="text" name="birthWeight" ng-model="editChild.child.birthWeight"
                     required>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="birthWeight"></yco-messages>
            </yco-input-container>
          </div>

          <div class="col-md-4 col-lg-3">
            <yco-input-container>
              <label>Szülő</label>
              <ui-select append-to-body="true" class="yco-child__autocomplete__select" name="parentId"
                         ng-model="editChild.child.parentId"
                         required>
                <ui-select-match allow-clear="false">
                  {{$select.selected.lastName}} {{$select.selected.firstName}}
                </ui-select-match>
                <ui-select-choices repeat="user.id as user in editChild.possibleParents track by $index"
                                   refresh="editChild.refreshPossibleParents($select.search)"
                                   refresh-delay="200"
                                   minimum-input-length="3">
                  <div class="inner-push-5 inner-push-5-t media">
                    <div class="media-left">
                      <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                           alt="{{user.lastName}} {{user.firstName}}">
                    </div>
                    <div class="media-right">
                      <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                      <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                    </div>
                    <div class="yco-child__autocomplete__suggestion__email">
                      <span>{{user.email}}</span>
                    </div>
                  </div>
                </ui-select-choices>
              </ui-select>
              <yco-messages form="editChild.form" server="editChild.getServerValidation()"
                            field-name="parentId"></yco-messages>
            </yco-input-container>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="block block-themed" ng-repeat="fieldGroup in editChild.child.fields.data.groups">
        <div class="block-header bg-flat">
          <h3 class="block-title">
            {{::fieldGroup.name}}
          </h3>
        </div>
        <div class="block-content clearfix">
          <div class="row" ng-if="fieldGroup.fields.length">
            <div
              class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-4 col-lg-3 clearfix"
              ng-repeat="field in fieldGroup.fields track by field.id"
              ng-class="{'yco-child__field-group__wrapper--child': !!field.parent}"
              ng-if="editChild.checkConditionalDisplay($index, fieldGroup.fields)">
              <div ng-switch="field.type">
                <text-field form="editChild.form"
                            field="field"
                            container="fieldGroup.fields"
                            ng-switch-default
                            readonly="false"></text-field>
                <number-field form="editChild.form"
                              field="field"
                              container="fieldGroup.fields"
                              ng-switch-when="number"
                              readonly="false"></number-field>
                <select-field form="editChild.form"
                              field="field"
                              container="fieldGroup.fields"
                              ng-switch-when="select"
                              readonly="false"></select-field>
                <select-field form="editChild.form"
                              field="field"
                              container="fieldGroup.fields"
                              ng-switch-when="multiselect"
                              readonly="false"></select-field>
                <date-field form="editChild.form"
                            field="field"
                            container="fieldGroup.fields"
                            ng-switch-when="date"
                            readonly="false"></date-field>
                <bno-field form="editChild.form"
                           field="field"
                           container="fieldGroup.fields"
                           ng-switch-when="bno"
                           readonly="false"></bno-field>
                <boolean-field form="editChild.form"
                               field="field"
                               container="fieldGroup.fields"
                               ng-switch-when="boolean"
                               readonly="false"></boolean-field>
              </div>
            </div>
          </div>

          <div class="yco-child__field-group__wrapper yco-child__field-group__wrapper--has-tabs"
               ng-if="!(fieldGroup.tabs | isEmpty)">
            <uib-tabset>
              <uib-tab ng-repeat="(tabKey, tab) in fieldGroup.tabs"
                       index="$index"
                       heading="{{::tab.name}}"
                       classes="yco-child__field-group__tab {{editChild.hasMissingField(tab) ? 'yco-child__field-group__tab--invalid' : 'yco-child__field-group__tab--valid' }}">
                <div class="row inner-push-20-t">
                  <div
                    class="yco-child__field-group__wrapper yco-child__field-group__wrapper--simple col-md-4 col-lg-3 clearfix"
                    ng-repeat="field in tab.fields track by field.id"
                    ng-class="{'yco-child__field-group__wrapper--child': !!field.parent}"
                    ng-if="editChild.checkConditionalDisplay($index, tab.fields)">
                    <div ng-switch="field.type">
                      <text-field form="editChild.form"
                                  field="field"
                                  container="tab.fields"
                                  ng-switch-default
                                  readonly="false"></text-field>
                      <number-field form="editChild.form"
                                    field="field"
                                    container="tab.fields"
                                    ng-switch-when="number"
                                    readonly="false"></number-field>
                      <select-field form="editChild.form"
                                    field="field"
                                    container="tab.fields"
                                    ng-switch-when="select"
                                    readonly="false"></select-field>
                      <select-field form="editChild.form"
                                    field="field"
                                    container="tab.fields"
                                    ng-switch-when="multiselect"
                                    readonly="false"></select-field>
                      <date-field form="editChild.form"
                                  field="field"
                                  container="tab.fields"
                                  ng-switch-when="date"
                                  readonly="false"></date-field>
                      <bno-field form="editChild.form"
                                 field="field"
                                 container="tab.fields"
                                 ng-switch-when="bno"
                                 readonly="false"></bno-field>
                      <boolean-field form="editChild.form"
                                     field="field"
                                     container="tab.fields"
                                     ng-switch-when="boolean"
                                     readonly="false"></boolean-field>
                    </div>
                  </div>
                </div>
              </uib-tab>
            </uib-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">

    <div class="block block-themed">
      <div class="block-header bg-flat">
        <h3 class="block-title">
          Dokumentumok
        </h3>
      </div>
      <div class="yco-child__documents block-content clearfix">
        <div class="row">
          <div class="col-sm-4">
            <div
              flow-init="editChild.flowOptions"
              flow-file-added="!!{jpg:1,jpeg:1,png:1,gif:1,pdf:1,xls:1,xlsx:1,doc:1,docx:1,ppt:1,pptx:1,zip:1}[$file.getExtension()]"
              flow-files-submitted="editChild.sendToAPI($file, $event, $flow)"
              flow-file-error="editChild.handleAPIErrors($file, $message, $flow)"
              flow-file-success="editChild.handleAPISuccess($file, $message, $flow)"
            >
              <div class="push-10-t push-10 position-relative" ng-if="editChild.hasFile">
                <button class="btn btn-rounded btn-danger" type="button"
                        ng-click="editChild.cancelFile($flow)"><i class="fa fa-times"></i></button>
                <img ng-if="editChild.isPublicationFileImage($flow.files[0])" class="image-responsive"
                     flow-img="$flow.files[0]">
                <span
                  ng-if="!editChild.isPublicationFileImage($flow.files[0])">{{$flow.files[0].name}}</span>
                </br>
                <button class="btn btn-info push-15-t"
                        ng-click="editChild.attachFilesToChild($flow)"
                        type="button">
                  <i class="fa fa-upload push-5-r"></i>
                  Dokumentum hozzáadása az adatlaphoz
                </button>
              </div>
              <input ng-if="!editChild.hasFile" type="file" name="image" flow-btn>
            </div>
          </div>
          <div ng-if="editChild.childDocuments.length" class="col-sm-8">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Fájlnév</th>
                <th class="text-center">Letöltés</th>
                <th class="text-center">Törlés</th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="document in editChild.childDocuments">
                <td>{{document.name}}</td>
                <td class="text-center">
                  <a ng-if="!editPublication.isPublicationAttachmentImage()"
                     class="btn btn-info"
                     ng-href="{{document.url}}"
                     uib-tooltip="Letöltés" download target="_blank"><i class="fa fa-download"></i>
                    Letöltés
                  </a>
                </td>
                <td class="text-center">
                  <button class="btn btn-danger" type="button"
                          ng-click="editChild.deleteDocument(document.id)">
                    <i class="fa fa-trash push-5-r"></i>
                    Törlés
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="block block-themed">
      <div class="block-content">
        <div class="push-20">
          <yco-button class="btn btn-success"
                      type="submit"
                      yco-is-processing="editChild.isProcessing()">
            <i class="fa fa-save push-5-r"></i>Mentés
          </yco-button>

          <button class="btn btn-default"
                  type="button"
                  ui-sref="viewChild({childId: editChild.child.id})">
            Vissza az adatlapra
          </button>
        </div>
      </div>
    </div>
</form>
