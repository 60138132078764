(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:selectField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoChild">
   <file name="index.html">
   <select-field></select-field>
   </file>
   </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('selectField', selectField);

  function selectField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        container: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/select-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function ($scope, $rootScope, _) {
        if ($scope.field.multiselect && !_.isEmpty($scope.field.exampleField)) {
          $scope.$watch(function () {
            return $scope.field.value;
          }, function (newVal, oldVal) {
            var changedElement = _.xor(newVal, oldVal);
            $rootScope.$broadcast('child.fieldWithAddByParentValueChanged', $scope.field.id, $scope.container, _.get($scope, 'field.value.length', 0), $scope.field.exampleField, changedElement);
          });
        }
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
