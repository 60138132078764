(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoPublication.factory:Publication
   *
   * @description
   *
   */
  angular
    .module('ycoPublication')
    .factory('Publication', Publication);

  function Publication(ycoResource) {
    var url = 'publications/'
      , PublicationBase;

    PublicationBase = ycoResource('publication', url + ':id', {id: '@id'});

    return PublicationBase;
  }
}());
