(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name koraszulottmentorExtranet.constant:datatablesI18n
   *
   * @description
   *
   */
  angular
    .module('koraszulottmentorExtranet')
    .constant('datatablesI18n', {
      sEmptyTable: 'Nincs rendelkezésre álló adat',
      sInfo: 'Találatok: _START_ - _END_ Összesen: _TOTAL_',
      sInfoEmpty: 'Nulla találat',
      sInfoFiltered: '(_MAX_ összes rekord közül szűrve)',
      sInfoPostFix: '',
      sInfoThousands: ' ',
      sLengthMenu: '_MENU_ találat oldalanként',
      sLoadingRecords: 'Betöltés...',
      sProcessing: 'Feldolgozás...',
      sSearch: 'Keresés:',
      sZeroRecords: 'Nincs a keresésnek megfelelő találat',
      oPaginate: {
        sFirst: 'Első',
        sPrevious: 'Előző',
        sNext: 'Következő',
        sLast: 'Utolsó'
      },
      oAria: {
        sSortAscending: ': aktiválja a növekvő rendezéshez',
        sSortDescending: ': aktiválja a csökkenő rendezéshez'
      }
    });
}());
