(function () {
  'use strict';

  angular
    .module('oneui')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('oneui', {
        url: '/oneui',
        templateUrl: 'oneui/views/oneui.tpl.html',
        controller: 'OneuiCtrl',
        controllerAs: 'oneui'
      });
  }
}());
