(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEnquiry.controller:EditEnquiryCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEnquiry')
    .controller('EditEnquiryCtrl', EditEnquiryCtrl);

  function EditEnquiryCtrl(enquiry, FormControl, Enquiry, YcoDialog, _, $q, $uibModal, User, userRoles, $state, enquiryStatuses, loggedUser, $scope, toastr) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('A regisztrációs kérelem frissítésre került.');

    vm.enquiry = enquiry.data;
    vm.children = vm.enquiry.children.data;
    vm.activeTab = 0;
    vm.possibleMentors = [];
    vm.loggedUser = loggedUser.data;
    vm.availableRoles = userRoles;
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    if (_.has(vm.enquiry, 'mentor.data')) {
      vm.possibleMentors.push(vm.enquiry.mentor.data);
    }

    vm.enquiry.mentorId = _.get(vm.enquiry, 'mentor.data.id', null);

    $scope.$watch(function () {
      vm.canNavigate = false;
    }, vm.enquiry.mentorId);

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.setActiveTab = function setActiveTab(tab) {
      vm.activeTab = tab;
    };

    vm.isNewEnquiry = function isNewEnquiry() {
      return vm.enquiry.status === enquiryStatuses.NEW.name;
    };

    vm.showRejectEnquiryDialog = function showRejectEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem elutasításra került.');

      $uibModal.open({
        templateUrl: 'yco-enquiry/views/reject-enquiry-dialog.tpl.html',
        controllerAs: 'rejectEnquiry',
        controller: 'RejectEnquiryDialogCtrl'
      })
        .result
        .then(function (rejectionReason) {
          var deferred = $q.defer()
            , enquiryResource;

          vm.enquiry.status = enquiryStatuses.REJECTED.name;
          vm.enquiry.statusMessage = rejectionReason;

          enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

          formControl
            .process(deferred.promise)
            .then(function () {
              $state.go('listEnquiries');
            });

          enquiryResource.$update(deferred.resolve, deferred.reject);
        });
    };

    vm.showApproveEnquiryDialog = function showApproveEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem elfogadásra került.');

      YcoDialog.show({
        title: 'Regisztráció elfogadása',
        content: 'Biztosan elfogadja a regisztrációt? Elfogadás után az adatokat rögzítjük a rendszerben, amelyről a jelentkező emailes értesítést kap. Ezzel létrejön a regisztrációt kezdeményező szülő/gondviselő felhasználói fiókja a rendszerbe.',
        actionButtonText: 'Elfogadás'
      }).then(function () {
        var deferred = $q.defer()
          , enquiryResource;

        vm.enquiry.status = enquiryStatuses.APPROVED.name;

        enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

        formControl
          .process(deferred.promise)
          .then(function () {
            $state.go('listEnquiries');
          });

        enquiryResource.$update(deferred.resolve, deferred.reject);
      });
    };

    vm.showAssignEnquiryDialog = function showAssignEnquiryDialog() {
      formControl.setSuccessText('A regisztrációs kérelem hozzárendelésre került a kiválasztott mentorhoz.');

      $uibModal.open({
        templateUrl: 'yco-enquiry/views/assign-enquiry-dialog.tpl.html',
        controllerAs: 'assignEnquiry',
        controller: 'AssignEnquiryDialogCtrl'
      })
        .result
        .then(function (dialogResult) {
          var deferred = $q.defer()
            , enquiryResource;

          vm.enquiry.status = enquiryStatuses.WAITING_FOR_APPROVEMENT.name;
          vm.possibleMentors = dialogResult.possibleMentors;
          vm.enquiry.mentorId = dialogResult.mentorId;

          enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

          formControl
            .process(deferred.promise)
            .then(function () {
            })
            .catch(function (err) {
              if (err.data) {
                toastr.error(err.data.detail);
              }
            });

          enquiryResource.$update(deferred.resolve, deferred.reject);
        });
    };

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.submit = function submit() {
      var deferred = $q.defer()
        , enquiryResource = _.defaultsDeep(new Enquiry(), vm.enquiry);

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listEnquiries');
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });

      enquiryResource.$update(deferred.resolve, deferred.reject);
    };
  }
}());
