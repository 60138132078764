(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoEvent.controller:ViewEventCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoEvent')
    .controller('ViewEventCtrl', ViewEventCtrl);

  function ViewEventCtrl(event, Event, user, $uibModalInstance, _, toastr) {
    var vm = this
      , participantIds;

    vm.event = event.data;
    vm.user = user.data;

    participantIds = _.map(vm.event.participants.data, 'id');
    vm.hasUserApplied = _.includes(participantIds, vm.user.id);

    vm.apply = function apply() {
      vm.event.users = [vm.user.id];
      Event.applyForAnEvent({id: vm.event.id}, vm.event).$promise
        .then(function () {
          toastr.success('A jelentkezés sikerült.');
          $uibModalInstance.close();
        });
    };

    vm.revokeApplication = function revokeApplication() {
      Event.deleteParticipantFromAnEvent({id: vm.event.id}).$promise
        .then(function () {
          toastr.success('A jelentkezés visszavonása sikerült.');
          $uibModalInstance.close();
        });
    };

    vm.close = function close() {
      $uibModalInstance.close();
    };
  }
}());
