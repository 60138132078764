<yco-input-container ng-if="!readonly">
  <label for="{{::field.id}}" ng-class="{'yco-child__label__missing-value': field.value === null}">{{::field.name}}</label>
  <ui-select id="{{::field.id}}" name="{{::field.id}}" ng-model="field.value" ng-disabled="field.readonly"
             ng-required="field.validation.required">
    <ui-select-match>{{$select.selected.value}}</ui-select-match>
    <ui-select-choices repeat="choice.key as (key, choice) in field.values">
      <div>{{choice.value}}</div>
    </ui-select-choices>
  </ui-select>
</yco-input-container>

<div ng-if="readonly">
  <p class="font-w600 push-5">{{::field.name}}</p>

  <p>
    <span ng-if="field.prefix">{{::field.prefix}}</span>
    {{::field.values[field.value]}}
    <span ng-if="field.suffix">{{::field.suffix}}</span>
  </p>
</div>
