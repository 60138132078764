(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:NewUserWithChildCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('NewUserWithChildCtrl', NewUserWithChildCtrl);

  function NewUserWithChildCtrl(FormControl, $q, User, Child, toastr, $state, userRoles, currentUser, moment, $scope, _) {
    var vm = this
      , formControl = new FormControl();

    vm.user = new User();
    vm.currentUser = currentUser.data;
    vm.userRoles = userRoles;
    vm.canRegister = false;
    vm.showWarning = false;
    vm.isCheckEmailDisabled = false;
    vm.parentId = null;
    vm.possibleMentors = [];
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.addChildRow = function addChildRow() {
      vm.children.unshift(new Child());
    };

    vm.removeChildRow = function removeChildRow(index) {
      vm.children.splice(index, 1);
    };

    vm.children = [
      new Child()
    ];


    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      vm.user.roleId = vm.userRoles.PARENT.id;
      vm.user.status = 'approved';

      formControl
        .process(deferred.promise)
        .then(function (data) {
          vm.parentId = data.data.id;
          toastr.success('A szülő sikeresen létrejött');
          vm.createChildren();
        })
        .catch(function (data) {
          toastr.error('Nem sikerült a létrehozás. Kérjük próbálja később.');
        });

      vm.user.$save(deferred.resolve, deferred.reject);
    };

    vm.checkEmail = function checkEmail() {
      var email = vm.user.email;

      if (!email) {
        return;
      }

      vm.isProcessing = true;
      User.getUserByEmail({email: email}).$promise
        .then(function () {
          vm.showWarning = true;
        })
        .catch(function (data) {
          if (data.status === 404) {
            vm.showWarning = false;
            vm.canRegister = true;
            vm.isCheckEmailDisabled = true;
            vm.user.roleId = 6; //set role to Parent
          }
        })
        .finally(function () {
          vm.isProcessing = false;
        });
    };

    vm.isMoreButtonDisabled = function isMoreButtonDisabled() {
      if (vm.activeTab === 1 && vm.parentForm.$invalid) {
        return true;
      }
      return vm.activeTab === 2 || !vm.canRegister;
    };

    vm.goToPrevTab = function getPrevTab() {
      vm.activeTab--;
    };

    vm.goToNextTab = function getNextTab() {
      vm.activeTab++;
    };

    if (vm.currentUser.roleId === vm.userRoles.MENTOR.id) {
      vm.possibleMentors.push(vm.currentUser);

      vm.user.mentorId = vm.currentUser.id;
    }

    $scope.$watch(function () {
      if (vm.children[0].birthWeek > 36 && vm.children[0].birthWeight > 2500) {
        vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        if (vm.childrenForm) {
          vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', true);
          vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', true);
        }
      }
    }, function () {
      return _.get(vm, 'children[0].birthWeek');
    });

    $scope.$watch(function () {
      if (vm.children[0].birthWeek > 36 && vm.children[0].birthWeight > 2500) {
        vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', false);
        vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', false);
      } else {
        if (vm.childrenForm) {
          vm.childrenForm['birthWeek'].$setValidity('weigthOrWeek', true);
          vm.childrenForm['birthWeight'].$setValidity('weigthOrWeek', true);
        }
      }
    }, function () {
      return _.get(vm, 'children[0].birthWeight');
    });

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };

    vm.createChildren = function createChildren() {
      var promises = [];

      _.forEach(vm.children, function (child) {
        child.parentId = vm.parentId;
        child.dateOfBirth = moment(child.birthYear.toString()).format('YYYY-MM-DD HH:mm:ss');
        promises.push(child.$save().promise);
      });

      formControl
        .process($q.all(promises))
        .then(function () {
          toastr.success('A gyermek(ek) sikeresen létrejöttek.');
          $state.go('listUsers');
        })
        .catch(function () {
          toastr.error('Hiba történt a gyermek(ek) létrehozásakor.');
        });
    }
  }
}());
