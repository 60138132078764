(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:ViewUserCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('ViewUserCtrl', ViewUserCtrl);

  function ViewUserCtrl(user, UserRole, loggedUser) {
    var vm = this;

    vm.user = user.data;
    vm.role = UserRole.getUserRoleById(vm.user.roleId);
    vm.isOwnAccount = user.data.id === loggedUser.data.id;
  }
}());
