<div class="content bg-image overflow-hidden yco-dashboard__header ng-scope"
     style="background-image: url('images/user-bg.jpg');">
  <div class="row">
    <div class="col-sm-6">
      <div class="push-50-t push-15">
        <h1 class="h2 text-white">Áttekintés</h1>

        <h2 class="h5 text-white-op">Koraszülött Mentor Extranet</h2>
      </div>
    </div>
    <div class="col-sm-3 col-sm-offset-3">
      <div
        ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id
        || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"
        class="yco-dashboard__admin">
        <div class="yco-dashboard__admin__name">Serényi Ági</div>
        <div class="yco-dashboard__admin__role">honlap adminisztrátor</div>
        <div class="yco-dashboard__admin__tel"><a href="tel:+36304625880">06 30 824 7718</a></div>
        <div class="yco-dashboard__admin__email"><a
          href="mailto:info@koraszulottmentor.hu">info@koraszulottmentor.hu</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content">
  <div
    ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id
        || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"
    class="row">
    <div class="col-xs-12">
      <div class="block block-themed">
        <div class="block-content">
          <div class="push-20">
            <a
              class="btn btn-success"
              ui-sref="newUserWithChild">
              <i class="fa fa-plus push-5-r"></i>Új mentorált és szülő felvitele
            </a>
            <a
              ui-sref="newChild"
              class="btn btn-success push-5-l" ui-sref="newChild">
              <i class="fa fa-plus push-5-r"></i> Új mentorált hozzáadása meglévő szülőhöz
            </a>
            <!-- hide it for a specific user -->
            <yco-button
              ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id &&
                     dashboard.user.id !== 65"
              ng-click="dashboard.processStatFile()"
              class="yco-dashboard__statistics-download btn btn-info push-5-l"
              type="button"
              yco-disabled="dashboard.statFileData.url.length"
              yco-is-processing="dashboard.showStatFileLoader">
              <i class="fa fa-line-chart push-5-r"></i> Havi statisztika előállítása
            </yco-button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div
    ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id"
    class="row">
    <div
      class="col-sm-6 col-lg-3"
      ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id
      ">
      <a class="block block-bordered">
        <table class="block-table text-center">
          <tbody>
          <tr>
            <td class="bg-gray-lighter border-r" style="width: 35%;">
              <div class="push-30 push-30-t">
                <i class="fa fa-wpforms fa-3x text-black-op"></i>
              </div>
            </td>
            <td style="width: 65%;">
              <div class="h1 font-w700">{{::dashboard.stats.allEnquiries}}</div>
              <div class="h5 text-muted text-uppercase push-5-t">Jelentkezés összesen</div>
            </td>
          </tr>
          </tbody>
        </table>
      </a>
    </div>
    <div class="col-sm-6 col-lg-3">
      <a class="block block-bordered">
        <table class="block-table text-center">
          <tbody>
          <tr>
            <td class="bg-gray-lighter border-r" style="width: 35%;">
              <div class="push-30 push-30-t">
                <i class="fa fa-check-circle fa-3x"></i>
              </div>
            </td>
            <td style="width: 65%;">
              <div class="h1 font-w700">{{::dashboard.stats.approvedEnquiries}}
              </div>
              <div class="h5 text-muted text-uppercase push-5-t">Elfogadott jelentkezés</div>
            </td>
          </tr>
          </tbody>
        </table>
      </a>
    </div>
    <div class="col-sm-6 col-lg-3">
      <a class="block block-bordered">
        <table class="block-table text-center">
          <tbody>
          <tr>
            <td class="bg-gray-lighter border-r" style="width: 35%;">
              <div class="push-30 push-30-t">
                <i class="fa fa-child fa-3x text-black-op"></i>
              </div>
            </td>
            <td style="width: 65%;">
              <div class="h1 font-w700">{{::dashboard.stats.allChildren}}</div>
              <div class="h5 text-muted text-uppercase push-5-t">Gyermek összesen</div>
            </td>
          </tr>
          </tbody>
        </table>
      </a>
    </div>
    <div class="col-sm-6 col-lg-3">
      <a class="block block-bordered">
        <table class="block-table text-center">
          <tbody>
          <tr>
            <td class="bg-gray-lighter border-r" style="width: 35%;">
              <div class="push-30 push-30-t">
                <i class="fa fa-check-circle fa-3x"></i>
              </div>
            </td>
            <td style="width: 65%;">
              <div class="h1 font-w700">{{::dashboard.stats.approvedEnquiryChildren}}</div>
              <div class="h5 text-muted text-uppercase push-5-t">Regisztrált gyermek</div>
            </td>
          </tr>
          </tbody>
        </table>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="yco-dashboard__unread-threads block block-bordered"
           ng-if="dashboard.user.roleId !== dashboard.userRoles.ADMIN.id">
        <div class="block-header">
          <ul class="block-options">
            <li>
              <a ui-sref="messages">Összes üzenet</a>
            </li>
          </ul>
          <h3 class="block-title">Olvasatlan üzenetváltások</h3>
        </div>
        <div class="block-content remove-padding">
          <table class="block-table text-center bg-gray-lighter">
            <tbody>
            <tr>
              <td>
                <div class="h1 font-w700">{{::dashboard.unreadThreads.length}}</div>
                <div class="h5 text-muted text-uppercase push-5-t">olvasatlan üzenetváltás</div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="text-center" ng-if="!dashboard.unreadThreads.length">
            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs olvasatlan üzenete.</div>
          </div>
          <div class="list-group" ng-if="dashboard.unreadThreads.length">
            <div class="yco-dashboard__unread-threads__list__item list-group-item"
                 ng-repeat="thread in dashboard.unreadThreads" ui-sref="messages({threadId: thread.id})">
              <p class="pull-right text-muted" title="{{::thread.createdAt | moment : 'YYYY.MM.DD. HH:mm:ss'}}">
                {{::thread.createdAt | fromNow}}
              </p>

              <h4 class="list-group-item-heading">{{thread.subject}}</h4>

              <p class="list-group-item-text push-5-t push-5">
                <small>
                  <i class="fa fa-fw push-5-r"
                     ng-class="{'fa-user': thread.participants.data.length === 1, 'fa-users': thread.participants.data.length > 1}"></i>
                  {{::thread.participants.data[0].user.data.lastName}}
                  {{::thread.participants.data[0].user.data.firstName}}
                  <span ng-if="thread.participants.data.length > 1">
                    és további {{::thread.participants.data.length - 1}} személy
                  </span>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="yco-dashboard__mentor-stats block block-bordered"
           ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>Teljes név</th>
            <th class="text-center">Összesen</th>
            <th class="text-center">Inaktív</th>
            <th class="text-center">Kitöltetlen</th>
            <th class="text-center">Hiányos</th>
            <th class="text-center">Kitöltött</th>
          </tr>
          </thead>
          <tbody>
          <tr ng-repeat="mentor in dashboard.mentorStats">
            <td>{{::mentor.fullName}}</td>
            <td class="text-center"><span>{{::mentor.sum}}</span></td>
            <td class="text-center"><span class="badge badge-warning">{{::mentor.inactive}}</span></td>
            <td class="text-center"><span class="badge badge-default">{{::mentor.unfilled}}</span></td>
            <td class="text-center"><span class="badge badge-danger">{{::mentor.deficient}}</span></td>
            <td class="text-center"><span class="badge badge-success">{{::mentor.filledOut}}</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      ng-if="dashboard.user.roleId === dashboard.userRoles.ADMIN.id
          || dashboard.user.roleId === dashboard.userRoles.MENTOR.id"
      class="col-md-6">
      <div class="block block-bordered">
        <div class="block-header">
          <ul class="block-options">
            <li>
              <a ui-sref="listEnquiries">Összes regisztrációs kérelem</a>
            </li>
          </ul>
          <h3 class="block-title">Új regisztrációs kérelmek</h3>
        </div>
        <div class="block-content remove-padding">
          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">
            <table class="table table-condensed" ng-if="dashboard.newEnquiries.length">
              <thead>
              <tr>
                <th>Szülő neve</th>
                <th>Státusz</th>
                <th>Regisztráció dátuma</th>
                <th class="text-center">Műveletek</th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="enquiry in dashboard.newEnquiries">
                <td>{{::enquiry.lastName}} {{::enquiry.firstName}}</td>
                <td ng-bind-html="dashboard.getEnquiryStatus(enquiry.status)"></td>
                <td>{{::enquiry.createdAt | moment : 'YYYY.MM.DD. HH:mm'}}</td>
                <td class="text-center">
                  <div class="btn-group">
                    <a class="btn btn-default btn-xs"
                       ui-sref="editEnquiry({enquiryId: enquiry.id})"
                       uib-tooltip="Szerkesztés">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="text-center" ng-if="!dashboard.newEnquiries.length">
            <table class="block-table text-center bg-gray-lighter">
              <tbody>
              <tr>
                <td>
                  <div class="h1 font-w700 ng-binding">0</div>
                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>
          </div>
        </div>
      </div>
    </div>
    <div
      ng-if="dashboard.user.roleId === dashboard.userRoles.DOCTOR.id"
      class="col-md-6">
      <div class="block block-bordered">
        <div class="block-header">
          <h3 class="block-title">Mentoráltjaim</h3>
        </div>
        <div class="block-content remove-padding">
          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">
            <table class="table table-condensed" ng-if="dashboard.childrenByDoctor.length">
              <thead>
              <tr>
                <th>Szülő neve</th>
                <th>Anyja neve</th>
                <th>Gyermek neve</th>
                <th>Születési dátuma</th>
                <th class="text-center">Műveletek</th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="child in dashboard.childrenByDoctor">
                <td>{{::child.parent.data.lastName}} {{::child.parent.data.firstName}}</td>
                <td>{{::child.motherName}}</td>
                <td>{{::child.lastName}} {{::child.firstName}}</td>
                <td>{{::child.dateOfBirth | moment : 'YYYY.MM.DD. HH:mm'}}</td>
                <td class="text-center">
                  <div class="btn-group">
                    <a class="btn btn-default btn-xs"
                       ui-sref="editChild({childId: child.id})"
                       uib-tooltip="Szerkesztés">
                      <i class="fa fa-pencil"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="text-center" ng-if="!dashboard.newEnquiries.length">
            <table class="block-table text-center bg-gray-lighter">
              <tbody>
              <tr>
                <td>
                  <div class="h1 font-w700 ng-binding">0</div>
                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>
          </div>
        </div>
      </div>
    </div>
    <div
      ng-if="dashboard.user.roleId === dashboard.userRoles.PARENT.id"
      class="col-md-6">
      <div class="block block-bordered">
        <div class="block-header">
          <h3 class="block-title">Mentor, Korai fejlesztők, Orvosok listája</h3>
        </div>
        <div class="block-content remove-padding">
          <div class="inner-push-t-20 inner-push-20-r inner-push-20 inner-push-20-l">
            <table class="table table-condensed" ng-if="dashboard.users.length">
              <thead>
              <tr>
                <th>Név</th>
                <th>Szerepkör</th>
                <th class="text-center">Műveletek</th>
              </tr>
              </thead>
              <tbody>
              <tr ng-repeat="user in dashboard.users">
                <td>{{::user.lastName}} {{::user.firstName}}</td>
                <td ng-bind-html="dashboard.getUserRoleById(user.roleId)"></td>
                <td class="text-center">
                  <div class="btn-group">
                    <a class="btn btn-default btn-xs push-5-r"
                       ui-sref="messages({userId: user.id})"
                       uib-tooltip="Üzenetküldés">
                      <i class="fa fa-envelope-o"></i>
                    </a>
                    <a class="btn btn-default btn-xs"
                       ui-sref="viewUser({userId: user.id})"
                       uib-tooltip="Megtekintés">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="text-center" ng-if="!dashboard.newEnquiries.length">
            <table class="block-table text-center bg-gray-lighter">
              <tbody>
              <tr>
                <td>
                  <div class="h1 font-w700 ng-binding">0</div>
                  <div class="h5 text-muted text-uppercase push-5-t">regisztrációs kérelem</div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="h4 text-muted push-20 push-20-t">Jelenleg nincs új regisztrációs kérelem.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
