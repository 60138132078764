(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoUser.controller:NewUserCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .controller('NewUserCtrl', NewUserCtrl);

  function NewUserCtrl(FormControl, User, $q, $state, UserRole, $stateParams, currentUser, userRoles, toastr, $scope) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('A felhasználói fiók sikeresen létrejött. Az első belépéshez ' +
      'szükséges információkat rendszerünk elküldte a megadott email címre.');

    vm.user = new User();
    vm.currentUser = currentUser.data;
    vm.user.roleId = $stateParams.role ? UserRole.getUserRoleBySlug($stateParams.role).id : undefined;
    vm.availableRoles = UserRole.getUserRoles();
    vm.canNavigate = false;

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      if (vm.user.roleId === vm.availableRoles.PARENT.id) {
        vm.user.status = 'approved';
      }

      formControl
        .process(deferred.promise)
        .then(function () {
          $state.go('listUsers');
        })
        .catch(function (err) {
          if (err.status === 422 && err.data.error.email.unique) {
            toastr.error('Ezzel az e-mail címmel már regisztráltak a rendszerben.')
          }
        });

      vm.user.$save(deferred.resolve, deferred.reject);
    };

    if (vm.currentUser.roleId === vm.availableRoles.MENTOR.id) {
      vm.possibleMentors.push(vm.currentUser);

      vm.user.mentorId = vm.currentUser.id;
    }

    vm.refreshPossibleMentors = function refreshPossibleMentors(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.MENTOR.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleMentors = response.data;
        });
    };
  }
}());
