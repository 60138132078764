/* global _ */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lodash.constant:lodash
   *
   * @description
   *
   */
  angular
    .module('lodash')
    .constant('_', _);
}());
