(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoMessage.controller:NewThreadCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoMessage')
    .controller('NewThreadCtrl', NewThreadCtrl);

  function NewThreadCtrl($uibModalInstance, User, FormControl, Thread, $q, toastr, Child, hasRecipient, recipient, $rootScope) {
    var vm = this
      , formControl = new FormControl();

    vm.thread = {};
    vm.form = {};
    vm.possibleParticipants = [];
    vm.possibleChildren = [];

    if (hasRecipient) {
      vm.hasRecipient = true;
      vm.possibleParticipants.push(recipient);
      vm.thread.participantIds = [recipient.id];
    }

    vm.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      if (vm.form.$invalid) {
        return;
      }

      formControl
        .process(deferred.promise);

      Thread.save(vm.thread)
        .$promise
        .then(function (response) {
          return Thread.addMessageToThread({id: response.data.id}, vm.thread);
        })
        .then(deferred.resolve, deferred.reject)
        .then(function () {
          vm.close();
          toastr.success('Az üzenetváltási szál sikeresen létrejött.');
          $rootScope.$broadcast('newThreadCreated');
        });
    };

    vm.refreshPossibleParticipants = function refreshPossibleParticipants(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParticipants = response.data;
        });
    };

    vm.refreshPossibleChildren = function refreshPossibleChildren(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , includes: 'parent'
      };

      return Child.query(options)
        .$promise
        .then(function (response) {
          vm.possibleChildren = response.data;
        });
    };
  }
}());
