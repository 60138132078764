(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ycoChild.controller:EditChildCtrl
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .controller('EditChildCtrl', EditChildCtrl);

  function EditChildCtrl(child, FormControl, _, userRoles, User, $q, Child, YcoDialog, $state, $scope, moment, user, $window, toastr, $rootScope, Auth, appConfig) {
    var vm = this
      , availableFile = {}
      , deferred
      , formControl = new FormControl();

    vm.form = {};
    vm.child = child.data;
    vm.availableRoles = userRoles;
    vm.loggedUser = user.data;
    vm.canNavigate = false;
    vm.possibleParents = [];
    vm.childDocuments = [];
    vm.newDocuments = [];
    vm.hasFile = false;
    $scope.fields = vm.child.fields;

    $window.onbeforeunload = function (event) {
      if (vm.form.$dirty && !vm.form.$submitted) {
        return 'Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?';
      }
    };

    $rootScope.$on('child.fieldWithAddByParentValueChanged', function (event, fieldId, container, targetNumber, fieldTemplate, targetFieldId) {
      var existingFields
        , numberOfExistingFields
        , parentFieldIndex
        , childFieldPredicate;

      childFieldPredicate = function (field) {
        return field.addByParentValue && field.parent === fieldId;
      };
      existingFields = _.filter(container, childFieldPredicate);
      numberOfExistingFields = existingFields.length;
      parentFieldIndex = _.findIndex(container, function (field) {
        return field.id === fieldId;
      });

      if (!_.isNumber(targetNumber) || targetNumber < 0) {
        return;
      }

      if (!targetNumber) {
        _.remove(container, childFieldPredicate);
        return;
      }

      if (numberOfExistingFields < targetNumber) {
        while (numberOfExistingFields < targetNumber) {
          container.splice(parentFieldIndex + numberOfExistingFields + 1, 0, generateFieldFromTemplate(targetFieldId || numberOfExistingFields, fieldTemplate));
          numberOfExistingFields++;
        }
      } else if (numberOfExistingFields > targetNumber) {
        if (targetFieldId) {
          _.remove(container, function (field) {
            return field.addByParentValue && field.parent === fieldId && _.endsWith(field.id, targetFieldId);
          });
        } else {
          while (numberOfExistingFields > targetNumber) {
            container.splice(parentFieldIndex + numberOfExistingFields, 1);

            numberOfExistingFields--;
          }
        }
      }
    });

    $scope.$on('$stateChangeStart', onStateChangeStart);

    function onStateChangeStart(event, toState) {
      if (vm.canNavigate || vm.form.$submitted) {
        return;
      }

      if (vm.form.$dirty) {
        vm.canNavigate = false;
        var r = confirm("Nem mentett módosítások el fognak veszni, biztosan elnavigálsz?");
        if (r === true) {
          vm.canNavigate = true;
          $state.go(toState);
        } else {
          event.preventDefault();
        }
      }
    }

    if (_.has(vm.child, 'parent.data')) {
      vm.possibleParents.push(vm.child.parent.data);
    }

    vm.child.parentId = _.get(vm.child, 'parent.data.id', null);

    formControl.setSuccessText('A gyermek sikeresen frissítve.');

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.dateOfBirth = moment(vm.child.dateOfBirth).toDate();

    vm.datePickerOptions = {
      minDate: moment('2010').toDate(),
      maxDate: new Date()
    };

    vm.dateFormat = 'yyyy-MM-dd';

    vm.updateEventDate = function (data) {
      vm.child.dateOfBirth = moment(data).format('YYYY-MM-DD HH:mm:ss');
    };

    vm.openDatePopup = function () {
      vm.popupDateIsOpen = true;
    };

    $scope.$watch(function () {
      return vm.dateTime;
    }, vm.updateEventDate);

    $scope.$watch(function () {
      if (moment(vm.dateOfBirth).year() >= 2010) {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', true);
      } else {
        vm.form['dateOfBirth'].$setValidity('minBirthYear', false);
      }
    }, vm.dateOfBirth);


    vm.submit = function submit() {
      var deferred = $q.defer()
        , childResource;

      if (vm.form.$invalid) {
        return;
      }
      vm.child.dateOfBirth = moment(vm.dateOfBirth).format('YYYY-MM-DD HH:mm:ss');
      vm.child.birthWeek = parseInt(vm.child.birthWeek, 10);
      vm.child.birthWeight = parseInt(vm.child.birthWeight, 10);
      childResource = _.assign(new Child(), vm.child, transformFields(_.assign({}, vm.child)));
      childResource.birthYear = moment(vm.child.dateOfBirth).year();

      formControl.process(deferred.promise)
        .then(function () {
          $state.go('viewChild', {childId: vm.child.id});
        })
        .catch(function (err) {
          if (err.data) {
            toastr.error(err.data.detail);
          }
        });
      childResource.$update(deferred.resolve, deferred.reject);
    };

    vm.refreshPossibleParents = function refreshPossibleParents(searchTerm) {
      var options;

      if (!searchTerm) {
        return null;
      }

      options = {
        limit: 10
        , search: searchTerm
        , roleIds: userRoles.PARENT.id
      };

      return User.query(options)
        .$promise
        .then(function (response) {
          vm.possibleParents = response.data;
        });
    };

    vm.showDeleteDialog = function showDeleteDialog() {
      formControl.setSuccessText('A gyermek adatlapot eltávolítottuk a rendszerből.');

      YcoDialog.show({
        title: 'Adatlap törlés',
        content: 'Biztosan törli az adatlapot? Törlés után az adatlaphoz köthető adatokat véglegesen eltávolítjuk rendszerünkből, mely művelet nem visszavonható.',
        actionButtonText: 'Törlés'
      }).then(function () {
        var deferred = $q.defer()
          , childResource = _.assign(new Child(), vm.child);

        formControl
          .process(deferred.promise)
          .then(function () {
            $state.go('listChildren');
          })
          .catch(function (err) {
            if (err.data) {
              toastr.error(err.data.detail);
            }
          });

        childResource.$delete(deferred.resolve, deferred.reject);
      });
    };

    vm.getParentField = function getParentField($index, container) {
      console.log(container[$index]);
    };

    vm.checkConditionalDisplay = function checkConditionalDisplay($index, container) {
      var currentField = container[$index]
        , dependencies = _.get(currentField, 'visibleIf')
        , shouldDisplay = true;

      if (!dependencies || _.isEmpty(dependencies)) {
        return shouldDisplay;
      }

      shouldDisplay = _.reduce(dependencies, function (acc, value, key) {
        acc = !!_.filter(container, function (field) {
          return field.id === key && field.value === value;
        }).length;

        return acc;
      }, shouldDisplay);

      return shouldDisplay;
    };

    function transformFields(childObject) {
      var outerFields = _.get(childObject, 'fields.data.fields')
        , groups = _.get(childObject, 'fields.data.groups');

      childObject.fields = {};

      if (!_.isEmpty(outerFields)) {
        childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(outerFields));
      }

      if (!_.isEmpty(groups)) {
        _.forEach(_.keys(groups), function (key) {
          var groupFields = _.get(groups[key], 'fields')
            , tabs = _.get(groups[key], 'tabs');

          if (!_.isEmpty(groupFields)) {
            childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(groupFields));
          }

          if (!_.isEmpty(tabs)) {
            _.forEach(groups[key].tabs, function (tab) {
              var tabFields = _.get(tab, 'fields');

              if (_.isEmpty(tabFields)) {
                return;
              }

              childObject.fields = _.assign(childObject.fields, getFieldValuesToObject(tabFields));
            });
          }
        });
      }

      return childObject;
    }

    /**
     * Iterates over an array of child fields, returning an object with field IDs as keys, and field values as values.
     *
     * @param {array} fields Child fields as an array
     * @returns {object} Child fields as an object
     */
    function getFieldValuesToObject(fields) {
      var fieldsArray = fields
        , fieldsObject;

      fieldsObject = fieldsArray.reduce(function (accumulator, field) {
        accumulator[field.id] = field.value;
        return accumulator;
      }, {});

      return fieldsObject;
    }

    function generateFieldFromTemplate(id, template) {
      var field = _.assign({}, template);

      field.id += id;

      return field;
    }

    /**
     * Shows whether a tab has untouched fields
     *
     * @param tab - Current tab
     * @returns {boolean} Current tab has unfilled fields
     */
    vm.hasMissingField = function markTab(tab) {
      return tab.fields.filter(function (item) {
        return item.parent === null && item.value === null
      }).length > 0;
    };

    /*
    File upload
     */
    vm.flowOptions = {
      target: appConfig.api.url + 'files/upload',
      headers: Auth.getAuthHeader()
    };

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise);
      $flow.upload();
    };

    vm.invalidateCoverImage = function invalidateCoverImage(fileName, $flow) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateCoverImage($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      YcoDialog.show({
        title: 'Hiba történt a fájl feltöltése során.',
        content: response.data.error
      }).then(function () {
        deferred.reject(response);
      });
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow) {
      /* jshint unused:false */
      /* eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      formControl.setSuccessText('A fájl sikeresen kiválasztva.');
      vm.hasFile = true;
      vm.newDocuments.push(response.data.id);
      deferred.resolve();
    };

    vm.cancelFile = function cancelFile($flow) {
      vm.hasFile = false;
      $flow.cancel();
    };

    vm.cancelPublicationAttachment = function cancelPublicationAttachment() {
      vm.attachment = '';
    };

    vm.getFileName = function getFileName() {
      if (!vm.attachment) {
        return false;
      }

      return vm.attachment.split('\\').pop().split('/').pop();
    };

    vm.getChildDocuments = function getChildDocuments() {
      Child.getFilesForChild({childId: vm.child.id}).$promise
        .then(function (data) {
          vm.childDocuments = data.data;
        });
    };

    vm.attachFilesToChild = function attachFilesToChild($flow) {
      Child.attachFilesToChild({childId: vm.child.id}, {
        fileIds: vm.newDocuments
      }).$promise
        .then(function () {
          toastr.success('A fájl adatlaphoz csatolása sikerült.');
          vm.newDocuments = [];
          vm.getChildDocuments();
          vm.cancelFile($flow);
        });
    };

    vm.deleteDocument = function deleteDocument(documentId) {
      console.log(documentId);
      Child.deleteFilesFromChild({
        childId: vm.child.id,
        fileIds: [documentId]
      }).$promise
        .then(function () {
          toastr.success('A fájl törlése sikerült.');
          vm.getChildDocuments();
        })
    };

    vm.getChildDocuments();
  }
}());
