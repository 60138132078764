(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoInputContainer
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-input-container></yco-input-container>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoInputContainer', ycoInputContainer);

  function ycoInputContainer() {
    return {
      restrict: 'E',
      template: '<div class="yco-input-container form-group" ng-transclude></div>',
      replace: true,
      transclude: true
    };
  }
}());
