(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoForm.directive:ycoMessages
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="ycoForm">
   <file name="index.html">
   <yco-messages form="formObject" field-name="fieldName"></yco-messages>
   </file>
   </example>
   *
   */
  angular
    .module('ycoForm')
    .directive('ycoMessages', ycoMessages);

  function ycoMessages() {
    var templateFolder = 'yco-form/';
    return {
      restrict: 'EA',
      scope: {
        fieldName: '@',
        form: '=',
        server: '='
      },
      templateUrl: templateFolder + 'directives/yco-messages-directive.tpl.html',
      replace: true,
      controllerAs: 'ycoMessages',
      bindToController: true,
      controller: function ($scope, _) {
        var vm = this;
        vm.messagesSrc = templateFolder + 'views/yco-messages-include.tpl.html';

        $scope.$watch(function () {
          return vm.server;
        }, function (newVal, oldVal) {
          var keyDiff = _.difference(_.keys(newVal), _.keys(oldVal));
          if (_.isEmpty(newVal) && _.isEmpty(keyDiff)) {
            return;
          }

          _.forEach(newVal, function (error, key) {
            _.forEach(error, function (isInvalid, errorType) {
              vm.form[key].$setValidity(errorType, !isInvalid);
            });
          });
        });

        $scope.$watch(function () {
          if (typeof vm.form[vm.fieldName] !== 'undefined') {
            return vm.form[vm.fieldName].$modelValue;
          }
        }, function (newVal, oldVal) {
          if (!_.isEmpty(vm.server)) {
            _.forEach(vm.server, function (error, key) {
              _.forEach(error, function (item, errorType) {
                vm.form[key].$setValidity(errorType, true);
                delete vm.server[key];
              });
            });
          }
        });
      }
    };
  }
}());
