(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoUser.constant:userRoles
   *
   * @description
   *
   */
  angular
    .module('ycoUser')
    .constant('userRoles', {
      ADMIN: {
        id: 1,
        name: {
          plural: 'Adminisztrátorok',
          singular: 'Adminisztrátor'
        },
        slug: {
          plural: 'admins',
          singular: 'admin'
        }
      },
      LEAD_DOCTOR: {
        id: 2,
        name: {
          plural: 'Orvos szakmai vezetők',
          singular: 'Orvos szakmai vezető'
        },
        slug: {
          plural: 'lead-doctors',
          singular: 'lead-doctor'
        }
      },
      DOCTOR: {
        id: 3,
        name: {
          plural: 'Orvosok',
          singular: 'Orvos'
        },
        slug: {
          plural: 'doctors',
          singular: 'doctor'
        }
      },
      MENTOR: {
        id: 4,
        name: {
          plural: 'Mentorok',
          singular: 'Mentor'
        },
        slug: {
          plural: 'mentors',
          singular: 'mentor'
        }
      },
      EARLY_DEV: {
        id: 5,
        name: {
          plural: 'Korai fejlesztők',
          singular: 'Korai fejlesztő'
        },
        slug: {
          plural: 'early-devs',
          singular: 'early-dev'
        }
      },
      PARENT: {
        id: 6,
        name: {
          plural: 'Szülők / Gondviselők',
          singular: 'Szülő / Gondviselő'
        },
        slug: {
          plural: 'parents',
          singular: 'parent'
        }
      }
    });
}());
