(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ycoChild.factory:Child
   *
   * @description
   *
   */
  angular
    .module('ycoChild')
    .factory('Child', Child);

  function Child(ycoResource) {
    var url = 'children/'
      , ChildBase;

    ChildBase = ycoResource('child', url + ':id', {id: '@id'}, {
      getLatestActivities: {
        url: url + ':id/activities',
        cache: false,
        params: {
          limit: -1
        }
      },
      getParticipants: {
        url: url + ':id/participants',
        cache: false,
        params: {
          limit: -1
        }
      },
      addParticipant: {
        url: url + ':childId/participants/:userId',
        method: 'POST'
      },
      removeParticipant: {
        url: url + ':childId/participants/:userId',
        method: 'DELETE'
      },
      createTextEntry: {
        url: url + ':childId/entries',
        method: 'POST'
      },
      searchBno: {
        url: 'bnos',
        method: 'GET'
      },
      getBnoById: {
        url: 'bnos/:bnoId',
        method: 'GET'
      },
      getFilesForChild: {
        url: url + ':childId/files',
        method: 'GET'
      },
      attachFilesToChild: {
        url: url + ':childId/files',
        method: 'POST'
      },
      deleteFilesFromChild: {
        url: url + ':childId/files/:fileIds',
        method: 'DELETE'
      }
    });

    return ChildBase;
  }
}());
