<yco-page-header>Összevont felhasználó létrehozás</yco-page-header>

<form name="newUserWithChild.form" ng-submit="newUserWithChild.submit()" novalidate>
  <div class="content ng-scope">
    <div class="col-md-8 col-md-offset-2">
      <div class="block block-bordered">
        <div class="block-header">
          <h3 class="block-title">Adatok</h3>
        </div>
        <div class="block-content tab-content">
          <uib-tabset active="newUserWithChild.activeTab" justified="true" class="block">
            <uib-tab index="0" heading="1. Email cím ellenőrzés" disable="true">
              <div class="col-md-6 col-md-offset-3 push-30-t">
                <div class="push-50">
                  <p>Írja be a regisztrálni kívánt email címet az alábbi mezőbe, majd kattintson az <em>Ellenőrzés</em>
                    gombra. Rendszerünk ezáltal ellenőrzi, hogy a regisztrálni kívánt email címmel létezik-e már
                    felhasználó a rendszerben.
                  </p>

                  <yco-input-container>
                    <label>Email</label>
                    <input class="form-control"
                           type="email"
                           name="email"
                           ng-disabled="newUserWithChild.isCheckEmailDisabled"
                           ng-model="newUserWithChild.user.email"
                           required>
                    <yco-messages form="newUserWithChild.form" server="newUserWithChild.getServerValidation()"
                                  field-name="email"></yco-messages>
                    <div class="block block-content text-center">
                      <yco-button ng-if="!newUserWithChild.canRegister"
                                  class="btn btn-info"
                                  type="button"
                                  yco-is-processing="newUserWithChild.isProcessing"
                                  ng-click="newUserWithChild.checkEmail()">
                        Ellenőrzés
                      </yco-button>
                      <button ng-if="newUserWithChild.canRegister" ng-click="newUserWithChild.goToNextTab()"
                              type="button" class="btn btn-success">Tovább
                      </button>
                    </div>
                  </yco-input-container>
                  <div ng-if="newUserWithChild.canRegister" class="alert alert-success">
                    <p>A megadott email cím még nincs használatban. A <strong>Tovább</strong> gomb megnyomásával
                      folytathatja a felhasználó létrehozást.</p>
                  </div>
                  <div ng-if="newUserWithChild.showWarning" class="alert alert-warning">
                    <h3 class="font-w300 push-15">Figyelem</h3>

                    <p>Ezzel az email címmel már létezik felhasználó a rendszerben. További teendők egyeztetése végett
                      kérjük,
                      lépjen kapcsolatba a projektirodával.</p>
                  </div>
                </div>
              </div>
            </uib-tab>
            <uib-tab index="1" heading="2. Szülő adatai" disable="true">
              <ng-form name="newUserWithChild.parentForm" novalidate>
                <div class="col-md-6 col-md-offset-3 push-30-t">
                  <div class="push-50">
                    <yco-input-container>
                      <label>Vezetéknév</label>
                      <input class="form-control"
                             type="text"
                             name="lastName"
                             ng-model="newUserWithChild.user.lastName"
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="lastName"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Keresztnév</label>
                      <input class="form-control"
                             type="text"
                             name="firstName"
                             ng-model="newUserWithChild.user.firstName"
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="firstName"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Email</label>
                      <input class="form-control"
                             type="text"
                             name="email"
                             ng-model="newUserWithChild.user.email"
                             disabled
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="email"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Telefonszám</label>
                      <input class="form-control"
                             type="text"
                             name="phone"
                             ng-model="newUserWithChild.user.phone"
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="phone"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Lakcím</label>
                      <input class="form-control"
                             type="text"
                             name="address"
                             ng-model="newUserWithChild.user.address"
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="address"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Település</label>
                      <input class="form-control"
                             type="text"
                             name="city"
                             ng-minlength="2"
                             ng-model="newUserWithChild.user.city"
                             required>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="city"></yco-messages>
                    </yco-input-container>

                    <yco-input-container>
                      <label>Hozzárendelt mentor</label>

                      <ui-select append-to-body="true"
                                 name="mentorId"
                                 class="yco-user__autocomplete__select"
                                 ng-disabled="newUserWithChild.currentUser.roleId === newUserWithChild.userRoles.MENTOR.id"
                                 ng-model="newUserWithChild.user.mentorId" required>
                        <ui-select-match allow-clear="false">
                          {{$select.selected.lastName}} {{$select.selected.firstName}}
                        </ui-select-match>
                        <ui-select-choices repeat="user.id as user in newUserWithChild.possibleMentors track by $index"
                                           refresh="newUserWithChild.refreshPossibleMentors($select.search)"
                                           refresh-delay="200"
                                           minimum-input-length="3">
                          <div class="inner-push-5 inner-push-5-t media">
                            <div class="media-left">
                              <img class="media-object img-avatar img-avatar32" ng-src="{{user.image}}"
                                   alt="{{user.lastName}} {{user.firstName}}">
                            </div>
                            <div class="media-right">
                              <h5 class="media-heading">{{user.lastName}} {{user.firstName}}</h5>

                              <div><span class="badge badge-default">{{user.roleId | userRole}}</span></div>
                            </div>
                            <div class="yco-newuser__autocomplete__suggestion__email">
                              <span>{{user.email}}</span>
                            </div>
                          </div>
                        </ui-select-choices>
                      </ui-select>
                      <yco-messages form="newUserWithChild.parentForm" server="newUserWithChild.getServerValidation()"
                                    field-name="mentorId"></yco-messages>
                    </yco-input-container>
                  </div>
                </div>
              </ng-form>
            </uib-tab>
            <uib-tab index="2" heading="3. Gyermekek adatai" disable="true">
              <ng-form name="newUserWithChild.childrenForm" novalidate>
                <table class="table table-bordered push-30-t">
                  <thead>
                  <tr>
                    <th>Vezetéknév</th>
                    <th>Keresztnév</th>
                    <th>Születési év</th>
                    <th>Születési hét</th>
                    <th>Születési súly</th>
                    <th>Törlés</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr ng-repeat="child in newUserWithChild.children">
                    <td>
                      <yco-input-container>
                        <input class="form-control mouseflow" type="text" name="lastName" ng-model="child.lastName"
                               ng-disabled="$index!==0"
                               required>
                        <yco-messages form="newUserWithChild.childrenForm"
                                      server="newUserWithChild.getServerValidation()"
                                      field-name="lastName"></yco-messages>
                      </yco-input-container>
                    </td>
                    <td>
                      <yco-input-container>
                        <input class="form-control mouseflow" type="text" name="firstName" ng-model="child.firstName"
                               ng-disabled="$index!==0"
                               required>
                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"
                                      field-name="firstName"></yco-messages>
                      </yco-input-container>
                    </td>
                    <td>
                      <yco-input-container>
                        <input class="form-control" type="number" name="birthYear" ng-model="child.birthYear"
                               min="2010-01-01" required
                               ng-disabled="$index!==0">
                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"
                                      field-name="birthYear"></yco-messages>
                      </yco-input-container>
                    </td>
                    <td>
                      <yco-input-container>
                        <input class="form-control" type="number" name="birthWeek" ng-model="child.birthWeek" required
                               ng-disabled="$index!==0">
                        <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"
                                      field-name="birthWeek"></yco-messages>
                      </yco-input-container>
                    </td>
                    <td>
                      <div class="input-group">
                        <yco-input-container>
                          <div class="input-group">
                            <input class="form-control" type="number" name="birthWeight" ng-model="child.birthWeight"
                                   ng-disabled="$index!==0"
                                   required>
                            <div class="input-group-addon">g</div>
                          </div>
                          <yco-messages form="newUserWithChild.childrenForm" server="newChild.getServerValidation()"
                                        field-name="birthWeight"></yco-messages>
                        </yco-input-container>
                      </div>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger"
                              ng-click="newUserWithChild.removeChildRow($index)">
                        Gyermek törlése
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-right">
                  <button type="button" class="btn btn-default" ng-click="newUserWithChild.addChildRow()"
                          ng-disabled="newUserWithChild.form.$invalid">Új Gyermek
                    hozzáadása
                  </button>
                </div>
              </ng-form>
            </uib-tab>
          </uib-tabset>
        </div>
        <div class="block-content block-content-mini block-content-full border-t">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-default"
                      type="button"
                      ng-disabled="newUserWithChild.activeTab <= 1"
                      ng-click="newUserWithChild.goToPrevTab()">
                <i class="fa fa-arrow-left"></i>
                Vissza
              </button>
            </div>
            <div class="col-md-6 text-right">
              <button class="btn btn-default"
                      type="button"
                      ng-disabled="newUserWithChild.isMoreButtonDisabled()"
                      ng-if="newUserWithChild.activeTab <= 1"
                      ng-click="newUserWithChild.goToNextTab()">
                Tovább <i class="fa fa-arrow-right"></i></button>
              <button class="btn btn-success"
                      type="submit"
                      ng-disabled="newUserWithChild.form.$invalid || newUserWithChild.children.length===0"
                      ng-if="newUserWithChild.activeTab === 2">
                Létrehozás
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
