(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name koraszulottmentorExtranet.filter:isEmpty
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('koraszulottmentorExtranet')
    .filter('isEmpty', isEmpty);

  function isEmpty(_) {
    return function (input) {
      return _.isEmpty(input);
    };
  }
}());
