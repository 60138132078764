(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name ycoChild.directive:booleanField
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="ycoChild">
       <file name="index.html">
        <boolean-field></boolean-field>
       </file>
     </example>
   *
   */
  angular
    .module('ycoChild')
    .directive('booleanField', booleanField);

  function booleanField() {
    return {
      restrict: 'EA',
      scope: {
        field: '=',
        readonly: '='
      },
      templateUrl: 'yco-child/directives/boolean-field-directive.tpl.html',
      replace: false,
      controllerAs: 'fieldCtrl',
      controller: function () {
        var vm = this;
        vm.name = 'booleanField';
      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
